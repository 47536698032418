<div class="flex h-full w-full flex-col gap-1 p-2 sm:gap-2">

  <div class="flex flex-wrap justify-between gap-1 border-b-2 border-sidebar px-4 py-2 sm:pr-2">
    <button class="default" (click)="onBackClicked()">
      <bizz-icon class="h-4 w-4" iconName="caret-left"></bizz-icon>
      <span>{{ 'Back' | translate}}</span>
    </button>
  </div>
  <div class="mx-4 flex grow flex-col gap-2.5 overflow-y-auto rounded bg-widget p-4">

    <div class="mb-3 flex flex-wrap justify-between gap-1 sm:pr-2">
      <div class="grid">
        <span class="widget-header">{{'History' | translate}}: {{ data.Caption }}</span>
        <span class="ml-1 hidden text-default sm:ml-2 sm:flex">{{'InstanceID' | translate}}: {{params.instanceId}} </span>
      </div>
      <div class="flex flex-grow items-center gap-2 sm:flex-grow-0">
        <button type="button" class="hidden px-1 py-1 sm:flex sm:px-2" (click)="export()">
          <bizz-icon class="icon-button size-5 sm:size-4" iconName="download" transform="grow-2"></bizz-icon>
        </button>
        
        <bizz-filter
                [collectionsId]="data.CollectionsID!"
                [filterType]="FilterItemType.History"
                [dropdownAccessible]="false"
                [active]="(gridResultData$ | async)?.activeFilter ?? false"
                (retrievedFilterData)="refreshFilterGrid($event)">
        </bizz-filter>

        <button type="button" class="mr-2 px-0 py-1 hover:text-blue-600 focus:text-blue-600 sm:hidden" (click)="toggleMobileActionsPopup($event)" #mobileActionsAnchor>
          <bizz-icon class="inline-block h-6 w-4 text-widget-button hover:cursor-pointer hover:text-blue-600 focus:text-blue-600" iconName="ellipsis-vertical"></bizz-icon>
        </button>
  
        <kendo-popup #propertiesPopup *ngIf="showMobileActionsPopup"
                     class="z-[1004] w-auto"
                     [anchor]="mobileAnchor"
                     [margin]="{ horizontal: 0, vertical: 10 }"
                     [collision]="{ horizontal: 'fit', vertical: 'fit' }"
                     [popupAlign]="{ horizontal: 'right', vertical: 'top' }"
                     [anchorAlign]="{ horizontal: 'right', vertical: 'bottom' }">
          <div class="bizz-popup-content">
            <button type="button" (click)="export()">
              <bizz-icon class="mr-2 h-5 w-5 sm:h-4 sm:w-4" iconName="download" transform="grow-2"></bizz-icon>
              Export
            </button>
          </div>
        </kendo-popup> 

      </div>
    </div>

    <bizz-grid class="bizz-grid flex h-full flex-col overflow-hidden" [currentPage]="currentPage"
      [gridOptions]="gridOptions" [gridData]="(gridData | async)!" [loading]="loading"
      [totalItems]="((gridResultData$ | async)?.totalItems ?? 0)"
      [gridCellMetadata]="{ trashFilterActive: isTrashView, collectionType, listOptions, isDocumentTreeGrid: false, userId, userType, managerTrainingQueue: false }"
      [autoFitColumns]="gridOptions.autoSize" (pageChange)="onPageChange($event)" (groupChange)="onGroupChange($event)"
      (sortChange)="onSortChange($event)"></bizz-grid>

  </div>

</div>