export enum CellActionType {
  OPEN_CONTEXT_MENU = 0,
  DOWNLOAD_FILE = 1,
  MAIL_VIEW = 2,
  TASK_EXECUTE = 3,
  TASK_FORWARD = 4,
  NOTIFICATION_READ = 5,
  NOTIFICATION_UNREAD = 6,
  TRAINING_QUEUE_SELECTED = 7,
  TRAINING_QUEUE_DELETE = 8,
  TRAINING_QUEUE_RECOVER = 9,
  VIEW_ONLINE = 10,
  CONSULT_RECORD = 11,
  NOTIFICATION_OPEN = 12,
  MAIL_EDIT = 13,
  MAIL_TRACK_CHANGES = 14,
  MAIL_DELETE_DRAFT = 15,
  MEMO_HYPERLINK_CLICKED = 16
}