<div class="flex h-svh w-svw flex-col items-center font-sans text-default">
    <bizz-header class="w-full" [mode]="2">
    </bizz-header>
    <bizz-loader class="w-full" [showLoader]="(init$ | async) ?? false" [fullscreen]="true"></bizz-loader>
    @if (exam$ | async; as examDetails) {
        <div class="flex h-16 w-full flex-col justify-center px-6 bg-page-header">
            <div class="gap-2.5 px-6 font-title text-3xl font-semibold hidden md:flex truncate">
                {{"Exam" | translate }}:&nbsp;{{ examDetails.Name }}
            </div>
            <div class="gap-2.5 px-6 font-title text-3xl font-semibold flex md:hidden truncate">
                {{ examDetails.Name }}
            </div>
            <hr class="mt-2 sm:hidden"/>
        </div>
        <div class="flex h-[calc(100%_-_3rem_-_4rem)] w-full flex-col items-center justify-start gap-4 bg-body-alternate sm:py-4">
            @if (pageConfig$.value.showTimeline) {
                <div class="my-2 flex w-full flex-col items-center sm:my-4" >
                    <span class="w-full text-center text-2xl font-bold"> 
                        {{ examDetails.Progress }} / {{ examDetails.TotalSessionQuestions }}
                    </span>
                        @if (questionsArray$ | async; as questionsArray) {
                        <kendo-stepper
                            class="w-full exams-stepper !hidden md:!grid"
                            [steps]="questionsArray"           
                            [currentStep]="examDetails.Progress - 1"
                            (activate)="onStepActivate($event)">
                            <ng-template kendoStepperStepTemplate let-step>
                                @if (step < examDetails.Progress) {
                                    <div class="custom-step w-5 h-5 rounded-full flex justify-center items-center bg-green-500"></div>
                                } @else if (step > examDetails.Progress) {
                                    <div class="custom-step w-5 h-5 rounded-full flex justify-center items-center bg-green-500">
                                        <div class="w-3 h-3 rounded-full bg-divider-handle"></div>
                                    </div>
                                } @else if (step === examDetails.Progress) {
                                    <div class="custom-step w-7 h-7 rounded-full flex justify-center items-center bg-white shadow-md">
                                        <div class="w-5 h-5 rounded-full bg-green-500"></div>
                                    </div>
                                }
                            </ng-template>
                        </kendo-stepper>
                    }                    
                </div>
            }

            <div class="flex h-full w-full flex-col items-center gap-10 overflow-y-auto bg-form px-7 pb-7 pt-2 shadow-md sm:h-5/6 sm:w-5/6 sm:rounded-lg sm:p-8">
                @switch (pageConfig$.value.pageContext) {
                    @case (TrainingExamPageComponentPageContext.COVER_PAGE) {
                        <bizz-training-exam-cover-content class="flex flex-1 w-full flex-col" (startExam)="startExam()"
                                                          [isLoading]="(isLoading$ | async) ?? false"
                                                          [exam]="examDetails"></bizz-training-exam-cover-content>

                    }
                    @case (TrainingExamPageComponentPageContext.QUESTION_PAGE) {
                        <bizz-training-exam-question-content class="flex flex-1 w-full flex-col" [isLoading]="(isLoading$ | async) ?? false"
                                                             [exam]="examDetails" (nextQuestion)="nextQuestion($event)"></bizz-training-exam-question-content>
                    }
                    @case (TrainingExamPageComponentPageContext.RESULT_PAGE) {
                        <bizz-training-exam-result-content class="flex flex-1 w-full flex-col"
                                                             [exam]="examDetails" (viewReport)="viewReport()"></bizz-training-exam-result-content>
                    }
                    @case (TrainingExamPageComponentPageContext.REPORT_PAGE) {
                        <bizz-training-exam-report-content class="flex flex-1 w-full flex-col"
                                                           [exam]="examDetails" (completed)="viewResult()"></bizz-training-exam-report-content>
                    }
                }
            </div>
        </div>
    }

</div>
