import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { formsFeature } from './forms-feature';
import { EffectsModule } from '@ngrx/effects';
import { FormsEffects } from './effects/forms-effects';
import { FormsValidationEffects } from './effects/forms-validation-effects';
import { FormsLinkedEffects } from './effects/forms-linked-effects';
import { FormsGridEffects } from './effects/forms-grid-effects';
import { FormsLockEffects } from './effects/forms-lock-effects';
import { FormsSchedulerEffects } from './effects/forms-scheduler-effects';
import { FormsScriptingEffects } from './effects/forms-scripting-effects';
import { FormsTaskEffects } from './effects/forms-task-effects';
import { FormsFieldsEffects } from './effects/forms-fields-effects';
import { FormsViewStackEffects } from './effects/forms-view-stack-effects.service';
import { FormsFormulaEffects } from './effects/forms-formula-effects';
import { FormsIntegrityEffects } from './effects/forms-integrity-effects';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(formsFeature),
    EffectsModule.forFeature(
      FormsEffects,
      FormsFieldsEffects,
      FormsGridEffects,
      FormsLinkedEffects,
      FormsLockEffects,
      FormsSchedulerEffects,
      FormsScriptingEffects,
      FormsFormulaEffects,
      FormsTaskEffects,
      FormsValidationEffects,
      FormsViewStackEffects,
      FormsIntegrityEffects
    ),
  ]
})
export class FormsFeatureStoreModule {
}
