import { Component, Input } from '@angular/core';
import { DatePipe, JsonPipe, NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule, DateTimePickerModule, TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { Operators } from '../../../../../../models/ts/operators.model';
import { IconComponent } from '../../../ui/icon/icon.component';
import { GridFilterDto } from '../../../../../../models/ts/grid-filter-dto.model';
import { FreeFieldType } from '../../../../../../models/ts/free-field-type.model';
import { FilterGroupType } from '../../../../../../models/ts/filter-group-type.model';
import { TableFieldDataType } from '../../../../../../models/ts/table-field-data-type.model';
import { Store } from '@ngrx/store';
import { selectUserSettingsFeature } from '../../../../../store/root-state-selectors';
import { ToggleArrowComponent } from '../../../ui/toggle-arrow/toggle-arrow.component';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { SkeletonModule } from '@progress/kendo-angular-indicators';
import { OrgChartSelectComponent } from '../../../../../features/bizzmine/org-chart/org-chart-select/org-chart-select.component';

@Component({
  selector: 'bizz-filter-modal-item',
  templateUrl: './filter-modal-item.component.html',
  styleUrls: ['./filter-modal-item.component.scss'],
  imports: [
    NgForOf,
    NgIf,
    NgClass,
    NgSwitch,
    FormsModule,
    NgSwitchCase,
    DatePickerModule,
    DateTimePickerModule,
    TimePickerModule,
    NgScrollbarModule,
    TranslatePipe,
    IconComponent,
    //KendoDatePipe,
    JsonPipe,
    ToggleArrowComponent,
    DropDownListModule,
    ReactiveFormsModule,
    TextBoxModule,
    SkeletonModule,
    OrgChartSelectComponent,
    DatePipe
  ],
  standalone: true
})

export class FilterModalItemComponent {
  @Input() public model: GridFilterDto;
  public collapseGroup = false;
  public collapsedIndexes: Array<number> = [];
  public userSettingsSignal = this.store$.selectSignal(selectUserSettingsFeature);
  public maxScrollHeightCollapsed: number = 0;
  //Enums
  protected readonly freeFieldType = FreeFieldType;
  protected readonly operators = Operators;
  protected readonly tableFieldDataType = TableFieldDataType;
  protected readonly filterGroupType = FilterGroupType;

  public constructor(private store$: Store) {
  }

  // Function to create new input fields of filter when Add button is clicked
  public newInput(groupIndex: number, fieldIndex: number, fieldId: number): void {
    const dupe = this.model.Groups[groupIndex].Fields.find(x => x.ID == fieldId)?.Values[fieldIndex];
    const myClonedObject = Object.assign({}, dupe);
    this.model.Groups[groupIndex].Fields.find(x => x.ID == fieldId)?.Values.push(myClonedObject);
  }

  // Function that manages collapsedIndexes array
  public collapseField(index: number): void {
    this.collapsedIndexes.includes(index + 1) ? this.collapsedIndexes.splice(this.collapsedIndexes.indexOf(index + 1), 1) : this.collapsedIndexes.push(index + 1);
    if (this.collapsedIndexes.length == this.model.Groups.length) {
      this.maxScrollHeightCollapsed = this.model.Groups.length * 64;
    } else {
      this.maxScrollHeightCollapsed = 0;
    }
  }

  // Function to delete input fields of filter
  public deleteInput(groupIndex: number, i: number, fieldId: number): void {
    const group = this.model.Groups[groupIndex];
    const fieldIndex = group.Fields.findIndex(x => x.ID === fieldId);
    group.Fields[fieldIndex].Values.splice(i, 1);
  }

  public makeKendoDate(input: string): string{
    return input.replace(/(MM)/g, 'MM').replace(/[^M]/g, char => char.toLowerCase());
  }

  public makeKendoDateTime(input: string): string {
    // Temporarily replace the patterns we want to preserve with placeholders
    const preservedmm = input.replace(/MM/g, '\x01');
    const preservedhhmma = preservedmm.replace(/hh:mm a/g, '\x02');
    const preservedhhmm = preservedhhmma.replace(/HH:mm/g, '\x03');

    // Convert all other characters to lowercase
    // eslint-disable-next-line no-control-regex
    const lowercased = preservedhhmm.replace(/[^Mmh:\x01\x02\x03]/g, char => char.toLowerCase());

    // Restore the preserved patterns
    // eslint-disable-next-line no-control-regex
    const restoredmm = lowercased.replace(/\x01/g, 'MM');
    // eslint-disable-next-line no-control-regex
    const restoredhhmmA = restoredmm.replace(/\x02/g, 'hh:mm a');
    // eslint-disable-next-line no-control-regex
    return restoredhhmmA.replace(/\x03/g, 'HH:mm');
  }


  public convertToDate(dateString: string | null): Date | null {
    return dateString ? new Date(dateString) : null;
    // Alternatively, using moment.js
    // return dateString ? moment(dateString).toDate() : null;
  }
}
