import { Component } from '@angular/core';
import { AlertComponent } from '../../../shared/components/ui/alert/alert.component';
import { AlertService } from 'src/app/features/bizzmine/alerts/alert.service';

@Component({
  selector: 'bizz-playground',
  standalone: true,
  imports: [
    AlertComponent
  ],
  templateUrl: './playground.component.html',
  styleUrl: './playground.component.scss'
})
export class PlaygroundComponent {
  
  public constructor(private alertService: AlertService) { }

  public generateAlert(type: 'success' | 'error' | 'warning' | 'info', icon: 'circle-exclamation' | 'triangle-exclamation' | 'circle-info' | 'circle-check'): void {
    this.alertService.setAlert({
      type: type,
      title: `This is a ${type} alert! ` + new Date().toLocaleString(),
      content: [new Error().stack!],
      icon: icon,
      dismissable: true,
      timed: false,
      timer: 5000,
    });

  }
}
