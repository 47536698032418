<div class="modal-container justify-between md:justify-normal">
  <div class="modal-close-container">
    <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0" (click)="dialogRef.close()" iconName="xmark"
      [hover]="true">
    </bizz-icon>
  </div>
  <div class="modal-body flex flex-col">
    <div class="modal-header">

      @if(tasks.length){
      <h1>
        {{tasks[0].Subject}}
      </h1>
    }
    </div>
    <hr class="my-4" />
    <div class="flex h-full w-full max-w-full flex-grow flex-col overflow-y-auto sm:max-w-[calc(min(80svw,1280px))]">
      <!-- Headers -->
      <!-- Width 1 - 5 -->
      <bizz-flow-status-tasks-grid [tasks]="tasks" [taskType]="FlowStatusTaskType.Detailed">

      </bizz-flow-status-tasks-grid>
    </div>
  </div>
  <div class="modal-buttons">
    <button class="save min-w-16" (click)="dialogRef.close(DialogModalButtons.Ok)">
      {{'Ok' | translate}}
    </button>
  </div>
</div>