import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CollectionFormLookupApiService } from '../../../../api/bizzmine/collection-form-lookup/collection-form-lookup-api.service';
import { LinkedCollectionType } from '../../../../../models/ts/linked-collection-type.model';
import { Observable } from 'rxjs/internal/Observable';
import { CollectionFormFieldSingleLookupData } from '../../../../../models/ts/collection-form-field-single-lookup-data.model';
import { CollectionFormFieldGridLookupData } from '../../../../../models/ts/collection-form-field-grid-lookup-data.model';
import { LookupService } from '../../../../shared/services/lookup/lookup.service';
import { StoreCollectionFormService } from './store-collection-form.service';
import { CollectionForm } from '../../../../../models/ts/collection-form.model';
import { formsActions } from '../../../../store/features/forms/forms-actions';
import { UpdateDeleteState } from '../../../../../models/ts/update-delete-state.model';
import { LookupItem } from '../../../../../models/ts/collection-list-summary-item.model';

/**
 * Service with a number of functions frequently used in forms with linked fields & grids.
 */
@Injectable({
  providedIn: 'root'
})
export class CollectionFormLinkedService {
  private store$ = inject(Store);
  private collectionFormLookupApiService = inject(CollectionFormLookupApiService);
  private storeCollectionFormService = inject(StoreCollectionFormService);

  //region Get LookupData
  // Identical to the grid record logic but returns a type specific to single records
  public getSingleRecordLookup(form: CollectionForm, viewDataSourceId: number, instanceId: number, versionId: number, crossLinkedInstanceId: number): Observable<CollectionFormFieldSingleLookupData> {
    const viewDataSource = form.ViewDataSources.find(vds => vds.ViewDataSourcesID == viewDataSourceId);
    if (viewDataSource !== undefined) {
      if (viewDataSource.ParentDataSourcesID != 0) {
        const snapshotViewDataSource = form.ViewDataSources.find(vds => vds.ParentDataSourcesID == viewDataSource.ViewDataSourcesID && vds.IsSnapshotRelation);
        if (snapshotViewDataSource !== undefined) {
          return this.getDeeperLevelLookupForInstance(form.CollectionFormId, viewDataSourceId, snapshotViewDataSource.CrossLinkCollectionsID, crossLinkedInstanceId, instanceId, versionId);
        } else throw new Error(`SnapshotViewDataSource not found.`);
      } else return this.getLookupForInstance(form.CollectionFormId, viewDataSourceId, instanceId, versionId);
    } else throw new Error(`ViewDataSource ${viewDataSourceId} not found.`);
  }

  // Identical to the single record logic but returns a type specific to grid records
  public getGridRecordLookup(form: CollectionForm, viewDataSourceId: number, instanceId: number, versionId: number, crossLinkedInstanceId: number): Observable<CollectionFormFieldGridLookupData> {
    const viewDataSource = form.ViewDataSources.find(vds => vds.ViewDataSourcesID == viewDataSourceId);
    if (viewDataSource !== undefined) {
      if (viewDataSource.ParentDataSourcesID != 0) {
        const snapshotViewDataSource = form.ViewDataSources.find(vds => vds.ParentDataSourcesID == viewDataSource.ViewDataSourcesID && vds.IsSnapshotRelation);
        if (snapshotViewDataSource !== undefined) {
            return this.getDeeperLevelGridLookupForInstance(form.CollectionFormId, viewDataSourceId, snapshotViewDataSource.CrossLinkCollectionsID, crossLinkedInstanceId, instanceId, versionId);
        } else throw new Error(`SnapshotViewDataSource not found.`);
      } else return this.getGridLookupForInstance(form.CollectionFormId, viewDataSourceId, instanceId, versionId);
    } else throw new Error(`ViewDataSource ${viewDataSourceId} not found.`);
  }

  public getLookupForInstance(formId: number, viewDataSourceId: number, instanceId: number, versionId: number): Observable<CollectionFormFieldSingleLookupData> {
    return this.collectionFormLookupApiService.getFormFieldValuesByLookupField(formId, viewDataSourceId, LinkedCollectionType.SingleRecord, instanceId, versionId) as Observable<CollectionFormFieldSingleLookupData>;
  }

  public getDeeperLevelLookupForInstance(formId: number, viewDataSourceId: number, crossLinkCollectionId: number, crossLinkInstanceId: number, instanceId: number, versionId: number): Observable<CollectionFormFieldSingleLookupData> {
    return this.collectionFormLookupApiService.getFormFieldValuesByDeepLookupField(formId, viewDataSourceId, LinkedCollectionType.SingleRecord, crossLinkCollectionId, crossLinkInstanceId, instanceId, versionId) as Observable<CollectionFormFieldSingleLookupData>;
  }

  public getGridLookupForInstance(formId: number, viewDataSourceId: number, instanceId: number, versionId: number): Observable<CollectionFormFieldGridLookupData> {
    return this.collectionFormLookupApiService.getFormFieldValuesByLookupField(formId, viewDataSourceId, LinkedCollectionType.GridRecord, instanceId, versionId) as Observable<CollectionFormFieldGridLookupData>;
  }

  public getDeeperLevelGridLookupForInstance(formId: number, viewDataSourceId: number, crossLinkCollectionId: number, crossLinkInstanceId: number, instanceId: number, versionId: number): Observable<CollectionFormFieldGridLookupData> {
    return this.collectionFormLookupApiService.getFormFieldValuesByDeepLookupField(formId, viewDataSourceId, LinkedCollectionType.GridRecord, crossLinkCollectionId, crossLinkInstanceId, instanceId, versionId) as Observable<CollectionFormFieldGridLookupData>;
  }

  //endregion

  public updateLookupFields(formId: string, form: CollectionForm, lookupData: CollectionFormFieldSingleLookupData): void {
    this.storeCollectionFormService.updateLookupFields(formId, form, lookupData);
  }

  public updateTrainingLookupFields(formId: string, lookupData: CollectionFormFieldSingleLookupData): void {
    this.storeCollectionFormService.linkExamTrainingInfo(formId, lookupData);
  }

  public updateTrainingViewDataSources(formId: string, lookupData: CollectionFormFieldSingleLookupData): void {
    this.storeCollectionFormService.linkTrainingViewDataSource(formId, lookupData);
    this.storeCollectionFormService.linkExamViewDataSources(formId, lookupData);

  }

  public clearChildLookupFields(formId: string, form: CollectionForm, viewDataSourceId: number): void {
    this.storeCollectionFormService.clearChildViewDataSourcesFieldsValue(formId, form, viewDataSourceId);
  }

  public clearLookup(formId: string, form: CollectionForm, viewDataSourceId: number): void {
    this.storeCollectionFormService.clearLookupFromForm(formId, structuredClone(form), viewDataSourceId);
  }

  public updateViewDataSourceInstance(formId: string, form: CollectionForm, viewDataSourceId: number, lookupItem: LookupItem, lookupData: CollectionFormFieldSingleLookupData): void {
    const viewDataSource = form.ViewDataSources.find(vds => vds.ViewDataSourcesID == viewDataSourceId);
    if (viewDataSource !== undefined) {
      if (viewDataSource.ParentDataSourcesID != 0) {
        lookupData.ViewDataSources.forEach(vds => {
          this.store$.dispatch(formsActions.updateFormViewDataSource({
            formId,
            viewDataSource: vds
          }));
        });
      } else {
        // Clone the ViewDataSource to update since the stored is immutable.
        const updatedViewDataSource = structuredClone(viewDataSource);
        // Replace the first (and only) instance in the viewDataSource
        // Due to how the API expects ID's and state to be handled, existing instances are partially overwritten instead of fully replaced.
        updatedViewDataSource.Instances = [{
          ChildInstancesID: lookupItem.InstancesID,
          ChildVersionsID: lookupItem.VersionsID,
          ParentVersionsID: 0,
          ParentInstancesID: 0,
          // If existing CrossLinkInstancesID you reuse, else use the one from the lookup, or if that one is 0, get the next lowest negative id.
          CrossLinkedInstancesID: updatedViewDataSource.Instances[0]?.CrossLinkedInstancesID > 0 ? updatedViewDataSource.Instances[0].CrossLinkedInstancesID : lookupItem.CrossLinkInstancesID == 0 ? LookupService.getLowestCrossLinkId(viewDataSource.Instances) : lookupItem.CrossLinkInstancesID,
          DataDesignCrossID: 0,
          OriginalChildInstancesID: lookupItem.OriginalChildInstancesID,
          State: updatedViewDataSource.Instances[0]?.CrossLinkedInstancesID > 0 ? UpdateDeleteState.Default : UpdateDeleteState.Update,
          PreviousChildVersionsID: updatedViewDataSource.Instances[0]?.ChildVersionsID ?? 0,
          RowDataDesignCrossID: updatedViewDataSource.Instances[0]?.RowDataDesignCrossID ?? -1,
          SnapshotCrosslinkInstancesID: 0
        }];

        this.store$.dispatch(formsActions.updateFormViewDataSource({
          formId,
          viewDataSource: updatedViewDataSource
        }));
      }
    } else throw new Error(`ViewDataSource ${viewDataSourceId} not found.`);
  }
}
