<div class="flex flex-col gap-2" [ngClass]="{'hidden' : storeField?.IsHidden}" tabIndex="-1">
    @if (displayCaption && !isGridControl) {
        <label class="flex text-sm font-semibold" [for]="storeField?.Id">
            <span>
                {{ storeField?.Caption }}
                @if (storeField?.IsRequired) {
                    <span class="relative -top-0.5 h-5 text-xl leading-4 text-red-400">*</span>
                }
                @if(errorState?.Message; as errorMessage){
                    <bizz-tooltip class="relative -top-0.5 h-5 text-xl leading-4 text-red-400 cursor-pointer inline-flex" [text]="errorMessage"></bizz-tooltip>
                }
            </span>
            @if (storeField && storeField.Hint) {
                <bizz-tooltip class="ml-1" [text]="storeField.Hint"></bizz-tooltip>
            }
        </label>
    }
    <div class="flex min-w-full" tabIndex="-1">
        <ng-container viewContainerRef>
        </ng-container>
    </div>
</div>
