<span class="flex items-center">
    @if(column.ExtensionDisplayType == ExtensionDisplayType.IconAndText || 
        column.ExtensionDisplayType == ExtensionDisplayType.Icon) {
        <bizz-extension-icon extension="{{value}}"></bizz-extension-icon>
    }
    @if(column.ExtensionDisplayType == ExtensionDisplayType.IconAndText ||
        column.ExtensionDisplayType == ExtensionDisplayType.Text) {
        <span class="ml-1.5 hidden text-sm font-normal text-widget sm:inline-block sm:text-xs">
            {{value}}
        </span>
    }
</span>
