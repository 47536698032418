import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { formsActions } from '../forms-actions';
import { exhaustMap, map, mergeMap, tap } from 'rxjs';
import { CollectionFormService } from '../../../../features/bizzmine/form/services/collection-form.service';
import { CollectionLockApiService } from '../../../../api/bizzmine/collection-lock/collection-lock-api.service';

/**
 * Effects for form locks
 */
@Injectable()
export class FormsLockEffects {
  private actions$ = inject(Actions);
  public lockDeleted$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.lockDeleted),
    map(({ formId, form }) => ({
        type: formsActions.updateForm.type,
        update: {
          id: formId,
          changes: CollectionFormService.toStoreCollectionForm(CollectionFormService.deleteLock(structuredClone(form)), formId)
        }
      })
    )
  ));
  public selfLockDeleted$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.selfLockDeleted),
    map(({ formId, form, close }) => {
        return close ?
          ({
            type: formsActions.formClosed.type,
            formId: formId
          }) : ({
            type: formsActions.updateForm.type,
            update: {
              id: formId,
              changes: CollectionFormService.toStoreCollectionForm(CollectionFormService.deleteLock(structuredClone(form)), formId)
            }
          });
      }
    )
  ));

  


  private collectionLockApiService = inject(CollectionLockApiService);
  public deleteLock$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.deleteLock),
    exhaustMap(({ formId, form }) => this.collectionLockApiService.deleteLock(form.CollectionsID, form.LocksID)
      .pipe(
        map(response => ({
          type: formsActions.lockDeleted.type,
          formId: formId,
          form: form
        }))
      )
    )
  ));
  public deleteSelfLock$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.deleteSelfLock),
    mergeMap(({ formId, form, close }) => this.collectionLockApiService.deleteSelfLock(form.LocksID)
      .pipe(
        map(response => ({
          type: formsActions.selfLockDeleted.type,
          formId: formId,
          form: form,
          close: close
        }))
      ), 3
    )
  ));
}