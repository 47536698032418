import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { formsActions } from '../forms-actions';
import { exhaustMap, filter, map, of, zip } from 'rxjs';
import { selectForm, selectFormField, selectFormFieldByPredicate } from '../forms-selectors';
import { Store } from '@ngrx/store';
import { FormulaService } from '../../../../features/bizzmine/form/services/formula/formula.service';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable()
export class FormsFormulaEffects {
  private actions$ = inject(Actions);
  private formulaService = inject(FormulaService);
  private store$ = inject(Store);
  public calculateFieldFormulas$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.calculateFormulaField),
    concatLatestFrom(({ formId, fieldId }) => this.store$.select(selectForm(formId))),
    map(([{ formId, fieldId }, form]) => {
      if (form && form.data)
        this.formulaService.calculateField(formId, structuredClone(form.data), fieldId);
    })
  ), { dispatch: false });
  public calculateFormFormulas$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.calculateFormulas),
    concatLatestFrom(({ formId }) => this.store$.select(selectForm(formId))),
    map(([{ formId }, form]) => {
      if (form && form.data)
        this.formulaService.calculate(formId, form.data);
    })
  ), { dispatch: false });
  public checkForFormula$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateFormFieldValue),
    exhaustMap(({ formId, fieldId }) => {
      return zip([
        of({ formId: formId, fieldId: fieldId }),
        this.store$.select(selectForm(formId)),
        this.store$.select(selectFormField(formId, fieldId))
      ]);
    }),
    // TODO: have BE add a property to field indicating which formula's a field belongs to so this check is more performant
    filter(([{
      fieldId
    }, form, field]) => form !== undefined && field !== undefined && FormulaService.fieldFormulas(form!.data.Formulas, fieldId).length > 0),
    map(([{ formId, fieldId }]) => ({
      type: formsActions.calculateFormulaField.type,
      formId: formId,
      fieldId: fieldId
    }))
  ));
  public checkForFormulaOnOutput$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateFormulaOutputField),
    exhaustMap(({ formId, collectionFieldId }) => {
      return zip([
        of({ formId: formId, collectionFieldId: collectionFieldId }),
        this.store$.select(selectForm(formId)),
        this.store$.select(selectFormFieldByPredicate(formId, field => field.CollectionFieldsID == collectionFieldId))
      ]);
    }),
    // TODO: have BE add a property to field indicating which formula's a field belongs to so this check is more performant
    filter(([{
      collectionFieldId
    }, form, field]) => form !== undefined && field !== undefined &&  FormulaService.fieldFormulas(form!.data.Formulas, field.Id).length > 0),
    map(([{ formId }, form, field]) => ({
      type: formsActions.calculateFormulaField.type,
      formId: formId,
      fieldId: field!.Id
    }))
  ));
}