import { CollectionForm } from '../../../../models/ts/collection-form.model';
import { EntityState } from '@ngrx/entity';
import { CollectionFormValidatorResult } from '../../../features/bizzmine/form/classes/collection-form-validator-result';
import { StoreCollectionFormOptions } from './interfaces/store-collection-form-options';
import { SchedulerDto } from 'src/models/ts/scheduler-dto.model';
import { SkillIntegrityCheck } from '../../../../models/ts/skill-integrity-check.model';

export type FormsState = EntityState<StoreCollectionForm>;

export class StoreCollectionForm {
  public id: string;
  public validationResult?: CollectionFormValidatorResult;
  public loading?: boolean;
  public reset?: boolean;
  public integrity?: Array<{fieldId:number, value: SkillIntegrityCheck}>;

  public constructor(public data: CollectionForm,
                     public options?: Partial<StoreCollectionFormOptions>,
                     public schedulerData?: SchedulerDto) {
    this.id = crypto.randomUUID();
  }

}
