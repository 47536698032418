import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { workspaceSidebarActions } from './workspace-sidebar-actions';
import { exhaustMap, map } from 'rxjs';
import { WorkspaceApiService } from '../../../api/bizzmine/workspace/workspace-api.service';
import { SidebarApiService } from '../../../api/bizzmine/sidebar/sidebar-api.service';

@Injectable()
export class WorkspaceSidebarEffects {
  public getSidebar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workspaceSidebarActions.getWorkspaceSidebar),
      exhaustMap(({ workspaceId }) =>
        this.sidebarApiService.getWorkspaceSidebar(workspaceId).pipe(
          map((result) => ({
            type: workspaceSidebarActions.setWorkspaceSidebar.type,
            workspaceSidebar: result,
          })),
        ),
      ),
    ),
  );

  public constructor(
    private actions$: Actions,
    private workspaceApiService: WorkspaceApiService,
    private sidebarApiService: SidebarApiService,
  ) {}
}
