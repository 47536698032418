import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuditLogGridSearchDto } from 'src/models/ts/audit-log-grid-search-dto.model';
import { GridDataDto } from 'src/models/ts/grid-data-dto.model';
import { GridOptionsDto } from 'src/models/ts/grid-options-dto.model';
import { AuditLogInfoDto } from 'src/models/ts/audit-log-info-dto.model';
import { PermissionHistoryGridSearchDto } from 'src/models/ts/permission-history-grid-search-dto.model';
import { AuditLogItemType } from 'src/models/ts/audit-log-item-type.model';
import { PermissionAccessExceptionDto } from 'src/models/ts/permission-access-exception-dto.model';

@Injectable({
  providedIn: 'root',
})
export class CollectionHistoryApiService {


  public constructor(private http: HttpClient) {

  }

  /**
    * Retrieves the gridoptions for the specified collectionsId.
    * @param collectionID
    */
  public getGridOptions(collectionId: number): Observable<GridOptionsDto> {
    return this.http.get<GridOptionsDto>(`api/history/collection/${collectionId}/gridoptions`);
  }
  /**
     * Gets the audit log history for a specific collection, instance and version based on search criteria.
     * @param collectionId
     * @param instanceId
     * @param versionId
     * @param searchModel
     */
  public getHistory(collectionId: number, instanceId: number, versionId: number, searchModel: Partial<AuditLogGridSearchDto>): Observable<GridDataDto> {
    return this.http.post<GridDataDto>(`api/history/collection/${collectionId}/instance/${instanceId}/version/${versionId}/search`, searchModel);
  }

  /**
   * Retrieves the audit log history information for a specific audit log entry.
   * @param auditLogsId
   * @param collectionId
   * @param instanceId
   * @param versionId
   */
  public getHistoryInfo(auditLogsId: number, collectionId: number, instanceId: number, versionId: number): Observable<AuditLogInfoDto> {
    return this.http.get<AuditLogInfoDto>(`api/history/${auditLogsId}/collection/${collectionId}/instance/${instanceId}/version/${versionId}/info`);
  }

  /**
   * Retrieves the history export data for a specific collection, instance, version, and search model.
   * @param collectionId - The ID of the collection.
   * @param instanceId - The ID of the instance.
   * @param versionId - The ID of the version.
   * @param searchModel - The search model containing the filters for the audit log grid.
   * @returns An Observable that emits the grid data for the history export.
   */
  public getHistoryExport(collectionId: number, instanceId: number, versionId: number, searchModel: Partial<AuditLogGridSearchDto>): Observable<ArrayBuffer> {
    return this.http.post(`api/history/collection/${collectionId}/instance/${instanceId}/version/${versionId}/export`, searchModel, {headers: {'Content-Type': 'application/json'}, responseType: 'arraybuffer'});
  }

  /**
   * Searches for permission history collection settings.
   * 
   * @param collectionId - The ID of the collection.
   * @param search - The search criteria.
   * @returns An Observable of type GridDataDto.
   */
  public searchPermissionhistoryCollectionSettings(collectionId: number, search: Partial<PermissionHistoryGridSearchDto>): Observable<GridDataDto> {
    return this.http.post<GridDataDto>(`settings/api/permissionhistory/collection/${collectionId}/search`, search);
  }
  

  /**
   * Searches for permission history audit log settings.
   * 
   * @param auditLogsId - The ID of the audit logs.
   * @param search - The search criteria for the permission history grid.
   * @returns An Observable that emits the grid data.
   */
  public searchPermissionhistoryAuditlogSettings(auditLogsId: number, search: Partial<PermissionHistoryGridSearchDto>): Observable<GridDataDto> {
    return this.http.post<GridDataDto>(`settings/api/permissionhistory/auditlog/${auditLogsId}/search`, search);
  }

  /**
   * Retrieves the grid options for permission history based on the specified item type.
   * @param itemType The type of item for which to retrieve the grid options.
   * @returns An Observable that emits the grid options for permission history.
   */
  public getPermissionHistoryGridOptions(itemType: AuditLogItemType): Observable<GridOptionsDto> {
    return this.http.get<GridOptionsDto>(`settings/api/permissionhistory/gridoptions/type/${itemType}`);
  }

  /**
   * Retrieves the details of a permission history entry.
   * @param permissionHistoryId - The ID of the permission history entry.
   * @returns An Observable that emits an array of PermissionAccessExceptionDto objects.
   */
  public getPermissionHistoryDetails(permissionHistoryId: number): Observable<PermissionAccessExceptionDto[]> {
    return this.http.get<PermissionAccessExceptionDto[]>(`settings/api/permissionhistory/${permissionHistoryId}/details`);
  }


}
