<div class="flex flex-row justify-between px-4 pt-2.5 pb-4">
    <div class=" ">
        {{ element.Caption }}
    </div>



    <button class="default hover:text-blue-500 group text-button" (click)="selectMailTemplate()" [disabled]="element.IsReadOnly">
                    <bizz-icon
                            class="header-popup-icon group-hover:text-blue-500 text-button"
                            iconName="envelope">
        </bizz-icon>
            {{ "CreateMail" | translate }}
    </button>
</div>

<div class="flex flex-row justify-between">

@if(gridOptions$ | async; as go){
    <bizz-grid class="bizz-grid flex h-full flex-col overflow-hidden rounded max-w-full"
               [currentPage]="filter$.value.Page"
               [loading]="isLoading"
               [gridOptions]="go"
               [totalItems]="totalItems"
               [autoFitColumns]="true"
               [gridData]="(data$ | async) ?? []"
               (cellAction)="onCellAction($event)"
               (pageChange)="onPageChange($event)"
               [showPager]="true"
               (sortChange)="onSortChange($event)"
               (groupChange)="onGroupChange($event)"
    >

    </bizz-grid>
}
</div>