import { Injectable } from '@angular/core';
import { TranslationService } from '../../../../../core/services/translation/translation.service';
import { ContextMenuItem } from '../classes/context-menu.item';
import { MenuAction } from '../classes/menu.action';
import { GridContextPermissions } from '../classes/grid-context-permissions';

@Injectable({
  providedIn: 'root',
})
export class ContextMenuItemService {

  public constructor(private translationService: TranslationService) {

  }

  /**
   * Returns the list of menuItems based on the permissions of the dataItem.
   * @private
   */
  public getMenuItems(permissions: GridContextPermissions, isMobileView: boolean, useSeparators: boolean): Partial<ContextMenuItem>[] {
    const menuItems: Partial<ContextMenuItem>[] = [];

    //in mobile view we need to add the cell actions too
    if (isMobileView) {
      if(permissions.showFolderShortcut) {
        menuItems.push({
          iconName: 'share',
          text: this.translationService.translate('FolderShortCut'),
          action: MenuAction.FolderShortcut,
        });
      }
      if(permissions.showForwardTask) {
        menuItems.push({
          iconName: 'share',
          text: this.translationService.translate('ForwardTask'),
          action: MenuAction.ForwardTask,
        });
      }
      if(permissions.showRead || permissions.showReadTrashCan) {
        menuItems.push({
          iconName: 'eye',
          text: this.translationService.translate('Read'),
          action: MenuAction.Read,
        });
      }
      if(permissions.showEdit) {
        menuItems.push({
          iconName: 'pencil',
          text: this.translationService.translate('Edit'),
          action: MenuAction.Edit,
        });
      }

    if(permissions.showExecuteSteps) {
      menuItems.push({
        iconName: 'bolt',
        text: this.translationService.translate('ExecuteSteps'),
        action: MenuAction.Execute,
      });
    }

    if(permissions.showRecover) {
      menuItems.push({
        iconName: 'recycle',
        text: this.translationService.translate('RecoverFromTrashCan'),
        action: MenuAction.RecoverInstance,
      });
    }

    if(permissions.showSubscribeToTraining) {
      menuItems.push({
        iconName: 'briefcase',
        text: this.translationService.translate('SubscribeToTraining'),
        action: MenuAction.SubscribeToTraining,
      });
    }

    if(permissions.showSubscribeToExam) {
      menuItems.push({
        iconName: 'graduation-cap',
        text: this.translationService.translate('SubscribeToExam'),
        action: MenuAction.SubscribeToExam,
      });
    }

    useSeparators && menuItems.push({ separator: true });
    }

    if (permissions.canConsultRecord) {
      menuItems.push({
        iconName: 'arrow-up-right-from-square',
        text: this.translationService.translate('ConsultRecord'),
        action: MenuAction.ConsultRecord
      });
    }

    if (permissions.showDuplicateExams) {
      menuItems.push({
        iconName: 'copy',
        text: this.translationService.translate('DuplicateExam'),
        action: MenuAction.DuplicateExam,
      });
    }

    if (permissions.showDownload || permissions.showViewOnline || permissions.showEditOnline) {
      if (permissions.showDownload) {
        menuItems.push({
          iconName: 'download',
          text: this.translationService.translate('DownloadFile'),
          action: MenuAction.DownloadFile,
        });
      }
      if (permissions.showViewOnline) {
        menuItems.push({
          iconName: 'eye',
          text: this.translationService.translate('ViewOnline'),
          action: MenuAction.ViewOnline,
        });
      }
      if (permissions.showEditOnline) {
        menuItems.push({
          iconName: 'pencil',
          text: this.translationService.translate('EditOnline'),
          action: MenuAction.EditOnline,
        });
      }

      if (permissions.viewFileHistory) {
        menuItems.push({
          iconName: 'clock',
          text: this.translationService.translate('FileHistory'),
          action: MenuAction.FileHistory,
        });
      }

      useSeparators && menuItems.push({ separator: true });
    }
    
    if (permissions.copyLinkToRecord || permissions.showDirectLink) {
      if (permissions.copyLinkToRecord) {
        menuItems.push({
          iconName: 'link',
          text: this.translationService.translate('CopyLinktoInstance'),
          action: MenuAction.CopyLinkToRecord,
        });
      }    
  
      if (permissions.showDirectLink) {
        menuItems.push({
          iconName: 'files',
          text: this.translationService.translate('CopyDirectLink'),
          action: MenuAction.CopyDirectLink,
        });
      }

      useSeparators && menuItems.push({ separator: true });
    }   

    if (permissions.showMinor || permissions.showMajor) {
      if (permissions.showMinor) {
        menuItems.push({
          iconName: 'm', //TODO: RV replace icon with lowercase m
          text: this.translationService.translate('CreateMinorChanges'),
          action: MenuAction.CreateMinorChanges,
        });
      }
  
      if (permissions.showMajor) {
        menuItems.push({
          iconName: 'm',
          text: this.translationService.translate('CreateMajorChanges'),
          action: MenuAction.CreateMajorChanges,
        });
      }
  
      useSeparators && menuItems.push({ separator: true });
    }    

    if (permissions.showCheckOut || permissions.showCheckIn) {
      if (permissions.showCheckOut) {
        menuItems.push({
          iconName: 'file-arrow-down',
          text: this.translationService.translate('CheckOutFile'),
          action: MenuAction.CheckoutFile,
        });
      }

      if (permissions.showCheckIn) {
        menuItems.push({
          iconName: 'file-arrow-up',
          text: this.translationService.translate('CheckInFile'),
          action: MenuAction.CheckInFile,
        });
      }

      if (permissions.showCheckIn) {
        menuItems.push({
          iconName: 'upload',
          text: this.translationService.translate('PutFileWithoutCheckIn'),
          action: MenuAction.PutFileWithoutCheckIn,
        });
      }

      if (permissions.showCheckIn) {
        menuItems.push({
          iconName: 'file-circle-minus',
          text: this.translationService.translate('UndoCheckoutFile'),
          action: MenuAction.UndoCheckoutFile,
        });
      }

      useSeparators && menuItems.push({ separator: true });
    }

    if (permissions.showProperties || permissions.showPermissions) {
      if (permissions.showProperties) {
        menuItems.push({
          iconName: 'gear',
          text: this.translationService.translate('DocumentSettings'),
          action: MenuAction.DocumentSettings,
        });
      }

      if (permissions.showPermissions) {
        menuItems.push({
          iconName: 'lock',
          text: this.translationService.translate('Permissions'),
          action: MenuAction.Permissions,
        });
      }

      useSeparators && menuItems.push({ separator: true });
    }

    if (permissions.showFlowStatus || permissions.showMonitor || permissions.showHistory || permissions.showTrackChanges || permissions.showArchive) {
      if (permissions.showFlowStatus) {
        menuItems.push({
          iconName: 'chart-line',
          text: this.translationService.translate('FlowStatus'),
          action: MenuAction.FlowStatus,
        });
      }
  
      if (permissions.showMonitor) {
        menuItems.push({
          iconName: 'display',
          text: this.translationService.translate('Monitor'),
          action: MenuAction.Monitor,
        });
      }
  
      if (permissions.showHistory) {
        menuItems.push({
          iconName: 'clock-rotate-left',
          text: this.translationService.translate('History'),
          action: MenuAction.History,
        });
      }
  
      if (permissions.showTrackChanges) {
        menuItems.push({
          iconName: 'clock-rotate-left',
          text: this.translationService.translate('TrackChanges'),
          action: MenuAction.TrackChanges,
        });
      }
  
      if (permissions.showArchive) {
        menuItems.push({
          iconName: 'box-archive',
          text: this.translationService.translate('Archive'),
          action: MenuAction.Archive,
        });
      }

      useSeparators && menuItems.push({ separator: true });
    }

    if (permissions.showRestartWorkflow) {
      menuItems.push({
        iconName: 'arrow-turn-down-left',
        text: this.translationService.translate('RestartWorkflow'),
        action: MenuAction.RestartWorkflow,
      });

      useSeparators && menuItems.push({ separator: true });
    }

    if (permissions.showReport) {
      menuItems.push({
        iconName: 'file-pdf',
        text: this.translationService.translate('DownloadReport'),
        action: MenuAction.Report,
      });
      useSeparators && menuItems.push({ separator: true });

    }

    if (permissions.showUnlink) {
      menuItems.push({
        iconName: 'link-slash',
        text: this.translationService.translate('UnlinkFromFolder'),
        cssClass: '*:!text-red-400',
        action: MenuAction.UnlinkFromFolder,
      });
    }

    if (permissions.showDelete || permissions.showDeleteFromFolder) {
      menuItems.push({
        iconName: 'xmark-large',
        text: this.translationService.translate('Delete'),
        cssClass: '*:!text-red-400',
        action: MenuAction.Delete,
      });
    }

   

    return menuItems;
  }
}
