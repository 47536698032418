import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BlobChunkDto } from '../../../../models/ts/blob-chunk-dto.model';
import { MediaCommitDto } from '../../../../models/ts/media-commit-dto.model';
import { MediaDto, MediaWithMeta } from '../../../../models/ts/media-dto.model';

@Injectable({
  providedIn: 'root'
})
export class MediaApiService {

  public constructor(private http: HttpClient) {

  }

  public downloadFile(collectionsId: number, instanceId: number, versionId: number): Observable<ArrayBuffer> {
    return this.http.get(
      `api/collection/${collectionsId}/instance/${instanceId}/version/${versionId}/file`, { responseType: 'arraybuffer' });
  }
  public downloadFileWithResponse(collectionsId: number, instanceId: number, versionId: number): Observable<HttpResponse<ArrayBuffer>> {
    return this.http.get(
      `api/collection/${collectionsId}/instance/${instanceId}/version/${versionId}/file`, { responseType: 'arraybuffer', observe: 'response' });
  }

  public downloadPdfFileWithResponse(collectionsId: number, instanceId: number, versionId: number): Observable<HttpResponse<ArrayBuffer>> {
    return this.http.get(
      `api/collection/${collectionsId}/instance/${instanceId}/version/${versionId}/pdffile`, { responseType: 'arraybuffer', observe: 'response' });
  }

  public downloadMailFile(request: {mailId: number, mediasId: number, name:string}): Observable<ArrayBuffer> {
    return this.http.post(
      `api/mail/${request.mailId}/media/${request.mediasId}/download`, JSON.stringify(request.name),{headers: {'Content-Type': 'application/json'}, responseType: 'arraybuffer'});
  }

  public uploadFileFromFormData(data: FormData): Observable<HttpEvent<BlobChunkDto>> {
    return this.http.post<BlobChunkDto>('api/media/upload', data, {
      reportProgress: true,
      observe: 'events',
    });
  }

  public commitMailAttachment(data: MediaCommitDto): Observable<number> {
    return this.http.post<number>('api/collectionupload/commitmailattachment', data);
  }

  public getMediaList(request: {mediaIds: Array<number>}): Observable<Array<MediaDto>>{
    return this.http.post<Array<MediaDto>>('api/media/mediaIds', request.mediaIds);
  }

  public getMediaWithMeta(request: {mediaIds: Array<number>}): Observable<Array<MediaWithMeta>>{
    return this.http.post<Array<MediaWithMeta>>('api/media/mediaWithMeta', request.mediaIds);
  }
}
