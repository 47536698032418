<div class="modal-container modal-lg">
    <div class="modal-close-container shrink-0">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="cancel()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>
    <div class="modal-body h-full">
        <div class="modal-header shrink-0">
            <h1>{{ 'OrganizationChart' | translate }}</h1>
        </div>
        <bizz-org-chart class="h-[calc(100%_-_2rem)]"
                        [selectedItems$]="selectedItems$"
                        [maxSelectedItems]="data.max"
                        [minSelectedItems]="data.min"
                        [allowedItemTypes]="data.allowedItemTypes"
                        [excludedUserIds]="data.excludedUserIds"
                        [additionalModes]="data.additionalModes"
                        [defaultView]="data.activeView"
        ></bizz-org-chart>
    </div>
    <div class="modal-buttons shrink-0">
        <button class="default" type="button" (click)="cancel()">{{ 'Cancel' | translate }}</button>
        <button class="save min-w-16" type="button" [disabled]="!validSelection" (click)="close()">{{ 'OK' | translate }}</button>
    </div>
</div>
