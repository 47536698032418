export enum ExecutorUserType {
  PredefinedList = 1,
  AssignedUsersFromPreviousStep = 3,
  UsersWhoExecutedPreviousStep = 4,
  OrganizationalItemFromCollectionFieldID = 6,
  AssignedExecutorsOfCurrentStep = 9,
  API = 11,
  EmailFromCollectionFieldID = 12,
  EmailAddress = 13,
  ExternalUsers = 14
}