import { Component, inject } from '@angular/core';
import { DialogModule } from '@angular/cdk/dialog';
import { ReminderPopupDto } from '../../../../../../../models/ts/reminder-popup-dto.model';
import { IconComponent } from '../../../icon/icon.component';
import { AsyncPipe, DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { PopupModule } from '@progress/kendo-angular-popup';
import { RouterLink } from '@angular/router';
import { NotificationBlockComponent } from '../notification-block/notification-block.component';
import { TranslatePipe } from '../../../../../pipes/translate/translate.pipe';
import { HeaderPopupListBase } from '../../classes/header-popup-list-base';
import { HeaderRemindersService } from '../../../../../services/header/header-reminders/header-reminders.service';
import { headerRemindersActions } from '../../../../../../store/features/header-reminders/header-reminders-actions';
import { headerRemindersFeature } from '../../../../../../store/features/header-reminders/header-reminders-feature';
import { LoaderComponent } from '../../../loader/loader.component';
import { FilterMenuModule } from '@progress/kendo-angular-grid';

/**
 * Reminders component that shows modal in header.
 */

@Component({
  selector: 'bizz-header-reminders',
  templateUrl: './header-reminders.component.html',
  styleUrls: ['./header-reminders.component.scss'],
  standalone: true,
  imports: [
    IconComponent,
    NgIf,
    PopupModule,
    DatePipe,
    RouterLink,
    NgForOf,
    DialogModule,
    NotificationBlockComponent,
    TranslatePipe,
    NgClass,
    LoaderComponent,
    AsyncPipe,
    FilterMenuModule
  ]
})
export class HeaderRemindersComponent extends HeaderPopupListBase<ReminderPopupDto> {
  public data = this.store$.selectSignal(headerRemindersFeature.selectReminders);
  public count = this.store$.selectSignal(headerRemindersFeature.selectCount);
  public loading = this.store$.selectSignal(headerRemindersFeature.selectLoading);
  private headerRemindersService = inject(HeaderRemindersService);


  public open(id: number): void {
    this.headerRemindersService.openReminder(id);
    this.togglePopup();
  }

  public refresh(): void {
    this.store$.dispatch(headerRemindersActions.fetchReminders());
  }

  public readReminders(): void {
    this.store$.dispatch(headerRemindersActions.readReminders({ ids: this.data().map(r => r.ID) }));
  }

  public readReminder(id: number): void {
    this.store$.dispatch(headerRemindersActions.readReminder({ id }));
  }

  public unreadReminder(id: number): void {
    this.store$.dispatch(headerRemindersActions.unreadReminder({ id }));
  }

  protected search(searchTerm: string): void {
    this.store$.dispatch(headerRemindersActions.updateSearch({ search: searchTerm }));
  }
}
