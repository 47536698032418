import { Injectable } from '@angular/core';
import { CollectionForm } from '../../../../../models/ts/collection-form.model';
import { CollectionFormField } from '../../../../../models/ts/collection-form-field.model';
import { CollectionFormService } from './collection-form.service';
import { UpdateDeleteState } from '../../../../../models/ts/update-delete-state.model';
import { ViewDataSource } from '../../../../../models/ts/view-data-source.model';
import { ViewDataSourcesInstance } from '../../../../../models/ts/view-data-sources-instance.model';
import { LinkedCollectionType } from '../../../../../models/ts/linked-collection-type.model';

/**
 * Service for converting CollectionForm objects to JSON. Functions return plain objects formatted as the back end expects.
 */
@Injectable({
  providedIn: 'root'
})
export class CollectionFormJsonService {

  /**
   * Returns a forms JSON representation
   * @param {CollectionForm} form
   * @return {Object}
   */
  public static formToJson(form: CollectionForm): Object {
    let formJson: Object = {};
    // Base form fields
    Object.assign(formJson, CollectionFormJsonService.baseFieldsToJson(form));
    // Fixed properties
    Object.assign(formJson, CollectionFormJsonService.formPropertiesToJson(form));
    // Relations (ViewDataSources)
    Object.assign(formJson, CollectionFormJsonService.viewDataSourcesToJson(form));
    return formJson;
  }

  /**
   * Returns a fields JSON representation.
   * @param {CollectionFormField} field
   * @return {Object}
   */
  public static fieldToJson(field: CollectionFormField): Object {
    return { [field.Bookmark]: CollectionFormService.getValueForField(field) };
  }

  /**
   * Returns an array of JSON representation of all fields not part of a relation.
   * @param {CollectionForm} form
   * @return {Object}
   */
  public static baseFieldsToJson(form: CollectionForm): Object {
    const fields = CollectionFormService.getFields(form, field => field.ViewDataSourcesID == 0 && !CollectionFormService.fieldIsGrid(field));
    let jsonFields: Object = {};
    fields.forEach(field => {
      Object.assign(jsonFields, CollectionFormJsonService.fieldToJson(field));
    });
    return jsonFields;
  }

  /**
   * Returns an array of JSON representation of a number of form properties.
   * @param {CollectionForm} form
   * @return {Object}
   */
  public static formPropertiesToJson(form: CollectionForm): Object {
    const propertiesObject = {
      DocumentProperties: form.DocumentProperties,
      InheritType: form.InheritType,
      DisplayFieldsInheritType: form.DisplayFieldsInheritType,
      ExamSubscriptions: form.ExamSubscriptions,
      TrainingAssessment: form.TrainingAssessment
    };
    // TODO: Back end should handle 0 value correctly. (CollectionJsonMapper => AddFieldsToBaseCollectionsModel => ?m.IsLookup = true?)
    if (form.VersionsID > 0)
      Object.assign(propertiesObject, { versionsID: form.VersionsID });
    return propertiesObject;

  }

  /**
   * Returns the JSON representation of all ViewDataSources in given form.
   * @param {CollectionForm} form
   * @return {Object}
   */
  public static viewDataSourcesToJson(form: CollectionForm): Object {
    let viewDataSourcesJson: Object = {};
    // Only ViewDataSources that are linked to the form collection and are not snapshots are useful for JSON
    form.ViewDataSources.filter(vds => !vds.IsSnapshotRelation && vds.ParentCollectionsID == form.CollectionsID && vds.ParentDataSourcesID == 0).forEach(viewDataSource => {
      Object.assign(viewDataSourcesJson, CollectionFormJsonService.viewDataSourceToJson(form, viewDataSource));
    });
    return viewDataSourcesJson;
  }

  /**
   * Returns the JSON representation of a ViewDataSource.
   * @param {CollectionForm} form
   * @param {ViewDataSource} viewDataSource
   * @return {Object}
   */
  public static viewDataSourceToJson(form: CollectionForm, viewDataSource: ViewDataSource): Object {
    if (viewDataSource.ParentDataSourcesID == 0) {
      return { [viewDataSource.Name]: CollectionFormJsonService.viewDataSourceInstancesToJson(form, viewDataSource) };
    } else {
      return { [viewDataSource.Name]: CollectionFormJsonService.deeperLevelViewDataSourceInstancesToJson(form, viewDataSource) };
    }
  }

  /**
   * Returns instances belonging to a deeper level VDS.
   * @param {CollectionForm} form
   * @param {ViewDataSource} viewDataSource
   * @return {Object}
   */
  public static deeperLevelViewDataSourceInstancesToJson(form: CollectionForm, viewDataSource: ViewDataSource): Object[] {
    // Instances are kept in the snapshot ViewDataSource linked to the current ViewDataSource
    const snapShotViewDataSource = form.ViewDataSources.find(
      vds => vds.IsSnapshotRelation && vds.ParentDataSourcesID == viewDataSource.ViewDataSourcesID
    );
    if (snapShotViewDataSource) {
      let viewDataSourceInstancesJson: Object[] = [];
      snapShotViewDataSource.Instances.forEach(instance => {
        let viewDataSourceInstance = {};
        // Fields
        Object.assign(viewDataSourceInstance, CollectionFormJsonService.viewDataSourceInstanceFieldsToJson(form, viewDataSource, instance));
        // Properties
        Object.assign(viewDataSourceInstance, CollectionFormJsonService.viewDataSourceInstancePropertiesToJson(viewDataSource, instance));
        // Children
        Object.assign(viewDataSourceInstance, CollectionFormJsonService.viewDataSourceChildrenToJson(form, snapShotViewDataSource));
        viewDataSourceInstancesJson.push(viewDataSourceInstance);
      });
      return viewDataSourceInstancesJson;
    } else return [];
  }

  /**
   * Returns a ViewDataSource's instances in JSON representation.
   * @param {CollectionForm} form
   * @param viewDataSource
   * @return {Object[]}
   */
  public static viewDataSourceInstancesToJson(form: CollectionForm, viewDataSource: ViewDataSource): Object[] {
    let viewDataSourceInstancesJson: Object[] = [];
    viewDataSource.Instances.forEach(instance => {
      let viewDataSourceInstance = {};
      // Fields
      Object.assign(viewDataSourceInstance, CollectionFormJsonService.viewDataSourceInstanceFieldsToJson(form, viewDataSource, instance));
      // Properties
      Object.assign(viewDataSourceInstance, CollectionFormJsonService.viewDataSourceInstancePropertiesToJson(viewDataSource, instance));
      // Children
      Object.assign(viewDataSourceInstance, CollectionFormJsonService.viewDataSourceChildrenToJson(form, viewDataSource));
      viewDataSourceInstancesJson.push(viewDataSourceInstance);
    });
    return viewDataSourceInstancesJson;
  }

  /**
   * Returns the JSON representation of a ViewDataSource's children.
   * @param {CollectionForm} form
   * @param {ViewDataSource} viewDataSource
   * @return {Object}
   */
  public static viewDataSourceChildrenToJson(form: CollectionForm, viewDataSource: ViewDataSource): Object {
    let childViewDataSources = form.ViewDataSources.filter(vds => vds.ParentDataSourcesID == viewDataSource.ViewDataSourcesID);
    if (childViewDataSources.length > 0) {
      let childViewDataSourcesJson: Object = {};
      childViewDataSources.forEach(childViewDataSource => {
        Object.assign(childViewDataSourcesJson, CollectionFormJsonService.viewDataSourceToJson(form, childViewDataSource));
      });
      return childViewDataSourcesJson;
    } else return {};
  }

  /**
   * Returns the JSON representation of form fields belonging to given instance.
   * @param {CollectionForm} form
   * @param {ViewDataSource} viewDataSource
   * @param {ViewDataSourcesInstance} instance
   * @return {Object}
   */
  public static viewDataSourceInstanceFieldsToJson(form: CollectionForm, viewDataSource: ViewDataSource, instance: ViewDataSourcesInstance): Object {
    let fields = CollectionFormService.getFields(form, field => field.ViewDataSourcesID == viewDataSource.ViewDataSourcesID);
    let jsonFields: Object = {};
    if (viewDataSource.SingleOrMany == LinkedCollectionType.SingleRecord) {
      fields.forEach(field => {
        Object.assign(jsonFields, CollectionFormJsonService.fieldToJson(field));
      });
      return jsonFields;
    } else {
      // TODO: replace with IsGridField once available.
      let gridField = fields.find(field => CollectionFormService.fieldIsGrid(field));
      if (gridField !== undefined) {
        let record = gridField.Records?.find(record => record.CrossLinkedInstancesID == instance.CrossLinkedInstancesID);
        if (record) {
          record.Fields.forEach(field => {
            Object.assign(jsonFields, CollectionFormJsonService.fieldToJson(field));
          });
        }
        return jsonFields;
      }
    }
    return {};
  }

  /**
   * Returns the JSON representation of a VDS instance properties.
   * @param {ViewDataSource} viewDataSource
   * @param instance
   * @return {Object}
   */
  public static viewDataSourceInstancePropertiesToJson(viewDataSource: ViewDataSource, instance: ViewDataSourcesInstance): Object {
    return {
      ViewDataSourcesID: viewDataSource.ViewDataSourcesID,
      crossLinkedInstancesID: instance.CrossLinkedInstancesID,
      deleteIfExists: instance.State == UpdateDeleteState.Delete,
      originalChildInstancesID: instance.OriginalChildInstancesID,
      previousChildVersionsID: instance.PreviousChildVersionsID,
      rowDataDesignCrossID: instance.RowDataDesignCrossID,
      versionsID: instance.ChildVersionsID
    };
  }
}
