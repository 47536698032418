import { DIALOG_DATA, Dialog, DialogRef } from '@angular/cdk/dialog';
import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { ReportTileListDto } from 'src/models/ts/report-tile-list-dto.model';
import { ModalComponent } from '../modal/modal.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { ThemeSelectorButtonComponent } from '../../theme-selector-button/theme-selector-button.component';
import { ReportInListDto } from 'src/models/ts/report-in-list-dto.model';
import { ReportApiService } from 'src/app/api/bizzmine/report/report-api.service';
import { ReportDto } from 'src/models/ts/report-dto.model';
import { exhaustMap, take } from 'rxjs';
import { ExtensionIconComponent } from '../../ui/icon/extension-icon/extension-icon.component';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IconComponent } from '../../ui/icon/icon.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemeDto } from 'src/models/ts/theme-dto.model';
import { ThemeSelectorModalComponent } from '../user-settings-modal/theme-selector-modal/theme-selector-modal.component';
import { CommonModule } from '@angular/common';
import { UnitType } from 'src/models/ts/unit-type.model';
import { Store } from '@ngrx/store';
import { selectUserSettingsFeature } from 'src/app/store/root-state-selectors';
import { ThemeSelectorControlComponent } from '../../forms/controls/theme-selector-control/theme-selector-control.component';
import { selectByCollectionId } from 'src/app/store/features/collections/collections-selectors';
import { CollectionStore } from 'src/app/store/features/collections/collections-state';
import { LoaderButtonComponent } from '../../loader-button/loader-button/loader-button.component';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { GridFilterDto } from 'src/models/ts/grid-filter-dto.model';
import { AlertService } from 'src/app/features/bizzmine/alerts/alert.service';
import { TranslationService } from 'src/app/core/services/translation/translation.service';

@Component({
  selector: 'bizz-report-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TranslatePipe,
    ThemeSelectorButtonComponent,
    ExtensionIconComponent,
    DropDownListModule,
    IconComponent,
    FormsModule,
    ReactiveFormsModule,
    ThemeSelectorControlComponent,
    LoaderButtonComponent,
    DropDownsModule
  ],
  templateUrl: './report-modal.component.html',
  styleUrl: './report-modal.component.scss'
})
export class ReportModalComponent implements OnInit {
  public activeReport: ReportInListDto;
  public activeReportDetails: ReportDto;
  public isLoading: boolean = true;
  public isGenerating: boolean = false;
  public reportForm: FormGroup;

  public userSettingsSignal = this.store$.selectSignal(selectUserSettingsFeature);
  public unitType: UnitType = this.userSettingsSignal().UnitType;
  public widgetData: CollectionStore;

  protected readonly UnitType = UnitType;

  public constructor(public dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: { collectionId: number, instanceId: number, versionId: number, listId: number, folderId: number, reportList: ReportTileListDto },
    private reportApiService: ReportApiService,
    private dialog: Dialog,
    private destroyRef: DestroyRef,
    private fb: FormBuilder,
    private store$: Store,
    private downloadService: DownloadService,
    private alertService: AlertService,
    private translationService: TranslationService
  ) {
  }


  public ngOnInit(): void {
    if (this.data.reportList.Reports?.length) {
      this.activeReport = this.data.reportList.Reports[0];
      this.loadDetails();
    } else {
      //No reports
      this.isLoading = false;
    }
  }

  private buildForm(data: ReportDto): FormGroup {
    return this.fb.group({
      ActiveReport: new FormControl(this.activeReport.ID),
      PageSize: new FormControl(data.Options.Size),
      TopMargin: new FormControl(this.roundToTwoDecimals(data.Options.TopMargin)),
      BottomMargin: new FormControl(this.roundToTwoDecimals(data.Options.BottomMargin)),
      LeftMargin: new FormControl(this.roundToTwoDecimals(data.Options.LeftMargin)),
      RightMargin: new FormControl(this.roundToTwoDecimals(data.Options.RightMargin)),
      CanPrint: new FormControl(data.Security.CanPrint),
      CanEdit: new FormControl(data.Security.CanEdit),
      CanCopy: new FormControl(data.Security.CanCopy),
      Orientation: new FormControl(data.Options.Orientation),
    });

  }
  public roundToTwoDecimals(num: number): number {
      return parseFloat(num.toFixed(2));
  }

  public loadDetails(): void {
    this.reportApiService.getDetails(this.activeReport.ID).pipe(
      take(1),
      exhaustMap((report: ReportDto) => {
        this.activeReportDetails = report;
        return this.store$.select(selectByCollectionId(this.activeReportDetails.CollectionsID)).pipe(take(1));
      }),
    ).subscribe({
      next: (res) => {
        this.widgetData = res as CollectionStore;
        this.reportForm = this.buildForm(this.activeReportDetails);
        this.isLoading = false;
      },
      error: (): void => {
        this.isLoading = false;
      }
    }
    );
  }

  public setActiveReport(report: ReportInListDto): void {
    this.activeReport = report;
    this.loadDetails();
  }

  public getSizeLabel(): string {
    return this.activeReportDetails.Options.Sizes[this.reportForm.get('PageSize')?.value - 1].Text;
  }

  public openThemeSelectorModal(): void {
    if(!this.isGenerating){
    const dialogRef = this.dialog.open<ThemeDto>(ThemeSelectorModalComponent,
      {
        data: {
          currentTheme: this.activeReportDetails.Themes.find(theme => theme.ID === this.activeReportDetails.ThemesID),
          themes: this.activeReportDetails.Themes,
          showFullPreviewIcons: false,
          applyStyle: false
        }
      });

    dialogRef.closed.subscribe({
      next: (chosenTheme: ThemeDto | undefined) => {
        if (chosenTheme) {
          this.activeReportDetails.ThemesID = chosenTheme.ID;
        }
      }
    });
  }
  }

  public submitForm(): void {
    this.isGenerating = true;
    //Set the values of the form to the active report details
    this.activeReportDetails.Options.Size = this.reportForm.get('PageSize')?.value;
    this.activeReportDetails.Options.TopMargin = this.reportForm.get('TopMargin')?.value;
    this.activeReportDetails.Options.BottomMargin = this.reportForm.get('BottomMargin')?.value;
    this.activeReportDetails.Options.LeftMargin = this.reportForm.get('LeftMargin')?.value;
    this.activeReportDetails.Options.RightMargin = this.reportForm.get('RightMargin')?.value;
    this.activeReportDetails.Security.CanPrint = this.reportForm.get('CanPrint')?.value;
    this.activeReportDetails.Security.CanEdit = this.reportForm.get('CanEdit')?.value;
    this.activeReportDetails.Security.CanCopy = this.reportForm.get('CanCopy')?.value;
    this.activeReportDetails.Options.Orientation = this.reportForm.get('Orientation')?.value;
    this.activeReportDetails.SearchFilter = { active: false } as GridFilterDto;

    if (this.data.collectionId !== undefined && this.data.instanceId !== undefined && this.data.versionId !== undefined) {
      this.reportApiService.exportReportPdf(this.activeReport.ID, this.data.instanceId, this.data.versionId, this.activeReportDetails).pipe(
        take(1)).subscribe({
          next: (file: ArrayBuffer) => {
            this.downloadService.startDownload(file, this.activeReport.Name + '.pdf')
          },
          error: (error): void => {
            this.isGenerating = false;
            this.alertService.setAlert({
              type: 'error',
              content: [error.message],
              title: this.translationService.translate('ErrorGeneratingReport'),
              icon: 'triangle-exclamation',
              dismissable: true,
              timed: true,
              timer: 5000
            });
          },
          complete: (): void => {
            this.isGenerating = false;
            this.dialogRef.close();
          }
        })
    } else {
      this.data.folderId = this.data.folderId ?? 0;
      this.reportApiService.exportListReportPdf(this.activeReport.ID, this.data.listId, this.data.folderId, this.activeReportDetails).pipe(
        take(1)).subscribe({
          next: (file: ArrayBuffer) => {
            this.downloadService.startDownload(file, this.activeReport.Name + '.pdf')
          },
          error: (error): void => {
            this.isGenerating = false;
            this.alertService.setAlert({
              type: 'error',
              content: [error.message],
              title: this.translationService.translate('ErrorGeneratingReport'),
              icon: 'triangle-exclamation',
              dismissable: true,
              timed: true,
              timer: 5000
            });
          },
          complete: (): void => {
            this.isGenerating = false;
            this.dialogRef.close();
          }
        })
    }

  }
}
