import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerModule, DateTimePickerComponent, DateTimePickerModule } from '@progress/kendo-angular-dateinputs';
import { ReactiveFormsModule } from '@angular/forms';
import { DatePickerControlComponent } from '../date-picker-control/date-picker-control.component';
import { placeholderDateTimeFormat } from '../../../../../../shared/constants/date-time-placeholders';
import { TooltipComponent } from '../../../../../../shared/components/ui/tooltip/tooltip.component';
import { userSettingsFeature } from 'src/app/store/features/user-settings/user-settings-feature';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Represents a control that allows the user to select a date and time.
 */
@Component({
  selector: 'bizz-date-time-picker-control',
  standalone: true,
  imports: [CommonModule, DateTimePickerModule, DatePickerModule, ReactiveFormsModule, TooltipComponent],
  templateUrl: './date-time-picker-control.component.html',
  styleUrls: ['./date-time-picker-control.component.scss']
})
export class DateTimePickerControlComponent extends DatePickerControlComponent{
  protected override placeholderFormat = placeholderDateTimeFormat;
  @ViewChild('input') public dateTimeinputElement: DateTimePickerComponent;


  public override ngOnInit() {
    super.ngOnInit();
    // TODO: remove once BE passes valid format string
    this.dateFormat = this.placeholderFormat;
    this.store$.select(userSettingsFeature.selectDateTimeFormatTypeString).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: value => {
        // TODO: uncomment when BE passes valid format strings
         this.dateFormat = this.dateFilterService.convertDateMomentFormat(value) ?? this.placeholderFormat;
      }
    });
  }

  public override ngAfterViewInit(): void{
    const objFrame = document.getElementById('TabIDTimePicker'+this.formFieldSignal()?.Bookmark);

    // Set tabindex to 0 or -1 to rendered input component.
    if (objFrame) {
      if (objFrame.firstElementChild){
        objFrame.firstElementChild.removeAttribute('tabindex')
        objFrame.firstElementChild.removeAttribute('ng-reflect-tabindex')
        objFrame.firstElementChild.setAttribute('tabindex', (this.readOnlyOverride || this.formFieldSignal()?.IsReadOnly ? '-1' : '0'))

        if (objFrame.firstElementChild.firstElementChild) {
          objFrame.firstElementChild.firstElementChild.setAttribute('tabindex', (this.readOnlyOverride || this.formFieldSignal()?.IsReadOnly ? '-1' : '0'))
        }

      }
    }
  }




  override setCurrentDate(): void {
      if(this.formControl.value=== null) this.formControl.setValue(new Date());
      this.focus();
  }
  protected override focus(): void {
      this.dateTimeinputElement.focus();
  }
}
