<bizz-modal modalSize="xs" [headerTemplate]="headerTemplateRef" [contentTemplate]="contentTemplateRef"
    [footerTemplate]="footerTemplateRef"></bizz-modal>

<ng-template #headerTemplateRef>
    <div class="modal-header">
        <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
            {{'UnlinkInstance' | translate}}
        </h1>

    </div>
    <hr class="my-5" />
</ng-template>

<ng-template #contentTemplateRef>
    <div class="overflow-y-auto px-4 text-sm">
        {{'Info_UnlinkInstanceFromFolder' | translate}}

        @if(modalData.inheritType == InheritType.CopyFromFolder && modalData.targetType ==
        FolderInstanceTargetType.Original) {
        <div class="mt-2">
            <p>{{'Info_InstancePermissionSetToCopyFromFolder' | translate}}</p>
        </div>
        }

        @if(modalData.inheritType == InheritType.InheritFromFolder && modalData.targetType ==
        FolderInstanceTargetType.Original) {
        <div class="mt-2">
            <p>{{'Info_InstancePermissionSetToInheritFromFolder' | translate}}</p>
            <p>{{'Info_OnlyManagersCanSetInstancePermissions' | translate}}</p>
        </div>
        }

        @if(modalData.targetType == FolderInstanceTargetType.Original && modalData.folders && modalData.folders.length >
        0) {
        <div class="mt-2">
            <div>
                <p>{{'Info_UnlinkInstanceInsideAFolder' | translate}}:</p>
                @for (tree of modalData.folders; track tree) {
                <div>
                    <b>{{tree.Tree}}</b>
                    <ul class="ml-12 mt-auto">
                        @for (folder of tree.Folders; track folder) {
                        <li>
                            {{folder.FolderCaption}}
                            @if(folder.TargetType == FolderInstanceTargetType.Link){
                            <bizz-icon iconName="share" class="size-4 text-list-row-action/50"></bizz-icon>
                            }
                        </li>
                        }
                    </ul>
                </div>
                }

            </div>
        </div>
        }
    </div>
</ng-template>


<ng-template #footerTemplateRef>
    <div class="modal-buttons">
        <button class="default" type="button" (click)="dialogRef.close()">
            {{ 'Cancel' | translate }}
        </button>
        <button class="delete min-w-16" (click)="unlink()">
            {{ 'Unlink' | translate }}
        </button>
    </div>
</ng-template>