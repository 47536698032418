<div class="flex flex-wrap items-center justify-center gap-5 overflow-y-auto w-full h-full">
    <div class="sm:flex hidden justify-center items-center flex-1">
        <img class="w-auto min-w-96 max-h-[16rem] sm:max-h-[24rem]"
             src="/assets/image/exam-splash.svg"
             alt="exam-splash"/>
    </div>
    <div class="place-self-center flex-1">
        <div class="flex flex-col gap-10 items-center">
            <div class="text-center w-full">
                <div class="text-2xl font-semibold leading-7 font-title">
                    {{ "Info_ExaminationCompleted" | translate }}
                </div>
                <span>{{ "YouMayCloseThisWindow" | translate }}</span>
            </div>
            <kendo-circularprogressbar [value]="exam.Result.Score.Percentage"
                                       [min]="0"
                                       [max]="100"
                                       [progressColor]="[{from: 0, to: 100, color: '#4A9E24'}]">
                <ng-template kendoCircularProgressbarCenterTemplate>
                    <div class="text-default">
                        <span class="text-5xl font-bold">{{ exam.Result.Score.Percentage.toString() | decimal: 0 }}</span>
                        <span class="font-bold">%</span>
                    </div>
                    <div class="mt-2.5 text-widget-header">{{ exam.Result.Score.SessionScore }}
                        / {{ exam.Result.Score.MaxPossibleScore }}
                    </div>
                </ng-template>
            </kendo-circularprogressbar>
            <div class="text-center">
                @if (exam.Result.UserSkillGrade.Name != null &&
                exam.Result.UserSkillGrade.Name != '') {
                    <p> {{ 'Skill' | translate }}:
                        <strong>{{ exam.Result.UserSkillGrade.Name }}</strong></p>
                }
                @if (exam.Result.UserSkillGrade.SkillGradeName != null &&
                exam.Result.UserSkillGrade.SkillGradeName != '') {
                    <p class="m-auto">{{ 'AchievedSkillGrade' | translate }}:
                        <strong>{{ exam.Result.UserSkillGrade.SkillGradeName }}</strong>
                    </p>
                }
            </div>
            

            @if (exam.ShowResultToExaminee == 2) {
                <button class="save w-fit"
                        (click)="viewReport.emit()">{{ "ViewReport" | translate }}
                </button>
            }
        </div>
    </div>
</div>

<div class="flex w-full justify-end max-h-fit">
    <button class="save" (click)="close()">
        {{ "CloseExam" | translate }}
    </button>
</div>