<div class="modal-container modal-sm">
    <div class="modal-close-container">
        <bizz-icon iconName="xmark" (click)="dialogRef.close(false)"></bizz-icon>
    </div>
    <div class="modal-body">
        <div class="modal-header"><h1>{{"ResetPassword" | translate}}</h1></div>
        <hr>

        <bizz-error *ngIf="error.length > 0" [errors]="error" class="mt-5 max-w-full text-wrap sm:max-w-[768px] md:max-w-[900px] lg:max-w-[1024px]"></bizz-error>
        <!--TODO: GL/BV/Geert Backend need to change to be able to have errors per input field (see mockups rené)-->
        <div class="flex flex-col gap-4 pt-10">
            <span class="flex w-full flex-col items-center gap-2 sm:justify-between md:flex-row">
                <label class="w-full text-sm md:w-2/5">{{"OldPassword" | translate}}</label>
                <input [ngClass]="{'border-2 border-danger' : error.length > 0}" class="w-full md:w-4/5" [(ngModel)]="resetPasswordDto.OldPassword" type="password" autocomplete="off">
            </span>
            <span class="flex w-full flex-col items-center gap-2 sm:justify-between md:flex-row">
                <label class="w-full text-sm md:w-2/5">{{"NewPassword" | translate}}</label>
                <input [ngClass]="{'border-2 border-danger' : error.length > 0}" class="w-full md:w-4/5" [(ngModel)]="resetPasswordDto.NewPassword" type="password" autocomplete="off">
            </span>
            <span class="flex w-full flex-col items-center gap-2 sm:justify-between md:flex-row">
                <label class="w-full text-sm md:w-2/5">{{"ConfirmNewPassword" | translate}}</label>
                <input [ngClass]="{'border-2 border-danger' : error.length > 0}" class="w-full md:w-4/5" [(ngModel)]="resetPasswordDto.NewPasswordConfirmed" type="password" autocomplete="off">
            </span>
        </div>
    </div>
    <div class="modal-buttons">
        <button class="save" (click)="setNewPassword(resetPasswordDto)">{{"ResetPassword" | translate}}</button>
    </div>
</div>