import { data } from 'autoprefixer';
import { Component, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectComponent, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import {TooltipComponent} from "../../../../../../shared/components/ui/tooltip/tooltip.component";
import { ToggleArrowComponent } from "../../../../../../shared/components/ui/toggle-arrow/toggle-arrow.component";
import { getDisplayStyleByFieldId } from 'src/app/shared/functions/helpers/display-style-helpers';
import { CollectionFieldDisplayStyleValueDto } from 'src/models/ts/collection-field-display-style-value-dto.model';
import { CollectionFormFieldValue } from 'src/models/ts/collection-form-field-value.model';

/**
 * Represents a control that allows the user to select a value from a list of options.
 */
@Component({
  selector: 'bizz-combobox-control',
  standalone: true,
  imports: [CommonModule, MultiSelectModule, ReactiveFormsModule, TooltipComponent, ToggleArrowComponent],
  templateUrl: './combobox-control.component.html',
  styleUrls: ['./combobox-control.component.scss'],
  //Needed to dynamically style the kendo multiselect tags
  encapsulation: ViewEncapsulation.Emulated
})
export class ComboboxControlComponent extends BaseFormControlComponent {
  @ViewChild('vcr', {  read: ViewContainerRef })
  public multiSelectGroupRef: ViewContainerRef;


  @ViewChild('multiSelectInput') inputElement: MultiSelectComponent;
  public selection: CollectionFormFieldValue[] = [];
  public displayValues: { [id:number]:CollectionFieldDisplayStyleValueDto } = {};
  public hasDisplayValues: boolean = false;
  public data: CollectionFormFieldValue[] = [];

  public onValueChange(value: any): void {
    if(value && value.length > 0) {
      let lastValue = value[value.length - 1];
      this.selection = [lastValue];
    }
    if(this.selection && this.selection.length > 0) {
      this.formControl.setValue(this.selection[0].CollectionFieldValuesID);
      let dValue = this.displayValues[this.selection[0].CollectionFieldValuesID];
      if(dValue && dValue.BackgroundColor) {
        this.inputElement.hostElement.nativeElement.style.setProperty(
          '--kendo-tag-background-color', dValue.BackgroundColor);
      }
    }
    else {
      this.formControl.setValue(null);
    }
  }

  public onFilterChange(value: any): void {
    this.data = this.formFieldSignal()!.FieldValues.filter((fieldValue) => {
      return fieldValue.Caption.toLowerCase().includes(value.toLowerCase());
    });
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    let field = this.formFieldSignal();
    if(field) {
      this.data = field.FieldValues;
      field.FieldValues.forEach((value) => {
        let displayValue = getDisplayStyleByFieldId(field.DisplayStyleValues, value.CollectionFieldValuesID);
        if(displayValue) {
          this.displayValues[value.CollectionFieldValuesID] = displayValue;
          this.hasDisplayValues = true;
        }
      });
      this.selection = field.FieldValues.filter(v => v.CollectionFieldValuesID == this.formControl.value);
      setTimeout(() => {
        this.onValueChange(this.selection);
      },1);
    }
  }

  protected override focus(): void {
    // Add your implementation here
    this.inputElement.focus();
  }
}