import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorColorPickerComponent, EditorComponent, EditorModule } from '@progress/kendo-angular-editor';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { IconComponent } from 'src/app/shared/components/ui/icon/icon.component';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import {
  alignCenterIcon,
  alignJustifyIcon,
  alignLeftIcon,
  alignRightIcon,
  chevronDownIcon,
  clearCssIcon,
  codeSnippetIcon,
  strikethroughIcon,
  subscriptIcon,
  supscriptIcon,
  tableAddIcon,
  unlinkIcon,
  foregroundColorIcon,
  dropletIcon
} from '@progress/kendo-svg-icons';
import { TooltipComponent } from '../../../../../../shared/components/ui/tooltip/tooltip.component';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';

/**
 * Represents a control with a rich text editor.
 * Can be simple or contain formatting options and HTML.
 */
@Component({
  selector: 'bizz-memo-control',
  standalone: true,
  imports: [CommonModule, EditorModule, IconComponent, ButtonModule, TooltipComponent],
  templateUrl: './memo-control.component.html',
  styleUrls: ['./memo-control.component.scss']
})
export class MemoControlComponent extends BaseFormControlComponent implements OnInit, AfterViewInit {
  public isMobileView = false;
  public chevronDownIcon = chevronDownIcon;

  @ViewChild('editor') public editor: EditorComponent;
  @ViewChild('foreColorPicker') public foreColorPicker: EditorColorPickerComponent;
  @ViewChild('backColorPicker') public backColorPicker: EditorColorPickerComponent;

  public data = [
    {
      text: 'Clean formatting',
      svgIcon: clearCssIcon,
      click: (): void => this.editor.exec('cleanFormatting')
    },
    {
      text: 'Strikethrough',
      svgIcon: strikethroughIcon,
      click: (): void => this.editor.exec('strikethrough')
    },
    {
      text: 'Subscript',
      svgIcon: subscriptIcon,
      click: (): void => this.editor.exec('subscript')
    },
    {
      text: 'Superscript',
      svgIcon: supscriptIcon,
      click: (): void => this.editor.exec('superscript')
    },
    {
      text: 'Foreground color',
      svgIcon: foregroundColorIcon,
      click: (): void => this.foreColorPicker.openDialog()
    },
    {
      text: 'Background color',
      svgIcon: dropletIcon,
      click: (): void => this.backColorPicker.openDialog()
    },
    {
      text: 'Align text left',
      svgIcon: alignLeftIcon,
      click: (): void => this.editor.exec('alignLeft')
    },
    {
      text: 'Align text center',
      svgIcon: alignCenterIcon,
      click: (): void => this.editor.exec('alignCenter')
    },
    {
      text: 'Align text right',
      svgIcon: alignRightIcon,
      click: (): void => this.editor.exec('alignRight')
    },
    {
      text: 'Align text justify',
      svgIcon: alignJustifyIcon,
      click: (): void => this.editor.exec('alignJustify')
    },
    {
      text: 'Unlink',
      svgIcon: unlinkIcon,
      click: (): void => this.editor.exec('unlink')
    },
    {
      text: 'Insert Table',
      svgIcon: tableAddIcon,
      click: (): void => this.editor.exec('insertTable', { rows: 1, cols: 1 })
    },
    {
      text: 'View source',
      svgIcon: codeSnippetIcon,
      click: (): void => this.editor.openDialog('viewSource')
    }
  ];

  @HostListener('window:resize')
  public onResize(): void {
    this.isMobileView = window.innerWidth <= 640;
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    //640px is the tailwindcss sm breakpoint (maybe make a class for this)
    this.isMobileView = window.innerWidth <= 640;
  }

  public ngAfterViewInit(): void{
    const objFrame = document.getElementById('tabID' + this.formFieldSignal()?.Bookmark);
    const iframeObj = objFrame ? objFrame.getElementsByTagName('iframe') : undefined;

    if (iframeObj) {
      iframeObj[0].setAttribute('tabindex', (this.readOnlyOverride || this.formFieldSignal()?.IsReadOnly ? '-1' : '0'))
    }
  }


  public setValue(value: any): void {
    this.formControl.markAsDirty();
    this.formControl.setValue(value);
  }
   // Implement the abstract member from MemoControlComponent
   protected override focus(): void {
     setTimeout(() => {
      this.editor.focus();
    }, 10);
  }

  // CSS imports (iframe mode)
  public embeddedCss = `
    @font-face {
      font-family: 'Noto Sans';
      font-weight: 100 900;
      font-stretch: 62.5% 100%; // 62.5% is the minimum value for the font-stretch property
      font-style: normal;
      src: url(/assets/fonts/Noto-Sans/NotoSans-Variable.woff2) format("woff2");
    }

    @font-face {
      font-family: Montserrat;
      font-weight: 100 900;
      font-style: normal;
      src: url(/assets/fonts/Montserrat/Montserrat-Variable.woff2) format("woff2");
    }

    .k-content {
      font-family: 'Noto Sans', sans-serif;
    }

    .k-content p {
      font-size: 12px;
    }

    .k-content h1, .k-content h2, .k-content h3, .k-content h4, .k-content h5, .k-content h6 {
      font-family: Montserrat, sans-serif;
    }
  `}

