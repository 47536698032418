@if (formFieldSignal(); as formField) {
    <div class="flex flex-col gap-2" 
    [ngClass]="{ 'hidden': formField.IsHidden }">
        @for (value of formField.FieldValues; track $index) {
            @if(displayValues[$index] && formControl.value == value.CollectionFieldValuesID) {
                <div class="display-style flex items-center rounded-full px-1.5 py-0.5"
                [ngStyle]="{ 'background-color': displayValues[$index].BackgroundColor, 'color': displayValues[$index].ForegroundColor }">
                    <input [value]="value.CollectionFieldValuesID"  [formControl]="formControl"
                            id="{{formField.Id + value.CollectionFieldValuesID}}"  type="radio" kendoRadioButton>
                    <label [for]="formField.Id + value.CollectionFieldValuesID"
                            class="ml-2 cursor-pointer truncate"
                            [class.text-danger]="errorState != undefined">{{ value.Caption }}</label>
                </div>
            }
            @else {
            <div class="flex items-center px-1.5 py-0.5">
                <input [value]="value.CollectionFieldValuesID" [formControl]="formControl" 
                id="{{formField.Id + value.CollectionFieldValuesID}}"  type="radio" kendoRadioButton>
                <label [for]="formField.Id + value.CollectionFieldValuesID"
                        class="ml-2 cursor-pointer truncate"
                        [class.text-danger]="errorState != undefined">{{ value.Caption }}</label>
            </div>
        }
    }
    </div>
}