import { Component, Input } from '@angular/core';
import { NgForOf } from '@angular/common';

/**
 * Reusable error component for validation errors.
 */

@Component({
  selector: 'bizz-error',
  templateUrl: './error.html',
  styleUrls: ['./error.scss'],
  imports: [
    NgForOf
  ],
  standalone: true
})
export class Error {
  @Input() errors: string[] = [];
}
