import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { formsActions } from '../forms-actions';
import { catchError, combineLatestWith, concatMap, exhaustMap, filter, map, of, tap } from 'rxjs';
import { StoreCollectionFormService } from '../../../../features/bizzmine/form/services/store-collection-form.service';
import { CollectionFormService } from '../../../../features/bizzmine/form/services/collection-form.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CollectionFormEditApiService } from '../../../../api/bizzmine/collection-form-edit/collection-form-edit-api.service';
import { CollectionFormLookupApiService } from '../../../../api/bizzmine/collection-form-lookup/collection-form-lookup-api.service';
import { StoreCollectionForm } from '../forms-state';
import { CollectionFormApiService } from '../../../../api/bizzmine/collection-form/collection-form-api.service';
import { selectForm, selectFormField } from '../forms-selectors';
import { Store } from '@ngrx/store';
import { CollectionFormFieldSingleLookupData } from '../../../../../models/ts/collection-form-field-single-lookup-data.model';
import { ProtectedFieldType } from '../../../../../models/ts/protected-field-type.model';
import { ControlledDocumentUploadIntent } from '../../../../features/bizzmine/form/components/controls/controlled-document-control/controlled-document-upload-intent';
import { ControlledDocumentTemplateIntent } from '../../../../features/bizzmine/form/components/controls/controlled-document-control/controlled-document-template-intent';
import { viewStackActions } from '../view-stack-actions';
import { LookupService } from '../../../../shared/services/lookup/lookup.service';
import { CollectionFormFieldGridLookupData } from '../../../../../models/ts/collection-form-field-grid-lookup-data.model';
import { CollectionFormJsonService } from '../../../../features/bizzmine/form/services/collection-form-json.service';
import { CollectionFormLinkedService } from '../../../../features/bizzmine/form/services/collection-form-linked.service';

/**
 * Effects for linked collection(forms) interactions and lookups
 */
@Injectable()
export class FormsLinkedEffects {
  private actions$ = inject(Actions);
  private linkedService = inject(CollectionFormLinkedService)

  public fetchLookupDataFromApi$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.lookupChanged),
    concatLatestFrom(({ formId, lookupFieldId }) => [
      this.store$.select(selectForm(formId)),
      this.store$.select(selectFormField(formId, lookupFieldId))
    ]),
    // Check for undefined and map to object
    map(([params, form, field]) => {
      if(form !== undefined && field !== undefined) return {params, form: form.data, field};
      else throw new Error(`Form ${params.formId} and/or field ${params.lookupFieldId} not found.`);
    }),
    // Get lookup from API
    exhaustMap(({params, form, field}) =>
      this.linkedService.getSingleRecordLookup(form, field.ViewDataSourcesID, params.lookupItem.InstancesID, params.lookupItem.VersionsID, params.lookupItem.CrossLinkInstancesID).pipe(
        map(lookupData => formsActions.lookupFetched({
          formId: params.formId,
          lookupFieldId: params.lookupFieldId,
          lookupItem: params.lookupItem,
          lookupData: lookupData
        })),
        catchError(error => of(formsActions.lookupFailed({
          formId: params.formId
        })))
      )
    )
  ));

  public clearLookup$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.lookupCleared),
    concatLatestFrom(({ formId }) => [
      this.store$.select(selectForm(formId))
    ]),
    map(([props, form]) => {
      if(form?.data !== undefined) {
        this.linkedService.clearLookup(props.formId, form.data, props.viewDataSourceId)
      } else throw new Error(`Form ${props.formId} not found.`);
    })
  ), {dispatch: false});

  public fetchGridLookupDataFromApi$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.gridLookupChanged),
    concatLatestFrom(({ formId, lookupFieldId }) => [
      this.store$.select(selectForm(formId)),
      this.store$.select(selectFormField(formId, lookupFieldId))
    ]),
    // Check for undefined and map to object
    map(([params, form, field]) => {
      if(form !== undefined && field !== undefined) return {params, form: form.data, field};
      else throw new Error(`Form ${params.formId} and/or field ${params.lookupFieldId} not found.`);
    }),
    // Get lookup from API
    exhaustMap(({params, form, field}) =>
      this.linkedService.getGridRecordLookup(form, field.ViewDataSourcesID, params.lookupItem.InstancesID, params.lookupItem.VersionsID, params.lookupItem.CrossLinkInstancesID).pipe(
        map(lookupData => formsActions.gridLookupFetched({
          formId: params.formId,
          gridFieldId: params.gridFieldId,
          recordId: params.recordId,
          lookupFieldId: params.lookupFieldId,
          lookupItem: params.lookupItem,
          lookupData: lookupData
        })),
        catchError(error => of(formsActions.gridLookupFailed({
          formId: params.formId
        })))
      )
    ),
  ));

  public updateLookupFields$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.lookupFetched),
    concatLatestFrom(props =>  [
      this.store$.select(selectForm(props.formId)),
      this.store$.select(selectFormField(props.formId, props.lookupFieldId)),
    ]),
    tap(([props, form, lookupField]) => {
      if (form?.data !== undefined && lookupField !== undefined) {
        this.linkedService.updateLookupFields(props.formId, form.data, props.lookupData);
        this.linkedService.clearChildLookupFields(props.formId, form.data, lookupField.ViewDataSourcesID);
        // TODO: Jonas check against racing conditions
        this.linkedService.updateTrainingLookupFields(props.formId, props.lookupData);
      } else throw new Error();
    })
  ), {dispatch: false});

  public updateLookupViewDataSources$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.lookupFetched),
    concatLatestFrom(props =>  [
      this.store$.select(selectForm(props.formId)),
      this.store$.select(selectFormField(props.formId, props.lookupFieldId)),
    ]),
    tap(([props, form, lookupField]) => {
      if (form?.data !== undefined && lookupField !== undefined) {
        this.linkedService.updateViewDataSourceInstance(props.formId, form.data, lookupField.ViewDataSourcesID, props.lookupItem, props.lookupData);
        // TODO: Jonas check against racing conditions
        this.linkedService.updateTrainingViewDataSources(props.formId, props.lookupData);
      } else throw new Error();
    })
  ), {dispatch: false});

  public updateReadOnlyOnViewDataSourceUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateFormViewDataSource),
    concatLatestFrom(({ formId }) => this.store$.select(selectForm(formId))),
    tap(([{ formId, viewDataSource }, form]) => {
      if (form?.data !== undefined)
        this.storeCollectionFormService.updateViewDataSourceFieldsReadOnly(formId, form.data, viewDataSource.ViewDataSourcesID);
      else throw new Error(`No form with id ${formId} found.`);
    })
  ), { dispatch: false });

  //region OLD EFFECTS
  public closeFormAfterSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createLinkedFormInstanceSucceeded, formsActions.saveLinkedFormInstanceSucceeded, formsActions.createGridLinkedFormInstanceSucceeded, formsActions.saveGridLinkedFormInstanceSucceeded),
    map(({ formId }) => formsActions.formClosed({
        formId,
        unsavedChanges: false
      })
    )
  ));
  private store$ = inject(Store);
  public changeLookupAfterLinkedFormSave$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createLinkedFormInstanceSucceeded, formsActions.saveLinkedFormInstanceSucceeded),
    map((params) => formsActions.lookupChanged({
        formId: params.parentFormId,
        lookupFieldId: params.parentFormFieldId,
        lookupItem: {
          InstancesID: params.instance.InstancesID,
          OriginalChildInstancesID: 0,
          Text: '',
          VersionsID: params.instance.VersionsID,
          CrossLinkInstancesID: 0,
          LinkedToParentVersions: []
        }
      }
    ))
  ));
  // TODO: rework
  // public getGridLookupAfterSuccessOnCreate$ = createEffect(() => this.actions$.pipe(
  //   ofType(formsActions.createGridLinkedFormInstanceSucceeded, formsActions.saveGridLinkedFormInstanceSucceeded),
  //   tap(({ formId }) => {
  //     const form = this.store$.selectSignal(selectForm(formId))();
  //     if (form != null) {
  //       this.store$.dispatch(refreshActions.refreshData({ collectionId: form.data.CollectionsID, formId: formId }));
  //     }
  //   }),
  //   exhaustMap(value => {
  //     return zip([
  //       of(value),
  //       this.store$.select(selectGridRecordField(value.parentFormId, value.parentGridFieldId, value.parentRecordId, field => field.CollectionFieldsID == value.parentFormFieldId)),
  //       this.store$.select(selectForm(value.parentFormId))
  //     ]);
  //   }),
  //   map(([{ parentFormId, parentGridFieldId, parentRecordId, instance }, formField, form]) =>
  //     (formField ? {
  //       type: formsActions.fetchGridLookup.type,
  //       formId: parentFormId,
  //       form: form!.data,
  //       field: formField!,
  //       gridFieldId: parentGridFieldId,
  //       recordId: parentRecordId,
  //       summaryItem: {
  //         InstancesID: instance.InstancesID,
  //         OriginalChildInstancesID: 0,
  //         Text: '',
  //         VersionsID: instance.VersionsID,
  //         CrossLinkInstancesID: 0,
  //         LinkedToParentVersions: []
  //       }
  //     } : {
  //       type: formsActions.fetchGridLookupNewRecord.type,
  //       formId: parentFormId,
  //       gridFieldId: parentGridFieldId,
  //       summaryItem: {
  //         InstancesID: instance.InstancesID,
  //         OriginalChildInstancesID: 0,
  //         Text: '',
  //         VersionsID: instance.VersionsID,
  //         CrossLinkInstancesID: 0,
  //         LinkedToParentVersions: []
  //       }
  //     })
  //   )
  // ));
  public formFetchedWithFiles$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.formFetchedWithFiles),
    filter(({ files }) => files != null && files.length > 0),
    tap(({
           form,
           files
         }) => {
        const fileField = CollectionFormService.getField(form.data, (f) => {
          return f.ProtectedFieldType == ProtectedFieldType.File && f.ViewDataSourcesID == 0;
        });
        if (fileField) {
          this.store$.dispatch(formsActions.updateFormFieldValue({
            formId: form.id,
            fieldId: fileField.Id,
            value: new ControlledDocumentUploadIntent(files)
          }));
        }

      }
    )
  ), { dispatch: false });
  public formFetchedWithTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.formFetchedWithTemplate),
    filter(({ selectedTemplate }) => selectedTemplate != null),
    tap(({
           form,
           selectedTemplate
         }) => {
        const fileField = CollectionFormService.getField(form.data, (f) => {
          return f.ProtectedFieldType == ProtectedFieldType.File && f.ViewDataSourcesID == 0;
        });
        if (fileField) {
          this.store$.dispatch(formsActions.updateFormFieldValue({
            formId: form.id,
            fieldId: fileField.Id,
            value: new ControlledDocumentTemplateIntent(selectedTemplate)
          }));
        }

      }
    )
  ), { dispatch: false });
  private collectionFormApiService = inject(CollectionFormApiService);
  public getLinkedInstanceRead$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getLinkedInstance),
    filter(action => action.read),
    exhaustMap(({
                  collectionId, instanceId, versionId, read, formFieldId, formId, relationType
                }) => this.collectionFormApiService.getFormByInstanceRead(collectionId, instanceId, versionId)
      .pipe(
        map(
          data => ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data, {
              parentFormId: formId,
              parentFormFieldId: formFieldId,
              linkType: relationType
            }),
            addToViewStack: true
          })
        )
      ))
  ));
  public getGridLinkedInstanceRead$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getGridLinkedInstance),
    filter(action => action.read),
    exhaustMap(({
                  collectionId, instanceId, versionId, read, formFieldId, formId, relationType
                }) => this.collectionFormApiService.getFormByInstanceRead(collectionId, instanceId, versionId)
      .pipe(
        map(
          data => ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data, {
              parentFormId: formId,
              parentFormFieldId: formFieldId,
              linkType: relationType
            }),
            addToViewStack: true
          })
        )
      ))
  ));
  public getLinkedInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getLinkedInstance),
    filter(action => !action.read),
    exhaustMap(({
                  collectionId, instanceId, versionId, read, formFieldId, formId, relationType
                }) => this.collectionFormApiService.getFormByInstance(collectionId, instanceId, versionId)
      .pipe(
        map(
          data => ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data, {
              parentFormId: formId,
              parentFormFieldId: formFieldId,
              linkType: relationType
            }),
            addToViewStack: true
          })
        )
      ))
  ));
  public getGridLinkedInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getGridLinkedInstance),
    filter(action => !action.read),
    exhaustMap(({
                  collectionId, instanceId, versionId, read, formFieldId, formId, gridFieldId, recordId, relationType
                }) => this.collectionFormApiService.getFormByInstance(collectionId, instanceId, versionId)
      .pipe(
        map(
          data => ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data, {
              parentFormId: formId,
              parentFormFieldId: formFieldId,
              parentGridFieldId: gridFieldId,
              parentRecordId: recordId,
              linkType: relationType
            }),
            addToViewStack: true
          })
        )
      ))
  ));
  private collectionFormEditApiService = inject(CollectionFormEditApiService);
  public getLinkedForm$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getLinkedForm),
    exhaustMap(({
                  formFieldId,
                  formId,
                  relationType
                }) => this.collectionFormEditApiService.getFormByCollectionFormField(formFieldId)
      .pipe(
        map(data => ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data, {
              parentFormId: formId,
              parentFormFieldId: formFieldId,
              linkType: relationType
            }),
            addToViewStack: true
          })
        )
      )
    )
  ));
  public getExternalLinkedForm$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getExternalLinkedForm),
    exhaustMap(({
                  externalAccess,
                  formFieldId,
                  formId,
                  relationType
                }) => this.collectionFormEditApiService.getFormByParentAndDataSource({
        parentFormId: externalAccess.FormID,
        viewDataSourceId: externalAccess.ViewDataSourcesID
      })
        .pipe(
          map(data => ({
              type: formsActions.formFetched.type,
              form: new StoreCollectionForm(data, {
                parentFormId: formId,
                parentFormFieldId: formFieldId,
                linkType: relationType
              }),
              addToViewStack: true
            })
          )
        )
    )
  ));
  public getGridLinkedForm$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getGridLinkedForm),
    exhaustMap(({
                  formId,
                  collectionFieldId,
                  gridFieldId,
                  recordId,
                  relationType
                }) => this.collectionFormEditApiService.getFormByCollectionFormFieldFromGrid(gridFieldId)
      .pipe(
        map(data => ({
            type: formsActions.formFetched.type,
            form: new StoreCollectionForm(data, {
              parentFormId: formId,
              parentFormFieldId: collectionFieldId,
              parentGridFieldId: gridFieldId,
              parentRecordId: recordId,
              linkType: relationType
            }),
            addToViewStack: true
          })
        )
      )
    )
  ));
  public getExternalForm$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getExternalGridLinkedForm),
    exhaustMap(({
                  externalAccess,
                  formFieldId,
                  formId,
                  gridFieldId,
                  recordId,
                  relationType
                }) => this.collectionFormEditApiService.getFormByParentAndDataSource({
        parentFormId: externalAccess.FormID,
        viewDataSourceId: externalAccess.ViewDataSourcesID
      })
        .pipe(
          map(data => ({
              type: formsActions.formFetched.type,
              form: new StoreCollectionForm(data, {
                parentFormId: formId,
                parentFormFieldId: formFieldId,
                parentGridFieldId: gridFieldId,
                parentRecordId: recordId,
                linkType: relationType
              }),
              addToViewStack: true
            })
          )
        )
    )
  ));
  public getGridLinkedFormWithFile$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getGridLinkedFormWithFile),
    exhaustMap(intent => {
        if (intent.externalAccess != null) {
          return this.collectionFormEditApiService.getFormByParentAndDataSource({
            parentFormId: intent.externalAccess.FormID,
            viewDataSourceId: intent.externalAccess.ViewDataSourcesID
          }).pipe(combineLatestWith(of(intent)));
        }
        return this.collectionFormEditApiService.getFormByCollectionFormFieldFromGrid(intent.gridFieldId).pipe(combineLatestWith(of(intent)));
      }
    ),
    map(([data, intent]) => ({
        type: formsActions.formFetchedWithFiles.type,
        form: new StoreCollectionForm(data, {
          parentFormId: intent.formId,
          parentFormFieldId: intent.formFieldId,
          parentGridFieldId: intent.gridFieldId,
          parentRecordId: intent.recordId,
          linkType: intent.relationType
        }),
        addToViewStack: true,
        files: intent.files
      })
    )
  ));
  public getGridLinkedFormWithTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getGridLinkedFormWithTemplate),
    exhaustMap(intent => {
        if (intent.externalAccess != null) {
          return this.collectionFormEditApiService.getFormByParentAndDataSource({
            parentFormId: intent.externalAccess.FormID,
            viewDataSourceId: intent.externalAccess.ViewDataSourcesID
          }).pipe(combineLatestWith(of(intent)));
        }
        return this.collectionFormEditApiService.getFormByCollectionFormFieldFromGrid(intent.gridFieldId).pipe(combineLatestWith(of(intent)));
      }
    ), map(([data, intent]) => ({
        type: formsActions.formFetchedWithTemplate.type,
        form: new StoreCollectionForm(data, {
          parentFormId: intent.formId,
          parentFormFieldId: intent.formFieldId,
          parentGridFieldId: intent.gridFieldId,
          parentRecordId: intent.recordId,
          linkType: intent.relationType
        }),
        addToViewStack: true,
        selectedTemplate: intent.selectedTemplate
      })
    )
  ));
  public preValidateCreateLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createLinkedFormInstance),
    filter(({ preValidate }) => preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.preValidateForm(
      form.CollectionsID,
      form.CollectionFormId,
      form.TasksID,
      form.MethodType,
      false
    ).pipe(
      map(data => ({
        type: formsActions.createLinkedFormInstance.type,
        parentFormId,
        parentFormFieldId,
        formId,
        form, preValidate: false
      })),
      catchError((response: HttpErrorResponse) => of(formsActions.preValidationFailed({
          formId: formId,
          response: response
        }))
      )
    ))
  ));
  public createLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createLinkedFormInstance),
    filter(({ preValidate }) => !preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.createInstanceWithJson(form, JSON.stringify(CollectionFormJsonService.formToJson(form)), false)
      .pipe(
        map(data => ({
          type: formsActions.createLinkedFormInstanceSucceeded.type,
          parentFormId,
          parentFormFieldId,
          formId,
          instance: data
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.createLinkedFormInstanceFailed({
            formId,
            response
          }))
        )
      )
    )
  ));
  public preValidateCreateGridLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createGridLinkedFormInstance),
    filter(({ preValidate }) => preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  parentGridFieldId,
                  parentRecordId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.preValidateForm(
      form.CollectionsID,
      form.CollectionFormId,
      form.TasksID,
      form.MethodType,
      false
    ).pipe(
      map(data => ({
        type: formsActions.createGridLinkedFormInstance.type,
        parentFormId,
        parentFormFieldId,
        parentGridFieldId,
        parentRecordId,
        formId,
        form,
        preValidate: false
      })),
      catchError((response: HttpErrorResponse) => of(formsActions.preValidationFailed({
          formId: formId,
          response: response
        }))
      )
    ))
  ));
  public createGridLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.createGridLinkedFormInstance),
    filter(({ preValidate, parentRecordId }) => !preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  parentGridFieldId,
                  parentRecordId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.createInstanceWithJson(form, JSON.stringify(CollectionFormJsonService.formToJson(form)), false).pipe(
        map(data => ({
          type: formsActions.createGridLinkedFormInstanceSucceeded.type,
          parentFormId,
          parentFormFieldId,
          parentGridFieldId,
          parentRecordId,
          formId,
          instance: data
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.createLinkedFormInstanceFailed({
            formId,
            response
          }))
        )
      )
    )
  ));
  public preValidateSaveLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveLinkedFormInstance),
    filter(({ preValidate }) => preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.preValidateForm(
      form.CollectionsID,
      form.CollectionFormId,
      form.TasksID,
      form.MethodType,
      false
    ).pipe(
      map(data => ({
        type: formsActions.saveLinkedFormInstance.type,
        parentFormId,
        parentFormFieldId,
        formId,
        form, preValidate: false
      })),
      catchError((response: HttpErrorResponse) => of(formsActions.preValidationFailed({
          formId: formId,
          response: response
        }))
      )
    ))
  ));
  public saveLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveLinkedFormInstance),
    filter(({ preValidate }) => !preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.saveInstanceWithJson(form, JSON.stringify(CollectionFormJsonService.formToJson(form)))
      .pipe(
        map(data => ({
          type: formsActions.saveLinkedFormInstanceSucceeded.type,
          parentFormId,
          parentFormFieldId,
          formId,
          instance: data
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.saveLinkedFormInstanceFailed({
            formId,
            response
          }))
        )
      )
    )
  ));
  public preValidateSaveGridLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveGridLinkedFormInstance),
    filter(({ preValidate }) => preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  parentGridFieldId,
                  parentRecordId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.preValidateForm(
      form.CollectionsID,
      form.CollectionFormId,
      form.TasksID,
      form.MethodType,
      false
    ).pipe(
      map(data => ({
        type: formsActions.saveGridLinkedFormInstance.type,
        parentFormId,
        parentFormFieldId,
        parentGridFieldId,
        parentRecordId,
        formId,
        form,
        preValidate: false
      })),
      catchError((response: HttpErrorResponse) => of(formsActions.preValidationFailed({
          formId: formId,
          response: response
        }))
      )
    ))
  ));
  public saveGridLinkedFormInstance$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.saveGridLinkedFormInstance),
    filter(({ preValidate }) => !preValidate),
    exhaustMap(({
                  parentFormId,
                  parentFormFieldId,
                  parentGridFieldId,
                  parentRecordId,
                  formId,
                  form
                }) => this.collectionFormEditApiService.saveInstanceWithJson(form, JSON.stringify(CollectionFormJsonService.formToJson(form)))
      .pipe(
        map(data => ({
          type: formsActions.saveGridLinkedFormInstanceSucceeded.type,
          parentFormId,
          parentFormFieldId,
          parentGridFieldId,
          parentRecordId,
          formId,
          instance: data
        })),
        catchError((response: HttpErrorResponse) => of(formsActions.saveLinkedFormInstanceFailed({
            formId,
            response
          }))
        )
      )
    )
  ));
  private collectionFormLookupApiService = inject(CollectionFormLookupApiService);
  // TODO: rework
  private lookupService = inject(LookupService);
  public getGridLookupByIdsForNewRecord$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.fetchGridLookupNewRecord),
    concatLatestFrom(props => [
      this.store$.select(selectForm(props.formId)),
      this.store$.select(selectFormField(props.formId, props.gridFieldId))
    ]),
    concatMap(([props, form, field]) => this.lookupService.getEmptyGridRecord(form!.data.CollectionFormId, field!).pipe(
        concatLatestFrom(() => [
          this.store$.select(selectForm(props.formId)),
          this.store$.select(selectFormField(props.formId, props.gridFieldId))
        ]),
        concatMap(([newRecord, form, field]) => of({
          props,
          form,
          field,
          newRecord,
          vds: CollectionFormService.getViewDataSourceForFieldById(form!.data, props.gridFieldId)
        })),
        concatMap(({ props, form, field, newRecord, vds }) =>
          this.collectionFormLookupApiService.getFormFieldValuesByLookupField(form!.data.CollectionFormId, vds!.ViewDataSourcesID, vds!.SingleOrMany, props.summaryItem.InstancesID, props.summaryItem.VersionsID).pipe(
            map((response) => ({
              type: formsActions.addRowsToGrid.type,
              formId: props.formId,
              gridFieldId: props.gridFieldId,
              records: [CollectionFormService.fillRecordWithLookupData(structuredClone(newRecord), response as CollectionFormFieldGridLookupData, LookupService.getNextNewRecordId(field?.Records ?? []))]
            }))
          )
        )
      )
    )
  ));

  public getGridLookupByIdsForNewRecords$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.fetchGridLookupNewRecords),
    tap(intent => {
      this.storeCollectionFormService.addRecordsFromSelection({
        formId: intent.formId,
        gridFieldId: intent.gridFieldId,
        summaryItems: intent.summaryItems
      });
    })
  ), { dispatch: false });

  private storeCollectionFormService = inject(StoreCollectionFormService);
  public clearLinkedFieldValues$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.clearLinkedFieldValues),
    concatLatestFrom(({ formId }) => this.store$.select(selectForm(formId))),
    tap(([{ formId, field }, form]) => {
      if (form && form.data)
        this.storeCollectionFormService.clearLookupFromForm(formId, structuredClone(form.data), field.ViewDataSourcesID);
    })
  ), { dispatch: false });
  public clearGridLinkedFieldValues$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.clearGridLinkedFieldValues),
    concatLatestFrom(({ formId }) => this.store$.select(selectForm(formId))),
    tap(([{ formId, fieldId, recordId }, form]) => {
      this.storeCollectionFormService.clearGridLinkedFields(formId, form!.data, fieldId, recordId);
    })
  ), { dispatch: false });
  /**
   * Fetches the lookup values for a crosslink field
   * Usually occurs because a childform is opened from a grid and we need to fill in the parentinfo
   * in the lookup fields of the childform
   * E.g: We add an employee to company form in the employees grid, in the new employee form
   * the company info needs to be filled in automatically.
   */
  public getLookupByCrossLinkId$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.fetchLookupByCrossLinkId),
    exhaustMap(({ formId, storeForm, gridParentFilter }) => {
        const form = storeForm.data;
        const vds = CollectionFormService.getViewDataSourceByCrossLinkId(form, gridParentFilter.CrosslinkCollectionsID);
        return this.collectionFormLookupApiService.getFormFieldValuesByLookupField(form.CollectionFormId, vds!.ViewDataSourcesID, vds!.SingleOrMany, gridParentFilter.ChildInstancesID, gridParentFilter.ChildVersionsID)
          .pipe(
            tap(lookupData => {
              this.storeCollectionFormService.updateLinkedFields(formId, form, lookupData as CollectionFormFieldSingleLookupData);
            }),
            map(() => ({
              type: viewStackActions.addFormToViewStack.type,
              data: storeForm
            }))
          );
      }
    )
  ));
  public updateLinkedFieldsIndividually$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateLinkedFieldValues),
    map(({
           formId,
           form,
           field,
           summaryItem,
           value
         }) =>
      this.storeCollectionFormService.updateFormWithLookup(formId, structuredClone(form), field, value, summaryItem)
    )
  ), { dispatch: false });
  public updateGridLinkedFieldsIndividually$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.updateGridLinkedFieldValues),
    concatLatestFrom(({ formId, fieldId }) => [
      this.store$.select(selectForm(formId)),
      this.store$.select(selectFormField(formId, fieldId))
    ]),
    tap(([props, form, field]) => {
      if (form == undefined || field == undefined) throw new Error(`Form ${props.formId} and/or field ${props.fieldId} not found.`);
    }),
    map(([{
        formId,
        fieldId,
        recordId,
        summaryItem,
        value
      }, form, field]) =>
        this.storeCollectionFormService.updateFormWithGridLookup(formId, structuredClone(form!.data), fieldId, recordId, field!, value, summaryItem)
    )
  ), { dispatch: false });
  /**
   * Calls a service that will get the required empty record, instance data, combine them and dispatch actions to add new records to the grid
   * @type {Observable<{readonly formId?: any, readonly form?: any, readonly field?: any, readonly instances?: any, readonly gridFieldId?: any}> & CreateEffectMetadata}
   * @private
   */
  public fetchMultipleGridLookUps = createEffect(() => this.actions$.pipe(
    ofType(formsActions.addInstancesToGrid),
    tap(({
           formId,
           form,
           field,
           gridFieldId,
           instances
         }) => this.storeCollectionFormService.addInstancesToGrid(formId, form, gridFieldId, field, instances))
  ), { dispatch: false });
  //endregion
}