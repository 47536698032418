<bizz-modal modalSize="xs" [headerTemplate]="headerTemplateRef"
    [contentTemplate]="activeContentTemplateRef" [footerTemplate]="footerTemplateRef"></bizz-modal>

<ng-template #headerTemplateRef>
    <div class="modal-header">
        <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
            {{'DuplicateExam' | translate}}
        </h1>
    </div>
    <hr class="my-5" />
</ng-template>

<ng-template #activeContentTemplateRef>
    <span class="mb-2 text-default">{{'NewExamName' | translate}}:</span>
    <kendo-textbox (valueChange)="onValueChange($event)">{{examName}}</kendo-textbox>
</ng-template>

<ng-template #footerTemplateRef>
    <div class="modal-buttons">
        <button class="default" (click)="dialogRef.close()"> <span>{{ 'Cancel' | translate }}</span></button>
        <bizz-loader-button class="save" (click)="save()" [isLoading]="isLoading">
            {{'Save' | translate}}
        </bizz-loader-button>
    </div>
</ng-template>