import { Routes } from '@angular/router';
import { FormReadonlyPageComponent } from './form-readonly-page/form-readonly-page.component';
import { FormCreatePageComponent } from './form-create-page/form-create-page.component';

import { authGuard } from '../../../core/guards/auth/auth.guard';
import { FormEditPageComponent } from './form-edit-page/form-edit-page.component';
import { FormDocumentPageComponent } from './form-document-page/form-document-page.component';
import { FormWorkspacePageComponent } from './form-workspace-page/form-workspace-page.component';

export const formRoutes: Routes = [
  {
    canActivate: [authGuard],
    path: 'collectionsid/:collectionId/instancesid/:instanceId/versionsid/:versionId/readonly',
    component: FormReadonlyPageComponent
  },
  {
    canActivate: [authGuard],
    path: 'collectionsid/:collectionId/instancesid/:instanceId/versionsid/:versionId/state/:state/readonly',
    component: FormReadonlyPageComponent
  },
  {
    canActivate: [authGuard],
    path: 'collectionsid/:collectionId/widget/:widgetId',
    component: FormCreatePageComponent
  },
  {
    canActivate: [authGuard],
    path: 'collectionsid/:collectionId/instancesid/:instanceId/versionsid/:versionId',
    component: FormEditPageComponent
  },
  {
    canActivate: [authGuard],
    path: 'collectionsid/:collectionId/instancesid/:instanceId/versionsid/:versionId/minor/create',
    component: FormDocumentPageComponent
  },
  {
    canActivate: [authGuard],
    path: 'collectionsid/:collectionId/instancesid/:instanceId/versionsid/:versionId/major/create',
    component: FormDocumentPageComponent
  },
  {
    canActivate: [authGuard],
    path: 'collectionsid/:collectionId/workspaceitemsid/:workspaceItemsId',
    component: FormWorkspacePageComponent
  }
];