@if(item && item.IsEnabled){

<div class="flex flex-col gap-0 md:flex-row md:gap-4">
  <div class="mb-2 w-full md:w-2/5">
    <div class="flex h-full flex-col justify-start">
      @if(item.Caption){
      <label class="mb-2.5">
        {{item.Caption}}
        @if(popperText){
        <bizz-popper [title]="popperText"></bizz-popper>
        }
      </label>
      }
      <div [class]="!floatLeft ? 'col-xs-12 col-sm-8':''">
        <kendo-dropdownlist [data]="accessList" [(ngModel)]="item.AccessType" textField="text" valueField="id"
          [valuePrimitive]="true" [disabled]="disabled" (valueChange)="setState()">
          <ng-template kendoDropDownListItemTemplate let-item>
            <span>{{ item.text }}</span>
          </ng-template>
        </kendo-dropdownlist>
      </div>
    </div>
  </div>
  <div class="mb-2 w-full md:w-3/5">
    <div class="flex h-full flex-col justify-start md:justify-between">
      <label class="mb-2.5">
        {{ "Except" | translate }}
      </label>
      <div>
        <!-- TODO: CHECK ALLOWED TYPES-->
        <bizz-org-chart-select [readonly]="disabled" [selection]="currentUser? item.SelectedOrganizationChartItems : item.SelectedOrganizationChartItemsDisplay"
          (selectionChanged)="selectedOrgChartItems$.next($event)" (selectionWithAllStatesChanged)="selectedOrgChartItemsWithAllStateChange($event)">
        </bizz-org-chart-select>
      </div>
    </div>
  </div>
</div>
}