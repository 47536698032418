<bizz-modal  modalSize="xs" [headerTemplate]="headerTemplateRef" [contentTemplate]="contentTemplateRef"></bizz-modal>

<ng-template #headerTemplateRef>
    <div class="modal-header">
        <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
            {{ 'PropertyChanged' | translate }}</h1>
    </div>
    <hr class="my-5" />
</ng-template>

<ng-template #contentTemplateRef>
    <div class="modal-content">
        <p class="mb-2 inline-block text-sm text-default">
            {{auditLogItem.Name}} {{'PropertyHasBeenChangedTo' | translate}} {{auditLogItem.NewValue}}.</p>
    </div>
</ng-template>