import { Component, input } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { SidebarService } from '../../../services/sidebar/sidebar.service';
import { IconComponent } from '../icon/icon.component';
import { HeaderUserComponent } from './sub-components/header-user/header-user.component';
import { HeaderRemindersComponent } from './sub-components/header-reminders/header-reminders.component';
import { HeaderNotificationsComponent } from './sub-components/header-notifications/header-notifications.component';
import { HeaderTasksComponent } from './sub-components/header-tasks/header-tasks.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TranslatePipe } from '../../../pipes/translate/translate.pipe';
import { HeaderNotificationsService } from '../../../services/header/header-notifcations/header-notifications.service';
import { HeaderRemindersService } from '../../../services/header/header-reminders/header-reminders.service';
import { BrandLogoComponent, BrandLogoType } from '../../brand-logo/brand-logo.component';
import { HeaderTaskService } from '../../../services/header/header-task/header-task.service';
import { RouterLink } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import * as Sentry from '@sentry/angular';
import { HeaderHelpComponent } from './sub-components/header-help/header-help.component';
import { HeaderComponentMode } from './header-component-mode';
import { TranslationService } from '../../../../core/services/translation/translation.service';
import { EditorModule } from '@progress/kendo-angular-editor';
import { tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { userSettingsFeature } from '../../../../store/features/user-settings/user-settings-feature';
import { DropDownListModule, SharedDirectivesModule } from '@progress/kendo-angular-dropdowns';
import { LanguageDto } from '../../../../../models/ts/language-dto.model';
import { userSettingsActions } from '../../../../store/features/user-settings/user-settings-actions';

@Component({
  selector: 'bizz-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    IconComponent,
    HeaderUserComponent,
    HeaderHelpComponent,
    HeaderRemindersComponent,
    HeaderNotificationsComponent,
    HeaderTasksComponent,
    TooltipModule,
    TranslatePipe,
    BrandLogoComponent,
    RouterLink,
    NgIf,
    AsyncPipe,
    EditorModule,
    DropDownListModule,
    SharedDirectivesModule
  ]
})
@Sentry.TraceClass({ name: 'HeaderComponent' })
export class HeaderComponent {
  public readonly BrandLogoType = BrandLogoType;
  public mode = input<HeaderComponentMode>(HeaderComponentMode.DEFAULT);
  public readonly HeaderComponentMode = HeaderComponentMode;

  protected readonly BrandLogoComponent = BrandLogoComponent;
  protected readonly environment = environment;
  public selectedLanguage: number | undefined;
  public selectedLanguageDto: LanguageDto | undefined;

  public languageOptions$ = this.translationService.getLanguageOptions().pipe(tap((options) => {
    const anon = this.store$.selectSignal(userSettingsFeature.selectAnonymousPortalUserLanguage)();
    const user = this.store$.selectSignal(userSettingsFeature.selectUserLanguagesID)();
    this.selectedLanguage = anon != undefined && anon > 0 ? anon : user > 0 ? user : options != undefined ? options?.find(_ => _.IsSystemLanguage)?.ID ?? options?.[0]?.ID : undefined;
    this.selectedLanguageDto = options?.find(_ => _.ID == this.selectedLanguage);
  }));

  public constructor(
    private sidebarService: SidebarService,
    private headerTaskService: HeaderTaskService,
    private headerNotificationsService: HeaderNotificationsService,
    private headerRemindersService: HeaderRemindersService,
    private translationService: TranslationService,
    private store$: Store) {
    this.headerTaskService.startPolling();
    this.headerRemindersService.startPolling();
    this.headerNotificationsService.startPolling();
  }

  public toggleSidebar(): void {
    this.sidebarService.toggleSidebar();
  }

  public changeLanguageSelection(language: {ID: number | undefined}): void {
    this.selectedLanguage = language.ID;
    if(this.selectedLanguage != undefined && this.selectedLanguage > 0) {
      this.store$.dispatch(userSettingsActions.setAnonymousPortalLanguage({language: this.selectedLanguage}));
    }

  }
}
