import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DocumentTreeFolderDto } from '../../../../models/ts/document-tree-folder-dto.model';
import { GridSearchFilterSearchDto } from '../../../../models/ts/grid-search-filter-search-dto.model';
import { GridDataDto } from '../../../../models/ts/grid-data-dto.model';
import { FolderDto } from '../../../../models/ts/folder-dto.model';
import { FolderDisplayFieldTemplateDto } from '../../../../models/ts/folder-display-field-template-dto.model';
import { UIFolderProperties } from 'src/app/features/bizzmine/widgets/document-tree-widget/modals/create-folder-modal/interfaces/ui-folder-properties';
import { UIFolderDisplayFieldTemplate } from 'src/app/features/bizzmine/widgets/document-tree-widget/modals/create-folder-modal/interfaces/ui-folder-display-field-template';
import { UITemplates } from 'src/app/features/bizzmine/widgets/document-tree-widget/modals/create-folder-modal/interfaces/ui-templates';
import { CollectionListDataInstance } from '../../../shared/interfaces/collection-list-data-instance';

@Injectable({
  providedIn: 'root',
})
export class DocumentTreeApiService {

  constructor(private http: HttpClient) {
  }

  /**
   * Retrieves the document tree data.
   * @param widgetID the id of the doctree widget
   * @param folderID the id of the folder
   * @param searchFilterSearchDto the search filter
   * @private
   */
  public getDocumentTreeData(widgetID: number, folderID: number, searchFilterSearchDto: Partial<GridSearchFilterSearchDto>): Observable<DocumentTreeFolderDto[]> {
    return this.http.post<DocumentTreeFolderDto[]>(`api/documenttree/${widgetID}/folder/${folderID}/children`, searchFilterSearchDto);
  }


  public getFolderData(listID: number, folderID: number, searchFilterSearchDto: Partial<GridSearchFilterSearchDto>):
    Observable<GridDataDto> {
    return this.http.post<GridDataDto>
    (`api/collection/list/${listID}/folder/${folderID}/search`, searchFilterSearchDto);
  }

  /**
   * Delete Document Tree Folder
   * @param widgetID the id of the doctree widget
   * @param folderID the id of the folder
   */
  public deleteFolder(widgetID: number, folderID: number): Observable<void> {
    return this.http.delete<void>(`api/documenttree/${widgetID}/folder/${folderID}`);
  }

  /**
   * Get a created folder
   * @param widgetID the id of the doctree widget
   * @param parentFolderID the id of the parent folder
   */
  public getCreateFolder(widgetID: number, parentFolderID: number): Observable<FolderDto> {
    return this.http.get<FolderDto>(`api/documenttree/${widgetID}/folder/create/parent/${parentFolderID}`);
  }

  /**
   * Get Folder
   * @param widgetID
   * @param folderID
   */
  public getFolder(widgetID: number, folderID: number): Observable<FolderDto> {
    return this.http.get<FolderDto>(`api/documenttree/${widgetID}/folder/${folderID}`);
  }

  /**
   * Save Folder
   * @param widgetID
   * @param folderID
   */
  public saveFolder(widgetID: number, folderID: number, settings: FolderDto ): Observable<FolderDto> {
    return this.http.post<FolderDto>(`api/documenttree/${widgetID}/folder/${folderID}`, settings);
  }

  /**
   * Creates a new folder within the document tree.
   *
   * @param widgetID - The ID of the widget.
   * @param folderID - The ID of the parent folder.
   * @param settings - The settings for the new folder.
   * @returns An observable that emits the created folder.
   */
  public createFolder(widgetID: number, folderID: number, settings: FolderDto): Observable<FolderDto> {
    return this.http.post<FolderDto>(`api/documenttree/${widgetID}/folder/create/parent/${folderID}`, settings);
  }

  public linkToFolder(widgetId: number, folderId: number, instancesIds: number[]): Observable<void> {
    return this.http.post<void>(`api/documenttree/${widgetId}/folder/${folderId}/instances`, instancesIds);
  }

  /**
   * Unlinks a file from a folder in the document tree.
   *
   * @param widgetID - The ID of the widget to unlink.
   * @param folderID - The ID of the folder from which to unlink the widget.
   * @param collectionID - The ID of the collection to which the widget belongs.
   * @param instanceID - The ID of the widget instance.
   * @returns An Observable that emits void when the unlink operation is successful.
   */
  public unlinkFromFolder(widgetID: number, folderID: number, collectionID: number, instanceID: number): Observable<void>{
    return this.http.delete<void>(`api/documenttree/${widgetID}/folder/${folderID}/collection/${collectionID}/instance/${instanceID}/unlink`);
  }

  /**
   * Get
   * @param widgetID
   * @param folderID
   */
  public getUncheckFolderTemplates(widgetID: number, folderID: number): Observable<boolean> {
    return this.http.get<boolean>(`api/documenttree/${widgetID}/folder/${folderID}/uncheckfoldertemplates`);
  }

  public getUncheckDisplayFieldTemplates(widgetID: number, folderID: number): Observable<boolean> {
    return this.http.get<boolean>(`api/documenttree/${widgetID}/folder/${folderID}/uncheckdisplayfieldtemplates`);
  }

  /**
   * Validate the folder display field templates
   * @param widgetID
   * @param templates
   */
  public validateFolderTemplates(widgetID: number, templates: UITemplates): Observable<void> {
    return this.http.post<void>(`api/documenttree/${widgetID}/folder/templates/validate`, templates);
  }

  public validateProperties(widgetId: number, properties: UIFolderProperties): Observable<void> {
    return this.http.post<void>(`api/documenttree/${widgetId}/folder/properties/validate`, properties);
  }
  /**
   * Get the create folder template
   * @param widgetID
   * @param collectionID
   */
  public getCreateTemplate(widgetID: number, collectionID: number): Observable<FolderDisplayFieldTemplateDto> {
    return this.http.get<FolderDisplayFieldTemplateDto>(`api/documenttree/${widgetID}/collection/${collectionID}/template/create`);
  }

}
