import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FlowStatusTaskType } from '../../../interfaces/flow-status-task-type';
import { FlowStatusTasksGridMobileColumnComponent } from './columns/flow-status-tasks-grid-mobile-column/flow-status-tasks-grid-mobile-column.component';
import { FlowStatusTasksGridCompletedByColumnComponent } from './columns/flow-status-tasks-grid-completed-by-column/flow-status-tasks-grid-completed-by-column.component';
import { SkeletonModule } from '@progress/kendo-angular-indicators';
import { StatusIconComponent } from '../../../../../../shared/components/ui/status-icon/status-icon.component';
import { TranslatePipe } from '../../../../../../shared/pipes/translate/translate.pipe';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { FlowStatusTaskDto } from '../../../../../../../models/ts/flow-status-task-dto.model';
import { OrganizationChartItemType } from 'src/models/ts/organization-chart-item-type.model';
import { OrgChartSelectComponent } from 'src/app/features/bizzmine/org-chart/org-chart-select/org-chart-select.component';

@Component({
  selector: 'bizz-flow-status-tasks-grid',
  standalone: true,
  imports: [CommonModule, GridModule, StatusIconComponent, TranslatePipe, IconComponent, NgScrollbarModule, FlowStatusTasksGridMobileColumnComponent, FlowStatusTasksGridCompletedByColumnComponent, SkeletonModule, OrgChartSelectComponent],
  templateUrl: './flow-status-tasks-grid.component.html',
  styleUrls: ['./flow-status-tasks-grid.component.scss'],
})
export class FlowStatusTasksGridComponent {

  @Input({ required: true }) public tasks: FlowStatusTaskDto[];

  @Input({ required: true }) public taskType: FlowStatusTaskType;

  @Output() public showAssessmentDetails = new EventEmitter<FlowStatusTaskDto>();
  @Output() public taskDetails = new EventEmitter<FlowStatusTaskDto>();


  public readonly MAX_MOBILE_WIDTH = 1024;
  public readonly TAILWIND_LG_BR = 1280;

  protected readonly OrganizationChartItemType = OrganizationChartItemType;
  protected readonly FlowStatusTaskType = FlowStatusTaskType;

}
