@if (formFieldSignal(); as formField) {
    <div class="flex input-button-group rounded" [ngClass]="{'hidden' : formField.IsHidden}" tabIndex="-1"
         [class.error]="errorState != null">

        @if (integrity(); as integrity) {
            @if (integrity.ExamUsages != null && integrity.ExamUsages.length > 0) {
                <button [title]="'ExamIsUsedInOtherPlaces' | translate"
                        (click)="showExamUsages()"
                        type="button"
                        class="default rounded-r-none border-form-control">
                    <bizz-icon class="size-4 text-red-400" iconName="square-question">
                    </bizz-icon>
                </button>
            }
            @if (integrity.LookupMismatches != null && integrity.LookupMismatches.length > 0) {
                <button [title]="'SkillsDoNotMatch' | translate"
                        (click)="showSkillMismatches()"
                        type="button"
                        class="default rounded-r-none border-form-control">
                    <bizz-icon class="size-4 text-red-400" iconName="square-question">
                    </bizz-icon>
                </button>
            }
        }

        <div class="relative h-full w-full">
            @if (errorState?.Message; as errorMessage) {
                <div class="absolute right-4 top-0 z-[2] flex h-full items-center justify-center">
                    <bizz-tooltip class="cursor-pointer text-red-400" [text]="errorMessage"></bizz-tooltip>
                </div>
            }
            <!-- TODO: seperate combobox configuration for NMBIL since allowCustom to true messes up when filtering and not picking an option -->
            <kendo-combobox #input [data]="searchResults"
                            [formControl]="formControl"
                            [textField]="'Text'"
                            [valueField]="'InstancesID'"
                            [filterable]="true"
                            [suggest]="true"
                            [readonly]="readOnlyOverride || formField.IsReadOnly"
                            [tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0"
                            [clearButton]="false"
                            [allowCustom]="false"
                            rounded="none"
                            class="hide-input-button !rounded-l !rounded-r-none"
                            [class.!rounded-l-none]="integrity() != null"
                            [ngClass]="{'lookup-selected-item' : formControl.value != undefined}"
                            (keydown.backspace)="clearLookup()"
                            (filterChange)="search$.next($event)">
                <ng-template kendoComboBoxNoDataTemplate>
                </ng-template>
            </kendo-combobox>
            {{ search$ | async }}
        </div>


        <bizz-linked-form-control-buttons [viewDataSource]="fieldViewDataSource" [formField]="formField"
                                          [readOnly]="readOnlyOverride || formField.IsReadOnly"
                                          tabIndex="-1"
                                          [ngClass]="{'readonlyTab' : readOnlyOverride || formField.IsReadOnly}"
                                          (actionClicked)="handleLinkedFormControlAction($event)"
                                          [externalAccess]="externalAccess"
                                          [userType]="userType"
                                          [formType]="form?.data?.FormType">
        </bizz-linked-form-control-buttons>
    </div>
}