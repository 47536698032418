import { CollectionFormField } from '../../../../../models/ts/collection-form-field.model';
import { Type } from '@angular/core';
import { DynamicFormControlComponent } from '../../../../shared/interfaces/dynamic-form-control-component';
import { TableFieldDataType } from '../../../../../models/ts/table-field-data-type.model';
import { CollectionFormService } from '../services/collection-form.service';
import { FormListControlComponent } from '../components/controls/form-list-control/form-list-control.component';
import { TABLE_FIELD_DATA_TYPE_COMPONENTS } from '../constants/table-field-data-type-components';
import { LinkedFormControlComponent } from '../components/controls/linked-form-control/linked-form-control.component';
import { FormFieldType } from '../../../../../models/ts/form-field-type.model';
import { FormLinkedCollectionControlComponent } from '../components/controls/form-linked-collection-control/form-linked-collection-control.component';

export function resolveControlComponent(field: CollectionFormField): Type<DynamicFormControlComponent> {
  // Linked Field/Grid
  const isProtected = field.ComponentType == TableFieldDataType.ExamScoreEvaluations || field.ComponentType == TableFieldDataType.ExamCreator;
  if(field.FormFieldType == FormFieldType.LinkedCollection){
    return FormLinkedCollectionControlComponent;
  }

  if(field.FormFieldType == FormFieldType.List){
    return FormListControlComponent;
  }

  //Note ReversedUserCollection has a field.ComponentType of OrganizationChartUnitSelector
  //So we always need to check if the field is a grid first.
  if (CollectionFormService.fieldIsGrid(field)) {
    return FormListControlComponent;
  }
  if (!isProtected && field.IsLookupField) {
    return LinkedFormControlComponent;
  } else {
    return TABLE_FIELD_DATA_TYPE_COMPONENTS[field.ComponentType];
  }
}