import { DestroyRef, inject } from '@angular/core';
// Choose a border color
// Lighten the border color with the following tool
// http=//pinetools.com/lighten-color
// 98.16%

import { Inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { filter, map } from "rxjs";
import { ThemeService } from "src/app/core/services/theme/theme.service";
import { FlowDiagramColors } from '../interfaces/flow-diagram-colors';
import { ThemeBrandingDto } from 'src/models/ts/theme-branding-dto.model';
import * as go from 'gojs';

// This class contains definitions for all colors used in the diagram.
@Injectable({
  providedIn: 'root'
})
export class FlowDiagramColorService {

  private  colors = {
    backLinkColor: '#f1b4b4',
    linkColor: '#CCD2E1',
    swimlaneDrag: '#efefef',
    swimlaneBorder: '#EAF0F6',
    swimlaneHeader: '#F8FAFC',
    blockColor: '#ffffff',
    blockBorderColor: '#8390AF',
    anonymousBlockColor: '#FFEBCC',
    portColor: '#aaaaaa',
    publicationBlockColor: '#F8FAFC',
    publicationBlockBorderColor: '#75b329',
    assessmentGatewayBlockColor: '#F9DFD2',
    assessmentBlockColor: '#F8FAFC',
    assessmentBlockBorderColor: '#E85B15',
    distributionBlockColor: '#F8FAFC',
    distributionBlockBorderColor: '#009DD8',
    textColor: '#394C62',
    gatewayBorderColor: '#E3BD14',
    gatewayBlockColor: '#FDF3CE',
    startBlockColor: '#D0EFC2',
    startBorderColor: '#3FAD10',
    endBlockColor: '#F7D7D4',
    endBorderColor: '#ED3F39',
    // Flow Status Colors
    openTask: '#009dd8',
    timedTask: '#40E0D0',
    expiredTask: '#E85B15',
    completedTask: '#3fad10',
    cancelledTask: '#666',
    // Selection colors
    selectedStep: '#009dd8',
    subscribeToExamBlockColor: '#fefbfa',
    subscribeToExamBlockBorderColor: '#556a',
    examinationBlockColor: '#fefbfa',
    examinationBlockBorderColor: '#01C1A7',
    popupBackgroundColor: '#242E38',
    popupTextColor: '#F8FAFC',
    shadowColor: 'rgba(0,0,0,0.5)',
    backgroundDotColor: '#EAF0F6',
} as FlowDiagramColors;

  
  @Inject(DestroyRef) private destroyRef: DestroyRef;
  private themeService: ThemeService;

  public get Colors(): FlowDiagramColors {
    return this.colors;
  }

  public constructor() {
    this.themeService = inject(ThemeService);
    this.themeService.currentTheme$.
    pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(theme => theme != null),
      map(theme => {
        this.applyThemeColors(theme!.BrandingV2)})
    ).subscribe();
    if(this.themeService.currentTheme$.value != null)
      this.applyThemeColors(this.themeService.currentTheme$.value?.BrandingV2);
  }

  private applyThemeColors(themeColors: ThemeBrandingDto): void {
      if(!themeColors)
        throw new Error('Flow status colors.Tried to apply theme colors with value null.');
    
    this.colors.swimlaneHeader = `rgb(${themeColors['--button-bg']})`;
    this.colors.textColor = `rgb(${themeColors['--text-default']})`;
    this.colors.linkColor = `rgb(${themeColors['--button-bg-hover']})`;

    this.colors.blockColor = `rgb(${themeColors['--body-popup-bg']})`;
    this.colors.blockBorderColor = `rgb(${themeColors['--button-bg-hover']})`;
    this.colors.subscribeToExamBlockColor = `rgb(${themeColors['--button-bg']})`;
    this.colors.examinationBlockColor = `rgb(${themeColors['--button-bg']})`;
    this.colors.swimlaneBorder = `rgb(${themeColors['--button-bg']})`;
    this.colors.swimlaneDrag = `rgb(${themeColors['--button-bg']})`;

    //We have to calculate the colors with color-mix function based on the bg instead of using a regular transparent color
    //as the blocks have a shadow which "muddies" the color.
    /*this.colors.gatewayBlockColor = `color-mix(in srgb, #E3BD14 30%, rgb(${themeColors['--body-bg']}))`;
    this.colors.startBlockColor = `color-mix(in srgb, #3fad10 25%, rgb(${themeColors['--body-bg']}))`;
    this.colors.assessmentGatewayBlockColor = `color-mix(in srgb, #E85B15 25%, #151d26)`;
    this.colors.assessmentBlockColor = `color-mix(in srgb, #E85B15 10%, rgb(${themeColors['--body-bg']}))`;
    this.colors.distributionBlockColor = `color-mix(in srgb, #009dd8 10%, rgb(${themeColors['--body-bg']}))`;
    this.colors.endBlockColor = `color-mix(in srgb, #ED3F39 25%, rgb(${themeColors['--body-bg']}))`;
    this.colors.backLinkColor = `color-mix(in srgb, #ED3F39 60%, rgb(${themeColors['--body-bg']}))`;
    this.colors.publicationBlockColor = `color-mix(in srgb, #3fad10 10%, rgb(${themeColors['--body-bg']}))`;
    this.colors.anonymousBlockColor = `color-mix(in srgb, #E3BD14 10%, rgb(${themeColors['--body-bg']}))`;
    this.colors.subscribeToExamBlockColor = `color-mix(in srgb, #14D9C5 10%, rgb(${themeColors['--body-bg']}))`;
    this.colors.examinationBlockColor = `color-mix(in srgb, #14D9C5 10%, rgb(${themeColors['--body-bg']}))`;*/
    
    this.colors.gatewayBlockColor = this.mixColors('#E3BD14', `rgb(${themeColors['--body-bg']})`, 0.7);
    this.colors.startBlockColor = this.mixColors('#3fad10', `rgb(${themeColors['--body-bg']})`, 0.75);
    this.colors.assessmentGatewayBlockColor = this.mixColors('#E85B15', `rgb(${themeColors['--body-bg']})`, 0.75);
    this.colors.assessmentBlockColor = this.mixColors('#E85B15', `rgb(${themeColors['--body-bg']})`, 0.9);
    this.colors.distributionBlockColor = this.mixColors('#009dd8', `rgb(${themeColors['--body-bg']})`, 0.9);
    this.colors.endBlockColor = this.mixColors('#ED3F39', `rgb(${themeColors['--body-bg']})`, 0.75);
    this.colors.backLinkColor = this.mixColors('#ED3F39', `rgb(${themeColors['--body-bg']})`, 0.4);
    this.colors.publicationBlockColor = this.mixColors('#3fad10', `rgb(${themeColors['--body-bg']})`, 0.9);
    this.colors.anonymousBlockColor = this.mixColors('#E3BD14', `rgb(${themeColors['--body-bg']})`, 0.9);
    this.colors.subscribeToExamBlockColor = this.mixColors('#14D9C5', `rgb(${themeColors['--body-bg']})`, 0.9);
    this.colors.examinationBlockColor = this.mixColors('#14D9C5', `rgb(${themeColors['--body-bg']})`, 0.9);


    this.colors.popupBackgroundColor = `rgb(${themeColors['--body-popup-bg']})`;
    this.colors.popupTextColor = `rgb(${themeColors['--text-default']})`;
  }


  private mixColors(colorA: string, colorB:string, fraction: number): string {
     return go.Brush.mix(colorA, colorB, fraction);
  }

}