import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridFilterDto } from '../../../../models/ts/grid-filter-dto.model';
import { OperatorDto } from '../../../../models/ts/operator-dto.model';
import { ItemDto } from '../../../../models/ts/item-dto.model';
import { List } from 'gojs';
import { SearchFilterDto } from '../../../../models/ts/search-filter-dto.model';
import { FilterItemType } from '../../../../models/ts/filter-item-type.model';
import { TrnUserSkillFilterValueDto } from '../../../../models/ts/trn-user-skill-filter-value-dto.model';
import { TrnFunctionSkillFilterDto } from '../../../../models/ts/trn-function-skill-filter-dto.model';
import { TrnUserSkillFilterDto } from '../../../../models/ts/trn-user-skill-filter-dto.model';

@Injectable({
  providedIn: 'root'
})
export class FilterApiService {

  public constructor(private http: HttpClient) { }

  public getFiltersByViewsID(viewID: number): Observable<GridFilterDto> {
    return this.http.get<GridFilterDto>(`api/filter/grid/view/${viewID}`);
  }

  public getFiltersByViewWidgetId(viewID: number, widgetID: number): Observable<GridFilterDto> {
    return this.http.get<GridFilterDto>(`api/filter/grid/view/${viewID}/widget/${widgetID}`)
  }

  public getTaskListFilter(filtersId: number, collectionsId: number, widgetId: number): Observable<GridFilterDto> {
    return this.http.get<GridFilterDto>(`api/filter/grid/tasklistfilter/${filtersId}/collection/${collectionsId}/widget/${widgetId}`);
  }

  public getFilterData(filterId: number): Observable<GridFilterDto> {
    return this.http.get<GridFilterDto>(`api/filter/grid/filter/${filterId}`);
  }

  public getFilterOperators(): Observable<OperatorDto> {
    return this.http.get<OperatorDto>(`api/filter/operators`);
  }
  public getFiltergrouptype(): Observable<ItemDto[]> {
    return this.http.get<ItemDto[]>(`api/filter/filtergrouptype`);
  }

  public getFilterList(viewsId: number): Observable<SearchFilterDto[]> {
    return this.http.get<SearchFilterDto[]>(`api/filter/view/${viewsId}/filterList`);
  }

  public getFilterByItemType(filterItemType : FilterItemType): Observable<GridFilterDto> {
    return this.http.get<GridFilterDto>(`api/filter/${filterItemType}`);
  }

  public getFilterByItemTypeAndCollection(filterItemType : FilterItemType | undefined, collectionsId: number): Observable<GridFilterDto> {
    return this.http.get<GridFilterDto>(`api/filter/${filterItemType}/collection/${collectionsId}`);
  }

  public getMatrixFilter(trnAppId: number, skillCollectionId: number): Observable<TrnUserSkillFilterDto> {
    return this.http.get<TrnUserSkillFilterDto>(`api/trainingapps/${trnAppId}/defaultuserfilter/${skillCollectionId}`);
  }
}