<bizz-modal modalSize="lg" [headerTemplate]="headerTemplateRef" [contentTemplate]="contentTemplateRef"></bizz-modal>

<ng-template #headerTemplateRef>
    <div class="modal-header">
        <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
            {{ 'TrackChanges' | translate }}</h1>
    </div>
    <hr class="my-5" />
</ng-template>

<ng-template #contentTemplateRef>
    <div class="modal-content overflow-auto">
        <bizz-track-changes-grid [data]="data"></bizz-track-changes-grid>
    </div>
</ng-template>