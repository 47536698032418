<span class="truncate text-sm font-normal text-widget sm:text-xs">
      @switch(column.GridColumnType) {
        @case(GridColumnType.Date) {
          {{ value | date }}
        }
        @case(GridColumnType.DateTime) {
          {{ value | datetime }}
        }
        @case(GridColumnType.Time) {
          {{ value | time }}
        }
      }
</span>
