import { Platform } from '@angular/cdk/platform';
import { DestroyRef, inject, Inject, Injectable } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { setManifestFile, Webmanifest, webmanifestConfig } from '../../functions/webmanifest';
import { BizzMineLocalStorageService } from 'src/app/shared/services/localStorage/bizzmine-local-storage.service';
import { environment } from 'src/environments/environment.prod';
import { SwUpdate } from '@angular/service-worker';
import { AlertService } from 'src/app/features/bizzmine/alerts/alert.service';
import { Router } from '@angular/router';
import { ThemeService } from '../theme/theme.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, map, take } from 'rxjs';

export interface CacheVersions {
  current: string | null;
  previous: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  public promptEvent: any;
  public cacheVersions: CacheVersions = {
    current: null,
    previous: null,
  }

  constructor(
    private localStorage: BizzMineLocalStorageService,
    private alertService: AlertService,
    private swUpdate: SwUpdate,
    private destroyRef: DestroyRef,
    private themeService: ThemeService
  ) {
    this.config();
    this.initialize();
  }

  private config() {
    this.cacheVersions.current = environment.appVersion;
    this.cacheVersions.previous = this.localStorage.getItem('previousCacheVersion');
    this.localStorage.set('previousCacheVersion', environment.appVersion);

    if (this.isVersionUpdated() && this.cacheVersions.previous) {
      //update
      console.log('Updated to version', this.cacheVersions.current);
    } else {
      //no update
      console.log('Started version', this.cacheVersions.previous);
    }
  }

  public initialize() {
    if (this.swUpdate.isEnabled) {
      console.log('PWA Service initialized ');
      //set webmanifestConfig
      this.themeService.currentTheme$.pipe(filter(theme => theme != null), take(1), map(theme => {
        const manifestConfig = new webmanifestConfig({
          background_color: `rgb(${theme.BrandingV2['--body-bg']})`,
          theme_color: `rgb(${theme.BrandingV2['--body-bg']})`,
        })
        setManifestFile(manifestConfig);
      })).subscribe();

      this.alertService.setAlert({
        type: 'info',
        title: 'Bizzmine',
        content: ['Started version ' + this.cacheVersions.current],
        icon: 'info-circle',
        dismissable: true,
        timed: true,
        timer: 3000,
      })
      this.checkForUpdate();
    }

  }
  public checkForUpdate(): void {
    this.swUpdate.checkForUpdate().then((hasUpdate: boolean) => {
      if (hasUpdate) {
        this.installUpdate();
      }
    });
  }

  private isVersionUpdated(): boolean {
    return this.cacheVersions.current !== this.cacheVersions.previous;
  }

  public async installUpdate() {
    console.log('Update installing now ...');
    //clear cache
    this.clearPwaCache();

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  private async clearPwaCache() {
    console.log('Clearing cache ...');
    // Delete cached files
    if ('serviceWorker' in navigator) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach(function (cacheName) {
          caches.delete(cacheName);
        });
      });
    }

    // Remove SW
    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
          registration.unregister()
        }
      });
    } else {
      console.log('No SW found');
    }
  }


  public isPwa() {
    return ["fullscreen", "standalone", "minimal-ui"].some(
      (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
    );
  }

}
