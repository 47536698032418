import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BizzMineSessionStorageService } from '../../../../shared/services/localStorage/bizzmine-session-storage.service';
import { AccessToken } from '../../../../core/constants/access-token';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { formsActions } from '../../../../store/features/forms/forms-actions';
import { ViewStackComponent } from '../../../../shared/components/ui/view-stack/view-stack.component';
import { HeaderComponent } from '../../../../shared/components/ui/header/header.component';
import { AsyncPipe } from '@angular/common';
import { ContextMenuComponent } from '../../../../features/bizzmine/widgets/context-menu/context-menu.component';
import { LoaderComponent } from '../../../../shared/components/ui/loader/loader.component';
import { SidebarComponent } from '../../../../shared/components/ui/sidebar/sidebar.component';
import { StackedAlertsComponent } from '../../../../shared/components/ui/stacked-alerts/stacked-alerts.component';
import { HeaderComponentMode } from '../../../../shared/components/ui/header/header-component-mode';
import { userSettingsActions } from '../../../../store/features/user-settings/user-settings-actions';
import { asapScheduler, catchError, filter, take } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { ExternalCompletedComponent } from '../external-completed/external-completed.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserType } from '../../../../../models/ts/user-type.model';
import { viewStackActions } from '../../../../store/features/forms/view-stack-actions';
import { ViewStackLoaderType } from '../../../../shared/enums/view-stack-event-type.enum';

@Component({
  selector: 'bizz-external-user-token',
  standalone: true,
  imports: [
    ViewStackComponent,
    HeaderComponent,
    AsyncPipe,
    ContextMenuComponent,
    LoaderComponent,
    RouterOutlet,
    SidebarComponent,
    StackedAlertsComponent,
    ExternalCompletedComponent
  ],
  templateUrl: './external-user-token.component.html',
  styleUrl: './external-user-token.component.scss'
})
export class ExternalUserTokenComponent implements OnInit, OnDestroy {
  public HeaderComponentMode = HeaderComponentMode.EXTERNAL_USER;
  public state: ExternalUserTokenState = ExternalUserTokenState.DEFAULT;
  public readonly ExternalUserTokenState = ExternalUserTokenState;
  public readonly externalTasksId = 'etid';
  public readonly anonymousCollectionId = 'arcol';
  public readonly store$ = inject(Store);
  public readonly actions$ = inject(Actions);
  private destroyRef = inject(DestroyRef);
  public type = ExternalActionType.ExternalUserTask;
  private activatedRoute = inject(ActivatedRoute);
  private httpClient = inject(HttpClient);
  private sessionStorage = inject(BizzMineSessionStorageService);
  private router = inject(Router);
  private formId: string;
  private returnUrl: string | null;

  public constructor() {
    this.actions$.pipe(ofType(userSettingsActions.setAnonymousPortalLanguage),takeUntilDestroyed(this.destroyRef)).subscribe(data => {
      if(data && data.language){
        this.refreshToken(data.language);
      }
    });

    this.actions$.pipe(ofType(userSettingsActions.userSettingsFetched), take(1)).subscribe(data => {
      if(data && data.userSettings.UserType == UserType.AnonymousUser){
        this.HeaderComponentMode = HeaderComponentMode.ANONYMOUS_USER;
      }
    });
  }

  public get title(): string {
    switch (this.state) {
      case ExternalUserTokenState.COMPLETED:
        return 'ThankYou';
      case ExternalUserTokenState.ERROR:
        return 'SomethingWentWrong';
      case ExternalUserTokenState.EXPIRED:
        return 'TaskAlreadyCompleted';
      default:
        return '';
    }
  }

  public refreshToken(languageId: number): void {
    const token = this.activatedRoute.snapshot.queryParamMap.get('token');
      this.httpClient.post(`api/anonreg/refresh`, {Token: token, LanguagesID: languageId}, {responseType: 'text'}).pipe(take(1)).subscribe(returnedToken => {
        this.refreshForm(returnedToken);
      });
  }


  public get info(): string {
    switch (this.state) {
      case ExternalUserTokenState.COMPLETED:
        return 'TheFormWasSubmittedSuccessfully';
      case ExternalUserTokenState.ERROR:
        return 'PleaseTryAgainLater';
      default:
        return '';
    }
  }

  public ngOnInit(): void {
    const token = this.activatedRoute.snapshot.queryParamMap.get('token');
    this.refreshForm(token);
  }

  private refreshForm(token: string | null): void {
    this.store$.dispatch(viewStackActions.showLoadingState({loaderType: ViewStackLoaderType.SKELETON}));
    this.sessionStorage.clear();
    this.httpClient.get(`api/account/externaluser?token=${token}&type=${this.type}`, {
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'x-tenant': window.location.host
      },
      responseType: 'text'
    })
      .pipe(catchError((error) => {

        if (error.url?.toLowerCase()?.includes('expired')) {
          this.state = ExternalUserTokenState.EXPIRED;
        } else {
          this.state = ExternalUserTokenState.ERROR;
        }
        throw error;
      }))
      .subscribe(decrypted => {
        this.sessionStorage.set('access_token', decrypted);
        if (decrypted != null) {
          const mapped = this.decodedAccessToken(decrypted);
          if (mapped) {
            const expiredTime = mapped['exp'];
            this.sessionStorage.set('expires_at', (+moment.unix(+expiredTime)).toString());
            const theme = this.activatedRoute.snapshot.queryParamMap.get('theme');
            const language = mapped['arlid'];
            this.returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
            this.store$.dispatch(userSettingsActions.fetchUserSettingsWithOverRules({
              themeId: theme ? +theme : undefined,
              language: language ? +language : undefined
            }));

            this.fetchForm(mapped);

          }
        }
      });
  }

  public ngOnDestroy(): void {
    this.sessionStorage.clear();
  }

  public fetchForm(accessToken: AccessToken | undefined): void {
    if (accessToken == null) return;
    const taskId = +accessToken[this.externalTasksId];
    this.actions$.pipe(ofType(formsActions.formFetched.type), filter(fetched => fetched.form.data.TaskInfo.ID == taskId), take(1))
      .subscribe(fetched => this.setFormId(fetched.form.id));
    this.store$.dispatch(formsActions.getTaskForm({ taskId: taskId }));
  }

  public decodedAccessToken(token: string): AccessToken | undefined {
    if (token) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
        );
        return JSON.parse(jsonPayload);
      } catch (error) {
        throw new Error('Error: Failed to decode access token');
      }
    }
    return undefined;
  }

  public setFormId(formId: string): void {
    this.formId = formId;
    this.actions$.pipe(ofType(formsActions.formClosed, formsActions.formCanceled),
      filter(_ => _.formId === this.formId),
      take(1)
    )
      .subscribe(action => {
          if (action.type === formsActions.formCanceled.type) {
            this.state = ExternalUserTokenState.CANCELED;
            this.sessionStorage.clear();
            if (this.returnUrl != undefined && this.returnUrl.trim() != '') {
              this.router.navigate([this.returnUrl]);
            }
            return;
          }

          this.state = ExternalUserTokenState.COMPLETED;
          this.sessionStorage.clear();
          if (this.returnUrl != undefined && this.returnUrl.trim() != '') {
            asapScheduler.schedule(() => {
              this.router.navigate([this.returnUrl]);
            }, 5000);
          }
        }
      );
  }
}

export enum ExternalUserTokenState {
  DEFAULT,
  EXPIRED,
  COMPLETED,
  ERROR,
  CANCELED
}

export enum ExternalActionType {
  None = 0,
  ExternalUserTask = 1,
  AnonymousRegistration = 2
}
