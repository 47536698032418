import { Component, DestroyRef, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { LinkedCollectionStorageType } from 'src/models/ts/linked-collection-storage-type.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe, NgClass } from '@angular/common';
import {ComboBoxModule} from "@progress/kendo-angular-dropdowns";

/**
 * Represents a control that allows the user to enter an email address.
 * The email address can be opened in the default mail client.
 */
@Component({
  selector: 'bizz-email-control',
  standalone: true,
    imports: [
        ReactiveFormsModule,
        IconComponent,
        AsyncPipe,
        NgClass,
        ComboBoxModule
    ],
  templateUrl: './email-control.component.html',
  styleUrls: ['./email-control.component.scss'],
})
export class EmailControlComponent extends BaseFormControlComponent implements OnInit {
  public disableMailButton = true;
  @ViewChild('input') inputElement: ElementRef;

  public override ngOnInit(): void {
    super.ngOnInit();
    if (this.formControl) {
      this.disableMailButton = (this.formControl.value === '');
      this.formControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: string) => {
        // TODO: refine validation of email
        this.disableMailButton = (value === '');
      });
    }
  }

  public openMailClient(): void {
    window.open(`mailto:${this.formControl.value}`, '_blank');
  }

  protected readonly LinkedCollectionStorageType = LinkedCollectionStorageType;
  protected override focus(): void {
      this.inputElement.nativeElement.focus();
  }
}