import { Component } from '@angular/core';
import { FormLandingPageComponent } from '../form-landing-page/form-landing-page.component';
import { formsActions } from '../../../../store/features/forms/forms-actions';

@Component({
  selector: 'bizz-form-edit-page',
  standalone: true,
  imports: [],
  templateUrl: './form-edit-page.component.html',
  styleUrl: './form-edit-page.component.scss'
})
export class FormEditPageComponent extends FormLandingPageComponent {
  public override routeApplied(): void {
    this.store$.dispatch(formsActions.getFormEdit({
      collectionId: this.collectionId!,
      versionId: this.versionId!,
      instanceId: this.instanceId!,
    }));
  }

}