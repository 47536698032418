import { Component, Input, OnInit } from '@angular/core';
import { ButtonDisplayType } from 'src/models/ts/button-display-type.model';
import { IconDto } from 'src/models/ts/icon-dto.model';
import { IconComponent } from '../icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'bizz-macro-button',
  standalone: true,
  templateUrl: './macro-button.component.html',
  styleUrl: './macro-button.component.scss',
  imports: [IconComponent, NgClass]
})
export class MacroButtonComponent implements OnInit {

  @Input({ required: true }) public icon: IconDto;
  @Input({ required: true }) public displayType: ButtonDisplayType;
  @Input({ required: true }) public caption: string;
  @Input({ required: true }) public url: string;
  @Input({ required: true }) public disabled = false;
  @Input({ required: true }) public style: string;

  public showIcon = false;
  public showCaption = false;

  public ngOnInit(): void {
    if (this.icon) {
      this.showIcon = this.displayType === ButtonDisplayType.Icon ||
        this.displayType === ButtonDisplayType.IconCaption;
      this.showCaption = this.displayType === ButtonDisplayType.Caption ||
        this.displayType === ButtonDisplayType.IconCaption;
    }
  }

  //See ngBizzFormMacroButton.js
}
