import { createActionGroup, props } from "@ngrx/store";

export interface RefreshProperties {
  collectionId?: number, 
  listId?: number,
  widgetId?: number,
  formId?: string,
}

export const refreshActions = createActionGroup({
  source: 'Refresh',
  events: {
    refreshData: props<RefreshProperties>(),
    refreshSkillGrades: props<{trainingAppId: number, versionId: number}>()
  }
});