<div class="flex h-full w-full flex-col gap-12 md:px-24 md:py-4">
    <div class="mb-4 w-full">
        <div class="m-auto md:w-full">
            <div #slider></div>
        </div>
    </div>

    @if (formFieldSignal(); as formField) {
        <div class="flex flex-wrap gap-4">
            @for (skillGrade of skillGrades; track $index) {
                <button class="h-9 items-center rounded-full text-center font-semibold leading-tight text-blue-500"
                        [ngClass]="{ 'bg-button text-disabled' : !skillGrade.Active,
                    'bg-blue-600/20 ' : skillGrade.Active, }"
                        (click)="toggleSkill(skillGrade.ID)"
                        [disabled]="skillGrade.Name == 'None' || formField.IsReadOnly || isLocked()">
                    {{ skillGrade.Name }}
                </button>
            }
        </div>
    }
</div>

