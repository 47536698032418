@if(formFieldSignal(); as formField) {
    <div class="flex flex-col overflow-y-auto overflow-x-hidden text-xs">
        <div class="flex justify-between">
            <div class="flex items-end font-bold leading-3">
                <p class="mx-7 mb-1 mt-0 last:ml-9">{{ "Actions" | translate }}</p>
                <p class="mx-7 mb-1 mt-0 last:ml-9">{{ "Questions" | translate }}</p>
            </div>
            <div class="relative mr-2 box-border flex h-8 w-full flex-row items-center justify-end sm:h-8">
                <input
                        [ngModel]="searchTerm$ | async"
                        (ngModelChange)="searchTerm$.next($event)"
                        class="size-8 max-w-xl rounded-md border-form-control text-transparent placeholder:text-lighter focus:w-full focus:border-3 focus:border-form-control-focus focus:text-default focus:shadow-inner focus:transition-all placeholder:focus:text-transparent sm:h-8 sm:w-full sm:text-default"
                        [placeholder]="'Search' | translate">
                <bizz-icon class="pointer-events-none absolute mr-2 size-4 cursor-pointer text-button sm:pointer-events-auto" iconName="magnifying-glass"></bizz-icon>
            </div>
        </div>
        <hr class="mx-4 mb-4 mt-3"/>

        @if (questions$ | async; as questions) {
            @for (question of questions; track question.ID) {
                @if (question.State != UpdateDeleteState.Delete) {

                    <div class="mx-2 block">
                        <div class="flex content-start items-center">
                            <div class="flex align-middle">
                                <button class="bg-transparent hover:cursor-pointer hover:text-blue-500"
                                        (click)="deleteQuestion($index)"
                                        [disabled]="formField.IsReadOnly || isLocked()">
                                    <bizz-icon class="h-4 w-4" iconName="trash-can"></bizz-icon>
                                </button>
                                <button class="bg-transparent hover:cursor-pointer hover:text-blue-500"
                                        (click)="editQuestion($index)"
                                        [disabled]="formField.IsReadOnly || isLocked()">
                                    <bizz-icon class="h-4 w-4" iconName="pencil"></bizz-icon>
                                </button>
                            </div>
                            <bizz-icon class="mx-4 box-border h-6 w-6 hover:cursor-pointer hover:text-blue-500" iconName="caret-right"
                                       (click)="question.IsExpanded=!question.IsExpanded"
                                       [ngStyle]="question.IsExpanded && { 'transform': 'rotate(90deg)' } ||  {'transform': 'none'}"></bizz-icon>


                            <div class="max-h-16 w-full overflow-y-auto overflow-x-hidden">
                                <label [hidden]="!question.IsRequired" class="relative -top-1 float-right mr-3 h-5 text-xl leading-tight text-red-500">*</label>
                                @if (question.Generated) {
                                    <bizz-icon class="float-left mr-2 h-3 w-3" iconName="head-side-brain"></bizz-icon>
                                }
                                <div class="question-content text-sm" [innerHtml]="question.Caption | safe : 'html'"
                                     [ngStyle]="question.IsExpanded && {'max-height':'250px'} || {'max-height': '50px'}">
                                </div>
                            </div>

                        </div>
                        @if (question.IsExpanded) {
                            <div class="ml-0 mt-3 flex max-h-[500px] flex-col overflow-y-auto rounded border-2 py-4 pt-4 sm:ml-[130px]"
                                 [hidden]="!question.IsExpanded">
                                <div class="sticky px-1.5 py-1">
                                    <div class="pt-4.5 my-1 flex justify-between px-3 font-bold">
                                        <p>{{ 'Answers' | translate }}</p>
                                        <p>{{ 'Score' | translate }}</p>
                                    </div>
                                    <hr/>
                                </div>
                                <div class="box-border block h-full w-full">
                                    @for (answer of question.Answers; track answer.ID) {
                                        @if (answer.State != UpdateDeleteState.Delete) {
                                            <div class="mb-1.5 ml-1 flex content-between items-center justify-between px-4 pb-4">
                                                <bizz-icon iconName="circle" class="mr-4 h-2 w-2"></bizz-icon>
                                                <div class="box-border max-h-[200px] w-full overflow-y-auto overflow-x-hidden text-sm"
                                                     [innerHtml]="answer.Caption | safe : 'html'"></div>
                                                <div class="mr-3 appearance-none font-bold">{{ answer.Score }}</div>
                                            </div>
                                        }
                                    }

                                </div>
                            </div>
                        }
                        <hr/>
                    </div>
                }
            }
        }

        @if(generatingQuestionQueue != null && generatingQuestionQueue.length > 0){
            <div class="mx-2 mt-2.5 flex flex-col text-right gap-2 justify-end">
            <p> Processing requests: {{getSuccessfulGenerates()}}/{{generatingQuestionQueue.length}}</p>
            @if(getFailedGenerates(); as failed){
                <p class="text-red-500">Failed: {{failed}}</p>
            }
            </div>
        }
        <div class="mx-2 mt-2.5 flex justify-end gap-4 text-sm text-widget">
            @if (hasAiLicense$ | async) {
                <bizz-loader-button [class]="'default'" (click)="generateWithAi()"
                                    [isLoading]="isLoadingAi"
                                    [disabled]="formField.IsReadOnly || isLocked()">
                    <p>{{ "GenerateWithAI" | translate }}
                        <bizz-icon class="ml-1 size-4" iconName="head-side-brain"></bizz-icon>
                    </p>

                </bizz-loader-button>
            }


            <button class="save" type="button" (click)="addQuestion()"
                    [disabled]="formField.IsReadOnly || isLoadingAi || isLocked()">
                <p>{{ 'AddQuestion' | translate }}</p>
                <bizz-icon class="ml-1 size-4" iconName="circle-plus"></bizz-icon>
            </button>
        </div>
    </div>
}

