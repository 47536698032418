<div class="flex flex-col justify-center gap-5 h-full lg:flex-row">
    <div class="flex justify-center items-center ">
        <img class="max-w-80 min-w-80 xl:max-w-xl" src="/assets/image/exam-splash.svg" alt="exam-splash" />
    </div>
    <div class="overflow-y-auto flex flex-col flex-1 justify-start items-center lg:justify-center lg:overflow-y-visible">
        <div class="text-2xl font-semibold leading-7 mb-5 font-title text-widget-header">
            {{ exam.Name }}
        </div>
        <div class="font-sans text-base sm:text-sm"
            [innerHtml]="exam.Description | safe : 'html'">
        </div>
</div>

<div class="flex justify-end max-h-fit my-5 lg:items-end">
    <bizz-loader-button class="save" (click)="startExam.emit()" [isLoading]="isLoading">
        {{ "Start" | translate }}
        <bizz-icon class="h-4 w-4"
                    iconName="caret-right"></bizz-icon>
    </bizz-loader-button>
</div>