<div class="flex h-full w-full flex-col gap-3">
    @if ((exam.Questions.length) > 0 && exam!.Questions[0]; as question) {
        <div class="w-full py-4 text-center font-title font-bold">
            {{ 'Q' + exam.Progress }}
            <span [innerHtml]="question.Caption | safe : 'html'"></span>
        </div>
        @if (question.Answers.length > 0) {
            <div class="flex w-full flex-col gap-5">
                @for (answer of question.Answers; track $index) {
                    <button class="group flex w-full items-center gap-5 rounded border-2 border-button bg-body/0 px-5 py-2.5"
                            (click)="!isLoading ? question.SubmittedAnswerID = answer.ID : null"
                            [ngClass]="{'!bg-green-500/20 !border-green-500' : question.SubmittedAnswerID == answer.ID,
                            'hover:bg-green-500/5 hover:border-green-500/50' : !isLoading}">
                        <div class="max-h-9 max-w-9 rounded-full bg-button p-2.5"
                             [ngClass]="{'!bg-green-500' : question.SubmittedAnswerID == answer.ID,
                             'group-hover:bg-green-500/50': !isLoading}">
                            <span class="block size-4 text-center text-base font-bold leading-none text-white"> {{ prefixLetters[$index] }}
                            </span>
                        </div>
                        <div class="mr-5 w-full !font-sans !font-medium text-start"
                             [innerHtml]="answer.Caption | safe : 'html'">
                        </div>
                    </button>
                }
            </div>
        }
    }
</div>


<div class="flex max-h-fit w-full justify-end">
    <bizz-loader-button class="save" (click)="nextQuestion.emit(exam.Questions[0])"
                        [isLoading]="(isLoading)"
                        [disabled]="(exam.Questions.length == 0 || exam.Questions[0].SubmittedAnswerID == 0)">
        {{ "Next" | translate }}
        <bizz-icon class="h-4 w-4"
                   iconName="caret-right"></bizz-icon>

    </bizz-loader-button>
</div>