import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { ButtonDisplayType } from '../../../../../../../models/ts/button-display-type.model';
import { MacroButtonComponent } from '../../../../../../shared/components/ui/macro-button/macro-button.component';
import {EditorModule} from "@progress/kendo-angular-editor";

/**
 * Represents a button that the user can click to go to a predefined url.
 */
@Component({
  selector: 'bizz-macro-button-control',
  standalone: true,
    imports: [CommonModule, IconComponent, MacroButtonComponent, EditorModule],
  templateUrl: './macro-button-control.component.html',
  styleUrls: ['./macro-button-control.component.scss']
})
export class MacroButtonControlComponent extends BaseFormControlComponent {

  public showIcon = false;
  public showCaption = false;

  public override ngOnInit(): void {
    const field = this.formFieldSignal()
    if (field) {
      this.showIcon = field.ButtonDisplayType === ButtonDisplayType.Icon || field.ButtonDisplayType === ButtonDisplayType.IconCaption;
      this.showCaption = field.ButtonDisplayType === ButtonDisplayType.Caption || field.ButtonDisplayType === ButtonDisplayType.IconCaption;
    }
  }
    protected override focus(): void {
      // Add your implementation here
    }
}