<div class="flex h-full w-full flex-col gap-1 p-2 sm:gap-2">
    <div class="flex flex-wrap justify-between gap-1 sm:pr-2">
        <span class="widget-header">{{ data.Caption }}</span>
        <div class="flex flex-grow items-center gap-2 sm:flex-grow-0">
            <!-- TODO: replace with generic search -->
            <bizz-filter
                    class="w-full"
                    [active]="(gridResultData$ | async)?.activeFilter ?? false"
                    [filterList]="data.FilterList"
                    [filterType]="filterItemType"
                    [viewId]="data.ListOptions.ViewID"
                    [widgetId]="data.ID"
                    [collectionsId]="data.CollectionsID"
                    [searchFieldAccessible]="true"
                    (retrievedFilterData)="refreshFilterGrid($event)"
            >
            </bizz-filter>

            <button type="button" class="hidden px-2 py-1 sm:flex" (click)="refreshGrid()">
                <bizz-icon class="icon-button size-4" iconName="rotate-right"></bizz-icon>
            </button>
            <button (click)="changeDownloadPopUpState()" class="hidden px-2 py-1 sm:flex"
                    [ngClass]="{'pointer-events-none': isDownloading, 'sm:hidden': !data.CanExport}" #anchor>
                @if (isDownloading) {
                    <div
                            class="text-surface inline-block h-4 w-4 animate-spin rounded-full border-3 border-solid border-current border-e-transparent align-[-0.125em] text-widget-button motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white">
                    </div>
                } @else {
                    <bizz-icon class="icon-button size-4 hover:text-widget-button-hover"
                               iconName="download"></bizz-icon>
                }
            </button>
            @if (toggleDownloadPopUp) {
                <kendo-popup [anchor]="anchor"
                             [animate]="true"
                             [popupClass]="'flex w-screen sm:w-auto h-auto sm:justify-center sm:rounded'"
                             class="z-[1004] mt-12 hidden sm:mt-0 sm:block sm:rounded maxw-sm:!top-0"
                             [positionMode]="'absolute'"
                             [anchorAlign]="anchorAlign"
                             [popupAlign]="popupAlign" #popup>
                    <div class="bizz-popup-content">
                        <button type="button" class="hidden px-2 py-1 sm:flex"
                                [ngClass]="{ 'sm:hidden' : !data.CanExport }"
                                (click)="exportCsv()">
                            <bizz-extension-icon class="icon-button size-4" extension="csv"></bizz-extension-icon>
                            {{ 'CSV' | translate }}
                        </button>

                        <button type="button" class="hidden px-2 py-1 sm:flex"
                                [ngClass]="{ 'sm:hidden' : !data.CanExport }"
                                (click)="exportXlsx()">
                            <bizz-extension-icon class="icon-button size-4" extension="xlsx"></bizz-extension-icon>
                            {{ 'XLSX' | translate }}
                        </button>
                        @if (data.CanExport) {
                            <button type="button" class="hidden px-2 py-1 sm:flex"
                                    [ngClass]="{ 'sm:hidden' : !reportsAvailableForUser }"
                                    (click)="exportPdf()">
                                <bizz-extension-icon class="icon-button size-4" extension="pdf"></bizz-extension-icon>
                                {{ 'PDF' | translate }}
                            </button>
                        }
                    </div>
                </kendo-popup>
            }
            @if (data.CanAccessTrashCan) {
                <button type="button" class="hidden px-2 py-1 sm:flex" (click)="toggleTrashView()">
                    <bizz-icon
                            class="icon-button size-4 hover:cursor-pointer hover:text-red-400 focus:text-red-400"
                            iconName="trash-can"
                            [class]="this.isTrashView ? '!text-red-500' : ''"></bizz-icon>
                </button>
            }
            @if (data.CanCreate && !readonly) {
                <button type="button" class="save round ml-0.5 drop-shadow-sm sm:flex"
                        (click)="newRecord()">
                    <bizz-icon class="size-4" iconName="plus"></bizz-icon>
                </button>
            }
            <button type="button" class="mr-2 px-0 py-1 hover:text-blue-600 focus:text-blue-600 sm:hidden"
                    (click)="toggleMobileActionsPopup($event)" #mobileActionsAnchor>
                <bizz-icon
                        class="inline-block h-6 w-4 text-widget-button hover:cursor-pointer hover:text-blue-600 focus:text-blue-600"
                        iconName="ellipsis-vertical"></bizz-icon>
            </button>
            @if (showMobileActionsPopup) {

                <kendo-popup #propertiesPopup
                             class="z-[1004] w-auto"
                             [anchor]="mobileAnchor"
                             [margin]="{ horizontal: 0, vertical: 10 }"
                             [collision]="{ horizontal: 'fit', vertical: 'fit' }"
                             [popupAlign]="{ horizontal: 'right', vertical: 'top' }"
                             [anchorAlign]="{ horizontal: 'right', vertical: 'bottom' }">
                    <div class="bizz-popup-content">
                        <bizz-filter
                                class="hidden sm:flex"
                                [active]="(gridResultData$ | async)?.activeFilter ?? false"
                                [filterList]="data.FilterList"
                                [filterType]="FilterItemType.Widget"
                                [viewId]="data.ListOptions.ViewID"
                                [widgetId]="data.ID"
                                [collectionsId]="data.CollectionsID"
                                (retrievedFilterData)="refreshFilterGrid($event)">
                        </bizz-filter>
                        <button type="button" (click)="refreshGrid()">
                            <bizz-icon class="mr-2 h-5 w-5 sm:h-4 sm:w-4" iconName="rotate-right"></bizz-icon>
                            {{ 'Refresh' | translate }}
                        </button>
                        @if (data.CanAccessTrashCan) {
                            <button type="button" (click)="toggleTrashView()">
                                <bizz-icon class="mr-2 h-5 w-5 sm:h-4 sm:w-4" iconName="trash-can"
                                           [class]="this.isTrashView ? '!text-red-400' : ''"></bizz-icon>
                                {{ 'TrashCan' | translate }}
                            </button>
                        }
                        @if (data.CanExport) {
                            <button type="button" class="px-2 py-1"
                                    (click)="exportCsv()">
                                <bizz-extension-icon class="icon-button size-4" extension="csv"></bizz-extension-icon>
                                {{ 'Export' | translate }} {{ 'CSV' | translate }}
                            </button>

                            <button type="button" class="px-2 py-1"
                                    (click)="exportXlsx()">
                                <bizz-extension-icon class="icon-button size-4" extension="xlsx"></bizz-extension-icon>
                                {{ 'Export' | translate }} {{ 'XLSX' | translate }}
                            </button>
                            @if (reportsAvailableForUser) {
                                <button type="button" class="px-2 py-1"
                                        (click)="exportPdf()">
                                    <bizz-extension-icon class="icon-button size-4"
                                                         extension="pdf"></bizz-extension-icon>
                                    {{ 'Export' | translate }} {{ 'PDF' | translate }}
                                </button>
                            }

                        }
                    </div>
                </kendo-popup>
            }
        </div>
    </div>
    <bizz-grid class="bizz-grid flex h-full flex-col overflow-hidden"
               [currentPage]="currentPage"
               [gridOptions]="gridOptions"
               [gridData]="(gridData | async)!"
               [loading]="loading"
               [totalItems]="((gridResultData$ | async)?.totalItems ?? 0)"
               [gridCellMetadata]="{ trashFilterActive: isTrashView, collectionType, listOptions, isDocumentTreeGrid: false, userId, userType, managerTrainingQueue: false }"
               [autoFitColumns]="listOptions.GridColumnsAutoWidth"
               [commandColumnTemplate]="commandColumnTemplate"
               (selectionChange)="selectionChange.emit($event)"
               (cellAction)="onCellAction($event)"
               (pageChange)="onPageChange($event)"
               (groupChange)="onGroupChange($event)"
               (sortChange)="onSortChange($event)"
    ></bizz-grid>
</div>
