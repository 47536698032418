import { GridColumnType } from 'src/models/ts/grid-column-type.model';
import { GridCellBaseComponent } from '../cells/grid-cell-base/grid-cell-base.component';
import { Type } from '@angular/core';
import { GridCellTextComponent } from '../cells/grid-cell-text/grid-cell-text.component';
import { GridCellActionsComponent } from '../cells/grid-cell-actions/grid-cell-actions.component';
import { GridCellStatusComponent } from '../cells/grid-cell-status/grid-cell-status.component';
import { GridCellFileSizeComponent } from '../cells/grid-cell-file-size/grid-cell-file-size.component';
import { GridCellTitleComponent } from '../cells/grid-cell-title/grid-cell-title.component';
import { GridCellExtensionComponent } from '../cells/grid-cell-extension/grid-cell-extension.component';
import { GridCellCheckboxComponent } from '../cells/grid-cell-checkbox/grid-cell-checkbox.component';
import { GridCellDateComponent } from '../cells/grid-cell-date/grid-cell-date.component';
import { GridCellFileComponent } from '../cells/grid-cell-file/grid-cell-file.component';
import { GridCellNumericComponent } from '../cells/grid-cell-numeric/grid-cell-numeric.component';
import { GridCellMacrobuttonComponent } from '../cells/grid-cell-macrobutton/grid-cell-macrobutton.component';
import { GridCellMemoComponent } from '../cells/grid-cell-memo/grid-cell-memo.component';
import { GridCellLinkComponent } from '../cells/grid-cell-link/grid-cell-link.component';
import { GridCellSchedulerActionsComponent } from '../cells/grid-cell-scheduler-actions/grid-cell-scheduler-actions.component';
import { GridCellTaskActionsComponent } from '../cells/grid-cell-task-actions/grid-cell-task-actions.component';
import { GridCellNotificationAndReminderActionsComponent } from '../cells/grid-cell-notification-and-reminder-actions/grid-cell-notification-and-reminder-actions.component';
import { GridCellNotificationAndReminderStatusComponent } from '../cells/grid-cell-notification-and-reminder-status/grid-cell-notification-and-reminder-status.component';
import { GridCellMemoWithHyperlinkComponent } from '../cells/grid-cell-memo-with-hyperlink/grid-cell-memo-with-hyperlink.component';
import { GridCellMailActionsComponent } from '../cells/grid-cell-mail-actions/grid-cell-mail-actions.component';
import { GridCellMailAttachmentComponent } from '../cells/grid-cell-mail-attachment/grid-cell-mail-attachment.component';
import { GridCellAuditlogInfoActionsComponent } from '../cells/grid-cell-auditlog-info-actions/grid-cell-auditlog-info-actions.component';
import { GridCellTrainingQueueActionsComponent } from '../cells/grid-cell-training-queue-actions/grid-cell-training-queue-actions.component';
import { GridCellCanvasComponent } from '../cells/grid-cell-canvas/grid-cell-canvas.component';
import { GridCellMailStatusComponent } from '../cells/grid-call-mail-status/grid-cell-mail-status/grid-cell-mail-status.component';

export const GRID_CELL_COMPONENT_TYPES: { [K in GridColumnType]: Type<GridCellBaseComponent<unknown>> } = {
  [GridColumnType.Text]: GridCellTextComponent,
  [GridColumnType.Actions]: GridCellActionsComponent,
  [GridColumnType.Status]: GridCellStatusComponent,
  [GridColumnType.Size]: GridCellFileSizeComponent,
  [GridColumnType.Title]: GridCellTitleComponent,
  [GridColumnType.Extension]: GridCellExtensionComponent,
  [GridColumnType.Checkbox]: GridCellCheckboxComponent,
  [GridColumnType.Date]: GridCellDateComponent,
  [GridColumnType.Time]: GridCellDateComponent,
  [GridColumnType.DateTime]: GridCellDateComponent,
  [GridColumnType.File]: GridCellFileComponent,
  [GridColumnType.Numeric]: GridCellNumericComponent,
  [GridColumnType.Hyperlink]: GridCellLinkComponent,
  [GridColumnType.MacroButton]: GridCellMacrobuttonComponent,
  [GridColumnType.Memo]: GridCellMemoComponent,
  [GridColumnType.MemoWithHyperlink]: GridCellMemoWithHyperlinkComponent,
  [GridColumnType.TaskStatus]: GridCellStatusComponent,
  [GridColumnType.EventType]: GridCellTextComponent, //TODO: Implement EventType component
  [GridColumnType.ExecutedByBehalf]: GridCellTextComponent, //TODO: Implement ExecutedByBehalf component
  [GridColumnType.AuditLogInfo]: GridCellAuditlogInfoActionsComponent, //TODO: Implement AuditLogInfo component
  [GridColumnType.AuditLogNewValue]: GridCellTextComponent, //TODO: Implement AuditLogNewValue component
  [GridColumnType.NotificationAndReminderStatus]: GridCellNotificationAndReminderStatusComponent,
  [GridColumnType.NotificationAndReminderActions]: GridCellNotificationAndReminderActionsComponent,
  [GridColumnType.TaskActions]: GridCellTaskActionsComponent,
  [GridColumnType.MailActions]: GridCellMailActionsComponent,
  [GridColumnType.MailAttachment]: GridCellMailAttachmentComponent,
  [GridColumnType.SchedulerActions]: GridCellSchedulerActionsComponent,
  [GridColumnType.TrnQueueActions]: GridCellTrainingQueueActionsComponent,
  [GridColumnType.Canvas]: GridCellCanvasComponent,
  [GridColumnType.MailStatus]: GridCellMailStatusComponent
};
