<div class="flex h-full w-full flex-col overflow-hidden">
    <kendo-grid class="h-full min-h-32 overflow-hidden" #kendoGrid
                [loading]="loading"
                [group]="gridDataInternal.length == 0 ? [] : gridOptions.dataSource.group"
                [sort]="gridOptions.dataSource.sort"
                [scrollable]="gridOptions.scrollable"
                [groupable]="gridDataInternal.length == 0 ? false : gridOptions.groupable"
                [sortable]="gridOptions.sortable"
                [selectable]="gridOptions.selectable"
                [resizable]="gridOptions.resizable"
                [reorderable]="gridOptions.reorderable"
                [kendoGridBinding]="gridDataInternal"
                kendoGridSelectBy
                [autoSize]="(!loading && gridDataInternal.length > 0) ? gridOptions.autoSize : false"
                [trackBy]="trackBy"
                (groupChange)="onGroupChange($event)"
                (sortChange)="onSortChange($event)"
                (cellClick)="onCellClick($event)"
                (selectionChange)="onSelectionChange($event)"
                [rowSelected]="isRowSelected.bind(this)">
        <ng-template kendoGridLoadingTemplate>
            <div class="relative mt-8 flex h-full min-h-24 w-full items-center justify-center bg-widget/40">
                <bizz-loader [showLoader]="true" backgroundClasses="bg-transparent"></bizz-loader>
            </div>
        </ng-template>
        @if (!loading && gridDataInternal.length > 0) {
            <ng-template kendoGridNoRecordsTemplate>
               <div class="empty-placeholder-image flex h-full w-full flex-col items-center justify-center">
                    <bizz-icon class="my-2 h-12 w-12 text-blue-500" iconStyle="duotone"
                               iconName="magnifying-glass"></bizz-icon>
                    <p class="h-full text-center font-title text-base font-bold text-widget-header">
                        {{ 'NoResultsFound' | translate }}
                    </p>
                </div>
            </ng-template>
        } @else {
            <ng-template kendoGridNoRecordsTemplate></ng-template>
        }
        @if (showMobileColumnView && gridDataInternal.length > 0) {
            <kendo-grid-column [media]="'(max-width: ' + MAX_MOBILE_WIDTH + 'px)'" class="text-xs" headerClass="hidden"
                               [width]="0">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <bizz-grid-mobile-cell [data]="dataItem" [gridColumns]="gridOptions.columns"
                                           [listOptions]="actionCellData.listOptions"
                                           [trashFilterActive]="actionCellData.trashFilterActive"
                                           [collectionType]="actionCellData.collectionType">
                    </bizz-grid-mobile-cell>
                </ng-template>
            </kendo-grid-column>
        }
        @if (!loading && gridOptions.selectable.enabled && gridDataInternal.length > 0) {
            <kendo-grid-checkbox-column [width]="32" [showSelectAll]="gridOptions.selectable.mode === 'multiple'">
            </kendo-grid-checkbox-column>
        }
        @if (commandColumnTemplate && gridDataInternal.length > 0) {
            <kendo-grid-command-column title="" [width]="112">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" let-column="column">
                    <ng-container [ngTemplateOutlet]="commandColumnTemplate"
                                  [ngTemplateOutletContext]="{$implicit: dataItem,rowIndex,column }">
                    </ng-container>
                </ng-template>
            </kendo-grid-command-column>
        }

        @for (col of gridOptions.columns; track $index) {
            <kendo-grid-column
                    [field]="col.field"
                    [title]="col.title"
                    [locked]="col.locked"
                    [sortable]="col.sortable"
                    [groupable]="col.groupable"
                    [hidden]="col.hidden"
                    [width]="!loading && gridDataInternal.length > 0 ? col.width : 0"
                    [minResizableWidth]="gridDataInternal.length > 0 ? 
                    (col.minResizableWidth ? col.minResizableWidth : minResizableWidth) : 0"
                    [media]="showMobileColumnView ? '(min-width: ' + (MAX_MOBILE_WIDTH + 1) + 'px)' : ''"
                    [class]="{ '!text-center' : col.alignmentType == AlignmentType.Center,
                    '!text-end' : col.alignmentType == AlignmentType.Right }">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    <ng-container [ngTemplateOutlet]="columnTemplate || defaultColumnTemplate"
                                  [ngTemplateOutletContext]="{ $implicit: dataItem, column:col, columnIndex:$index }">
                    </ng-container>
                    <ng-template #defaultColumnTemplate>
                        @if (col.GridColumnType == gridColumnType.Actions) {
                            <bizz-grid-cell-actions [data]="dataItem"
                                                    [metaData]="actionCellData"
                                                    (onCellAction)="cellAction.emit($event)">
                            </bizz-grid-cell-actions>
                        } @else {
                            <bizz-grid-cell [data]="dataItem" [gridColumn]="col" [metaData]="actionCellData"
                                            (onCellAction)="cellAction.emit($event)">
                            </bizz-grid-cell>
                        }
                    </ng-template>
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                    <ng-container [ngTemplateOutlet]="editColumnTemplate"
                                  [ngTemplateOutletContext]="{$implicit: dataItem, formGroup, column:col, columnIndex: $index }">
                    </ng-container>
                </ng-template>

            </kendo-grid-column>
        }
    </kendo-grid>
</div>
@if (showPager && gridDataInternal.length > 0) {
    <kendo-datapager [style.width.%]="100" [pageSize]="gridOptions.pageSize"
                     [skip]="(currentPageInternal - 1) * gridOptions.pageSize" [total]="totalItems"
                     (pageChange)="onPageChange($event)"
                     [size]="'large'">
        <ng-template kendoDataPagerTemplate>
            <div class="flex w-full items-center justify-between">
                <kendo-datapager-info class="hidden md:block"></kendo-datapager-info>
                <div class="flex w-full items-center justify-between gap-0.5 md:w-auto md:justify-start">
                    <kendo-datapager-page-sizes class="pageSizes gap-2" [pageSizes]="pageSizes">
                    </kendo-datapager-page-sizes>
                    @if (totalItems > gridOptions.pageSize) {
                        <div class="k-pager-numbers-wrap">
                            <kendo-datapager-prev-buttons></kendo-datapager-prev-buttons>
                            <kendo-datapager-numeric-buttons [buttonCount]="gridOptions.pageable.buttonCount!">

                            </kendo-datapager-numeric-buttons>
                            <kendo-datapager-next-buttons></kendo-datapager-next-buttons>
                        </div>
                    }
                </div>
            </div>
        </ng-template>
    </kendo-datapager>
}