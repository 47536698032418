import { Component, DestroyRef, EventEmitter, OnInit, Output } from '@angular/core';
import { ReminderApiService } from 'src/app/api/bizzmine/reminder/reminder-api.service';
import { GridOptions } from 'src/app/shared/classes/list/grid-options';
import { GridDataDto } from 'src/models/ts/grid-data-dto.model';
import { ReminderSearchDto } from 'src/models/ts/reminder-search-dto.model';
import { TranslatePipe } from '../../../shared/pipes/translate/translate.pipe';
import { ReadFilterType } from 'src/models/ts/read-filter-type.model';
import { CollectionType } from 'src/models/ts/collection-type.model';
import { ReminderGridOptionsDto } from 'src/models/ts/reminder-grid-options-dto.model';
import { DropDownListModule, SharedDirectivesModule } from '@progress/kendo-angular-dropdowns';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { IconComponent } from '../../../shared/components/ui/icon/icon.component';
import { PageChangeEvent, SharedFilterModule } from '@progress/kendo-angular-grid';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime } from 'rxjs';
import { CellActionType } from '../../../features/bizzmine/widgets/collection-list-widget/classes/cell-action-type';
import { CellActionData } from '../../../features/bizzmine/widgets/collection-list-widget/interfaces/cell-action-data';
import { GridComponent } from '../../../shared/components/grid/grid.component';
import { HeaderRemindersService } from '../../../shared/services/header/header-reminders/header-reminders.service';
import { data } from 'autoprefixer';
import { SearchComponent } from "../../../shared/components/ui/search/search.component";

@Component({
  selector: 'bizz-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss'],
  standalone: true,
  imports: [
    GridComponent,
    DropDownListModule,
    SharedDirectivesModule,
    TranslatePipe,
    IconComponent,
    SharedFilterModule,
    SearchComponent
]
})

export class RemindersComponent implements OnInit {
  public searchTerm: string = '';
  public searchTerm$ = new BehaviorSubject<string>(this.searchTerm);
  public remindersSearch: ReminderSearchDto = {
    ReadFilterType: ReadFilterType.Unread,
    Sort: [],
    Group: [],
    Search: '',
    Page: 1,
    Records: 20
  };

  public searchedReminderDto: ReminderGridOptionsDto;
  public reminders: GridDataDto;
  public gridOptions: GridOptions;
  public CollectionType = CollectionType;
  public searching: boolean;
  public isLoading: boolean;
  //public reminders: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public totalRecords: number;


  public constructor(
    private reminderApiService: ReminderApiService,
    private headerRemindersService: HeaderRemindersService,
    private destroyRef: DestroyRef) {
    this.searchTerm$.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300))
      .subscribe(() => {
        this.remindersSearch.Search = this.searchTerm;
        this.getReminderSearch();
      });
  }

  public ngOnInit(): void {
    this.getReminderGridOptions();
    this.getReminderSearch();
  }

  public onCellAction(event: { action: CellActionType, data: CellActionData }): void {
    switch (event.action) {
      case CellActionType.NOTIFICATION_OPEN:
        this.headerRemindersService.openReminder(event.data.data.ID);
        break;
      case CellActionType.NOTIFICATION_READ:
        this.reminderApiService.readReminder(event.data.data.ID).subscribe({
          complete: (): void => {
            this.getReminderSearch();
          },
          error: (): void => {
            this.isLoading = false;
          }
        });
        break;
      case CellActionType.NOTIFICATION_UNREAD:
        this.reminderApiService.unreadReminder(event.data.data.ID).subscribe({
          complete: (): void => {
            this.getReminderSearch();
          },
          error: (): void => {
            this.isLoading = false;
          }
        });
        break;
    }
  }

  public getReminderGridOptions(): void {
    this.reminderApiService.getReminderGridOptions().subscribe({
      next: (reminders): void => {
        this.searchedReminderDto = reminders;
        this.gridOptions = new GridOptions(this.searchedReminderDto.GridOptions);
        this.searching = true;
      },
      error: (): void => {
        this.isLoading = false;
      },
      complete: (): void => {
        this.isLoading = false;
      }
    });
  }

  public searchTermChanged(search: string): void {
    this.searchTerm = search;
    this.searchTerm$.next(this.searchTerm);
  }

  public getReminderSearch(): void {
    this.remindersSearch.Page = this.currentPage;
    this.remindersSearch.Records = this.gridOptions.pageSize;
    this.reminderApiService.searchReminders(this.remindersSearch).subscribe({
      next: (reminders): void => {
        this.reminders = reminders;
        //this.reminders.next(reminders.Data);
        this.totalRecords = reminders.TotalRecords;
        this.searching = true;
      },
      error: (): void => {
        this.isLoading = false;
      },
      complete: (): void => {
        this.isLoading = false;
      }
    });
  }

  public onReadTypeChanged(type: ReadFilterType): void {
    this.remindersSearch.ReadFilterType = type;
    this.getReminderSearch();
  }

  public currentPage: number = 1;

  public onPageChange(event: PageChangeEvent): void {
    this.currentPage = (event.skip / event.take) + 1; // Update the current page number
    this.gridOptions.pageSize = event.take;
    this.getReminderSearch();
  }

  protected readonly console = console;
}
