<div class="modal-container modal-sm">
    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="closeDialog()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>

    @if (exam$ | async; as exam) {
        <div class="modal-body w-full">
            <div class="modal-header">
                <div class="box-border w-full">
                    @if (exam.Name) {
                        <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
                            {{ "EnrollForTheExam" | translate }} {{ exam.Name }}</h1>
                        <hr class="border-box m-auto my-5 h-0 w-full border-t-2 border-divider">
                    }

                </div>
            </div>
            <div class="box-border flex w-full flex-col items-center px-8">
                @if (exam.Description) {
                    <div class="mb-3 rounded bg-button px-7 py-4 text-sm text-default"
                    [innerHTML]="exam.Description | safe: 'html'"></div>                    
                }
                @if (exam.IsUserSubscribed) {
                    <div class="mb-3 flex w-full items-center gap-4 rounded bg-yellow-700/20 px-7 py-4 text-sm text-default">
                        <bizz-icon class="size-6 fill-yellow-500"
                                   iconName="circle-info">
                        </bizz-icon>
                        <div>
                            <p>{{ "Info_AlreadyEnrolled" | translate }}</p>
                            @if (exam.IsPublished) {
                                <p><a (click)="openExam()">{{ "ClickHereToOpenExam" | translate }}</a></p>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
        <div class="modal-buttons">
            <div class="flex flex-row justify-end gap-4 text-center">
                @if (!exam.IsUserSubscribed) {
                    <bizz-loader-button class="save" [disabled]="isLoading$ | async" (click)="enrollForExam(false)" [isLoading]="((isLoading$ | async) && !(isPolling$ | async))">
                        {{'Enroll' | translate}}
                    </bizz-loader-button>
                    <bizz-loader-button class="save" [disabled]="isLoading$ | async"  (click)="enrollForExam(true)" [isLoading]="((isLoading$ | async) && (isPolling$ | async))">
                    {{'EnrollAndStartExam' | translate}}
                    </bizz-loader-button>
                } @else {
                    <button class="save rounded-md"
                            (click)="closeDialog()">
                        {{ 'Close' | translate }}
                    </button>
                }
            </div>

        </div>
    }


</div>