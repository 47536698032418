<ng-template #toolbarTemplate>

    <div class="border-0">
        <kendo-button (click)="triggerFileSelect()" class="flex flex-row justify-center gap-1 bg-transparent">
            <bizz-icon class="size-4 align-middle" iconName="image"></bizz-icon>
        </kendo-button>
        <input type="file" #fileUploadId id="fileUploadId" [accept]="extensionToValidate" (change)="onFileChange($event)" class="hidden" />
    </div>


</ng-template>

<ng-template #popupTemplate>
    <div class="border-0">
        <kendo-button (click)="triggerFileSelect()" class="flex flex-row justify-center gap-1 bg-transparent">
            <bizz-icon class="size-4 align-middle" iconName="image"></bizz-icon>
        </kendo-button>
        <input type="file" #fileUploadId id="fileUploadId" [accept]="extensionToValidate" (change)="onFileChange($event)" class="hidden" />
    </div>

</ng-template>

