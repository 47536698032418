<ng-template #content>
    @if (widgetData) {
        <bizz-instance-selection-list class="flex h-svh min-h-80 flex-col overflow-hidden rounded sm:h-[60svh] sm:max-w-[1440px]"
                                   [data]="widgetData"
                                   [formFieldId]="dialogModel.formFieldId!"
                                   [isGridField]="dialogModel.isGridField!"
                                   [sourceCollectionId]="dialogModel.formFieldSourceCollectionId!"
                                   [openLinksInStack]="true"
                                   [lookupData]="dialogModel.lookupData"
                                   (selectionChange)="onSelectionChange($event)"
                                   (onNewRecord)="close()">

        </bizz-instance-selection-list>
    }

</ng-template>
<ng-template #footer>
    <div class="modal-buttons">
        <button class="default" (click)="dialogRef.close()">
            {{ 'Cancel' | translate }}
        </button>
        <button class="save min-w-16" (click)="close()">
            {{ 'Ok' | translate }}
        </button>
    </div>
</ng-template>

<bizz-modal title="SelectACollectionItem"
            [contentTemplate]="content"
            [footerTemplate]="footer"></bizz-modal>

