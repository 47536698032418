<span class="flex w-fit justify-between pl-1">
    <div class="flex w-auto items-center gap-2 text-list-row-action">
        @if (canReadInStack()) {
            <button class="inline-block max-h-5 w-4 p-0 text-widget-button hover:cursor-pointer hover:text-widget-button-hover sm:max-h-4"
                    kendoTooltip [title]="'Read' | translate"
                    (click)="onOpenClicked()">
                <bizz-icon class="inline-block size-3.5 hover:cursor-pointer hover:text-blue-500"
                           iconName="eye">
                </bizz-icon>
            </button>
        }
        @if (canEditInStack()) {
            <button class="inline-block max-h-5 w-4 p-0 text-widget-button hover:cursor-pointer hover:text-widget-button-hover sm:max-h-4"
                    kendoTooltip [title]="'EditInNewWindow' | translate"
                    (click)="onEditInNewWindowClicked()">
                <bizz-icon class="inline-block size-3.5 hover:cursor-pointer hover:text-blue-500"
                           iconName="arrow-up-right-from-square">
                </bizz-icon>
            </button>
        }
        @if (canEditInline()) {
            <button class="inline-block max-h-5 w-4 p-0 text-widget-button hover:cursor-pointer hover:text-widget-button-hover sm:max-h-4"
                    kendoTooltip [title]="'EditInline' | translate"
                    (click)="editInlineClicked.emit($event)">
                <bizz-icon class="inline-block size-3.5 hover:cursor-pointer hover:text-blue-500"
                           iconName="pencil"></bizz-icon>
            </button>
        }
        @if (canSave()) {
            <button class="inline-block max-h-5 w-4 p-0 text-widget-button hover:cursor-pointer hover:text-widget-button-hover sm:max-h-4"
                    kendoTooltip [title]="'SaveLine' | translate"
                    (click)="saveClicked.emit($event)">
                <bizz-icon class="inline-block size-3.5 hover:cursor-pointer hover:text-blue-500"
                           iconName="circle-check"></bizz-icon>
            </button>
        }
        @if (canDelete()) {
            <button class="inline-block max-h-5 w-4 p-0 text-widget-button hover:cursor-pointer hover:text-widget-button-hover sm:max-h-4"
                    kendoTooltip [title]="'DeleteLine' | translate"
                    (click)="deleteClicked.emit($event)">
                <bizz-icon class="inline-block size-3.5 hover:cursor-pointer hover:text-blue-500"
                           iconName="trash-can"
                ></bizz-icon> 
            </button>
        }
        @if (canEditDocument()) {
            <button class="inline-block max-h-5 w-4 p-0 text-widget-button hover:cursor-pointer hover:text-widget-button-hover sm:max-h-4"
                    kendoTooltip [title]="'EditDocument' | translate"
                    (click)="onEditInNewWindowClicked()">
                <bizz-icon class="inline-block size-3.5 hover:cursor-pointer hover:text-blue-500"
                           iconName="file"></bizz-icon>
            </button>
        }
    </div>
</span>