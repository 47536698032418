@if (formFieldSignal(); as formField) {
    <bizz-org-chart-select #input [errorState]="errorState"
                           [selection]="formControl.getRawValue()"
                           [min]="formField.MinValue"
                           [max]="formField.MaxValue"
                           [allowedItemTypes]="allowedTypes"
                           [readonly]="readOnlyOverride || formField.IsReadOnly || userType == UserType.ExternalUser || userType == UserType.AnonymousUser"
                           tabIndex="-1"
                           [ngClass]="{'hidden' : formField.IsHidden}"
                           (selectionChanged)="selectionChanged($event)">
    </bizz-org-chart-select>
}
