import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionFormField } from '../../../../../../models/ts/collection-form-field.model';
import { FormFieldType } from 'src/models/ts/form-field-type.model';
import { GridComponent } from '../../../../../shared/components/grid/grid.component';
import { FormMailingListComponent } from '../controls/form-mailing-list/form-mailing-list.component';
/**
 * Displays various static form elements like titles, text, etc.
 */
@Component({
  selector: 'bizz-form-element',
  standalone: true,
  imports: [CommonModule, GridComponent, FormMailingListComponent],
  templateUrl: './form-element.component.html',
  styleUrls: ['./form-element.component.scss'],
})
export class FormElementComponent {
  @Input({ required: true }) public element: CollectionFormField;
  @Input() public formId: string;
  public readonly FormFieldType = FormFieldType;
}
