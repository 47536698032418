import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { DocumentTreeApiService } from 'src/app/api/bizzmine/document-tree/document-tree-api.service';
import { take } from 'rxjs';
import { UnlinkFromFolderParams } from './interfaces/unlink-from-folder-params';
import { InheritType } from 'src/models/ts/inherit-type.model';
import { FolderInstanceTargetType } from 'src/models/ts/folder-instance-target-type.model';
import { IconComponent } from '../../ui/icon/icon.component';

@Component({
  selector: 'bizz-unlink-from-folder-modal',
  standalone: true,
  imports: [TranslatePipe, ModalComponent, IconComponent],
  templateUrl: './unlink-from-folder-modal.component.html',
  styleUrl: './unlink-from-folder-modal.component.scss'
})
export class UnlinkFromFolderModalComponent {

  protected readonly InheritType = InheritType;
  protected readonly FolderInstanceTargetType = FolderInstanceTargetType;

  public constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public modalData: UnlinkFromFolderParams,
    private documentTreeApiService: DocumentTreeApiService,
  ) {
    this.dialogRef.disableClose = true;
  }

 

  public unlink(): void {
    if (this.modalData.widgetId)
      this.documentTreeApiService.unlinkFromFolder(this.modalData.widgetId, this.modalData.folderId, this.modalData.collectionId, this.modalData.instanceId)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.dialogRef.close();
            //TODO: refresh grid
          },
          error: () => {
            //TODO: handle error
          },
        });
  }

}
