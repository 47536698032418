import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CollectionForm } from '../../../../models/ts/collection-form.model';
import { CollectionMethodType } from '../../../../models/ts/collection-method-type.model';
import { CollectionFormValidatorResult } from '../../../features/bizzmine/form/classes/collection-form-validator-result';
import { CreatedFormInstance } from '../../../features/bizzmine/form/classes/created-form-instance';
import { CollectionFormService } from 'src/app/features/bizzmine/form/services/collection-form.service';
import { TaskType } from '../../../../models/ts/task-type.model';
import { OrganizationChartItemType } from 'src/models/ts/organization-chart-item-type.model';

@Injectable({
  providedIn: 'root'
})
export class CollectionFormEditApiService {

  public constructor(
    private http: HttpClient
  ) {
  }

  public getFormByCollectionFormField(formFieldId: number): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/collection/form/collectionformfield/${formFieldId}/view`);
  }

  public getFormByCollectionFormFieldFromGrid(collectionFormFieldsID: number): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/collection/form/datasource/collectionformfield/${collectionFormFieldsID}`);
  }

  public getFormByParentAndDataSource(request: {parentFormId: number, viewDataSourceId: number}): Observable<CollectionForm> {
    return this.http.get<CollectionForm>(`api/collection/parentform/${request.parentFormId}/viewdatasource/${request.viewDataSourceId}`);
  }

  public saveSchedulerData(data: CollectionForm): Observable<CreatedFormInstance> {
    let stringify = JSON.parse(CollectionFormService.stringify(data));
    return this.http.post<CreatedFormInstance>(`api/collection/id/${data.CollectionsID}/form/${data.CollectionFormId}/schedule`, stringify, { headers: { 'Content-Type': 'application/json; charset=utf-8' } });
  }

  public saveInstanceWithJson(data: CollectionForm, serializedData: string): Observable<CollectionForm> {
    return this.http.post<CollectionForm>(`api/collection/id/${data.CollectionsID}/form/${data.CollectionFormId}/edit`, serializedData, { headers: { 'Content-Type': 'application/json; charset=utf-8' } });
  }

  public createInstanceWithJson(data: CollectionForm, serializedData: string, saveAndClose: boolean): Observable<CreatedFormInstance> {
    return this.http.post<CreatedFormInstance>(`api/collection/id/${data.CollectionsID}/form/${data.CollectionFormId}/create/flow/${saveAndClose}`, serializedData, { headers: { 'Content-Type': 'application/json; charset=utf-8' } });
  }

  public createDocumentInstanceWithJson(data: CollectionForm, serializedData: string, saveAndClose: boolean): Observable<CreatedFormInstance> {
    return this.http.post<CreatedFormInstance>(`api/collection/id/${data.CollectionsID}/form/${data.CollectionFormId}/folder/${data.FoldersID}/create/flow/${saveAndClose}`, serializedData, { headers: { 'Content-Type': 'application/json; charset=utf-8' } });
  }

  public saveWithJson(collectionId: number, formId: number, taskId: number, actionId: number, taskType: TaskType, methodType: CollectionMethodType, serializedData: string): Observable<any> {
    return this.http.post<any>(`api/collection/id/${collectionId}/form/${formId}/task/${taskId}/action/${actionId}/type/${taskType}/flow/${methodType}/edit`, serializedData, { headers: { 'Content-Type': 'application/json; charset=utf-8' } });
  }

  public saveAndCloseStepWithJson(collectionId: number, formId: number, taskId: number, actionId: number, taskType: TaskType, methodType: CollectionMethodType, serializedData: string): Observable<any> {
    return this.http.post<any>(`api/collection/id/${collectionId}/form/${formId}/task/${taskId}/action/${actionId}/type/${taskType}/flow/${methodType}`, serializedData, { headers: { 'Content-Type': 'application/json; charset=utf-8' } });
  }

  public saveAndCloseStepOnBehalfOfWithJson(collectionId: number, formId: number, taskId: number, actionId: number, taskType: TaskType, methodType: CollectionMethodType, assignedToId: number, assignedToType: OrganizationChartItemType,serializedData: string,): Observable<any> {
    return this.http.post<any>(`api/collection/id/${collectionId}/form/${formId}/task/${taskId}/action/${actionId}/type/${taskType}/flow/${methodType}/user/${assignedToId}/type/${assignedToType}/viaexecuteonbehalfof`, serializedData, { headers: { 'Content-Type': 'application/json; charset=utf-8' } });
  }

  public preValidateForm(collectionsId: number, formsId: number, tasksId: number, methodType: CollectionMethodType, saveAndClose: boolean): Observable<CollectionFormValidatorResult> {
    return this.http.get<CollectionFormValidatorResult>(`/api/collection/id/${collectionsId}/form/${formsId}/task/${tasksId}/flow/${methodType}/prevalidate/${saveAndClose}`);
  }

  public preValidateFormOnBehalfOf(collectionsId: number, formsId: number, tasksId: number, methodType: CollectionMethodType, assignedToId: number, saveAndClose: boolean): Observable<CollectionFormValidatorResult> {
    return this.http.get<CollectionFormValidatorResult>(`/api/collection/id/${collectionsId}/form/${formsId}/task/${tasksId}/flow/${methodType}/prevalidate/${saveAndClose}/user/${assignedToId}`);
  }

  public undoCheckOut(intent: {
    collectionId: number,
    instanceId: number,
    versionId: number
  }): Observable<void> {
    return this.http.post<void>(`api/collection/id/${intent.collectionId}/instancesid/${intent.instanceId}/versionsID/${intent.versionId}/undocheckout`, {});
  }

  public resizeImage(base64: string): Observable<string> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.http.post('api/collection/resizeimage', JSON.stringify(base64), {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'text'
    });
  }
}
