import { Component } from '@angular/core';
import { ExtensionDisplayType } from '../../../../../../models/ts/extension-display-type.model';
import { NgIf } from '@angular/common';
import { ExtensionIconComponent } from '../../../ui/icon/extension-icon/extension-icon.component';
import { CellActionType } from '../../../../../features/bizzmine/widgets/collection-list-widget/classes/cell-action-type';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { TitleFunctionType } from '../../../../../../models/ts/title-function-type.model';

/**
 * Cell containing a file extension icon and text
 * Can contain both or either.
 */
@Component({
  selector: 'bizz-grid-cell-title',
  templateUrl: './grid-cell-title.component.html',
  styleUrls: ['./grid-cell-title.component.scss'],
  imports: [
    ExtensionIconComponent,
    NgIf
  ],
  standalone: true
})
export class GridCellTitleComponent extends GridCellBaseComponent<string> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly ExtensionDisplayType = ExtensionDisplayType;

  public titleAction(mouseEvent: MouseEvent): void {
    const titleFunction = this.column.TitleFunctionType;
    switch(titleFunction) {
      case TitleFunctionType.ViewOnline:
        this.onCellAction.emit({ mouseEvent: mouseEvent, action: CellActionType.VIEW_ONLINE, data: { data: this.data } });
        break;
      case TitleFunctionType.Download:
        this.onCellAction.emit({ mouseEvent: mouseEvent, action: CellActionType.DOWNLOAD_FILE, data: { data: this.data } });
        break;
      case TitleFunctionType.ConsultRecord:
        this.onCellAction.emit({ mouseEvent: mouseEvent, action: CellActionType.CONSULT_RECORD, data: { data: this.data } });
        break;
      default:
        break;
    }
  }
}
