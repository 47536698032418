import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CellClickEvent, GridModule, SelectableSettings } from '@progress/kendo-angular-grid';
import { RowArgs } from '@progress/kendo-angular-grid/rendering/common/row-args';
import { TranslatePipe } from '../../../../shared/pipes/translate/translate.pipe';
import { OrganizationChartIndexDto } from '../../../../../models/ts/organization-chart-index-dto.model';
import { OrganizationChartApiService } from '../../../../api/bizzmine/organization-chart/organization-chart-api.service';
import { BehaviorSubject, debounceTime, shareReplay, switchMap, tap } from 'rxjs';
import { OrganizationChartItemType } from '../../../../../models/ts/organization-chart-item-type.model';
import { GridOrganizationGroupSearchDto } from '../../../../../models/ts/grid-organization-group-search-dto.model';
import { OrgChartItem } from '../interfaces/org-chart-item';
import { PagerModule } from '@progress/kendo-angular-pager';
import { IconComponent } from '../../../../shared/components/ui/icon/icon.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ORG_CHART_ITEM_TYPE_ICONS } from '../constants/org-chart-item-type-icons';
import { GridGroupSortDto } from 'src/models/ts/grid-group-sort-dto.model';
import { select } from '@ngrx/store';

/**
 * Displays the Organization Chart as a List
 * Utilizes the Kendo Grid component.
 */
@Component({
  selector: 'bizz-org-chart-list',
  standalone: true,
  imports: [CommonModule, GridModule, TranslatePipe, PagerModule, IconComponent],
  templateUrl: './org-chart-list.component.html',
  styleUrls: ['./org-chart-list.component.scss']
})
export class OrgChartListComponent implements OnInit {
  @Input() public selectableSettings: SelectableSettings = {
    mode: 'multiple',
    cell: false,
    enabled: true
  };
  @Input() public selectedItems$ = new BehaviorSubject<OrgChartItem[]>([]);
  @Input() public replaceDepartmentsAndFunctionsWithUsers = false;
  @Input() public selectedKeys: string[] = [];
  @Output() public selectedKeysChange = new EventEmitter<OrgChartItem[]>();
  @Input() public list: OrganizationChartIndexDto | null;
  @Input() public maxSelectedItems = 0;
  @Input() public minSelectedItems = 0;
  @Input() public allowedItemTypes: Array<OrganizationChartItemType> = [OrganizationChartItemType.User, OrganizationChartItemType.Department, OrganizationChartItemType.Function];
  @Input() public excludedUserIds: Array<number> = [];
  public currentPage = 1;
  public pageSize = 20;
  public totalItems = 0;
  public pageSizes = [1, 2, 5, 10, 20, 50, 200];
  public sort: Array<GridGroupSortDto> = [];
  public filter$ = new BehaviorSubject<GridOrganizationGroupSearchDto>(
    {
      Group: this.groups,
      Search: '',
      Page: this.currentPage,
      Records: this.pageSize,
      Sort: this.sort,
      RootNodeId: null,
      RootNodeType: null,
      ExcludeExternalUsers: true
    }
  );
  public searchTerm$ = new BehaviorSubject<string>('');
  public items$ = this.filter$.pipe(
    switchMap(filter => {
      return this.organizationChartApiService.searchOrgChartByFilter(filter);
    }),
    tap(data => this.totalItems = data.TileListSearch.Records),
    shareReplay(1)
  );
  public typMedia: string = '';
  public userTypMedia: string = '';
  protected readonly ORG_CHART_ITEM_TYPE_ICONS = ORG_CHART_ITEM_TYPE_ICONS;

  public constructor(private organizationChartApiService: OrganizationChartApiService, private destroyRef: DestroyRef) {
    this.searchTerm$.pipe(takeUntilDestroyed(destroyRef), debounceTime(500)).subscribe(search => {
      const currentFilter = this.filter$.value;
      currentFilter.Search = search;
      this.filter$.next(currentFilter);
    });
  }

  @Input({ required: true })
  public set groups(value: Array<OrganizationChartItemType>) {
    if (value == null || value.length === 0) {
      value = [OrganizationChartItemType.User, OrganizationChartItemType.Function, OrganizationChartItemType.Department, OrganizationChartItemType.CurrentUser];
    }
    this.filter$.next({ ...this.filter$.value, Group: value });
    this.setTypeMediaQuery();
  }

  @Input()
  public set searchTerm(value: string) {
    this.searchTerm$.next(value);
  }

  public isSelected(data: OrgChartItem): boolean {
    if (data == null) return false;
    return this.selectedKeys?.includes(data.UniqueID) ?? false;
  }

  public isRowSelected(data: RowArgs): boolean {
    if (data == null) return false;
    return this.selectedKeys?.includes(data.dataItem.UniqueID) ?? false;
  }

  public isEnabledForSelection(orgChartItem: OrgChartItem): boolean {
    let isEnabled = false;
    if(!this.excludedUserIds?.includes(orgChartItem.ObjectID)){
      if((this.allowedItemTypes?.includes(orgChartItem.ItemType) || this.replaceDepartmentsAndFunctionsWithUsers)){
        isEnabled = true;
      }
    }
    return isEnabled;
  }

  public setTypeMediaQuery(): void {
    this.typMedia = '';
    this.userTypMedia = '';
    const groups = this.filter$.value.Group;
    if (groups != null && groups.length == 1 && groups[0] == OrganizationChartItemType.User) {
      this.typMedia = '(min-width:1024px)';
    }
    if (groups != null && groups.length > 1) {
      this.userTypMedia = '(min-width:1024px)';
    }
  }

  public filterData(): void {
    this.filter$.next(this.filter$.value);
  }

  public onPageChange(event: { skip: number, take: number }): void {
    this.currentPage = (event.skip / event.take) + 1;
    this.pageSize = event.take;

    const filter = this.filter$.value;
    filter.Page = this.currentPage;
    filter.Records = this.pageSize;
    this.filter$.next(filter);
  }

  public ngOnInit(): void {
    if (this.selectedItems$.value && this.selectedItems$.value.length > 0) {
      this.selectedKeys = this.selectedItems$.value.map(item => item.UniqueID);
    }
    this.selectedItems$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((items) => {
      this.selectedKeys = items?.map(item => item.UniqueID) ?? [];
    });
  }

  // Fix for Kendo Grid not firing selection event on item that's already clicked:
  // Option to not remove already selected items:
  // Used in cases where a function/department selects it's users instead, clicking it again should not unselect the users (legacy functionality)
  public handleSelection(item: OrgChartItem, ignoreAlreadySelected = false): void {
    if (this.isSelected(item)) {
      if (!ignoreAlreadySelected)
        this.selectedKeys = this.selectedKeys.filter(key => key !== item.UniqueID);
    } else {
      this.selectedKeys.push(item.UniqueID);
    }
  }

  // End of fix

  public onCellClick(e: CellClickEvent, kendoItems: Array<OrgChartItem>): void {
    e.originalEvent.preventDefault();
    //check if user is not excluded
    if(!this.excludedUserIds?.includes(e.dataItem.ObjectID)){
      if (this.replaceDepartmentsAndFunctionsWithUsers && (e.dataItem.ItemType == OrganizationChartItemType.Department || e.dataItem.ItemType == OrganizationChartItemType.Function)) {
        this.replaceFunctionDepartmentWithUsers(e.dataItem, kendoItems);
      } else {
        this.onSelectedKeysChange([e.dataItem.UniqueID], kendoItems);
      }
    }
  }

  public replaceFunctionDepartmentWithUsers(item: OrgChartItem, items: OrgChartItem[]): void {
    this.organizationChartApiService.getUsersForFunctionDepartment([item.UniqueID]).subscribe({
      next: users => {
        users.forEach(user => this.handleSelection(user, true));
        users.forEach(user => {
          if (items.find(i => i.UniqueID == user.UniqueID) == null) {
            items.push(user);
          }
        })
        this.onSelectedKeysChange(this.selectedKeys, items);
      }
    });
  }

  public onSelectedKeysChange(data: Array<string>, kendoItems: Array<OrgChartItem>): void {
    const items = structuredClone(kendoItems);
    if (this.selectedItems$.value != null && this.selectedItems$.value.length > 0 && items != null && items.length > 0) {
      this.selectedItems$.value.forEach(orgChartItem => {
        if (items.find(innerOrgChartItem => orgChartItem.UniqueID === innerOrgChartItem.UniqueID) == null) {
          items.push(orgChartItem);
        }
      });
    }

    // sadly this doesnt work alike tree -> this only passes new selection as data, not the full selection - thus more logic :(
    if (this.maxSelectedItems > 0 && this.selectedKeys.length >= this.maxSelectedItems) {
      return;
    }
    
    this.selectedKeys = this.selectedKeys.filter(key => {
      const exist = data.find(d => d === key) == null;
      if(!exist) data = data.filter(k => k !== key);
      return exist;
    });

    //   // parsing -> getting ItemType info etc
    let selectedItems = <Array<OrgChartItem>>data.map(uniqueId => items.find(node => node.UniqueID === uniqueId)).filter(item => item != null);
    if (this.allowedItemTypes != null && this.allowedItemTypes.length > 0) {
      selectedItems = selectedItems.filter(item => this.allowedItemTypes?.includes(item.ItemType));
    }
   
    this.selectedKeys = this.selectedKeys.concat(selectedItems.map(item => item.UniqueID));
    const fullMappedList = <Array<OrgChartItem>>this.selectedKeys.map(uniqueId => items.find(node => node.UniqueID === uniqueId)).filter(item => item != null);
    this.selectedKeysChange.emit(fullMappedList);
    this.selectedItems$.next(fullMappedList);
  }

  public buildUniqueId(context: RowArgs): string {
    return context.dataItem['UniqueID'];
  }
}

