<div #band class="grid auto-cols-fr grid-flow-row gap-4 xl:grid-flow-col xl:grid-cols-12">
    @defer (on viewport(band)) {
        @for (item of dashboardBand.Items; track item.ID) {
            <bizz-widget
                    class="maxw-lg:!col-span-full"
                    [style.height]="dashboardBand.FullSize ? ('calc(100svh - 3rem - 32px)') : (dashboardBand.Height + 'px')"
                    [style.max-height]="dashboardBand.FullSize ? ('calc(100svh - 3rem - 32px)') : (dashboardBand.Height + 'px')"
                    [style.grid-column]="'span ' + item.ColumnWidth + ' / span ' + item.ColumnWidth"
                    [workspaceItemId]="item.ParentID"
                    [workspaceItemsWidgetId]="item.WorkspaceItemsWidgetID"
                    [widgetId]="item.WidgetID">
            </bizz-widget>
        }
    }
</div>
