@if (formFieldSignal(); as formField) {
    <div class="input-button-group flex" [ngClass]="{'hidden' : formField.IsHidden}">
        <input #input [readOnly]="readOnlyOverride || formField.IsReadOnly"
               [tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0"
               [formControl]="formControl"
               id="{{formField.Id}}"
               placeholder="{{formField.Placeholder}}"
               type="email">
        <div class="button-group">
            <button type="button"
                    class="default !px-2 !py-1"
                    [disabled]="disableMailButton || readOnlyOverride || formField.IsReadOnly"
                    (click)="openMailClient()">
                <bizz-icon class="h-4 w-4" iconName="envelope"></bizz-icon>
            </button>
        </div>
    </div>
}
