// used in SignalR as a type aka - must be class(typed) not interface
export class CompleteStepMessageCallBackSignalrMessage {
  request: string;
  state: CompleteStepMessageCallBackState;
  type: string;
  item: CompleteStepMessage;
}

export enum CompleteStepMessageCallBackState {
  Undefined,
  Success,
  Error
}

export class CompleteStepMessageJobResult<TClass>{
  request: TClass;
  item: CompleteStepMessage;
}

export class ManualExamEnrollmentRequest {
  TrainingAppId: number;
  ExamVersionsID: number;
}

export class CompleteStepMessage{
  versionsID: number;
}