<!-- TODO: check if buttons can be enabled/disabled with scripting -->
@if (formFieldSignal(); as formField) {

    <div class="flex" [ngClass]="{'hidden' : formField.IsHidden}">
        <bizz-macro-button
                [icon]="formField.Icon"
                [disabled]="readOnlyOverride || formField.IsReadOnly"
                [tabIndex]="readOnlyOverride || formField.IsReadOnly ? -1  : 0"
                [caption]="formField.Caption"
                [displayType]="formField.ButtonDisplayType"
                [url]="formField.Url"
                class="outline-0"
                [style]="formField.Style">
        </bizz-macro-button>
    </div>
}
<!-- TODO: styling options -->