import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../../../../ui/icon/icon.component';
import { TranslatePipe } from '../../../../../../pipes/translate/translate.pipe';
import { DatetimePipe } from '../../../../../../pipes/dates/datetime.pipe';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { CompareBaseModalComponent } from '../../compare-base-modal.component';
import { ExtensionIconComponent } from 'src/app/shared/components/ui/icon/extension-icon/extension-icon.component';
import { VersionType } from 'src/app/shared/enums/version-type';
import { take } from 'rxjs';


/**
 * Modal that offers the user the choice to download the draft or published and/or pdf
 * version of an instance.
 */
@Component({
  selector: 'bizz-download-draft-published-or-pdf-modal',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslatePipe, DatetimePipe, TextAreaModule, ExtensionIconComponent],
  templateUrl: './download-draft-published-or-pdf-modal.component.html',
  styleUrls: ['../../compare-base-modal.component.scss'],
})

export class DownloadDraftPublishedOrPdfModal extends CompareBaseModalComponent {
  private buildDownloadUrl = (versionType: VersionType, docType: 'file' | 'pdffile'): string => {
    const {CollectionsID: collectionsId, ID: instanceId} = this.data;
    if (!collectionsId || !instanceId) {
      throw new Error('CollectionsID, InstanceID are required to download a file');
    }

    let url = `api/collection/${collectionsId}/instance/${instanceId}/version/`

    if (versionType === VersionType.Draft) {
      const { DraftsID: draftId } = this.data;
      url += `${draftId}/`;
    } else if (versionType === VersionType.Public) {
      const { VersionsID: versionId } = this.data;
      url += `${versionId}/`;
    }  

    return url + docType
  }

  private buildFileName = (versionType: VersionType, docType: 'file' | 'pdffile'): string | undefined => {
    const model = versionType === VersionType.Draft ? this.model['DraftVersion'] : this.model['PublicVersion'];
    const {Title: title, Extension: extension} = model;

    if (!title || !title.Value || !extension || !extension.Value) {
      return undefined;
    }

    return `${title.Value}${docType === 'pdffile' ? '.pdf' : extension.Value}`;
  }

  public download(versionType: VersionType, docType: 'file' | 'pdffile'): void {
    const url = this.buildDownloadUrl(versionType, docType)

    this.http.get(
      url,
      { responseType: 'arraybuffer' })
    .pipe(take(1))
    .subscribe((data: ArrayBuffer) => {
      this.downloadService.startDownload(data, this.buildFileName(versionType, docType));
    }); 
    
    this.dialogRef.close();
  }

  public getFileExtension (versionType: VersionType): string | undefined {
    if (versionType === VersionType.Draft && typeof this.model['DraftVersion']['Extension']?.Value === 'string') {
      return this.model['DraftVersion']['Extension']?.Value;
    } else if (versionType === VersionType.Public && typeof this.model['PublicVersion']['Extension']?.Value === 'string') {
      return this.model['PublicVersion']['Extension']?.Value;
    }

    return;
  }
}
