<div class="modal-container justify-between md:justify-normal">
  <div class="modal-close-container">
    <bizz-icon
      class="ml-auto block h-5 w-5 text-button md:ml-0"
      (click)="dialogRef.close()"
      iconName="xmark"
      [hover]="true">
    </bizz-icon>
  </div>
  <div class="modal-body">
    <div class="modal-header">
      <h1>
        {{ 'Monitor' | translate }}
      </h1>
      <p>{{ 'MonitorSubtitle' | translate }} {{ model.DocumentVersion }}</p>
    </div>
    <hr class="my-4" />
    <div class="flex max-h-[80%] w-full flex-col overflow-y-auto">
      <bizz-monitor-step *ngFor="let step of model.Steps; let last = last"
                         class="flex min-w-full"
                         [step]="step" [isLastStep]="last">
      </bizz-monitor-step>
      <div
        class="flex justify-center"
        *ngIf="model.Steps === undefined || model.Steps?.length == 0">
        <svg width="258px" height="151px" viewBox="0 0 258 151">
          <g
            id="NoSearchResult"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd">
            <path
              d="M238.94012,11 C244.496038,11 249,15.521924 249,21.1 C249,26.678076 244.496038,31.2
          238.94012,31.2 L181.45509,31.2 C187.011008,31.2 191.51497,35.721924 191.51497,41.3 C191.51497,46.878076
          187.011008,51.4 181.45509,51.4 L213.071856,51.4 C218.627775,51.4 223.131737,55.921924 223.131737,61.5
          C223.131737,67.078076 218.627775,71.6 213.071856,71.6 L198.450664,71.6 C191.445375,71.6 185.766467,76.121924
          185.766467,81.7 C185.766467,85.4187173 188.640719,88.785384 194.389222,91.8 C199.94514,91.8
          204.449102,96.321924 204.449102,101.9 C204.449102,107.478076 199.94514,112 194.389222,112
          L75.1077844,112 C69.551866,112 65.0479042,107.478076 65.0479042,101.9 C65.0479042,96.321924
          69.551866,91.8 75.1077844,91.8 L19.0598802,91.8 C13.5039618,91.8 9,87.278076 9,81.7 C9,76.121924
          13.5039618,71.6 19.0598802,71.6 L76.5449102,71.6 C82.1008286,71.6 86.6047904,67.078076 86.6047904,61.5
          C86.6047904,55.921924 82.1008286,51.4 76.5449102,51.4 L40.6167665,51.4 C35.060848,51.4 30.5568862,46.878076
          30.5568862,41.3 C30.5568862,35.721924 35.060848,31.2 40.6167665,31.2 L98.1017964,31.2 C92.545878,31.2
          88.0419162,26.678076 88.0419162,21.1 C88.0419162,15.521924 92.545878,11 98.1017964,11 L238.94012,11 Z
          M239,51 C244.522847,51 249,55.4771525 249,61 C249,66.5228475 244.522847,71 239,71 C233.477153,71
          229,66.5228475 229,61 C229,55.4771525 233.477153,51 239,51 Z"
              id="Background"
              fill="#F3FCFF"></path>
            <g
              id="Search"
              transform="translate(66.471264, 12.436782)"
              stroke-width="2.5">
              <circle
                id="Oval"
                stroke="#75D9FE"
                fill="#F3FCFF"
                cx="48.5287356"
                cy="48.5632184"
                r="48"></circle>
              <path
                d="M40.831341,85.837485 C43.3532992,86.3029959 45.9190974,86.544907 48.5287356,86.5632184
            C69.5155561,86.5632184 86.5287356,69.5500389 86.5287356,48.5632184 C86.5287356,27.5763979
            69.5155561,10.5632184 48.5287356,10.5632184 C43.137556,10.5632184 38.0085946,11.6859082
            33.3628929,13.7102465 C25.2897152,17.2280881 18.6759127,23.468779 14.6814728,31.2723319
            C12.0265179,36.4590618 10.5287356,42.3362272 10.5287356,48.5632184 C10.5287356,54.2130346
            11.7617293,59.5748698 13.9733118,64.3943191 C15.5522255,67.8350656 17.6299213,70.9993607
            20.1138247,73.7946298"
                id="Oval"
                stroke="#75D9FE"
                fill="#FFFFFF"
                stroke-linecap="round"></path>
              <path
                d="M24.5287356,77.5632184 C27.5108088,80.0742782 30.8798043,82.1112241 34.5287356,83.5632184"
                id="Oval"
                stroke="#75D9FE"
                stroke-linecap="round"></path>
              <path
                d="M48.5287356,21.5632184 C46.0807085,21.5632184 43.7087748,21.8890134 41.4539278,22.4996105
            C29.9738062,25.6083481 21.5287356,36.0995573 21.5287356,48.5632184"
                id="Oval"
                stroke="#A4E6FE"
                stroke-linecap="round"></path>
              <line
                x1="87.5287356"
                y1="85.5632184"
                x2="96.5287356"
                y2="94.5632184"
                id="Path-4"
                stroke="#75D9FE"></line>
              <path
                d="M99.676655,91.3242898 C101.567114,91.3242898 103.457574,92.0454757 104.899945,93.4878475
            L104.899945,93.4878475 L120.604107,109.192009 C122.046478,110.63438 122.767664,112.52484
            122.767664,114.415299 C122.767664,116.305758 122.046478,118.196218 120.604107,119.638589
            C119.161735,121.080961 117.271276,121.802147 115.380816,121.802147 C113.490357,121.802147
            111.599898,121.080961 110.157526,119.638589 L110.157526,119.638589 L94.4533647,103.934428
            C93.0109929,102.492056 92.289807,100.601597 92.289807,98.7111378 C92.289807,96.8206785
            93.0109929,94.9302193 94.4533647,93.4878475 C95.8957365,92.0454757 97.7861958,91.3242898
            99.676655,91.3242898 Z"
                id="Rectangle"
                stroke="#75D9FE"
                fill="#E8F8FE"></path>
              <line
                x1="101.528736"
                y1="96.5632184"
                x2="117.528736"
                y2="112.563218"
                id="Line"
                stroke="#FFFFFF"
                stroke-linecap="round"></line>
            </g>
            <path
              d="M201.277778,49.5 L176.468316,49.5 M211,49.5 L208,49.5 M192,60.5 L180,60.5"
              id="Line-8"
              stroke="#A4E6FE"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              d="M55.2777778,79.5 L30.468316,79.5 M22.3,79.5 L16.390332,79.5 M63,92.5 L51,92.5"
              id="Line-8"
              stroke="#A4E6FE"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
          </g>
        </svg>
      </div>
    </div>
  </div>
</div>
