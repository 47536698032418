<bizz-modal modalSize="lg" [headerTemplate]="headerTemplateRef" [contentTemplate]="contentTemplateRef"
    [footerTemplate]="footerTemplateRef"></bizz-modal>

<ng-template #headerTemplateRef>
    <div class="modal-header">
        <h1 class="mb-5 text-center font-title text-xl font-bold text-default">
            {{'InstancePermissions' | translate}}
        </h1>
    </div>
    <hr class="my-5" />
</ng-template>

<ng-template #contentTemplateRef>
    <bizz-permissions-data class="overflow-y-auto" [data]="data"></bizz-permissions-data>
</ng-template>

<ng-template #footerTemplateRef>
    <div class="modal-buttons">
        <button class="default" type="button" (click)="dialogRef.close()">
            {{ 'Cancel' | translate }}
        </button>
        <button class="save min-w-16" type="submit" (click)="save()">
            <bizz-icon class="size-4" iconName="circle-check"></bizz-icon>
            {{ 'Save' | translate }}
        </button>
    </div>
</ng-template>