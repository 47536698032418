import { Component, Inject } from '@angular/core';
import { VersionType } from 'src/app/shared/enums/version-type';
import { CollectionListDataInstance } from 'src/app/shared/interfaces/collection-list-data-instance';
import { CollectionInstanceCompareDto } from 'src/models/ts/collection-instance-compare-dto.model';
import { GridService } from 'src/app/features/bizzmine/widgets/collection-list-widget/services/grid/grid.service';
import { CollectionListApiService } from 'src/app/api/bizzmine/collection-list/collection-list-api.service';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { FolderInstanceTargetType } from 'src/models/ts/folder-instance-target-type.model';
import { InstanceComparisonModalModel } from './compare-modal-model';
import { DownloadService } from 'src/app/core/services/download/download.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'bizz-compare-base-modal',
  standalone: true,
  imports: [],
  templateUrl: './compare-base-modal.component.html',
  styleUrl: './compare-base-modal.component.scss'
})

export class CompareBaseModalComponent {
  protected selectedVersion?: VersionType = undefined;
  protected deleteReason = '';
  protected model: CollectionInstanceCompareDto;
  protected data: Partial<CollectionListDataInstance>;
  
  public constructor(public dialogRef: DialogRef<CollectionListDataInstance>,
              @Inject(DIALOG_DATA) public modalData: InstanceComparisonModalModel,
              protected collectionListService: CollectionListApiService,
              protected gridService: GridService,
              protected downloadService: DownloadService,
              protected http: HttpClient) {
    this.dialogRef.disableClose = true;
    const { comparison, ...rest } = this.modalData;
    this.model = comparison;
    this.data = rest;
  }

  protected setSelectedVersion(version: VersionType): void {
    this.selectedVersion = version;
  }

  protected get hasData(): boolean {
    return Object.entries(this.model).length > 0;
  }

  protected get hasPublic(): boolean {
    return Object.entries(this.model['PublicVersion']).length > 0;
  }

  protected get hasDraft(): boolean {
    return Object.entries(this.model['DraftVersion']).length > 0;
  } 

  protected getVersionIdByType(versionType: VersionType): number | unknown {
    return versionType === VersionType.Draft ? (this.data['draftId'] ?? this.data['DraftsID']) : (this.data['versionId'] ?? this.data['VersionsID']);
  }

  protected readonly FolderInstanceTargetType = FolderInstanceTargetType;
  protected readonly VersionType = VersionType;
}
