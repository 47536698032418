import { Component, Inject } from '@angular/core';
import { ModalComponent } from 'src/app/shared/components/modals/modal/modal.component';
import { IconComponent } from 'src/app/shared/components/ui/icon/icon.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { PermissionsParams } from '../../interfaces/permissions-params.interface';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { PermissionsDataComponent } from '../permissions-data/permissions-data.component';
import { CollectionListApiService } from 'src/app/api/bizzmine/collection-list/collection-list-api.service';
import { take } from 'rxjs';

@Component({
  selector: 'bizz-permissions-modal',
  standalone: true,
  imports: [IconComponent, TranslatePipe, ModalComponent, PermissionsDataComponent],
  templateUrl: './permissions-modal.component.html',
  styleUrl: './permissions-modal.component.scss'
})
export class PermissionsModalComponent {

  public constructor(public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: PermissionsParams,
    private collectionListService: CollectionListApiService) { 
      this.dialogRef.disableClose = true;
    }


  public save(): void {
    this.collectionListService.savePermissions(this.data.collectionId, this.data.instanceId, this.data.versionId, this.data.folderId, this.data.permissions)
      .pipe(take(1)).subscribe(() => {
        this.dialogRef.close();
      })
  }
}
