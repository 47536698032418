import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ViewStackService } from '../../../../shared/services/view-stack/view-stack.service';
import { formsActions } from '../forms-actions';
import { debounceTime, map, tap } from 'rxjs';
import { ViewStackItem } from '../../../../shared/classes/view-stack-item';
import { ViewStackFormComponent } from '../../../../features/bizzmine/form/components/view-stack-form/view-stack-form.component';
import { viewStackActions } from '../view-stack-actions';
import { ViewStackLoaderType } from '../../../../shared/enums/view-stack-event-type.enum';

@Injectable()
export class FormsViewStackEffects {
  private actions$ = inject(Actions);

  public showLoaderSkeletonActions$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.getFormInstanceById,
      formsActions.saveFormInstance,
      formsActions.saveGridLinkedFormInstance,
      formsActions.getTaskForm,
      formsActions.getFormByCollectionId,
      formsActions.getFormByCollectionField,
      formsActions.getFormByCollectionMethodType,
      formsActions.getFormCreate,
      formsActions.getFormByCollectionGridField,
      formsActions.getFormEdit,
      formsActions.getFormReadOnly,
      formsActions.getFormEdit,
      formsActions.getFormByWidgetId,
      formsActions.getLinkedForm,
      formsActions.getTaskOnBehalfOf
      ),
    map(() => ({
      type: viewStackActions.showLoadingState.type,
        loaderType: ViewStackLoaderType.SKELETON
    })
    )
  ));
  public showLoaderDefaultActions$ = createEffect(() => this.actions$.pipe(
    ofType(
      formsActions.deleteSelfLock,
      formsActions.deleteLock,
      formsActions.refreshForm,
      formsActions.saveFormInstance,
      formsActions.createFormInstance,
      formsActions.saveTask
    ),
    map(() => ({
        type: viewStackActions.showLoadingState.type,
        loaderType: ViewStackLoaderType.DEFAULT
      })
    )
  ));

  public removeLoaderActions$ = createEffect(() => this.actions$.pipe(
    ofType(formsActions.formFetched,
      formsActions.lookupFailed,
      formsActions.gridLookupFailed,
      formsActions.saveFormInstanceSucceeded,
      formsActions.createFormInstanceSucceeded,
      formsActions.executeTaskSucceeded,
      formsActions.getEmptyRowForGridSucceeded,
      formsActions.formValidationSucceeded,
      formsActions.saveFormInstanceFailed,
      formsActions.createFormInstanceFailed,
      formsActions.executeTaskFailed,
      formsActions.getEmptyRowForGridFailed,
      formsActions.formValidationFailed,
      formsActions.saveGridLinkedFormInstanceSucceeded,
      formsActions.selfLockDeleted,
      formsActions.lockDeleted,
      formsActions.formRefreshed,
      formsActions.formClosed
    ),
    map(() => ({
      type: viewStackActions.removeLoadingState.type
    }))
  ));

  private viewStackService = inject(ViewStackService);
  public addFormToViewStack$ = createEffect(() => this.actions$.pipe(
    ofType(viewStackActions.addFormToViewStack),
    tap(({ data }) => {
      this.viewStackService.addItemToStack(new ViewStackItem(ViewStackFormComponent, data.id));
    })
  ), { dispatch: false });
  public removeFormFromViewStack$ = createEffect(() => this.actions$.pipe(
    ofType(viewStackActions.removeFormFromViewStack),
    tap(() => {
      this.viewStackService.removeLastItemFromStack();
    })
  ), { dispatch: false });

  public showLoadingState$ = createEffect(() => this.actions$.pipe(
    ofType(viewStackActions.showLoadingState),
    tap(({loaderType}) => {
      this.viewStackService.setLoadingState(loaderType ?? ViewStackLoaderType.DEFAULT);
    })
  ), { dispatch: false });

  public removeLoadingState$ = createEffect(() => this.actions$.pipe(
    ofType(viewStackActions.removeLoadingState),
    debounceTime(300),
    tap(() => {
      this.viewStackService.removeLoadingState();
    })
  ), { dispatch: false });
}