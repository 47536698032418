import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { GraphAuthentication } from '../../../../models/ts/graph-authentication.model';
import { Observable } from 'rxjs';

import { OneDriveItem } from '../../../../models/ts/one-drive-item.model';
import { FileInfoModel } from '../../../../models/ts/file-info-model.model';
import { FileUploadEditOnlineModalComponentMode } from '../../../../models/ts/file-upload-edit-online-modal-component-mode';

@Injectable({
  providedIn: 'root'
})
export class EditOnlineApiServiceService {

  public constructor(private httpClient: HttpClient) { }

  public authentication(): Observable<GraphAuthentication> {
    return this.httpClient.post<GraphAuthentication>('api/editonline/authentication', null);
  }

  public retrieveOnlineMediaId(request:{
    mediaId: number,
    oneDriveKey: string,
    lastModified: string
  }): Observable<HttpResponse<ArrayBuffer>> {
    return this.httpClient.post(`api/collection/${request.mediaId}/editonline/${request.oneDriveKey}/retrieve`, JSON.stringify(request.lastModified),{  headers: { 'Content-Type': 'application/json;charset=UTF-8' },observe:'response', responseType: 'arraybuffer' });
  }

  public removeCheckOutOnlineMedia(request: {
    oneDriveKey: string,
    mediasId: number,
    mode: FileUploadEditOnlineModalComponentMode,
    undoCheckout: boolean
  }): Observable<boolean>{
    return this.httpClient.get<boolean>(`api/collection/editonline/${request.oneDriveKey}/remove/${request.mediasId}/mode/${request.mode}/undocheckout/${request.undoCheckout}`);
  }

  public checkOutOnlineMedia(request: {
    doCheckoutCheckin: boolean,
    mode: FileUploadEditOnlineModalComponentMode,
    mediaId: number,fileName: string
  }): Observable<OneDriveItem> {
    return this.httpClient.post<OneDriveItem>(`api/collection/${request.mediaId}/editonline/${request.mode}/checkout/${request.doCheckoutCheckin}`,JSON.stringify(request.fileName), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } });
  }

  public retrieveMedia(request: {
    collectionId: number,
    instanceId: number,
    versionId: number,
  }): Observable<FileInfoModel> {
    return this.httpClient.get<FileInfoModel>(`api/media/collection/${request.collectionId}/instance/${request.instanceId}/version/${request.versionId}`);
  }
}
