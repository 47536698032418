import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FolderInstanceTargetType } from '../../../../../../models/ts/folder-instance-target-type.model';
import { IconComponent } from '../../../ui/icon/icon.component';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CollectionListDataInstance } from '../../../../interfaces/collection-list-data-instance';
import { HttpErrorResponse } from '@angular/common/http';
import { CollectionListApiService } from '../../../../../api/bizzmine/collection-list/collection-list-api.service';

/**
 * Modal that asks for reason and gives you the option to restart the
 * workflow of an instance.
 * @returns might return an error response if the instance is already published
 * and can't be restarted.
 */
@Component({
  selector: 'bizz-restart-workflow-modal',
  standalone: true,
  imports: [CommonModule, IconComponent, TextAreaModule, TranslatePipe],
  templateUrl: './restart-workflow-modal.component.html',
  styleUrls: ['./restart-workflow-modal.component.scss']
})
export class RestartWorkflowModalComponent {
  protected restartReason = '';
  protected dataItem: CollectionListDataInstance;
  protected readonly FolderInstanceTargetType = FolderInstanceTargetType;

  public constructor(public dialogRef: DialogRef<HttpErrorResponse>,
                     @Inject(DIALOG_DATA) public model: CollectionListDataInstance,
                     private collectionListService: CollectionListApiService) {
    this.dialogRef.disableClose = true;
    this.dataItem = model;
  }

  protected restart(): void {
    this.collectionListService.restartWorkflow(this.dataItem.CollectionsID!, this.dataItem.ID, this.dataItem.DraftsID ?? this.dataItem.VersionsID,
      this.restartReason).subscribe({
      next: () => {
        this.dialogRef.close();
      }, error: (value: HttpErrorResponse | undefined) => {
        this.dialogRef.close(value);
      }
    });
  }
}
