<!-- timestamp -->
<div class="hidden h-10 flex-col items-end justify-center text-xs md:flex">
  <span class="font-bold">{{ step.StepActionDate | utcDate }}</span>
  <span>{{ step.StepActionDate | time }}</span>
</div>

<!-- Icon and Timeline line -->
<div class="hidden flex-col md:flex">
  <div class="mx-12 my-0">
    @switch (step.StepType) {
    @case (StepType.DistributionStep) {
    <bizz-icon *ngIf="step.StepType == StepType.DistributionStep" 
    class="ml-auto block size-10 text-blue-500 md:ml-0"
      iconName="share-nodes">
    </bizz-icon>
    }
    @case (StepType.AssessmentStep) {
    <bizz-icon [ngClass]="{
                                    'text-lighter': step.HasCancelledTasks,
                                    'text-default': !step.HasCancelledTasks
                                  }" class="ml-auto block h-10 w-10 text-button md:ml-0"
      iconName="file-circle-check">
    </bizz-icon>
    }
    @case (StepType.PublicationStep) {
    <bizz-icon class="ml-auto block h-10 w-10 text-green-500 md:ml-0" iconName="envelope">
    </bizz-icon>
    }
    }

  </div>

  @if (!isLastStep) {
  <div class="mx-auto my-1.5 h-full w-0 border border-divider">
  </div>
  }

</div>

<!-- Step content & tasks -->
<div class="flex-1 pb-8">
  <div class="flex h-10 items-center text-xs">
    <span [ngClass]="{ 'text-lighter': step.HasCancelledTasks }" class="text-lg font-bold">{{
      step.StepName }}</span>

    <span class="ml-2 flex whitespace-normal md:hidden">
      {{ step.StepActionDate | utcDate }}
      {{ step.StepActionDate | time }}
    </span>

    <div *ngIf="step.StepType != StepType.PublicationStep" class="ml-auto flex items-center justify-center"
      (click)="step.collapsed = !step.collapsed">
      <bizz-icon *ngIf="step.collapsed" class="ml-auto block h-6 w-6 text-button md:ml-0 md:h-4 md:w-4"
        iconName="chevron-down">
      </bizz-icon>
      <bizz-icon *ngIf="!step.collapsed" class="ml-auto block h-6 w-6 text-button md:ml-0 md:h-4 md:w-4"
        iconName="chevron-up">
      </bizz-icon>
    </div>
  </div>

  @if (!step.collapsed) {
  <div class="flex flex-col">
    @for (task of step.Tasks; track task.TasksID) {
    <div class="mb-2 flex flex-col text-sm">
      <!--  TODO: RV move complex if statements to calculated properties -->
      <div class="mb-2 flex">
        <!-- #region Completed -->
        <div class="mr-4 flex min-w-[16px] items-center" *ngIf="task.Completed == TaskCompletedType.Completed">
          <bizz-icon *ngIf="(((task.AssessmentType == AssessmentType.Approved &&
                task.Remarks == '') ||
                step.StepType == StepType.DistributionStep) &&
                task.ReadAndUnderstood &&
                !task.IsForwarded) ||
              (task.AssessmentType == AssessmentType.Unknown &&
                step.IsVeto &&
                step.StepType != StepType.DistributionStep &&
                !task.IsForwarded)" class="ml-auto block h-4 w-4 text-green-500 md:ml-0" iconName="circle-check">
          </bizz-icon>

          <!-- Accepted with remarks -->
          <bizz-icon *ngIf="
              task.AssessmentType == AssessmentType.Approved &&
              task.Remarks != '' &&
              !task.IsForwarded" class="ml-auto block h-4 w-4 text-warning md:ml-0" iconName="triangle-exclamation">
          </bizz-icon>

          <!-- Forwarded -->
          <bizz-icon *ngIf="
              task.AssessmentType == AssessmentType.Unknown &&
              task.Completed == TaskCompletedType.Completed &&
              task.IsForwarded
            " class="ml-auto block h-4 w-4 text-blue-500 md:ml-0" iconName="share">
          </bizz-icon>
          <!-- Rejected -->
          <bizz-icon *ngIf="(task.AssessmentType == AssessmentType.Unknown &&
                task.IsCancelled &&
                !step.IsVeto) ||
              task.AssessmentType == AssessmentType.Rejected ||
              (step.StepType == StepType.DistributionStep &&
                !task.ReadAndUnderstood)" class="ml-auto block h-4 w-4 text-danger md:ml-0" iconName="circle-xmark">
          </bizz-icon>
        </div>

        <div class="flex items-center text-xs" *ngIf="task.Completed == TaskCompletedType.Completed">
          <span>{{ task.Date | utcDate }} {{ task.Date | time }}</span>
          <span class="whitespace-pre-wrap"> - </span>
          <span class="whitespace-pre-wrap font-bold">{{
            task.CompletedBy
            }}</span>
          <span class="ml-2 w-min whitespace-pre-wrap rounded bg-button px-1.5 py-1 text-xs md:w-auto" *ngIf="
              task.CompletedBy != task.CompletedOnBehalfOf &&
              !task.AutoCompleted">
            {{ 'OnBehalfOf' | translate }} {{ task.CompletedOnBehalfOf }}
          </span>
          @if (task.IsVeto) {
          <span
            class="ml-2 whitespace-pre-wrap rounded bg-green-500 px-1.5 py-1 font-sans font-bold uppercase text-white">
            {{ 'Veto' | translate }}
          </span>
          }
          @if (task.IsForwarded) {
          <span class="ml-2 w-min whitespace-pre-wrap rounded bg-button px-1.5 py-1 text-xs md:w-auto">
            {{ 'ForwardedTo' | translate }} {{ task.ForwardedTo }}
          </span>
          }
        </div>

        @if (task.Completed == TaskCompletedType.Incompleted) {
        <div class="mr-4 flex items-center">
          <bizz-icon class="ml-auto block h-4 w-4 text-button md:ml-0" iconName="hourglass-half">
          </bizz-icon>
        </div>
        <div class="flex items-center">
          <span class="font-bold">{{ task.AssignedToName }}</span>
        </div>
        }

      </div>
      @if (task.ReadAndUnderstoodReason) {
      <div class="mb-4 ml-8 mr-12 mt-0 flex text-xs">
        <div [ngClass]="{ truncate: task.reasonCollapsed }" id="understood-remark-{{ task.TasksID }}"
          class="grey-darkest text-center">
          {{ task.ReadAndUnderstoodReason }}
        </div>
        <div class="ml-auto pl-2" (click)="task.reasonCollapsed = !task.reasonCollapsed">
          @if (task.reasonCollapsed) {
          <bizz-icon class="ml-auto block h-6 w-6 text-button md:ml-0 md:h-4 md:w-4"
            iconName="chevron-down">
          </bizz-icon>
          } @else if (isTextCollapsable('understood-remark-' + task.TasksID)) {
          <bizz-icon class="ml-auto block h-6 w-6 text-button md:ml-0 md:h-4 md:w-4"
            iconName="chevron-up">
          </bizz-icon>
          }
        </div>
      </div>
      }

      <div class="mb-4 ml-8 mr-12 mt-0 grid max-w-[900px] grid-cols-[auto_auto] text-xs"
        *ngIf="task.ForwardedReason != '' || task.Remarks != ''">
        <div *ngIf="task.ForwardedReason != ''" [ngClass]="{ truncate: task.collapsed }" id="forward-{{ task.TasksID }}"
          class="grey-darkest">
          {{ 'Reason_Remark' | translate }}: {{ task.ForwardedReason }}
        </div>
        <div *ngIf="task.ForwardedReason == '' && task.Remarks != ''" [ngClass]="{ truncate: task.collapsed }"
          id="remark-{{ task.TasksID }}" class="grey-darkest">
          {{ 'Reason_Remark' | translate }}: {{ task.Remarks }}
        </div>
        <div class="ml-auto pl-2" (click)="task.collapsed = !task.collapsed">
          <bizz-icon *ngIf="task.collapsed"
            class="ml-auto hidden text-button md:ml-0 md:block md:h-4 md:w-4"
            iconName="chevron-down">
          </bizz-icon>
          <bizz-icon *ngIf="
              !task.collapsed &&
              isTextCollapsable(
                (task.ForwardedReason == '' ? 'remark-' : 'forward-') +
                  task.TasksID
              )
            " class="ml-auto hidden text-button md:ml-0 md:block md:h-4 md:w-4"
            iconName="chevron-up">
          </bizz-icon>
        </div>
      </div>
    </div>
  }
  </div>
}
</div>