<bizz-file-select-multi #MailingAttachmentsUploadComponentFileSelect (filesSelected)="filesDropped($event)"></bizz-file-select-multi>
<div bizz-drag-drop
     class="flex h-auto flex-col rounded border-2 border-dashed pt-4"
     [class.border-blue-500]="isHoveringDragDrop"
     [class.bg-blue-50]="isHoveringDragDrop"
     (files)="filesDropped($event)"
     (isHoveringDragDrop)="isHoveringDragDrop = $event"
     [class.border-form-control]="!isHoveringDragDrop"
     [class.bg-section]="!isHoveringDragDrop"
     [ngClass]="{'bg-blue-500/20 border-blue-500' : isHoveringDragDrop}">
    <div class="relative flex flex-col items-center justify-between p-4 sm:flex-row" #attachmentSelector>
        <!-- Dropzone-->

        <div class="flex flex-1 cursor-pointer flex-col items-center justify-center gap-2.5" (click)="openFileSelect();">
            <bizz-icon
                    class="ml-0 block text-blue-500 sm:ml-auto md:ml-0 size-10"
                    iconName="cloud-arrow-up"
                    [hover]="true">
            </bizz-icon>
            <p class="select-none text-xs sm:text-sm">{{ "DropOrUploadFile" | translate }}</p>
        </div>
        @if (media$ | async; as media) {
            <div class="flex justify-center items-center">
                <div class="m-2.5 h-full w-full rounded bg-divider-handle sm:h-12 sm:w-1">
                </div>
            </div>

            <div class="flex flex-1 cursor-pointer flex-col items-center justify-center gap-2.5"
                 (click)="showPopup = !showPopup"
                    #anchorParent>
                <bizz-icon
                        class="ml-0 block text-blue-500 sm:ml-auto md:ml-0 size-10"
                        iconName="file-circle-plus"
                        [hover]="true">
                </bizz-icon>
                <p class="select-none text-xs sm:text-sm">{{ "AddAttachmentCurrentForm" | translate }}                 <bizz-icon class="size-4 items-center align-middle transition-transform" iconName="caret-down"
                                                                                                                       [ngClass]="{'rotate-180': showPopup }"></bizz-icon><br>
                </p>
            </div>
            @if (showPopup) {
                <kendo-popup
                        [anchor]="anchor"
                        [margin]="{ horizontal: 0, vertical: 15 }"
                        class="rounded"
                        #popup
                >
                    @if (media$ | async; as medias) {

                        <kendo-grid
                                [data]="gridView"
                                [groupable]="false"
                                [group]="groups"
                                [resizable]="true"
                                [ngStyle]="{'width': attachmentWidth}"
                                (cellClick)="onCellClick($event)"
                                class="max-h-96 cursor-pointer overflow-auto rounded p-2.5"
                        >
                            <kendo-grid-column field="CollectionName" [hidden]="true">
                                <ng-template kendoGridGroupHeaderTemplate let-value="value">
                                    {{ value }}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column [media]="'(max-width: ' + MAX_MOBILE_WIDTH + 'px)'">
                                <ng-template kendoGridCellTemplate let-media>
                                    <div class="flex flex-col gap-2.5 p-2.5">
                                        <div class="flex w-full justify-between">
                                            <div>{{ 'Extension' | translate }}</div>
                                            <div>
                                                <bizz-extension-icon class="size-4 items-end"
                                                                     [extension]="getFileExtension(media.Extension)"></bizz-extension-icon>
                                            </div>
                                        </div>
                                        <div class="flex w-full justify-between">
                                            <div>{{ 'Title' | translate }}</div>
                                            <div>{{ media.OriginalFileName }}</div>
                                        </div>
                                        <div class="flex w-full justify-between">
                                            <div>{{ 'FileSize' | translate }}</div>
                                            <div>{{ media.FileSize }}</div>
                                        </div>


                                        <div class="flex w-full justify-between">
                                            <div>{{ 'Author' | translate }}</div>
                                            <div>{{ media.RegisteredBy }}</div>
                                        </div>

                                        <div class="flex w-full justify-between">
                                            <div>{{ 'Version' | translate }}</div>
                                            <div>{{ media.Version }}</div>
                                        </div>


                                        <div class="flex w-full justify-between">
                                            <div>{{ 'VersionDate' | translate }}</div>
                                            <div>{{ media.UploadTimestamp | date:'short' }}</div>
                                        </div>

                                        <div class="flex w-full justify-end">
                                            <input type="checkbox" [checked]="containsMedia(media)"
                                                   (change)="toggleMediaAttachment(media)">
                                        </div>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column [title]="'Extension' | translate" [width]="70"
                                               [media]="'(min-width: ' + MAX_MOBILE_WIDTH + 'px)'">
                                <ng-template kendoGridCellTemplate let-media>
                                    <bizz-extension-icon class="mx-4 size-4"
                                                         [extension]="getFileExtension(media.Extension)"></bizz-extension-icon>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column [width]="200"
                                               field="OriginalFileName"
                                               [title]="'Title' | translate"
                                               [media]="'(min-width: ' + MAX_MOBILE_WIDTH + 'px)'"
                            ></kendo-grid-column>
                            <kendo-grid-column
                                    field="FileSize"
                                    [title]="'FileSize' | translate"
                                    [media]="'(min-width: ' + MAX_MOBILE_WIDTH + 'px)'"
                            ></kendo-grid-column>


                            <kendo-grid-column
                                    [media]="'(min-width: ' + MAX_MOBILE_WIDTH + 'px)'"
                                    field="Author"
                                    [title]="'RegisteredBy' | translate"
                            ></kendo-grid-column>

                            <kendo-grid-column
                                    [media]="'(min-width: ' + MAX_MOBILE_WIDTH + 'px)'"
                                    [width]="60"
                                    field="Version"
                                    [title]="'Version' | translate"
                            ></kendo-grid-column>


                            <kendo-grid-column
                                    [media]="'(min-width: ' + MAX_MOBILE_WIDTH + 'px)'"
                                    [title]="'VersionDate' | translate"
                            >
                                <ng-template kendoGridCellTemplate let-media>
                                    <span>{{ media.UploadTimestamp | date:'short' }}</span>
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column [width]="50"
                                               [media]="'(min-width: ' + MAX_MOBILE_WIDTH + 'px)'">
                                <ng-template kendoGridCellTemplate let-media>
                                    <input type="checkbox" [checked]="containsMedia(media)"
                                           (change)="toggleMediaAttachment(media)">
                                </ng-template>
                            </kendo-grid-column>

                        </kendo-grid>
                        <div class="flex flex-row items-center justify-end">
                            <button class="save m-4" (click)="applySelection(medias)">
                                <bizz-icon class="size-3" iconName="circle-plus"></bizz-icon>
                                {{ "AddSelectedFiles" | translate }}
                            </button>
                        </div>

                    }
                </kendo-popup>
            }
        }


    </div>
    <div class="flex flex-row rounded" #anchor></div>
</div>


<div class="my-2.5 flex min-w-full max-w-full flex-row flex-wrap gap-2.5 text-xs">
    @for (attachment of attachments; track attachment.id) {
        <div class="flex h-fit rounded bg-section">
            <div class="flex select-none items-center justify-between rounded border border-section py-2">
                <bizz-extension-icon class="mx-2.5 flex h-4 w-4 items-center"
                                     [extension]="getFileExtension(attachment.fileName)"></bizz-extension-icon>
                <p class="m-auto">{{ attachment.fileName }} <span>({{ formatFileSize(attachment.size) }})</span></p>
                <bizz-icon (click)="removeAttachment(attachment.id)" class="mx-2.5 h-4 w-4 cursor-pointer hover:text-red-500" iconName="circle-xmark">
                </bizz-icon>
            </div>
        </div>
    }
    @for (fileUpload of fileUploads; track $index) {
        @if(fileUpload.fileChunkUpload){
            <div class="flex h-fit flex-wrap gap-2.5 rounded border border-widget bg-section border-blue-500/20">
                <div class="flex select-none items-center justify-between py-2">
                    <bizz-extension-icon class="mx-2.5 flex h-4 w-4 items-center"
                                         [extension]="'.' + getFileExtension(fileUpload.fileChunkUpload.fileName)"></bizz-extension-icon>
                    <div class="flex flex-col">
                        <p class="m-auto">{{ fileUpload.fileChunkUpload.fileName }} <span>({{ formatFileSize(fileUpload.fileChunkUpload.size) }}
                            )</span></p>
                        <div class="m-auto flex w-full justify-between gap-2.5">
                            <div class="m-auto h-2.5 w-full rounded-full bg-button">
                                <div class="h-2.5 w-full rounded-full bg-blue-600"
                                     [ngStyle]="{'width': fileUpload.fileChunkUpload.progress + '%'}"></div>
                            </div>
                            <div class="ml-4">{{ (fileUpload.fileChunkUpload.progress.toFixed(0) + '%') }}</div>
                        </div>
                    </div>

                    <bizz-icon (click)="fileUpload.cancel()" class="mx-2.5 h-4 w-4 cursor-pointer hover:text-red-500"
                               iconName="circle-xmark">
                    </bizz-icon>
                </div>

            </div>
        }

    }
    <div class="flex">
        @if(emptyExtensionError){
            <p class="text-danger">{{"Info_EmptyExtension" | translate}}</p>
        }
        @if(sizeError){
            <p class="text-danger">{{"FileExceedsMaxSizeLimit" | translate}}</p>
        }
    </div>

</div>