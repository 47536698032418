import {
  GroupableSettings,
  PagerSettings,
  ScrollMode,
  SelectableSettings,
  SortSettings
} from '@progress/kendo-angular-grid';
import { GroupDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { GridOptionsDto } from '../../../../models/ts/grid-options-dto.model';
import { GridDataSourceDto } from '../../../../models/ts/grid-data-source-dto.model';
import { GridColumnBase } from './grid-column-base';

export class GridOptions {
  public name: string;
  public columns: GridColumnBase[];
  public scrollable: ScrollMode;
  public sortable: SortSettings;
  public selectable: SelectableSettings;
  public resizable: boolean;
  public reorderable: boolean;
  public groupable: GroupableSettings;
  public dataSource: { group: Array<GroupDescriptor>, sort: Array<SortDescriptor> };
  public pageable: PagerSettings;
  public pageSize = 10;
  public skip = 0;
  public autoSize = false;

  // private PagerType: "numeric" | "input";

  public constructor(gridOptionsDto: GridOptionsDto) {
    this.name = gridOptionsDto.name;
    //TODO: RV Improve this mapping
    this.columns = gridOptionsDto.columns as unknown as GridColumnBase[];
    this.scrollable = gridOptionsDto.scrollable ? 'scrollable' : 'none'; //TODO: RV check if it's possible to make a grid non scrollable in bizzmine.
    this.sortable = {
      allowUnsort: gridOptionsDto.sortable.allowUnsort,
      showIndexes: gridOptionsDto.sortable.showIndexes,
      mode: 'multiple',
      multiSortKey: 'none'
    };
    if (typeof (gridOptionsDto.selectable) === 'boolean' || gridOptionsDto.selectable === undefined) {
      this.selectable = {
        enabled: gridOptionsDto.selectable ?? false,
        checkboxOnly: true,
        mode: 'single',
        cell: false,
        drag: false
      };
    } else {
      this.selectable = gridOptionsDto.selectable;
    }

    this.resizable = gridOptionsDto.resizable;
    this.reorderable = gridOptionsDto.reorderable;
    this.groupable = {
      enabled: true,
      showFooter: false,
      emptyText: gridOptionsDto.groupable.messages.empty
    };
    this.dataSource = this.mapDataSource(gridOptionsDto.dataSource);

    //Ignore backend pageable object as it's used for legacy BizzMine.
    this.pageable = {
      buttonCount: 3,
      info: true,
      type: 'numeric',
      previousNext: true,
      responsive: true,
      position: 'bottom',
      pageSizes: [1, 2, 5, 10, 20, 50, 200]
    };
  }

  /**
   * Maps legacy dataSource to the new kendo datasource format.
   * @param dataSource
   * @private
   */
  private mapDataSource(dataSource: GridDataSourceDto): { group: Array<GroupDescriptor>, sort: Array<SortDescriptor> } {
    const group = new Array<GroupDescriptor>();
    dataSource.group.map(g => {
      group.push({ field: g.field, dir: g.dir as 'asc' | 'desc' });
    });
    const sort = new Array<SortDescriptor>();
    dataSource.sort.map(s => {
      sort.push({ field: s.field, dir: s.dir as 'asc' | 'desc' });
    });
    return { group, sort };
  }
}
