import { Component, computed, OnInit } from '@angular/core';
import { BaseFormControlComponent } from '../../../../classes/base-form-control.component';
import { AsyncPipe, NgStyle } from '@angular/common';
import { TrnExamQuestion } from '../../../../../../../../models/ts/trn-exam-question.model';
import { TranslatePipe } from '../../../../../../../shared/pipes/translate/translate.pipe';
import {
  BehaviorSubject, catchError,
  concat,
  lastValueFrom,
  map,
  mergeMap,
  Observable, of,
  take,
  tap
} from 'rxjs';
import { FormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UpdateDeleteState } from '../../../../../../../../models/ts/update-delete-state.model';
import { SafePipe } from 'safe-pipe';
import { IconComponent } from '../../../../../../../shared/components/ui/icon/icon.component';
import { IconLibrary } from 'src/models/ts/icon-library.model';
import { TrainingApiService } from '../../../../../../../api/bizzmine/training/training-api.service';
import { Dialog } from '@angular/cdk/dialog';
import { ExamCreatorAddQuestionControlComponent } from '../exam-creator-add-question-control/exam-creator-add-question-control/exam-creator-add-question-control.component';
import { LoaderComponent } from '../../../../../../../shared/components/ui/loader/loader.component';
import { LoaderButtonComponent } from '../../../../../../../shared/components/loader-button/loader-button/loader-button.component';
import { ExamCreatorGenerateAiControlComponent } from '../exam-creator-ai-control-component/exam-creator-generate-ai-control.component';
import { ProtectedFieldType } from '../../../../../../../../models/ts/protected-field-type.model';
import {
  selectForm,
  selectFormFieldValues,
  selectFormLockState
} from '../../../../../../../store/features/forms/forms-selectors';
import { formsActions } from '../../../../../../../store/features/forms/forms-actions';
import { getFileName } from '../../../../../../../shared/functions/helpers/file-name-helpers';

@Component({
  selector: 'bizz-exam-creator-control',
  standalone: true,
  imports: [
    TranslatePipe,
    FormsModule,
    AsyncPipe,
    SafePipe,
    NgStyle,
    IconComponent,
    LoaderComponent,
    LoaderButtonComponent
  ],
  templateUrl: './exam-creator-control.component.html',
  styleUrl: './exam-creator-control.component.scss'
})
export class ExamCreatorControlComponent extends BaseFormControlComponent implements OnInit {
  public isLoadingAi = false;
  public isLocked = computed(() => {
    if (this.formId != null) {
      return this.store$.selectSignal(selectFormLockState(this.formId))();
    }
    return false;
  });
  public searchTerm$ = new BehaviorSubject<string>('');
  public hasAiLicense$ = this.trainingApiService.hasAiLicense().pipe(take(1));
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly UpdateDeleteState = UpdateDeleteState;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly IconLibrary = IconLibrary;
  private questions: Array<TrnExamQuestion>;
  public questions$ = this.searchTerm$
    .pipe(
      takeUntilDestroyed(this.destroyRef),
      map(search => {
        return this.applyFilter(this.questions, search)
          .map((item) => ({
            ...item,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            IsExpanded: false
          }));
      }));
  private collectionsId: number;
  private versionId: number;

  public constructor(private trainingApiService: TrainingApiService,
                     private dialog: Dialog) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    const cloned = structuredClone(this.formFieldSignal());
    this.questions = <Array<TrnExamQuestion>>cloned?.Value;
  }

  public addQuestion(): void {
    this.editQuestion(undefined);
  }

  public generateWithAi(): void {
    let maxQuestion: number | null = null;
    let maxAnswers: number | null = null;
    let examName: string | null = null;

    if (!this.collectionsId || !this.versionId) {
      this.store$.select(selectForm(this.formId)).pipe(take(1)).subscribe(form => {
        if (form && form.data) {
          this.collectionsId = form.data.CollectionsID;
          this.versionId = form.data.VersionsID;
        }
      });
    }

      this.store$.select(selectFormFieldValues(this.formId, {collectionId: this.collectionsId},[ProtectedFieldType.ExamAmountOfQuestions, ProtectedFieldType.ExamAmountOfAnswersPerQuestion, ProtectedFieldType.ExamName]))
        .pipe(
          take(1)
        ).subscribe(fieldValues => {
          if (fieldValues) {
            maxQuestion = fieldValues[ProtectedFieldType.ExamAmountOfQuestions] as number;
            maxAnswers = fieldValues[ProtectedFieldType.ExamAmountOfAnswersPerQuestion] as number;
            examName = fieldValues[ProtectedFieldType.ExamName] as string;
          }
        }
      );



    this.dialog.open<{
      questions: number,
      answersPerQuestion: number
    }>(ExamCreatorGenerateAiControlComponent, {
      data: {
        defaultQuestions: maxQuestion,
        defaultAnswersPerQuestion: maxAnswers
      }
    }).closed.pipe(take(1)).subscribe((result) => {
      if (result != null && result.answersPerQuestion > 0 && result.questions > 0) {
        this.invokeGenerateWithAiIntent({
          amountOfQuestions: result.questions,
          amountOfAnswers: result.answersPerQuestion,
          examName: examName ?? '',
          collectionsId: this.collectionsId,
          examVersionId: this.versionId
        });
      }
    });
  }

  public deleteQuestion(index: number): void {
    this.questions[index].State = UpdateDeleteState.Delete;
    this.searchTerm$.next(this.searchTerm$.value);
    this.dispatchValueChange();
  }

  private dispatchValueChange(): void {
    const field = this.formFieldSignal();
    if(field){
      this.store$.dispatch(formsActions.updateFormField({ formId: this.formId, fieldId: field.Id, value: this.questions.map(q => {
          q.ID = q.ID ?? 0;
          q.State = q.State ?? UpdateDeleteState.Update;
          q.Answers = q.Answers.map(a => {
            a.ID = a.ID ?? 0;
            a.State = a.State ?? UpdateDeleteState.Update;
            return a;
          })
          return q;
        }) }));
    }
  }

  public editQuestion(index: number | undefined): void {
    const question = index != null ? this.questions[index] : null;
    lastValueFrom(this.dialog.open<TrnExamQuestion>(ExamCreatorAddQuestionControlComponent, {
      data: question
    }).closed).then((result) => {
      if (result != null) {
        if (index == null) {
          this.questions.push(result);
        } else {
          result.Generated = false;
          this.questions[index] = result;
        }
        this.searchTerm$.next(this.searchTerm$.value);
      }
      this.dispatchValueChange();
    });
  }

  public generatingQuestionQueue: Array<{
    Failed?: boolean | undefined,
    Success?: boolean | undefined,
  }> = [];

  public getFailedGenerates() : number {
    return this.generatingQuestionQueue.filter((item) => item.Failed === true).length;
  }
  public getSuccessfulGenerates() : number {
    return this.generatingQuestionQueue.filter((item) => item.Success === true).length;
  }
  private invokeGenerateWithAiIntent(intent: {
    collectionsId: number,
    examVersionId: number,
    examName: string,
    amountOfQuestions: number,
    amountOfAnswers: number
  }): void {
    this.isLoadingAi = true;

    const questionOvservables: Array<Observable<Array<TrnExamQuestion>>> = [];
    this.generatingQuestionQueue = [];
    for (let i = 0; i < intent.amountOfQuestions; i++) {
      this.generatingQuestionQueue.push({});
      questionOvservables.push(this.trainingApiService.getGeneratedQuestionsWithAi({
        amountOfQuestions: intent.amountOfQuestions,
        examName: intent.examName,
        collectionsId: intent.collectionsId,
        examVersionId: intent.examVersionId,
        amountOfAnswers: intent.amountOfAnswers
      })
        .pipe(take(1),
          catchError(() => {
            return of([]);
          }),
          tap(questions => {

            if(questions != null && questions.length > 0) {
              this.generatingQuestionQueue[i] = { Success: true};
              this.questions = this.questions ?? [];
              const clonedQuestions = structuredClone(questions); // properties are readonly -> need to clone
              clonedQuestions?.forEach((question) => {
                question.Generated = true;
                this.questions.push(question);
              });
              this.searchTerm$.next('');
            } else {
              this.generatingQuestionQueue[i] = { Failed: true};
            }
            this.dispatchValueChange();
          })))
    }
    concat(questionOvservables).pipe(mergeMap((obs) => {
      return obs;
    },2)).subscribe({
      complete: () => {
        this.isLoadingAi = false
        this.generatingQuestionQueue = [];
      }
    });
  }

  private applyFilter(source: Array<TrnExamQuestion>, searchTerm: string): Array<TrnExamQuestion> {
    return source.filter((item) => {
      return item.Caption.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }

  protected override focus(): void {
    // Add your implementation here
  }
}
