import { CommonModule, NgStyle, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { IconLibrary } from '../../../../../../models/ts/icon-library.model';
import { ProtectedFieldType } from '../../../../../../models/ts/protected-field-type.model';
import { IconComponent } from '../icon.component';
import { MultiSwitchCasePipe } from '../../../../pipes/multi-switch-case/multi-switch-case.pipe';
import { EXTENSION_ICON_NAMES } from './extension-icon-names';
import { EXTENSION_ICON_COLORS } from './extension-icon-colors';
import { EXTENSION_ICON_SIZES } from './extension-icon-size';

/**
 * Creates a file bizz-icon component for the correct extension.
 */
@Component({
  selector: 'bizz-extension-icon',
  templateUrl: './extension-icon.component.html',
  styleUrls: ['./extension-icon.component.scss'],
  imports: [
    CommonModule,
    IconComponent,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    MultiSwitchCasePipe,
    NgStyle
  ],
  standalone: true
})
export class ExtensionIconComponent implements OnInit, OnChanges {

  public readonly IconLibrary = IconLibrary;

  @Input({ required: true }) extension: string;
  @Input() isUploadIcon = false;
  @Input() size = 'small';

  public iconLibrary = IconLibrary.FontAwesome;
  public iconName: string;
  public iconSize: string;

  protected readonly ProtectedFieldType = ProtectedFieldType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly DEFAULT_ICON_COLOR = 'rgb(var(--sidebar-icon-text))';

  public iconColor: string = this.DEFAULT_ICON_COLOR;

  public ngOnInit(): void {
    this.updateIcon();
  }

  public ngOnChanges(): void {
    this.updateIcon();
  }

  private updateIcon(): void {
    if (this.extension && this.extension.length > 0 && !this.extension.startsWith('.')) {
      this.extension = '.' + this.extension;
    }
    if (Object.prototype.hasOwnProperty.call(EXTENSION_ICON_NAMES, this.extension)) {
      this.iconName = EXTENSION_ICON_NAMES[this.extension];
      //A bit hacky but all icons only have these two libraries for now.
      if (this.iconName.startsWith('fa-')) {
        this.iconLibrary = IconLibrary.FontAwesome;
      } else {
        this.iconLibrary = IconLibrary.BizzMine;
      }
    } else {
      this.iconName = 'file';
      this.iconLibrary = IconLibrary.FontAwesome;
    }

    //Set color
    if (Object.prototype.hasOwnProperty.call(EXTENSION_ICON_COLORS, this.iconName))
      this.iconColor = EXTENSION_ICON_COLORS[this.iconName];
    else
      this.iconColor = this.DEFAULT_ICON_COLOR;

    //Remove fa- prefix because fontawesome svg sprites don't have it.
    this.iconName = this.iconName.replace('fa-', '');

    //Set sizes
    this.iconSize = EXTENSION_ICON_SIZES[this.size];
  }
}
