import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {

  public transform(value: string, maxlength: number): string {
    return (value.length > maxlength) ?
      value.slice(0, maxlength - 1) + '…' : value;
  }

}
