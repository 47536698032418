<div class="flex max-w-full flex-grow flex-wrap-reverse gap-2 sm:flex-nowrap" [ngClass]="{
    'flex-row-reverse justify-start' : taskStatusAccessible,
    'justify-end' : !taskStatusAccessible }">
    @if(searchFieldAccessible){
        <bizz-search [searchTerm]="searchTerm" (searchTermChange)="onSearchTermChanged($event)">
        </bizz-search>
    }
    @if (taskStatusAccessible){
        <kendo-dropdownlist
                class="h-full w-28 sm:w-48"
                [data]="searchedTaskDto"
                [value]="taskStateFilterType"
                textField="Text"
                valueField="Value"
                [valuePrimitive]="true"
                (valueChange)="onTaskStatusChanged($event)">
        </kendo-dropdownlist>
    }
    @if(filterEnabled){
        <div class="flex flex-row rounded">
            <button [disabled]="loading" (click)="openFilterModal()" class="p-1">
                <bizz-icon class="icon-button h-4 w-4" [ngClass]="activeFilter ? '!text-blue-500' : ''" iconName="filter"></bizz-icon>
            </button>
            @if (dropdownAccessible){
                <button class="p-1" type="button" #anchor (click)="toggle()">
                    <bizz-toggle-arrow class="icon-button h-4 w-4" [ngClass]="activeFilter ? '!text-blue-500' : ''" [direction]="caretVisible ? 'up' : 'down'"></bizz-toggle-arrow>
                </button>
                <kendo-popup #popup
                             *ngIf="caretVisible && (window.innerWidth > 640)"
                             [popupClass]="'flex w-screen sm:w-auto h-auto sm:justify-center'"
                             class="z-[1004] mt-12 sm:mt-0"
                             [positionMode]="'absolute'"
                             [anchorAlign]="anchorAlign"
                             [popupAlign]="popupAlign"
                             [anchor]="anchor">
                    <div class="bizz-popup-content flex flex-col">

                        <button type="button" (click)="resetFilterDefault()">
                            <bizz-icon class="mr-2 size-6 sm:size-4" [hover]="true" iconName="rotate-left"></bizz-icon>
                            {{"ResetToDefaultFilter" | translate}}
                        </button>
                        @if (filterList.length > 1){
                            <hr>
                        }
                        @for (filter of filterList; track $index){
                            @if (filter.Priority > 1){
                                <button type="button" (click)="loadCaretFilter(filter.ID)" [ngClass]="filter.ID == activeFiltersId ? 'text-blue-500' : ''">
                                    <bizz-icon class="mr-2 size-6 sm:size-4" [hover]="true" iconName="filter" [ngClass]="filter.ID == activeFiltersId ? 'text-blue-500' : ''"></bizz-icon>
                                    {{filter.Name}}
                                </button>
                            }
                        }
                        <hr>
                        <button type="button" (click)="clearFilter()">
                            <bizz-icon class="mr-2 size-6 sm:size-4" [hover]="true" iconName="eraser"></bizz-icon>
                            {{"ClearAllFilters" | translate}}
                        </button>

                    </div>
                </kendo-popup>
                <kendo-popup #mobilePopup *ngIf="caretVisible && (window.innerWidth < 640)"
                             class="z-[1004] w-screen"
                             [anchor]="anchor"
                             [margin]="{ horizontal: 0, vertical: 10 }"
                             [collision]="{ horizontal: 'fit', vertical: 'fit' }"
                             [popupAlign]="{ horizontal: 'right', vertical: 'top' }"
                             [anchorAlign]="{ horizontal: 'right', vertical: 'bottom' }">

                    <div class="bizz-popup-content flex flex-col">
                        <button type="button" (click)="resetFilterDefault()">
                            <bizz-icon class="mr-2 size-6 sm:size-4" [hover]="true" iconName="rotate-left"></bizz-icon>
                            {{"ResetToDefaultFilter" | translate}}
                        </button>
                        @if (filterList.length > 1){
                            <hr>
                        }
                        @for (filter of filterList; track $index){
                            @if (filter.Priority > 1){
                                <button type="button" (click)="loadCaretFilter(filter.ID)" [ngClass]="filter.ID == activeFiltersId ? 'text-blue-500' : ''">
                                    <bizz-icon class="mr-2 size-6 sm:size-4" [hover]="true" iconName="filter" [ngClass]="filter.ID == activeFiltersId ? 'text-blue-500' : ''"></bizz-icon>
                                    {{filter.Name}}
                                </button>
                            }
                        }
                        <hr>
                        <button type="button" (click)="clearFilter()">
                            <bizz-icon class="mr-2 size-6 sm:size-4" [hover]="true" iconName="eraser"></bizz-icon>
                            {{"ClearAllFilters" | translate}}
                        </button>

                    </div>
                </kendo-popup>
            }
        </div>
    }
</div>