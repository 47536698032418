import { GridParentFilter } from '../../../../models/ts/grid-parent-filter.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { StoreCollectionForm } from './forms-state';
import { Update } from '@ngrx/entity';
import { LinkedCollectionType } from '../../../../models/ts/linked-collection-type.model';
import { CollectionForm } from '../../../../models/ts/collection-form.model';
import { CollectionFormField } from '../../../../models/ts/collection-form-field.model';
import { ViewDataSource } from '../../../../models/ts/view-data-source.model';
import { SelectedOrganizationChartItemDto } from '../../../../models/ts/selected-organization-chart-item-dto.model';
import { HttpErrorResponse } from '@angular/common/http';
import { CollectionFormValidatorResult } from '../../../features/bizzmine/form/classes/collection-form-validator-result';
import { CreatedFormInstance } from '../../../features/bizzmine/form/classes/created-form-instance';
import { SchedulerDto } from 'src/models/ts/scheduler-dto.model';
import { OrganizationChartItemType } from '../../../../models/ts/organization-chart-item-type.model';
import { Record } from 'src/models/ts/collection-form-field.model';
import { StateType } from '../../../../models/ts/state-type.model';
import { CollectionMethodType } from '../../../../models/ts/collection-method-type.model';
import { CollectionFormFieldGridLookupData } from '../../../../models/ts/collection-form-field-grid-lookup-data.model';
import { CollectionFormFieldSingleLookupData } from '../../../../models/ts/collection-form-field-single-lookup-data.model';
import { VersionType } from 'src/app/shared/enums/version-type';
import { MediaCommitDto } from '../../../../models/ts/media-commit-dto.model';
import { LookupFieldData } from 'src/app/shared/services/lookup/lookup.service';
import { FlowStatusTaskDto } from '../../../../models/ts/flow-status-task-dto.model';
import { CollectionListDataInstance } from '../../../shared/interfaces/collection-list-data-instance';
import { ReadOnlyPriority } from '../../../features/bizzmine/form/enums/read-only-priority.enum';
import { CollectionFormExternalAccessDto } from '../../../../models/ts/collection-form-external-access-dto';
import { SkillIntegrityCheck } from '../../../../models/ts/skill-integrity-check.model';
import { CollectionListSummaryItem, LookupItem } from '../../../../models/ts/collection-list-summary-item.model';

export type SuccessProps = {
  formId: string,
  versionId: number,
  instanceId: number,
  collectionId: number,
  close: boolean
}

// TODO: GL add more actions and define props
export const formsActions = createActionGroup({
  source: 'Forms',
  events: {
    // Update
    updateForm: props<{ update: Update<StoreCollectionForm> }>(),
    refreshForm: props<{
      formId: string,
      collectionId: number,
      instanceId: number,
      versionId: number
    }>(),
    formRefreshed: props<{ formId: string, form: CollectionForm }>(),
    formGroupReset: props<{ formId: string }>(),

    // Linked Instance
    saveLinkedFormInstance: props<{
      parentFormId: string,
      parentFormFieldId: number,
      formId: string,
      form: CollectionForm, preValidate: boolean
    }>(),
    saveLinkedFormInstanceSucceeded: props<{
      parentFormId: string,
      parentFormFieldId: number,
      formId: string,
      instance: CreatedFormInstance
    }>(),
    saveLinkedFormInstanceFailed: props<{
      formId: string,
      response: HttpErrorResponse
    }>(),
    saveGridLinkedFormInstance: props<{
      parentFormId: string,
      parentFormFieldId: number,
      parentGridFieldId: number,
      parentRecordId: number,
      formId: string,
      form: CollectionForm, preValidate: boolean
    }>(),
    saveGridLinkedFormInstanceSucceeded: props<{
      parentFormId: string,
      parentFormFieldId: number,
      parentGridFieldId: number,
      parentRecordId: number,
      formId: string,
      instance: CreatedFormInstance
    }>(),
    createLinkedFormInstance: props<{
      parentFormId: string,
      parentFormFieldId: number,
      formId: string,
      form: CollectionForm, preValidate: boolean
    }>(),
    createLinkedFormInstanceSucceeded: props<{
      parentFormId: string,
      parentFormFieldId: number,
      formId: string,
      instance: CreatedFormInstance
    }>(),
    createGridLinkedFormInstance: props<{
      parentFormId: string,
      parentFormFieldId: number | undefined,
      parentGridFieldId: number,
      parentRecordId: number | undefined,
      formId: string,
      form: CollectionForm, preValidate: boolean
    }>(),
    createGridLinkedFormInstanceSucceeded: props<{
      parentFormId: string,
      parentFormFieldId: number | undefined,
      parentGridFieldId: number,
      parentRecordId: number | undefined,
      formId: string,
      instance: CreatedFormInstance
    }>(),
    createLinkedFormInstanceFailed: props<{
      formId: string,
      response: HttpErrorResponse
    }>(),

    // Save
    saveFormInstance: props<{
      formId: string,
      form: CollectionForm,
      closeStep: boolean,
      closeForm: boolean,
      preValidate: boolean
    }>(),
    saveRevision: props<{
      formId: string,
      form: CollectionForm,
      closeStep: boolean,
      closeForm: boolean,
      preValidate: boolean
    }>(),
    saveFormScheduler: props<{ formId: string, scheduler: SchedulerDto }>(),
    saveFormInstanceFailed: props<{ formId: string, response: HttpErrorResponse }>(),
    saveFormInstanceSucceeded: props<SuccessProps>(),

    // Create
    createFormInstance: props<{
      formId: string,
      form: CollectionForm,
      closeStep: boolean,
      closeForm: boolean,
      preValidate: boolean
    }>(),
    createDocumentInstance: props<{
      formId: string,
      form: CollectionForm,
      closeStep: boolean,
      closeForm: boolean,
      preValidate: boolean
    }>(),
    createFormInstanceFailed: props<{ formId: string, response: HttpErrorResponse }>(),
    createFormInstanceSucceeded: props<SuccessProps>(),

    // Task
    saveTask: props<{ formId: string, form: CollectionForm, closeForm: boolean, preValidate: boolean }>(),
    saveTaskFailed: props<{ formId: string, response: HttpErrorResponse }>(),
    executeTask: props<{ formId: string, form: CollectionForm, closeForm: boolean, preValidate: boolean }>(),
    executeTaskFailed: props<{ formId: string, response: HttpErrorResponse }>(),
    executeTaskSucceeded: props<SuccessProps>(),
    getPendingSteps: props<{
      collectionId: number,
      instanceId: number,
      versionId: number,
      draftId: number | undefined,
      retryCount?: number | undefined,
      formId?: string | undefined
    }>(),
    executePendingSteps: props<{
      pendingSteps: FlowStatusTaskDto[],
      collectionId: number,
      formId?: string | undefined,
      executorSelected?: boolean | undefined
    }>(),
    openTaskInstanceDialogComponent: props<{
      pendingSteps: FlowStatusTaskDto[],
      collectionId: number,
      formId: string
    }>(),
    // Read => Edit
    editReadOnlyForm: props<{
      formId: string, collectionId: number,
      instanceId: number,
      versionId: number
    }>(),
    editReadOnlyFormFetched: props<{
      formId: string,
      form: CollectionForm
    }>(),

    // GET
    getFormByFolder: props<{
      collectionId: number,
      folderId: number,
    }>(),
    getFormInstanceById: props<{
      collectionId: number,
      instanceId: number,
      versionId: number,
      read: boolean,
      schedulerData?: SchedulerDto | undefined,
      versionType?: VersionType,
      draftId?: number
    }>(),
    getFormInstanceForGrid: props<{
      parentFormId: string,
      viewDataSourceId: number,
      crossLinkedInstancesId: number,
      read: boolean
    }>(),
    getFormReadOnly: props<{
      collectionId: number,
      instanceId: number,
      versionId: number,
      state?: StateType | undefined,
    }>(),
    getFormCreate: props<{
      collectionId: number,
      crossLinkCollectionId: number | undefined,
      childInstanceId: number | undefined,
      childVersionId: number | undefined,
      originalChildInstanceId: number | undefined,
      widgetId: number | undefined
    }>(),
    getFormEdit: props<{
      collectionId: number,
      instanceId: number,
      versionId: number,
    }>(),
    getFormWorkspace: props<{
      collectionId: number,
      workspaceItemId: number
    }>(),
    getFormByCollectionMethodType: props<{
      collectionId: number,
      instanceId: number,
      versionId: number,
      collectionMethodType: CollectionMethodType
    }>(),
    getTaskForm: props<{ taskId: number, formId?: string | undefined }>(),
    getAnonymousForm: props<{ collectionId: number, formId?: string | undefined }>(),
    getTaskOnBehalfOf: props<{
      taskId: number,
      collectionsId: number,
      assignedToId: number,
      assignedToType: OrganizationChartItemType,
      formId?: string | undefined
    }>(),
    getFormByWidgetId: props<{ collectionId: number, widgetId: number }>(),
    getFormByCollectionId: props<{
      collectionId: number,
      formGridParentFilter?: GridParentFilter | undefined,
      addToViewStack: boolean
    }>(),
    getLinkedForm: props<{ formFieldId: number, formId: string, relationType: LinkedCollectionType }>(),
    getExternalLinkedForm: props<{
      externalAccess: CollectionFormExternalAccessDto,
      formFieldId: number,
      formId: string,
      relationType: LinkedCollectionType }>(),
    getGridLinkedForm: props<{
      formId: string,
      collectionFieldId: number | undefined,
      gridFieldId: number,
      recordId: number | undefined,
      relationType: LinkedCollectionType
    }>(),
    getIntegrity: props<{
      formId: string
    }>(),
    fetchIntegrity: props<{
      form: StoreCollectionForm
    }>(),
    mapIntegrity: props<{
      form: StoreCollectionForm,
      documentVersionId: number,
      skillVersionId: number,
      examVersionId: number,
      trainingVersionId: number
    }>(),
    getExternalGridLinkedForm: props<{
      externalAccess: CollectionFormExternalAccessDto,
      formFieldId: number,
      formId: string,
      gridFieldId: number,
      recordId: number | undefined,
      relationType: LinkedCollectionType
    }>(),
    getGridLinkedFormWithFile: props<{
      formFieldId: number,
      formId: string,
      gridFieldId: number,
      recordId: number,
      externalAccess: CollectionFormExternalAccessDto | undefined,
      relationType: LinkedCollectionType,
      files: Array<File>
    }>(),
    getGridLinkedFormWithTemplate: props<{
      formFieldId: number,
      formId: string,
      gridFieldId: number,
      recordId: number,
      externalAccess: CollectionFormExternalAccessDto | undefined,
      relationType: LinkedCollectionType,
      selectedTemplate: {
        mediaId: number,
        sourceCollectionId: number,
        instanceId: number,
        versionId: number,
        targetCollection: number
      }
    }>(),
    getFormByCollectionGridField: props<{ formFieldId: number, addToViewStack: boolean }>(),
    getFormByCollectionField: props<{ formFieldId: number, addToViewStack: boolean }>(),
    getLinkedInstance: props<{
      collectionId: number,
      instanceId: number,
      versionId: number,
      read: boolean,
      formFieldId: number,
      formId: string,
      relationType: LinkedCollectionType
    }>(),
    getGridLinkedInstance: props<{
      collectionId: number,
      instanceId: number,
      versionId: number,
      read: boolean,
      formFieldId: number,
      formId: string,
      gridFieldId: number,
      recordId: number,
      relationType: LinkedCollectionType
    }>(),
    getSchedulerByWidgetId: props<{ widgetId: number }>(),

    // GET API Responses
    formFetched: props<{ form: StoreCollectionForm, addToViewStack: boolean }>(),
    formFetchedWithFiles: props<{ form: StoreCollectionForm, addToViewStack: boolean, files: Array<File> }>(),
    formFetchedWithTemplate: props<{
      form: StoreCollectionForm, addToViewStack: boolean, selectedTemplate: {
        mediaId: number,
        sourceCollectionId: number,
        instanceId: number,
        versionId: number,
        targetCollection: number
      }
    }>(),

    // Closing Form
    formCanceled: props<{
      formId: string,
      form: CollectionForm,
      unsavedChanges: boolean,
      performFileCheckoutValidation: boolean
    }>(),
    formCanceledFileCheckoutDialogValidation: props<{
      formId: string,
      form: CollectionForm,
      unsavedChanges: boolean
    }>(),
    formClosed: props<{ formId: string, unsavedChanges: boolean }>(),
    checkForUnsavedChanges: props<{ formId: string, form: CollectionForm }>(),

    // Form Field actions
    updateFileFields: props<{ formId: string, field: CollectionFormField, file: MediaCommitDto | null }>(),
    clearFileFields: props<{ formId: string, field: CollectionFormField }>(),
    updateIntegrity: props<{ formId: string, integrity: Map<number, SkillIntegrityCheck> | undefined }>(),
    updateScheduler: props<{ formId: string, scheduler: SchedulerDto }>(),
    updateFormCheckedOutBy: props<{ formId: string, checkOutByUserId: number }>(),
    updateFormCheckedIn: props<{
      formId: string,
      file: { title: string, id: number, extension: string, size: number }
    }>(),
    updateFormField: props<{ formId: string, fieldId: number, value: unknown }>(),
    updateFormFieldFailed: emptyProps(),
    updateGridFormField: props<{
      formId: string,
      fieldId: number,
      recordId: number,
      field: CollectionFormField,
      value: unknown
    }>(),
    updateFormFieldValue: props<{ formId: string, fieldId: number, value: unknown }>(),
    updateGridFormFieldValue: props<{
      formId: string,
      fieldId: number,
      recordId: number,
      recordFieldId: number,
      value: unknown
    }>(),
    updateFormFieldEnumValue: props<{ formId: string, fieldId: number, value: number }>(),
    updateGridFormFieldEnumValue: props<{
      formId: string,
      gridFieldId: number,
      recordId: number,
      recordFieldId: number,
      value: number
    }>(),
    updateOrgChartFieldValue: props<{
      formId: string,
      fieldId: number,
      value: Array<SelectedOrganizationChartItemDto>
    }>(),
    updateGridOrgChartFieldValue: props<{
      formId: string,
      fieldId: number,
      recordId: number,
      recordFieldId: number,
      value: Array<SelectedOrganizationChartItemDto>
    }>(),
    setFieldReadOnly: props<{
      formId: string,
      formFieldId: number,
      isReadOnly: boolean,
      readOnlyPriority: ReadOnlyPriority
    }>(),
    setGridFieldReadOnly: props<{
      formId: string,
      fieldId: number,
      recordId: number,
      recordFieldId: number,
      isReadOnly: boolean,
      readOnlyPriority: ReadOnlyPriority
    }>(),
    setFieldHidden: props<{ formId: string, formFieldId: number, isHidden: boolean }>(),

    // Grid: Add Empty Editable Row
    addEmptyRowToGrid: props<{ formId: string, gridFieldId: number }>(),
    getEmptyRowForGridSucceeded: props<{
      formId: string,
      gridFieldId: number,
      emptyRow: Record
    }>(),
    getEmptyRowForGridFailed: props<{ formId: string, error: Error }>(),

    addInstancesToGrid: props<{
      formId: string,
      form: CollectionForm,
      gridFieldId: number,
      field: CollectionFormField,
      instances: CollectionListDataInstance[]
    }>(),

    addViewDataInstanceToGrid: props<{
      formId: string,
      instanceId: number,
      versionId: number,
      viewDataSourceId: number,
      linkedCollectionType: LinkedCollectionType,
      originalChildInstancesId: number,
      crossLinkInstancesId?: number,
      viewDataSources: Array<ViewDataSource>,
      targetGridField: CollectionFormField,
      row: Record | undefined,
      isNewInstanceOnLookup: boolean
    }>(),

    // TODO: DELETE
    addRowToGrid: props<{ formId: string, gridFieldId: number, updatedForm: CollectionForm }>(),
    rowAddedToGrid: props<{ formId: string, gridFieldId: number }>(),
    // TODO: DELETE

    // Add Grid Record(s)
    addRowsToGrid: props<{ formId: string, gridFieldId: number, records: Record[] }>(),
    rowsAddedToGrid: props<{ formId: string, gridFieldId: number }>(),


    // Remove Grid Record(s)
    removeRecordFromGrid: props<{ formId: string, gridFieldId: number, recordId: number }>(),
    recordRemovedFromGrid: props<{ formId: string, gridFieldId: number }>(),

    // Updating Grid Records
    updateGridRecordWithLookup: props<{ formId: string, gridFieldId: number, recordId: number, lookupData: CollectionFormFieldGridLookupData }>(),
    updateGridRecordInstance: props<{ formId: string, gridFieldId: number, recordId: number, instanceId: number }>(),
    updateGridRecordEditMode: props<{
      formId: string,
      gridFieldId: number,
      recordId: number,
      editMode: boolean
    }>(),

    // Scripting & Formula's
    performOnOpenScripting: props<{ formId: string, form: CollectionForm }>(),
    scriptingTriggered: props<{ formId: string, fieldId: number }>(),
    scriptingFailed: props<{ formId: string }>(),
    updateScriptingForm: props<{ update: Update<StoreCollectionForm> }>(),

    // Formula's
    calculateFormulaField: props<{ formId: string, fieldId: number }>(),
    calculateFormulas: props<{ formId: string }>(),
    updateFormulaOutputField: props<{ formId: string, collectionFieldId: number, value: unknown }>(),
    updateFormulaOutputGridField: props<{
      formId: string,
      gridFieldId: number,
      recordId: number,
      collectionFieldId: number,
      value: unknown
    }>(),

    // Locks
    deleteLock: props<{ formId: string, form: CollectionForm }>(),
    lockDeleted: props<{ formId: string, form: CollectionForm }>(),
    deleteSelfLock: props<{ formId: string, form: CollectionForm, close: boolean }>(),
    selfLockDeleted: props<{ formId: string, form: CollectionForm, close: boolean }>(),

    // Change Reason
    requestChangeReason: props<{ formId: string, existingReason: string | undefined }>(),
    changeReasonSubmitted: props<{ formId: string, changeReason: string }>(),

    // Signature
    requestSignature: props<{ formId: string }>(),
    signatureSubmitted: props<{ formId: string, signature: unknown }>(),

    // Single Record Lookups
    lookupChanged: props<{
      formId: string,
      lookupFieldId: number,
      lookupItem: LookupItem
    }>(),
    lookupCleared: props<{
      formId: string,
      viewDataSourceId: number
    }>(),
    lookupFetched:props<{
      formId: string,
      lookupFieldId: number,
      lookupItem: LookupItem,
      lookupData: CollectionFormFieldSingleLookupData
    }>(),
    lookupFailed:props<{
      formId: string
    }>(),

    // Grid Record lookups
    gridLookupChanged: props<{
      formId: string,
      gridFieldId: number,
      recordId: number,
      lookupFieldId: number,
      lookupItem: LookupItem
    }>(),
    gridLookupCleared: props<{
      formId: string,
      gridFieldId: number,
      recordId: number
    }>(),
    gridLookupFetched:props<{
      formId: string,
      gridFieldId: number,
      recordId: number,
      lookupFieldId: number,
      lookupItem: LookupItem,
      lookupData: CollectionFormFieldGridLookupData
    }>(),
    gridLookupFailed:props<{
      formId: string
    }>(),
    // Older more complex lookups
    // TODO: refactor
    fetchGridLookupNewRecord: props<{
      formId: string,
      gridFieldId: number,
      summaryItem: CollectionListSummaryItem
    }>(),
    fetchGridLookupNewRecords: props<{
      formId: string,
      gridFieldId: number,
      summaryItems: Array<CollectionListSummaryItem>
    }>(),
    fetchLookupByCrossLinkId: props<{
      formId: string,
      storeForm: StoreCollectionForm,
      gridParentFilter: GridParentFilter,
    }>(),
    updateFormViewDataSource: props<{ formId: string, viewDataSource: ViewDataSource }>(),
    updateFormViewDataSources: props<{ formId: string, viewDataSources: Array<ViewDataSource> }>(),
    enableDisableLinkedFields: props<{
      formId: string,
      form: CollectionForm,
      field: CollectionFormField,
      value: CollectionFormFieldGridLookupData | CollectionFormFieldSingleLookupData
    }>(),
    updateLinkedFieldValues: props<{
      formId: string,
      form: CollectionForm,
      field: CollectionFormField,
      summaryItem: CollectionListSummaryItem
      value: CollectionFormFieldSingleLookupData
    }>(),
    updateGridLinkedFieldValues: props<{
      formId: string,
      form: CollectionForm,
      fieldId: number,
      recordId: number,
      field: CollectionFormField,
      summaryItem: CollectionListSummaryItem,
      value: CollectionFormFieldGridLookupData
    }>(),
    clearLinkedFieldValues: props<{ formId: string, field: CollectionFormField }>(),
    clearGridLinkedFieldValues: props<{
      formId: string,
      fieldId: number,
      recordId: number,
      field: CollectionFormField
    }>(),

    // Form Validation
    validateSchedulerForm: props<{ formId: string, form: CollectionForm, schedule: SchedulerDto }>(),
    preValidateInstance: props<{
      formId: string,
      form: CollectionForm,
      closeStep: boolean,
      closeForm: boolean
    }>(),
    preValidateInstanceOnBehalfOf: props<{
      formId: string,
      form: CollectionForm,
      closeStep: boolean,
      closeForm: boolean
    }>(),
    preValidationFailed: props<{ formId: string, response: HttpErrorResponse }>(),
    formValidationSucceeded: props<{ dummyProp: any }>(),
    formValidationFailed: props<{ formId: string, validationErrors: CollectionFormValidatorResult }>()
  }
});