import { Component, Inject } from '@angular/core';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { FilterMenuModule } from '@progress/kendo-angular-grid';
import { IconComponent } from '../../../../shared/components/ui/icon/icon.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TranslatePipe } from '../../../../shared/pipes/translate/translate.pipe';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { TrainingDto } from '../../../../../models/ts/training-dto.model';
import { DatetimePipe } from '../../../../shared/pipes/dates/datetime.pipe';
import { TimePipe } from '../../../../shared/pipes/dates/time.pipe';
import { UtcDatePipe } from '../../../../shared/pipes/dates/utc-date.pipe';
import { SafePipe } from 'safe-pipe';
import { Store } from '@ngrx/store';
import { formsActions } from '../../../../store/features/forms/forms-actions';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs';

;

@Component({
  selector: 'bizz-training-subscribe-modal',
  standalone: true,
  imports: [
    AsyncPipe,
    FilterMenuModule,
    IconComponent,
    NgTemplateOutlet,
    TooltipModule,
    TranslatePipe,
    DatetimePipe,
    TimePipe,
    UtcDatePipe,
    SafePipe
  ],
  providers: [UtcDatePipe],
  templateUrl: './training-subscribe-modal.component.html',
  styleUrl: './training-subscribe-modal.component.scss'
})
export class TrainingSubscribeModalComponent {

  public selectedSession: TrainingDto | null = null;
  public TrainingSubscribeModalComponentMode = TrainingSubscribeModalComponentMode;

  public constructor(@Inject(DIALOG_DATA) public data: {
                       data: TrainingDto,
                       enableNoPreference: boolean,
                       enableCreateNewSession: boolean,
                       mode: TrainingSubscribeModalComponentMode,
                       trainingSessionsCollectionsId?: number
                     },
                     public dialogRef: DialogRef,
                     private datePipe: UtcDatePipe,
                     private store$: Store,
                     private actions$: Actions) {
  }

  public isSameDate(date1: Date, date2: Date): boolean {
    return this.datePipe.transform(date1) === this.datePipe.transform(date2);
  }

  public close(): void {
    this.dialogRef.close(this.data.enableNoPreference ? this.selectedSession : this.selectedSession ?? undefined);
  }

  public createNewSession(): void {
    if (this.data.trainingSessionsCollectionsId) {
      this.actions$.pipe(ofType(formsActions.formFetched.type), take(1)).subscribe(() => {
        this.close();
      });
      this.store$.dispatch(formsActions.getFormByCollectionId({ collectionId: this.data.trainingSessionsCollectionsId,
      addToViewStack: true }));
    }
  }
}

export enum TrainingSubscribeModalComponentMode {
  Subscribe,
  Queue
}


