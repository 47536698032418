import { Component, DestroyRef, OnInit } from '@angular/core';
import { NotificationApiService } from 'src/app/api/bizzmine/notification/notification-api.service';
import { GridOptions } from 'src/app/shared/classes/list/grid-options';
import { GridDataDto } from 'src/models/ts/grid-data-dto.model';
import { NotificationSearchDto } from 'src/models/ts/notification-search-dto.model';
import { NotificationsDto } from 'src/models/ts/notifications-dto.model';
import { CollectionType } from 'src/models/ts/collection-type.model';
import { DropDownListModule, SharedDirectivesModule } from '@progress/kendo-angular-dropdowns';
import { TranslatePipe } from '../../../shared/pipes/translate/translate.pipe';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { FilterComponent } from '../../../shared/components/ui/filter-component/filter.component';
import { PageChangeEvent, SharedFilterModule } from '@progress/kendo-angular-grid';
import { GridGroupSortDto } from 'src/models/ts/grid-group-sort-dto.model';
import { IconComponent } from '../../../shared/components/ui/icon/icon.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime } from 'rxjs';
import { CellActionType } from '../../../features/bizzmine/widgets/collection-list-widget/classes/cell-action-type';
import { CellActionData } from '../../../features/bizzmine/widgets/collection-list-widget/interfaces/cell-action-data';
import { GridComponent } from '../../../shared/components/grid/grid.component';
import { HeaderNotificationsService } from '../../../shared/services/header/header-notifcations/header-notifications.service';
import { SearchComponent } from "../../../shared/components/ui/search/search.component";
import { ReadFilterType } from 'src/models/ts/read-filter-type.model';

@Component({
  selector: 'bizz-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  standalone: true,
  imports: [
    GridComponent,
    DropDownListModule,
    SharedDirectivesModule,
    TranslatePipe,
    FilterComponent,
    IconComponent,
    SharedFilterModule,
    SearchComponent
]
})
export class NotificationsComponent implements OnInit {
  public currentPage = 1;
  public pageSizes: Array<number> = [1, 2, 5, 10, 20, 50, 200, 500];
  public totalRecords: number;
  public searchTerm: string = '';
  public searchTerm$ = new BehaviorSubject<string>(this.searchTerm);

  public searchedNotificationDto: NotificationsDto;
  public notifications: GridDataDto;
  public gridOptions: GridOptions;
  public CollectionType = CollectionType;
  public isLoading: boolean;
  public readType: ReadFilterType = ReadFilterType.Unread;

  public constructor(
    //private searchFilterApiService: SearchFilterApiService,
    private notificationApiService: NotificationApiService,
    private headerNotificationsService: HeaderNotificationsService,
    private destroyRef: DestroyRef) {
    this.searchTerm$.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300))
      .subscribe((value) => {
        this.searchTerm = value;
        this.fetchSearchData();
      });
  }

  public ngOnInit(): void {
    this.getNotificationGridOptions();
  }

  public searchTermChanged(search: string): void {
    this.currentPage = 1;
    this.searchTerm$.next(search);
  }

  public getNotificationGridOptions(): void {
    this.isLoading = true;
    this.notificationApiService.getNotifications().subscribe({
      next: (notifications): void => {
        this.searchedNotificationDto = notifications;
        this.gridOptions = new GridOptions(this.searchedNotificationDto.GridOptions);
        this.fetchSearchData();
      },
      error: (): void => {
        this.isLoading = false;
      },
      complete: (): void => {
        this.isLoading = false;
      }
    });
  }

  public onCellAction(event: { action: CellActionType, data: CellActionData }): void {
    switch (event.action) {
      case CellActionType.NOTIFICATION_OPEN:
        this.headerNotificationsService.openNotification(event.data.data.ID);
        break;
      case CellActionType.NOTIFICATION_READ:
        this.headerNotificationsService.readNotification(event.data.data.ID);
        break;
      case CellActionType.NOTIFICATION_UNREAD:
        this.notificationApiService.unreadNotification(event.data.data.ID).subscribe({
          complete: (): void => {
            this.fetchSearchData();
          },
          error: (): void => {
            this.isLoading = false;
          }
        });
        break;
    }
  }

  public onReadTypeChanged(type: ReadFilterType): void {
    this.readType = type;
    this.searchTerm = '';
    this.fetchSearchData();
  }

  public fetchSearchData(): void {
    this.isLoading = true;

    const search: NotificationSearchDto = {
      ReadType: this.readType,
      Search: this.searchTerm,
      Page: this.currentPage,
      Records: this.gridOptions.pageSize,
      Group: this.gridOptions.dataSource.group as GridGroupSortDto[],
      Sort: this.gridOptions.dataSource.sort as GridGroupSortDto[]
    };

    this.notificationApiService.searchNotifications(search).subscribe({
      next: (notifications): void => {
        this.notifications = notifications;
        this.totalRecords = notifications.TotalRecords;
      },
      error: (): void => {
        this.isLoading = false;
      },
      complete: (): void => {
        this.isLoading = false;
      }
    });
  }

  public onPageChange(event: PageChangeEvent): void {
    this.currentPage = (event.skip / event.take) + 1; // Update the current page number
    this.gridOptions.pageSize = event.take;
    this.fetchSearchData();
  }
}
