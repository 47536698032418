import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupModule, PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { SafePipe } from 'safe-pipe';
import { TruncatePipe } from '../../../../pipes/truncate/truncate.pipe';
import { IconComponent } from '../../../ui/icon/icon.component';
import { GridCellBaseComponent } from '../grid-cell-base/grid-cell-base.component';
import { CellActionType } from '../../../../../features/bizzmine/widgets/collection-list-widget/classes/cell-action-type';

const HIDE_POPUP_DELAY_TIME = 100; //ms

@Component({
  selector: 'bizz-collection-list-widget-cell-memo-with-hyperlink',
  standalone: true,
  templateUrl: './grid-cell-memo-with-hyperlink.component.html',
  styleUrl: './grid-cell-memo-with-hyperlink.component.scss',
  imports: [CommonModule, IconComponent, PopupModule, SafePipe, TruncatePipe],
  changeDetection: ChangeDetectionStrategy.Default
})
export class GridCellMemoWithHyperlinkComponent extends GridCellBaseComponent<string> implements OnInit {

  @ViewChild('anchor', { read: ElementRef })
  public anchorEl: ElementRef;

  @ViewChild('readmorePopupTemplateRef', { static: true })
  public readmorePopupTemplateRef: TemplateRef<any>;

  public htmlContent: string = '';
  public showReadMoreIcon: boolean = false;

  private readmorePopup: PopupRef | null = null;

  private anchorHovered = false;
  private popupHovered = false;

  public constructor(private popupService: PopupService) {
    super();
  }

  protected override onDataChanged(): void {
    this.htmlContent = this.data[this.column.field + '_Preview'] ?? this.value ?? '';
    this.showReadMoreIcon = this.htmlContent.length > 150;
    if (!this.column.KeepNewLines) {
      this.htmlContent = this.htmlContent.replaceAll('<br/>', ' ');
      this.htmlContent = this.htmlContent.replaceAll('</br>', ' ');
      this.htmlContent = this.htmlContent.replaceAll('<br>', ' ');
      this.htmlContent = this.htmlContent.replaceAll('\n', ' ');
    }
  }

  public ngOnInit(): void {
    this.column.ShowReadMoreIcon = false;
  }

  public onClicked() : void {
    this.onCellAction.emit({ action: CellActionType.MEMO_HYPERLINK_CLICKED, data: { data: this.data } });
  }

  public onAnchorMouseEnter(): void {
    this.anchorHovered = true;
    this.showPopup();
  }

  public onAnchorMouseLeave(): void {
    this.anchorHovered = false;
    setTimeout(() => {
      if (!this.anchorHovered && !this.popupHovered)
        this.hidePopup();
    }, HIDE_POPUP_DELAY_TIME);
  }

  public onPopupMouseEnter(): void {
    this.popupHovered = true;
    this.showPopup();
  }

  public onPopupMouseLeave(): void {
    this.popupHovered = false;
    setTimeout(() => {
      if (!this.anchorHovered && !this.popupHovered)
        this.hidePopup();
    }, HIDE_POPUP_DELAY_TIME);
  }

  public showPopup(): void {
    if (this.readmorePopup != null)
      return;

    this.readmorePopup = this.popupService.open({
      anchor: this.anchorEl,
      content: this.readmorePopupTemplateRef,
      popupClass: 'w-svw h-svh sm:w-fit sm:h-auto sm:min-w-[400px] sm:-mt-[0.5rem] sm:rounded-md maxw-sm:!top-0 !z-[9999] maxw-sm:!left-0 maxw-sm:!right-0',
      animate: false,
      anchorAlign: { horizontal: 'center', vertical: 'bottom' },
      popupAlign: { horizontal: 'center', vertical: 'top' },
      collision: { horizontal: 'fit', vertical: 'fit' }
    });
  }

  public hidePopup(): void {
    if (this.readmorePopup) {
      this.readmorePopup.close();
      this.readmorePopup = null;
    }
    event?.preventDefault();
  }
}

