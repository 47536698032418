import { Component, ElementRef, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import { BytesPipe } from 'src/app/shared/pipes/bytes/bytes.pipe';
import { ProtectedFieldType } from 'src/models/ts/protected-field-type.model';
import {TooltipComponent} from "../../../../../../shared/components/ui/tooltip/tooltip.component";
import { FORMAT_EXCEPTION_FIELD_TYPES } from '../../../constants/form-constants';
import { getDisplayStyleForNumber } from 'src/app/shared/functions/helpers/display-style-helpers';
import { DecimalPipe } from "../../../../../../shared/pipes/decimal/decimal.pipe";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CollectionFieldDisplayStyleValueDto } from 'src/models/ts/collection-field-display-style-value-dto.model';

/**
 * Represents a control that allows the user to enter a numeric value.
 * Decimal and thousand separators are automatically added.
 * Amount of decimal places is restricted according to configuration.
 */
@Component({
  selector: 'bizz-numeric-control',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, NgStyle, NumericTextBoxModule, AsyncPipe, BytesPipe, TooltipComponent, DecimalPipe],
  templateUrl: './numeric-control.component.html',
  styleUrls: ['./numeric-control.component.scss']
})
export class NumericControlComponent extends BaseFormControlComponent {
  @ViewChild('input') inputElement: ElementRef;
  @ViewChild('inputKendo') inputKendoElement: NumericControlComponent;
  protected ProtectedFieldType = ProtectedFieldType;

  public backgroundColor = '';
  public foregroundColor = '';
  public hasDisplayStyle = false;
  public inputHasFocus = false;

  public override ngOnInit(): void {
    super.ngOnInit();

    let field = this.formFieldSignal();
    if(field) {
      this.setThemeColors(field.DisplayStyleValues, field[this.fieldValueProperty]);
    }

    this.formControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: value => this.setThemeColors(this.formFieldSignal()!.DisplayStyleValues, value)
    });
  }

  private setThemeColors(styles: CollectionFieldDisplayStyleValueDto[], value: number): void {
    let displayValue = getDisplayStyleForNumber(styles, value);
    if (displayValue) {
      this.backgroundColor = displayValue.BackgroundColor;
      this.foregroundColor = displayValue.ForegroundColor;
      this.hasDisplayStyle = true;
    }
  }

  protected override valueSetter(field: CollectionFormField): void {
    this.formControl.setValue(!isNaN(field[this.fieldValueProperty]) ? field[this.fieldValueProperty] : undefined, { emitEvent: false });
  }

  // TODO: Once unit tests are in place, test this formatting method
   /**
   * Creates the correct format for numeric inputs. 
   * Is an empty String in case of a limited set of protected field types.
   * @param {CollectionFormField} field
   * @returns {string}
   */
  public format (field: CollectionFormField): string {
    if (!FORMAT_EXCEPTION_FIELD_TYPES.includes(field.ProtectedFieldType)) {
      return `n${field.MaxDecimals}`
    }

    return '';
  }

  public onFocus(): void {
    if(!this.formFieldSignal()?.IsReadOnly){
      this.inputHasFocus = true;
    }
  }

  public onBlur(): void {
    this.inputHasFocus = false;
  }

   protected override focus(): void {
      if(this.formFieldSignal()?.ProtectedFieldType == ProtectedFieldType.Size){
        this.inputElement.nativeElement.focus();
      } else {
        this.inputKendoElement.focus();
      }
  }
}
