<div class="flex gap-2 mx-2">
    <bizz-icon class="hidden h-3.5 w-3.5 hover:cursor-pointer hover:text-blue-500 sm:inline-block"
               (click)="onView()" iconName="eye">
    </bizz-icon>
    @if (status && !isLockedOrReadonly) {
        <bizz-icon class="hidden h-3.5 w-3.5 hover:cursor-pointer hover:text-blue-500 sm:inline-block"
                   (click)="onEdit()" iconName="pencil">
        </bizz-icon>
    }
    @if (canViewChanges) {
        <bizz-icon class="hidden h-3.5 w-3.5 hover:cursor-pointer hover:text-blue-500 sm:inline-block"
                   (click)="onTrackChanges()" iconName="clock-rotate-left">
        </bizz-icon>
    }

    @if (status && !isLockedOrReadonly) {
        <bizz-icon class="hidden h-3.5 w-3.5 hover:cursor-pointer hover:text-red-500 sm:inline-block"
                   (click)="onDelete()" iconName="trash-can">
        </bizz-icon>
    }
</div>