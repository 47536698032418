import { Component, input } from '@angular/core';
import { TranslatePipe } from '../../../../shared/pipes/translate/translate.pipe';

@Component({
  selector: 'bizz-external-completed',
  standalone: true,
  imports: [
    TranslatePipe
  ],
  templateUrl: './external-completed.component.html',
  styleUrl: './external-completed.component.scss'
})
export class ExternalCompletedComponent {
  public title = input.required<string>();
  public info = input<string>();
}
