import { Component, Inject, OnDestroy } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ElectronicSignatureDialogData,
  ElectronicSignatureResponseDto
} from '../../../../models/ts/electronic-signature-response-dto.model';
import { DatePipe, NgIf } from '@angular/common';
import { EditorModule } from '@progress/kendo-angular-editor';
import { ExtensionIconComponent } from '../ui/icon/extension-icon/extension-icon.component';
import { IconComponent } from '../ui/icon/icon.component';
import { MailingAttachmentsUploadComponent } from '../../../features/bizzmine/form/components/controls/form-mailing-list/mailing-attachments-upload/mailing-attachments-upload.component';
import { SharedFilterModule } from '@progress/kendo-angular-grid';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TranslatePipe } from '../../pipes/translate/translate.pipe';
import { ElectronicSignatureType } from 'src/models/ts/electronic-signature-type.model';
import { ElectronicSignatureSigningType } from '../../../../models/ts/electronic-signature-signing-type.model';
import { BizzMineLocalStorageService } from '../../services/localStorage/bizzmine-local-storage.service';
import { BehaviorSubject } from 'rxjs';
import { HttpRequest } from '@angular/common/http';
import { LoaderButtonComponent } from '../loader-button/loader-button/loader-button.component';
import { TwoFactorTokenComponent } from './two-factor-token/two-factor-token.component';
import { Store } from '@ngrx/store';
import { viewStackActions } from 'src/app/store/features/forms/view-stack-actions';

@Component({
  selector: 'bizz-electronic-signature-dialog',
  standalone: true,
  imports: [
    DatePipe,
    EditorModule,
    ExtensionIconComponent,
    IconComponent,
    MailingAttachmentsUploadComponent,
    NgIf,
    SharedFilterModule,
    TooltipModule,
    TranslatePipe,
    LoaderButtonComponent,
    TwoFactorTokenComponent
  ],
  templateUrl: './electronic-signature-dialog.component.html',
  styleUrl: './electronic-signature-dialog.component.scss'
})
export class ElectronicSignatureDialogComponent implements OnDestroy {

  public ElectronicSignatureType = ElectronicSignatureType;
  public ElectronicSignatureSigningType = ElectronicSignatureSigningType;
  public password: string;
  public emailCode: string;
  public twoFactorSecret: string;
  public userName: string;
  public isConfirmDisabled = true;

  public data: ElectronicSignatureResponseDto;
  public newRequest$: BehaviorSubject<HttpRequest<unknown> | null> = new BehaviorSubject<HttpRequest<unknown> | null>(null);
  public isLoading = false;

  public constructor(public dialogRef: DialogRef,
                     @Inject(DIALOG_DATA) public params: ElectronicSignatureDialogData,
                     private bizzMineLocalStorageService: BizzMineLocalStorageService,
                    private store$: Store) {
    this.data = params.error.error as ElectronicSignatureResponseDto;
  }

  public ngOnDestroy(): void {
    if (this.newRequest$) {
      this.newRequest$.complete();
    }
  }

  public twoFactorAuth(code: string): void {
    this.twoFactorSecret = code;
    this.confirm();
  }

  public confirm(): void {
    this.validate();
    if (this.isConfirmDisabled) return;
    const headers: Array<ElectronicSignatureHeader> = [];
    if (this.data.SigningType == ElectronicSignatureSigningType.Password) {
      headers.push({ key: 'password', value: this.password });
    }
    if (this.data.SigningType == ElectronicSignatureSigningType.LoginAndPassword) {
      headers.push({ key: 'username', value: this.userName });
      headers.push({ key: 'password', value: this.password });
    }
    if (this.data.SigningType == ElectronicSignatureSigningType.Device) {
      headers.push({ key: 'twofactor', value: this.twoFactorSecret });
    }
    if (this.data.SigningType == ElectronicSignatureSigningType.Email) {
      headers.push({ key: 'emailcode', value: this.emailCode });
    }
    let requestHeaders = this.params.request.headers;
    headers.forEach(header => {
      requestHeaders = requestHeaders.append(header.key, header.value);
    });
    this.isLoading = true;
    this.newRequest$.next(this.params.request.clone({ headers: requestHeaders }));
  }

  public redirectToTwoFactorSetup(): void {
    window.open('./workspace/' + this.bizzMineLocalStorageService.getItem('workspaceID') + '/user-settings', '_blank');
  }



  public validate(): void {
    if (this.data.SigningType == ElectronicSignatureSigningType.Password) {
      this.isConfirmDisabled = this.password == null || this.password.trim() == '';
    }
    if (this.data.SigningType == ElectronicSignatureSigningType.LoginAndPassword) {
      this.isConfirmDisabled = this.password == null || this.password.trim() == '';
      this.isConfirmDisabled = this.isConfirmDisabled || this.userName == null || this.userName.trim() == '';
    }
    if (this.data.SigningType == ElectronicSignatureSigningType.Device) {
      this.isConfirmDisabled = this.twoFactorSecret == null || this.twoFactorSecret.trim() == '' || this.twoFactorSecret.trim().length < 6;
    }
    if (this.data.SigningType == ElectronicSignatureSigningType.Email) {
      this.isConfirmDisabled = this.emailCode == null || this.emailCode.trim() == '';
    }
  }

  public close(): void {
    this.store$.dispatch(viewStackActions.removeLoadingState())
    this.dialogRef.close();
  }

}

export interface ElectronicSignatureHeader {
  key: string;
  value: string;
}
