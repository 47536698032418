@if (model.Groups && model.OperatorList) {
    <div class="flex h-full w-full flex-col gap-3.5 pb-4">
        @for (group of model.Groups; track group; let groupIndex = $index; ) {
            <fieldset class="!mt-0 flex h-full w-full flex-col gap-6"
                      [ngClass]="{'max-h-[64px]' : collapsedIndexes.includes(groupIndex + 1)}">
                <!-- Field Groups -->
                <div class="sticky top-0 z-10 -mb-3 flex flex-col bg-body-popup py-2.5 pb-3"
                     (click)="collapseField(groupIndex)">
                    <div class="flex items-center justify-between">
                        <h2 class="text-lg font-bold text-blue-400">{{
                                group.FilterType === filterGroupType.Included ?
                                        'Included' :
                                        'Excluded' | translate
                            }}</h2>
                        <bizz-icon [hover]="true"
                                   [iconName]="collapsedIndexes.includes(groupIndex + 1) ? 'angle-down' : 'angle-up'"
                                   class="h-4 w-4 text-default">
                        </bizz-icon>
                    </div>
                    <hr class="h-[1px] w-full bg-divider"/>
                </div>
                <!-- Field Groups -->
                <div class="flex h-full w-full flex-col gap-6"
                     [ngClass]="{'!h-0 hidden' : collapsedIndexes.includes(groupIndex + 1)}">
                    @for (field of group.Fields; track field) {
                        @for (filtervalues of field.Values; track filtervalues; let i = $index) {
                            <!-- NORMAL SEARCH FIELD -->
                            @if (field.SearchFilterFieldType == 1) {
                                <div class="flex flex-col items-center gap-2 sm:mx-2.5 sm:flex-row sm:gap-5">
                                    <!--TODO: missing enum Strings, not found in Legacy ? -->
                                    <p [ngClass]="{'sm:text-right text-disabled' : i !== 0}"
                                       class="caption w-full truncate text-sm font-normal text-default sm:w-[calc(25%-(1.25rem/2))]">{{
                                            i !== 0 ? 'AND' : field.Caption | translate
                                        }}</p>
                                    @switch (field.FieldType) {
                                        <!-- Alphanumeric -->
                                        @case (tableFieldDataType.AlphaNumeric) {
                                            <div class="operator">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.Operator"
                                                                    [value]="filtervalues.Operator"
                                                                    [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"
                                                                    [valuePrimitive]="true"
                                                                    [data]="model.OperatorList.StringOperators"
                                                                    textField="Text"
                                                                    valueField="Value">
                                                </kendo-dropdownlist>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>


                                            <div class="value">
                                                <input *ngIf="(filtervalues.Operator != operators.HasValue) && (filtervalues.Operator != operators.IsEmpty)"
                                                       type="text" [(ngModel)]="filtervalues.StringValue"
                                                       [min]="field.MinValue" [max]="field.MaxValue" class="!text-sm"/>
                                                <input *ngIf="(filtervalues.Operator == operators.HasValue)" type="text"
                                                       class="! invisible"/>
                                                <button class="remove-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="deleteInput(groupIndex ,0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="xmark"></bizz-icon>
                                                </button>
                                            </div>
                                        }
                                        <!-- Decimal -->
                                        @case (tableFieldDataType.Numeric) {
                                            <div class="operator">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.Operator"
                                                                    [value]="filtervalues.Operator"
                                                                    [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"
                                                                    [valuePrimitive]="true"
                                                                    [data]="model.OperatorList.NumberOperators"
                                                                    textField="Text"
                                                                    valueField="Value">
                                                </kendo-dropdownlist>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>

                                            <div class="value">
                                                <input
                                                        *ngIf="(filtervalues.Operator != operators.HasValue) && (filtervalues.Operator != operators.IsEmpty)"
                                                        [(ngModel)]="filtervalues.DoubleValue"
                                                        [disabled]="field.FreeFieldType == freeFieldType.ApprovalRate" class="!text-sm"/>
                                                <input *ngIf="(filtervalues.Operator == operators.HasValue)" type="text"
                                                       class="! invisible"/>

                                                <button class="remove-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="deleteInput(groupIndex ,0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="xmark"></bizz-icon>
                                                </button>
                                            </div>

                                        }
                                        <!-- Email -->
                                        @case (tableFieldDataType.Email) {
                                            <div class="operator">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.Operator"
                                                                    [value]="filtervalues.Operator"
                                                                    [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"
                                                                    [valuePrimitive]="true"
                                                                    [data]="model.OperatorList.StringOperators"
                                                                    textField="Text"
                                                                    valueField="Value">
                                                </kendo-dropdownlist>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>

                                            <div class="value">
                                                <input
                                                        *ngIf="(filtervalues.Operator != operators.HasValue) && (filtervalues.Operator != operators.IsEmpty)"
                                                        [(ngModel)]="filtervalues.StringValue" class="!text-sm"/>
                                                <input *ngIf="(filtervalues.Operator == operators.HasValue)"
                                                       class="! invisible" type="text"/>

                                                <button class="remove-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="deleteInput(groupIndex ,0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="xmark"></bizz-icon>
                                                </button>
                                            </div>
                                        }
                                        <!-- DatePicker -->
                                        @case (tableFieldDataType.DatePicker) {
                                            <div class="operator">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.Operator"
                                                                    [value]="filtervalues.Operator"
                                                                    [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"
                                                                    [valuePrimitive]="true"
                                                                    [data]="model.OperatorList.NumberOperators"
                                                                    textField="Text"
                                                                    valueField="Value">
                                                </kendo-dropdownlist>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>


                                            <div class="value">
                                                <kendo-datepicker
                                                        *ngIf="(filtervalues.Operator != operators.HasValue) && (filtervalues.Operator != operators.IsEmpty)"
                                                        [(ngModel)]="filtervalues.DateTimeValue"
                                                        [format]="makeKendoDate(userSettingsSignal().DateFormatTypeString)"
                                                        [value]="convertToDate(filtervalues.DateTimeValue)" class="!text-sm">
                                                </kendo-datepicker>

                                                <button class="remove-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="deleteInput(groupIndex ,0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="xmark"></bizz-icon>
                                                </button>
                                            </div>
                                        }
                                        <!-- DatePicker -->
                                        @case (tableFieldDataType.TimePicker) {
                                            <div class="operator">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.Operator"
                                                                    [value]="filtervalues.Operator"
                                                                    [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"
                                                                    [valuePrimitive]="true"
                                                                    [data]="model.OperatorList.NumberOperators"
                                                                    textField="Text"
                                                                    valueField="Value">
                                                </kendo-dropdownlist>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>

                                            <div class="value">
                                                <kendo-timepicker
                                                        *ngIf="(filtervalues.Operator != operators.HasValue) && (filtervalues.Operator != operators.IsEmpty)"
                                                        [(ngModel)]="filtervalues.DateTimeValue"
                                                        [format]="userSettingsSignal().TimeFormatType" class="!text-sm">
                                                </kendo-timepicker>
                                                <!--TODO:format="{{field.format}}-->

                                                <button class="remove-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="deleteInput(groupIndex ,0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="xmark"></bizz-icon>
                                                </button>
                                            </div>
                                        }
                                        <!-- DateTimePicker -->
                                        @case (tableFieldDataType.DateTimePicker) {
                                            @if (!filtervalues.Operator) {
                                                <div class="operator">
                                                    <kendo-dropdownlist class="w-full !text-sm"
                                                                        [(ngModel)]="filtervalues.Operator"
                                                                        [value]="operators.Equals"
                                                                        [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"
                                                                        [valuePrimitive]="true"
                                                                        [data]="model.OperatorList.NumberOperators"
                                                                        textField="Text"
                                                                        valueField="Value">
                                                    </kendo-dropdownlist>

                                                    <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                            type="button"
                                                            (click)="newInput(groupIndex, 0, field.ID)">
                                                        <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                    </button>
                                                </div>
                                            } @else {
                                                <div class="operator">
                                                    <kendo-dropdownlist class="w-full !text-sm"
                                                                        [(ngModel)]="filtervalues.Operator"
                                                                        [value]="filtervalues.Operators"
                                                                        [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"
                                                                        [valuePrimitive]="true"
                                                                        [data]="model.OperatorList.NumberOperators"
                                                                        textField="Text"
                                                                        valueField="Value">
                                                    </kendo-dropdownlist>

                                                    <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                            type="button"
                                                            (click)="newInput(groupIndex, 0, field.ID)">
                                                        <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                    </button>
                                                </div>
                                            }
                                            <div class="value">
                                                <kendo-datetimepicker
                                                        *ngIf="(filtervalues.Operator != operators.HasValue) && (filtervalues.Operator != operators.IsEmpty)"
                                                        [(ngModel)]="filtervalues.DateTimeValue"
                                                        [value]="convertToDate(filtervalues.DateTimeValue)"
                                                        [format]="makeKendoDateTime(userSettingsSignal().DateTimeFormatTypeString)" class="!text-sm">
                                                    <!-- | kendoDate-->
                                                </kendo-datetimepicker>

                                                <button class="remove-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="deleteInput(groupIndex ,0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="xmark"></bizz-icon>
                                                </button>
                                            </div>
                                        }
                                        <!-- Checkbox -->
                                        @case (tableFieldDataType.Checkbox) {
                                            <!--TODO: CHECK-->
                                                    <!--TODO: check if operator ?-->
                                                    <!--                  <select class="operator" [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"  disabled>
                                                                          <option value="=">=</option>
                                                                      </select>-->
                                            <div class="operator">
                                                <input class="!text-sm" disabled placeholder="="/>
                                                <input *ngIf="(filtervalues.Operator == operators.HasValue)"
                                                       class="! invisible" type="text"/>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>

                                            <div class="value">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.DoubleValue"
                                                                    [value]="filtervalues.DoubleValue"
                                                                    [valuePrimitive]="true"
                                                                    [data]="model.OperatorList.CheckOperators"
                                                                    textField="Text"
                                                                    valueField="Value"
                                                                    [defaultItem]="[{text:'=', value:'0'}]">
                                                </kendo-dropdownlist>

                                                <button class="remove-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="deleteInput(groupIndex ,0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="xmark"></bizz-icon>
                                                </button>
                                            </div>
                                            <!--      <select class="value" [(ngModel)]="filtervalues.Operator" [value]="filtervalues.Operator">
                                                      <option value=""></option>
                                                      <option *ngFor="let operator of model.OperatorList.CheckOperators" [value]="operator.Value">
                                                          {{operator.Text}}</option>
                                                  </select>-->
                                        }
                                        <!-- Radiogroup -->
                                        @case (tableFieldDataType.RadioGroup) {
                                            <!--TODO: CHECK-->
                                            <div class="operator">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.Operator"
                                                                    [value]="filtervalues.Operator"
                                                                    [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"
                                                                    [valuePrimitive]="true"
                                                                    [data]="model.OperatorList.ComboOperators"
                                                                    textField="Text"
                                                                    valueField="Value">
                                                </kendo-dropdownlist>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>
                                            <!-- <div> [class]="{'table-cell' : !field.desactivateDeletedField}"  ng-options="obj.value as obj.name for obj in field.optionList"-->
                                                    <!--       <select class="value" [(ngModel)]="filtervalues.DoubleValue">
                                                               <option value=""></option>
                                                               <option *ngFor="let operator of field.OptionValues" [value]="operator.ID">{{operator.Value}}</option>
                                                           </select>-->
                                            <div class="value">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.DoubleValue"
                                                                    [value]="''"
                                                                    [valuePrimitive]="true"
                                                                    [data]="field.OptionValues"
                                                                    textField="Text"
                                                                    valueField="ID">
                                                </kendo-dropdownlist>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>
                                        }
                                        <!-- Memo -->
                                        @case (tableFieldDataType.Memo) {
                                            <div class="operator">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.Operator"
                                                                    [value]="filtervalues.Operator"
                                                                    [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"
                                                                    [valuePrimitive]="true"
                                                                    [data]="model.OperatorList.StringOperators"
                                                                    textField="Text"
                                                                    valueField="Value">
                                                </kendo-dropdownlist>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>

                                            <div class="value">
                                                <input
                                                        *ngIf="(filtervalues.Operator != operators.HasValue) && (filtervalues.Operator != operators.IsEmpty)"
                                                        [(ngModel)]="filtervalues.StringValue" type="text" class="!text-sm"/>
                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>
                                        }
                                        <!-- Combobox -->
                                        @case (tableFieldDataType.Combobox) {
                                            <!-- <div> [class]="{'table-cell' : !field.desactivateDeletedField}"-->
                                                    <!--TODO: CHECK-->
                                            <div class="operator">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.Operator"
                                                                    [value]="filtervalues.Operator"
                                                                    [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"
                                                                    [valuePrimitive]="true"
                                                                    [data]="model.OperatorList.ComboOperators"
                                                                    textField="Text"
                                                                    valueField="Value">
                                                </kendo-dropdownlist>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>

                                            <div class="value">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.DoubleValue"
                                                                    [value]="filtervalues.DoubleValue"
                                                                    [valuePrimitive]="true"
                                                                    [data]="field.OptionValues"
                                                                    textField="Value"
                                                                    valueField="ID">
                                                </kendo-dropdownlist>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>

                                            <!--                                    <select class="value" [(ngModel)]="filtervalues.DoubleValue">
                                                                                    <option value=""></option>
                                                                                    <option *ngFor="let operator of field.OptionValues" [value]="operator.ID">{{operator.Value}}
                                                                                    </option>
                                                                                </select>-->

                                        }
                                        <!-- OrganizationChart -->
                                        @case (tableFieldDataType.OrganizationChartUnitSelector) {
                                            <!--TODO:CHECK-->
                                            <!--<select class="operator" [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"  [(ngModel)]="filtervalues.Operator" [value]="filtervalues.Operator">
                                                <option *ngFor="let operator of model.OperatorList.OrgChartOperators" [value]="operator.Value">-->
                                            <!--TODO: will be changed in backend: {{operator.Text}} operators not matching see filterfunctionservice setoperators-->
                                            <div class="operator">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.Operator"
                                                                    [value]="filtervalues.Operator"
                                                                    [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"
                                                                    [valuePrimitive]="true"
                                                                    [data]="model.OperatorList.OrgChartOperators"
                                                                    textField="Text"
                                                                    valueField="Value">
                                                </kendo-dropdownlist>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>

                                            <div class="value">
                                                <bizz-org-chart-select
                                                        class="w-full"
                                                        [readonly]="false"
                                                        [selection]="filtervalues.SelectedOrganizationChartItems"
                                                        (selectionChanged)="filtervalues.SelectedOrganizationChartItems = $event"
                                                >

                                                </bizz-org-chart-select>


                             <!--                   <bizz-org-chart-select [selection]="formControl.getRawValue()"
                                                                       [readonly]="readOnlyOverride || formField.IsReadOnly"
                                                                       [ngClass]="{'hidden' : formField.IsHidden}"
                                                                       (selectionChanged)="formControl.setValue($event)">
                                                </bizz-org-chart-select>

-->


                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>
                                            <!-- <div *ngSwitchCase="13" class="full-width">
                                             <ng-bizz-org-selector disabled="field.disabled" selected-items="filtervalues.SelectedOrganizationChartItems" types="field.types" state="filtervalues.State" max="maxUsers" min="minUsers" keep-removed-items="true"></ng-bizz-org-selector>
                                             </div> -->
                                        }
                                        <!-- Controlled Document -->
                                        @case (tableFieldDataType.ControlledDocument) {
                                            <div class="operator">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.Operator"
                                                                    [value]="filtervalues.Operator"
                                                                    [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"
                                                                    [valuePrimitive]="true"
                                                                    [data]="model.OperatorList.StringOperators"
                                                                    textField="Text"
                                                                    valueField="Value">
                                                </kendo-dropdownlist>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>

                                            <div class="value">
                                                <input *ngIf="(filtervalues.Operator != operators.HasValue) && (filtervalues.Operator != operators.IsEmpty)"
                                                        [(ngModel)]="filtervalues.StringValue" type="text" class="!text-sm"/>
                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>
                                        }
                                        <!-- HyperLink -->
                                        @case (tableFieldDataType.HyperLink) {
                                            <div class="operator">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.Operator"
                                                                    [value]="filtervalues.Operator"
                                                                    [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"
                                                                    [valuePrimitive]="true"
                                                                    [data]="model.OperatorList.StringOperators"
                                                                    textField="Text"
                                                                    valueField="Value">
                                                </kendo-dropdownlist>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>
                                            <div class="value">
                                                <input
                                                        *ngIf="(filtervalues.Operator != operators.HasValue) && (filtervalues.Operator != operators.IsEmpty)"
                                                        [(ngModel)]="filtervalues.StringValue" type="text" class="!text-sm"/>
                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>
                                        }
                                        <!-- OrganizationChartUnitSelectorForTrnApp -->
                                        @case (tableFieldDataType.OrganizationChartUnitSelectorForTrnApp) {
                                            <!-- TODO:CHECK -->
                                            <div class="operator">
                                                <input disabled type="text" placeholder="Not implemented yet"/>
                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>
                                            <div class="value">
                                                <input disabled type="text" placeholder="Not implemented yet"/>
                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>

                                            </div>
                                            <!-- <div *ngSwitchCase="19" class="full-width">
                                                                            <ng-bizz-org-selector selected-items="filtervalues.SelectedOrganizationChartItems" types="field.types" state="filtervalues.State" max="maxUsers" min="minUsers" keep-removed-items="true"></ng-bizz-org-selector>
                                                                        </div> -->
                                        }
                                        <!-- EnumList -->
                                        @case (tableFieldDataType.EnumList) {
                                            <!-- TODO:CHECK -->
                                            <div class="operator">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.Operator"
                                                                    [value]="filtervalues.Operator"
                                                                    [id]="'select' + field.FieldType + 'group' + i + groupIndex + field.Caption"
                                                                    [valuePrimitive]="true"
                                                                    [data]="model.OperatorList.StringOperators"
                                                                    textField="Text"
                                                                    valueField="Value">
                                                </kendo-dropdownlist>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>

                                            <div class="value">
                                                <kendo-dropdownlist class="w-full !text-sm"
                                                                    [(ngModel)]="filtervalues.DoubleValue"
                                                                    [value]="filtervalues.DoubleValue"
                                                                    [valuePrimitive]="true"
                                                                    [data]="field.EnumOptionValues"
                                                                    textField="Value"
                                                                    valueField="ID">
                                                </kendo-dropdownlist>

                                                <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                        type="button"
                                                        (click)="newInput(groupIndex, 0, field.ID)">
                                                    <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                                </button>
                                            </div>
                                        }
                                    }
                                    <div class="hidden w-auto flex-row items-center gap-2.5 sm:flex">
                                        <button class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                                                type="button"
                                                (click)="newInput(groupIndex, 0, field.ID)">
                                            <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                        </button>
                                        <button class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                                                type="button"
                                                (click)="deleteInput(groupIndex ,0, field.ID)">
                                            <bizz-icon class="h-4 w-4" iconName="xmark"></bizz-icon>
                                        </button>
                                    </div>
                                </div>
                            } @else {
                                <!-- TYPEAHEAD SEARCH FIELDS-->

                                @if (tableFieldDataType) {
                                    <div class="flex flex-col items-center gap-2 sm:mx-2.5 sm:flex-row sm:gap-5">
                                        <!--TODO: missing enum Strings, not found in Legacy ? -->
                                        <p [ngClass]="{'sm:text-right text-disabled' : i !== 0}"
                                           class="caption w-full truncate text-sm font-normal text-default sm:w-[calc(25%-(1.25rem/2))]">{{
                                                i !== 0 ? 'AND' : field.Caption | translate
                                            }}</p>
                                        <div class="operator">
                                            <input disabled type="text" placeholder="Typeahead not implemented yet"/>
                                            <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                    type="button"
                                                    (click)="newInput(groupIndex, 0, field.ID)">
                                                <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                            </button>
                                        </div>
                                        <div class="value">
                                            <input disabled type="text" placeholder="Not implemented yet"/>
                                            <button class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                                                    type="button"
                                                    (click)="newInput(groupIndex, 0, field.ID)">
                                                <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                            </button>

                                        </div>
                                        <!--<ng-bizz-typeahead
                                                tags="filtervalues.SelectedTypeaheadItems"
                                                filter-item-type="properties.FilterItemType"
                                                field-item-type="field.SearchFilterFieldItemType"
                                                view-search-fields-id="filtervalues.ForeignID"
                                                search-field-values-id="filtervalues.ID"
                                                collections-id="properties.CollectionsID"
                                        />-->
                                        <div class="hidden w-auto flex-row items-center gap-2.5 sm:flex">
                                            <button class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                                                    type="button"
                                                    (click)="newInput(groupIndex, 0, field.ID)">
                                                <bizz-icon class="h-4 w-4" iconName="plus"></bizz-icon>
                                            </button>
                                            <button class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                                                    type="button"
                                                    (click)="deleteInput(groupIndex ,0, field.ID)">
                                                <bizz-icon class="h-4 w-4" iconName="xmark"></bizz-icon>
                                            </button>
                                        </div>
                                    </div>
                                }
                            }
                        }

                    }
                </div>
            </fieldset>
        }
    </div>
} @else {
    <fieldset class="!mt-0 flex h-full min-h-[50%] w-full flex-col gap-6">
        <!-- Field Groups -->
        <div class="sticky top-0 z-10 -mb-3 flex flex-col bg-body-popup py-2.5 pb-3">
            <div class="flex justify-between">
                <kendo-skeleton
                        shape="text"
                        animation="wave"
                        [width]="100"
                ></kendo-skeleton>
                <bizz-toggle-arrow class="h-4 w-4 text-default"
                                   [direction]="collapseGroup ? 'up' : 'down'"></bizz-toggle-arrow>
            </div>
            <hr class="h-[1px] w-full bg-divider"/>
        </div>

        <ng-scrollbar
                class="scroll-filter scroll-filter-mobile sm:scroll-filter-desktop w-full scroll-smooth transition-all">
            <div class="flex h-full w-full flex-col gap-6 pr-8 sm:pr-8">
                <div class="flex flex-col items-center gap-2 sm:mx-2.5 sm:flex-row sm:gap-5">
                    <kendo-skeleton
                            shape="text"
                            animation="wave"
                            class="w-full sm:w-[calc(25%-(1.25rem/2))]"
                    ></kendo-skeleton>
                    <div class="operator">
                        <kendo-skeleton
                                shape="text"
                                animation="wave"
                                class="h-full w-full"
                        ></kendo-skeleton>
                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                        >

                        </kendo-skeleton>
                    </div>
                    <div class="value">
                        <kendo-skeleton
                                shape="text"
                                animation="wave"
                                class="h-full w-full"
                        ></kendo-skeleton>

                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="remove-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                        >
                        </kendo-skeleton>
                    </div>
                    <div class="hidden w-[calc(10%-1.25rem)] flex-row items-center gap-2.5 sm:flex">
                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                        >

                        </kendo-skeleton>

                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                        >

                        </kendo-skeleton>
                    </div>
                </div>
                <div class="flex flex-col items-center gap-2 sm:mx-2.5 sm:flex-row sm:gap-5">
                    <kendo-skeleton
                            shape="text"
                            animation="wave"
                            class="w-full sm:w-[calc(25%-(1.25rem/2))]"
                    ></kendo-skeleton>
                    <div class="operator">
                        <kendo-skeleton
                                shape="text"
                                animation="wave"
                                class="h-full w-full"
                        ></kendo-skeleton>
                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                        >

                        </kendo-skeleton>
                    </div>
                    <div class="value">
                        <kendo-skeleton
                                shape="text"
                                animation="wave"
                                class="h-full w-full"
                        ></kendo-skeleton>

                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="remove-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                        >

                        </kendo-skeleton>
                    </div>
                    <div class="hidden w-[calc(10%-1.25rem)] flex-row items-center gap-2.5 sm:flex">
                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                        >

                        </kendo-skeleton>

                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                        >

                        </kendo-skeleton>
                    </div>
                </div>
                <div class="flex flex-col items-center gap-2 sm:mx-2.5 sm:flex-row sm:gap-5">
                    <kendo-skeleton
                            shape="text"
                            animation="wave"
                            class="w-full sm:w-[calc(25%-(1.25rem/2))]"
                    ></kendo-skeleton>
                    <div class="operator">
                        <kendo-skeleton
                                shape="text"
                                animation="wave"
                                class="h-full w-full"
                        ></kendo-skeleton>
                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                        >

                        </kendo-skeleton>
                    </div>
                    <div class="value">
                        <kendo-skeleton
                                shape="text"
                                animation="wave"
                                class="h-full w-full"
                        ></kendo-skeleton>

                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="remove-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                        >

                        </kendo-skeleton>
                    </div>
                    <div class="hidden w-[calc(10%-1.25rem)] flex-row items-center gap-2.5 sm:flex">
                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                        >

                        </kendo-skeleton>

                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                        >

                        </kendo-skeleton>
                    </div>
                </div>
            </div>
        </ng-scrollbar>
    </fieldset>
    <fieldset class="!mt-0 flex h-full min-h-[50%] w-full flex-col gap-6">
        <!-- Field Groups -->
        <div class="sticky top-0 z-10 -mb-3 flex flex-col bg-body-popup py-2.5 pb-3">
            <div class="flex justify-between">
                <kendo-skeleton
                        shape="text"
                        animation="wave"
                        [width]="100"
                ></kendo-skeleton>
                <bizz-toggle-arrow class="h-4 w-4 text-default"
                                   [direction]="collapseGroup ? 'up' : 'down'"></bizz-toggle-arrow>
            </div>
            <hr class="h-[1px] w-full bg-divider"/>
        </div>
        <ng-scrollbar
                class="scroll-filter scroll-filter-mobile sm:scroll-filter-desktop w-full scroll-smooth transition-all">
            <div class="flex h-full w-full flex-col gap-6 pr-8 sm:pr-8">
                <div class="flex flex-col items-center gap-2 sm:mx-2.5 sm:flex-row sm:gap-5">
                    <kendo-skeleton
                            shape="text"
                            animation="wave"
                            class="w-full sm:w-[calc(25%-(1.25rem/2))]"
                    ></kendo-skeleton>
                    <div class="operator">
                        <kendo-skeleton
                                shape="text"
                                animation="wave"
                                class="h-full w-full"
                        ></kendo-skeleton>
                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                        >

                        </kendo-skeleton>
                    </div>
                    <div class="value">
                        <kendo-skeleton
                                shape="text"
                                animation="wave"
                                class="h-full w-full"
                        ></kendo-skeleton>

                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="remove-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                        >

                        </kendo-skeleton>
                    </div>
                    <div class="hidden w-[calc(10%-1.25rem)] flex-row items-center gap-2.5 sm:flex">
                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                        >

                        </kendo-skeleton>

                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                        >

                        </kendo-skeleton>
                    </div>
                </div>
                <div class="flex flex-col items-center gap-2 sm:mx-2.5 sm:flex-row sm:gap-5">
                    <kendo-skeleton
                            shape="text"
                            animation="wave"
                            class="w-full sm:w-[calc(25%-(1.25rem/2))]"
                    ></kendo-skeleton>
                    <div class="operator">
                        <kendo-skeleton
                                shape="text"
                                animation="wave"
                                class="h-full w-full"
                        ></kendo-skeleton>
                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                        >

                        </kendo-skeleton>
                    </div>
                    <div class="value">
                        <kendo-skeleton
                                shape="text"
                                animation="wave"
                                class="h-full w-full"
                        ></kendo-skeleton>

                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="remove-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                        >

                        </kendo-skeleton>
                    </div>
                    <div class="hidden w-[calc(10%-1.25rem)] flex-row items-center gap-2.5 sm:flex">
                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                        >

                        </kendo-skeleton>

                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                        >

                        </kendo-skeleton>
                    </div>
                </div>
                <div class="flex flex-col items-center gap-2 sm:mx-2.5 sm:flex-row sm:gap-5">
                    <kendo-skeleton
                            shape="text"
                            animation="wave"
                            class="w-full sm:w-[calc(25%-(1.25rem/2))]"
                    ></kendo-skeleton>
                    <div class="operator">
                        <kendo-skeleton
                                shape="text"
                                animation="wave"
                                class="h-full w-full"
                        ></kendo-skeleton>
                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="add-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                        >

                        </kendo-skeleton>
                    </div>
                    <div class="value">
                        <kendo-skeleton
                                shape="text"
                                animation="wave"
                                class="h-full w-full"
                        ></kendo-skeleton>

                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="remove-field round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white sm:hidden"
                        >

                        </kendo-skeleton>
                    </div>
                    <div class="hidden w-[calc(10%-1.25rem)] flex-row items-center gap-2.5 sm:flex">
                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                        >

                        </kendo-skeleton>

                        <kendo-skeleton
                                shape="circle"
                                animation="wave"
                                class="round flex h-6 w-6 justify-self-center bg-button text-blue-300 hover:bg-blue-300 hover:text-white"
                        >

                        </kendo-skeleton>
                    </div>
                </div>
            </div>
        </ng-scrollbar>
    </fieldset>
}


