import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PDFViewerModule } from '@progress/kendo-angular-pdfviewer';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { MediaCommitDto } from '../../../../../../../models/ts/media-commit-dto.model';
import { FileUploadComponent } from '../../../../../../shared/components/file-upload/file-upload/file-upload.component';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import { BehaviorSubject } from 'rxjs';
import { ControlledDocumentUploadIntent } from './controlled-document-upload-intent';
import { ControlledDocumentTemplateIntent } from './controlled-document-template-intent';

/**
 * Represents a control that allows the user to upload a document to BizzMine, specific to document collections.
 */
@Component({
  selector: 'bizz-controlled-document-control',
  standalone: true,
  imports: [CommonModule, PDFViewerModule, FileUploadComponent],
  templateUrl: './controlled-document-control.component.html',
  styleUrls: ['./controlled-document-control.component.scss']
})
export class ControlledDocumentControlComponent extends BaseFormControlComponent {

  @Output() public fileChanged: EventEmitter<MediaCommitDto> = new EventEmitter<MediaCommitDto>();
  public uploadFile$: BehaviorSubject<ControlledDocumentUploadIntent | ControlledDocumentTemplateIntent | undefined> = new BehaviorSubject<ControlledDocumentUploadIntent | ControlledDocumentTemplateIntent | undefined>(undefined);

  public fileUploaded(file: MediaCommitDto | undefined): void {
    if (file) {
      this.formControl.setValue(file.MediasID);
      this.formControl.markAsDirty();
    }
    this.fileChanged.emit(file);
  }

  protected override fieldValueChangeComparator(previous: CollectionFormField | undefined, current: CollectionFormField | undefined): boolean {
    return JSON.stringify(current?.Value) == JSON.stringify(previous?.Value);
  }

  protected override valueSetter(field: CollectionFormField): void {
    const value = field[this.fieldValueProperty];
    if (value?.selectedTemplate != null || value?.files != null) {
      // do not actually SET value - instead, set the uploadFile$ subject -> starting an upload in the file-upload component
      if(value?.selectedTemplate != null){
        this.uploadFile$.next(value as ControlledDocumentTemplateIntent);
      }

      if(value?.files != null){
        this.uploadFile$.next(value as ControlledDocumentUploadIntent);
      }

      return;
    }
  }

  protected override focus(): void {
    // Add your implementation here
  }

}

