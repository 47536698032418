<div class="modal-container modal-sm">

    <div class="modal-close-container">
        <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                   (click)="dialogRef.close()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>

    <div class="modal-body w-full">
        <div class="modal-header">
            <h1>{{ "TaskInfo" | translate }}</h1>
        </div>
        <div [innerHtml]="data.Body | notificationFilterPipe | safe: 'html'">


        </div>
        </div>




    <div class="modal-buttons">
        <div class="flex flex-row justify-end gap-3 text-center">
            <button class="save" (click)="dialogRef.close()">
                {{ "Close" | translate }}
            </button>
        </div>

    </div>
</div>
