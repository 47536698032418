@if (formData.DocumentProperties.ReadAndUnderstood) {
    <form class="bg-form shadow-md sm:rounded-b" [formGroup]="readAndUnderstoodForm">
        <div class="h-1 w-full bg-blue-500 sm:h-2 sm:rounded-t">
        </div>
        <div class="mx-4 my-2 flex items-center gap-2 text-blue-500 sm:rounded-t">
            <bizz-icon class="h-4 w-4" iconName="file-check"></bizz-icon>
            <span class="text-lg font-bold">{{"ReadAndUnderstood" | translate}}</span>
        </div>
        <div class="mx-4 flex flex-col">
            <hr class="hidden sm:flex">
            <div class="my-4 flex flex-col gap-2 sm:flex-row sm:gap-0">
                <!-- ReadAndUnderstood Inputs  -->
                <div class="flex sm:w-fit sm:min-w-56">
                    <div class="flex flex-col gap-4 sm:mx-8">
                        <label class="text-sm font-semibold">
                            {{"ReadAndUnderstoodQuestion" | translate}}?
                            <span class="relative -top-0.5 h-5 text-xl leading-tight text-red-400">*</span>
                        </label>
                        <div class="flex flex-wrap gap-8 sm:mx-4 sm:justify-center">
                            <div class="flex items-center justify-evenly gap-2">
                                <input id="approve" type="radio" kendoRadioButton [value]="ReadAndUnderstoodType.Yes"
                     [formControlName]="'Result'" />
                                <label class="cursor-pointer text-blue-500" for="approve">{{'Yes' | translate}}</label>
                            </div>
                            <div class="flex items-center justify-evenly gap-2">
                                <input id="reject" type="radio" kendoRadioButton [value]="ReadAndUnderstoodType.No"
                     [formControlName]="'Result'"/>
                                <label class="cursor-pointer text-red-300" for="reject">{{'No' | translate}}</label>
                            </div>
                        </div>
                    </div>
              </div>
                <!-- Remarks -->
                <div class="sm:h-18 flex flex-col gap-4 border-divider sm:w-full sm:border-l sm:pl-4">
                        <label>
                            {{'Remarks' | translate}}
                        </label>
                        <kendo-textarea [rows]="2"
                        
                        [readonly]="formData.DocumentProperties.ReadAndUnderstood.Result != ReadAndUnderstoodType.No"
                         [resizable]="'vertical'" [formControlName]="'RejectReason'">
                        </kendo-textarea>
                </div>
            </div>
        </div>    </form>

}
