<section class="relative flex flex-col items-end justify-start">
        <!-- BizzIcon and counting -->
        <div class="relative h-6 w-auto cursor-pointer sm:h-5 sm:w-5" #anchor (click)="togglePopup()">
            <bizz-icon
                    class="header-popup-icon"
                    [hover]="true"
                    iconName="bell">
            </bizz-icon>
            @if (count() > 0) {
                <div class="header-popup-icon-counter">
                    <span>{{ count() }}</span>
                </div>
            }
        </div>
        <!-- BizzIcon and counting end -->
        
        <!-- Optimized popup code -->  
        <div #popup [ngClass]="{'invisible' : !showPopup}" 
            class="fixed right-0 top-0 z-[2000] flex h-svh w-svw max-w-[25rem] flex-col items-end bg-transparent transition-all duration-300 ease-in-out sm:right-auto sm:top-auto sm:mt-[34px] sm:h-[calc(100vh-48px)] sm:w-40 sm:overflow-y-clip">
            <div [ngClass]="{'translate-y-[0]' : showPopup, '!opacity-100' : showPopup, 'translate-y-[-110%]' : !showPopup, 'opacity-0' : !showPopup}" 
                class="shadow-black absolute flex h-full w-full flex-col bg-body-popup shadow-lg drop-shadow-xl transition-all duration-300 ease-in-out sm:h-auto sm:w-[27rem] sm:rounded">
            <!-- Optimized popup code end-->      

                <div class="flex flex-col gap-3.5 p-4">
                    <div class="flex items-center font-bold">
                          <h1 class="flex flex-row items-center text-lg text-form-header">
                            <bizz-icon class="sm:h-size-5 mr-2.5 size-6 text-form-header"
                                       iconName="bell"></bizz-icon>
                            {{ 'Reminders' | translate }}
                          </h1>
                          <button class="ml-auto w-fit !p-0" (click)="togglePopup()">
                            <bizz-icon class="sm:h-size-5 size-6 text-form-header"
                                       iconName="xmark"></bizz-icon>
                          </button>
                    </div>
                    <div class="relative flex h-full w-full flex-row items-center justify-end">
                        <input [ngModel]="searchTerm | async"
                               (ngModelChange)="searchTerm.next($event)"
                               class="size-8 w-full rounded border-form-control focus:w-full focus:border-3 focus:border-form-control-focus focus:text-default focus:shadow-inner focus:transition-all placeholder:focus:text-lighter sm:h-8 sm:w-full sm:text-default sm:placeholder:text-lighter"
                               placeholder="{{'Search' | translate}}">
                        <bizz-icon
                                class="pointer-events-none absolute mr-2 size-4 cursor-pointer text-button sm:pointer-events-auto"
                                iconName="magnifying-glass">
                        </bizz-icon>
                    </div>

                    <bizz-loader [showLoader]="loading()"></bizz-loader>
                    <div class="flex items-center justify-between border-b-2 border-divider pb-2">
                      <a class="text-sm font-semibold text-blue-500 no-underline" [routerLink]="['./reminders']"
                         (click)="togglePopup()">{{'AllReminders' | translate}}
                      </a>
                      <bizz-icon
                        class="h-4 w-4 text-default"
                        (click)="refresh()"
                        [hover]="true"
                        iconName="rotate-right">
                      </bizz-icon>
                    </div>


                    @if (data().length > 0) {
                        @for (reminder of data(); track reminder.ID) {
                            <bizz-notification-block
                                    [notification]="reminder"
                                    [isReminder]="true"
                                    (openReminder)="open($event)"
                                    (readIcon)="readReminder($event)"
                                    (unreadIcon)="unreadReminder($event)"
                            >
                            </bizz-notification-block>
                        }
                    } @else {
                        <div class="my-8 flex h-full w-full flex-col items-center justify-center sm:w-96">
                            <bizz-icon class="my-5 size-32 text-blue-500"
                                       iconStyle="duotone"
                                       iconName="envelope-open-text">

                            </bizz-icon>
                            <span class="h-full text-center font-title text-2xl font-bold text-widget-header">{{ 'NoRemindersAvailable' | translate }}</span>
                        </div>
                    }
                </div>
            </div>
        </div>
</section>