import {Component, DestroyRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CommonModule, Location} from '@angular/common';
import {SplitterModule} from '@progress/kendo-angular-layout';
import {FlowStatusDto} from '../../../../models/ts/flow-status-dto.model';
import {SidebarService} from '../../../shared/services/sidebar/sidebar.service';
import {ActivatedRoute} from '@angular/router';
import {CollectionMethodType} from '../../../../models/ts/collection-method-type.model';
import { CollectionFlowStatusApiService } from '../../../api/bizzmine/collection-flow-status/collection-flow-status-api.service';
import { FlowStatusHeaderComponent } from '../../../features/bizzmine/flow-status/components/flow-status-header/flow-status-header.component';
import { FlowStatusDiagramComponent } from '../../../features/bizzmine/flow-status/components/flow-status-diagram/flow-status-diagram.component';
import { FlowStatusTasksComponent } from '../../../features/bizzmine/flow-status/components/flow-status-tasks/flow-status-tasks.component';
import { StepperComponent } from "../../../shared/components/ui/stepper/stepper.component";
import { StepperStep } from 'src/app/shared/components/ui/stepper/interfaces/stepper-step';
import { BehaviorSubject, Observable, filter, of, switchMap, take, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'bizz-flow-status',
    standalone: true,
    templateUrl: './flow-status.component.html',
    styleUrls: ['./flow-status.component.scss'],
    imports: [CommonModule, FlowStatusHeaderComponent, FlowStatusDiagramComponent, FlowStatusTasksComponent, SplitterModule, StepperComponent]
})
export class FlowStatusComponent implements OnInit {

  @ViewChild('bizzFlowStatusDiagram', { static: false }) 
  public flowStatusDiagramComponent: FlowStatusDiagramComponent;

  @Input() public flowStatus: FlowStatusDto;
  public flowStatus$: Observable<FlowStatusDto>;

  @Output() public backClick: EventEmitter<void> = new EventEmitter<void>();

  public currentStep = new BehaviorSubject<FlowStatusStepType>(FlowStatusStepType.TASKS);
  public flowStatusStepType = FlowStatusStepType;

  public steps: StepperStep[] = [
    {
      label: 'FlowStatus',
      icon: {
        name: 'chart-line',
      },
      disabled: false,
      showLabelOnMobile: true,
    },
    {
      label: 'Tasks',
      icon: {
        name: 'square-list',
      },
      disabled: false,
      showLabelOnMobile: true,
    }
  ];

  public sidebarIsShown = this.sidebarService.getObservable();

  public constructor(private sidebarService: SidebarService,
                     private route: ActivatedRoute,
                     private location: Location,
                     private collectionFlowStatusApiService: CollectionFlowStatusApiService,
                     private destroyRef: DestroyRef) {

  }

  public ngOnInit(): void {
    //The flow status is passed in as an input, so we don't need to get it from the API
    console.log(this.flowStatus);
    if(this.flowStatus != undefined) {
      this.flowStatus$ = of(this.flowStatus);
    } else {
      this.flowStatus$ = this.route.params.pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(params => {
          const collectionId = +params['collectionId']; // (+) converts string 'id' to a number
          const instanceId = +params['instanceId']; // (+) converts string 'id' to a number
          const versionId = +params['versionId']; // (+) converts string 'id' to a number
          const methodType = (+params['methodType']) as CollectionMethodType; // (+) converts string 'id' to a number
          return this.collectionFlowStatusApiService.getFlowStatus(collectionId, instanceId, versionId, methodType);
        })
      );
    }

    //On mobile we want to zoom the diagram after it's first shown on the screen.
    this.currentStep.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(step => step === FlowStatusStepType.FLOW
        && this.flowStatusDiagramComponent != undefined),
      take(1),
      tap(() => { 
        this.flowStatusDiagramComponent.zoomToFit();
        this.flowStatusDiagramComponent.setDiagramScale(DEFAULT_DIAGRAM_MOBILE_SCALE);
      })
    ).subscribe();
  }

  public onBackClicked(): void {
    if(this.flowStatus != undefined){
      this.backClick.emit();
    }else{
      this.location.back();
    }
  }
}

export enum FlowStatusStepType {
  FLOW = 0,
  TASKS = 1,
}

const DEFAULT_DIAGRAM_MOBILE_SCALE = 0.8;