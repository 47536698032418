import { ChangeDetectorRef, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupModule } from '@progress/kendo-angular-popup';
import { FormControlComponent } from '../controls/form-control/form-control.component';
import { FormElementComponent } from '../form-element/form-element.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormHeaderComponent } from '../form-header/form-header.component';
import { filter, Observable, take, tap } from 'rxjs';
import { CollectionFormGroupService } from '../../services/collection-form-group.service';
import { CollectionFormService } from '../../services/collection-form.service';
import { FormLockComponent } from '../form-lock/form-lock.component';
import { FormAssessmentComponent } from '../form-assessment/form-assessment.component';
import { FormAccordionHeaderComponent } from '../form-accordion-header/form-accordion-header.component';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { FieldFormControlPipe } from '../../../../../shared/pipes/form/field-form-control/field-form-control.pipe';
import { ValidationSummaryComponent } from '../../../../../shared/components/errors/validation-summary/validation-summary.component';
import { CollectionForm } from '../../../../../../models/ts/collection-form.model';
import { AccessToken } from '../../../../../core/constants/access-token';
import { TaskType } from '../../../../../../models/ts/task-type.model';
import { AccordionStatusType } from '../../../../../../models/ts/accordion-status-type.model';
import { AuthService } from '../../../../../core/services/auth/auth.service';
import { LookupService } from '../../../../../shared/services/lookup/lookup.service';
import { Claims } from '../../../../../core/constants/claims';
import { AssessmentProperties } from '../../../../../../models/ts/assessment-properties.model';
import { MediaCommitDto } from '../../../../../../models/ts/media-commit-dto.model';
import { CollectionFormField } from '../../../../../../models/ts/collection-form-field.model';
import { ProtectedFieldType } from '../../../../../../models/ts/protected-field-type.model';
import { getFileExtension, getFileName } from '../../../../../shared/functions/helpers/file-name-helpers';
import { ViewDataSource } from '../../../../../../models/ts/view-data-source.model';
import { FormFieldType } from '../../../../../../models/ts/form-field-type.model';
import { CollectionFormAccordion } from '../../../../../../models/ts/collection-form-accordion.model';
import { StepType } from 'src/models/ts/step-type.model';
import * as Sentry from '@sentry/angular';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { Dialog } from '@angular/cdk/dialog';
import { TaskInfoDialogComponent } from '../dialogs/task-info-dialog/task-info-dialog.component';
import { FormTrainingappAssessmentComponent } from '../form-trainingapp-assessment/form-trainingapp-assessment.component';
import { TrainingAssessmentDto } from '../../../../../../models/ts/training-assessment-dto.model';
import {
  ExamCreatorFormControlAction,
  ExamCreatorFormControlActionType
} from '../../interfaces/exam-creator-form-control-action';
import { SchedulerPanelComponent } from '../scheduler-panel/scheduler-panel.component';
import { SchedulerDto } from 'src/models/ts/scheduler-dto.model';
import { selectForm } from '../../../../../store/features/forms/forms-selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { StoreCollectionForm } from '../../../../../store/features/forms/forms-state';
import { UserType } from '../../../../../../models/ts/user-type.model';
import { formsActions } from '../../../../../store/features/forms/forms-actions';
import { FormButtonStates } from '../../classes/form-button.states';
import { LoaderComponent } from '../../../../../shared/components/ui/loader/loader.component';
import { DocumentCheckinType } from '../../../../../../models/ts/document-checkin-type.model';
import { CollectionMethodType } from '../../../../../../models/ts/collection-method-type.model';
import { ReadAndUnderstoodDto } from 'src/models/ts/read-and-understood-dto.model';
import { FormReadAndUnderstoodComponent } from '../form-read-and-understood/form-read-and-understood.component';
import { LinkedCollectionType } from '../../../../../../models/ts/linked-collection-type.model';
import { UnsavedChangesService } from '../../../../../shared/services/unsaved-changes/unsaved-changes.service';
import { AutofocusService } from '../../services/autofocus.service';
import { Actions, ofType } from '@ngrx/effects';
import { NotificationFilterPipe } from '../../../../../shared/pipes/filter/notification-filter.pipe';
import { NavigationEnd, Router } from '@angular/router';
import { CollectionFormJsonService } from '../../services/collection-form-json.service';
import { environment } from '../../../../../../environments/environment';


/**
 * Main component for displaying a form.
 * Holds the main layout of forms and handles the loading of the form data.
 * @implements {ViewStackLoadedComponent<CollectionForm>}
 * @see ViewStackComponent
 */
@Component({
  selector: 'bizz-form',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    FormControlComponent,
    TranslatePipe,
    FormElementComponent,
    ReactiveFormsModule,
    FieldFormControlPipe,
    PopupModule,
    ValidationSummaryComponent,
    FormHeaderComponent,
    FormLockComponent,
    FormAssessmentComponent,
    FormReadAndUnderstoodComponent,
    FormAccordionHeaderComponent,
    TooltipModule,
    FormTrainingappAssessmentComponent,
    SchedulerPanelComponent,
    LoaderComponent,
    NotificationFilterPipe
  ],
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
@Sentry.TraceClass({ name: 'FormComponent' })
export class FormComponent implements OnInit {
  // Properties
  public loading = false;
  /**
   * The Angular Forms FormGroup containing all the form controls with the values of each field.<br>
   * Any changes to its controls value's will trigger an updated event.
   * @type {FormGroup}
   * @see updated
   */
  public form: FormGroup;

  // Inputs & Outputs
  @Input() public formId: string;
  @Input() public showHeader = true;
  public formButtonStates: FormButtonStates = new FormButtonStates();
  public storeForm$: Observable<Readonly<StoreCollectionForm> | undefined>;
  public storeForm: Readonly<StoreCollectionForm> | undefined;
  @Output() public generateExamQuestionsWithAi = new EventEmitter<{
    examName: string,
    id: number,
    questions: number,
    answers: number,
    collectionId: number
  }>();
  // Enums, Types & Constants
  protected readonly userType: UserType;
  protected readonly token: AccessToken;
  protected readonly taskType = TaskType;
  protected readonly accordionStatusType = AccordionStatusType;
  protected readonly StepType = StepType;
  protected readonly UserType = UserType;
  private unsavedChangesService = inject(UnsavedChangesService);

  public constructor(
    private authService: AuthService,
    private lookupService: LookupService,
    private formUtilitiesService: CollectionFormGroupService,
    private dialog: Dialog,
    private cdr: ChangeDetectorRef,
    private store$: Store,
    private destroyRef: DestroyRef,
    private autofocusService: AutofocusService,
    private actions$: Actions,
    private router: Router
  ) {
    if (this.authService.decodedAccessToken) {
      this.userType = this.authService.getAccessTokenClaim(Claims.UserType) as UserType;
      this.token = this.authService.decodedAccessToken;
    }
  }

  public get formData(): CollectionForm | undefined {
    return this.storeForm?.data;
  }

  public get isSelfCheckout(): boolean {
    return (
      this.formData?.DocumentProperties.CheckedOutByID !==
      this.token[Claims.UserID]
    );
  }

  public debugForm(): void {
    console.log(CollectionFormJsonService.formToJson(this.formData!));
  }

  public cancel(): void {
    if (this.formData) {
      if (this.form.dirty)
        this.unsavedChangesService.openUnsavedChangesDialog().subscribe({
          next: result => {
            if (result) this.dispatchCancel();
          }
        });
      else this.dispatchCancel();
    }
  }

  public dispatchCancel(): void {
    if (this.formData)
      this.store$.dispatch(formsActions.formCanceled({
        formId: this.formId,
        form: this.formData,
        unsavedChanges: false,
        performFileCheckoutValidation: true
      }));
  }

  public editForm(): void {
    if (this.formData)
      this.store$.dispatch(formsActions.editReadOnlyForm({
        formId: this.formId,
        collectionId: this.formData.CollectionsID,
        instanceId: this.formData.InstancesID,
        versionId: this.formData.VersionsID
      }));
    this.actions$.pipe(ofType(formsActions.updateForm), take(1), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.focusFirstField();
    });
  }

  public performOnOpenScripting(): void {
    if (this.formData)
      this.store$.dispatch(formsActions.performOnOpenScripting({ formId: this.formId, form: this.formData }));
  }

  public calculateFormulas(): void {
    this.store$.dispatch(formsActions.calculateFormulas({ formId: this.formId }));
  }

  public assessmentUpdated(assessmentProperties: AssessmentProperties): void {
    if (this.formData) {
      const updatedForm = structuredClone(this.formData);
      updatedForm.DocumentProperties.AssessmentProperties = assessmentProperties;
      this.update(updatedForm);
    }
  }

  public readAndUnderstoodChange(readAndUnderstood: ReadAndUnderstoodDto): void {
    if (this.formData) {
      const updatedForm = structuredClone(this.formData);
      updatedForm.DocumentProperties.ReadAndUnderstood = readAndUnderstood;
      if (this.formData.DocumentProperties.ReadAndUnderstood) {
        updatedForm.DocumentProperties.ReadAndUnderstood.ActionsID = this.formData.DocumentProperties.ReadAndUnderstood.ActionsID;
      }
      this.update(updatedForm);
    }
  }

  public trainingAssessmentDtoPropertiesChange(trainingAssessment: TrainingAssessmentDto): void {
    if (this.formData) {
      const updatedForm = structuredClone(this.formData as CollectionForm);
      updatedForm.TrainingAssessment = trainingAssessment;
      this.update(updatedForm);
    }
  }

  public taskInfo(): void {
    if (this.formData?.TaskInfo != undefined) {
      this.dialog.open(TaskInfoDialogComponent, {
        data: this.formData.TaskInfo
      });
    }

  }

  /**
   * Initializes the form group with the form data
   * @see updated
   */
  @Sentry.TraceMethod({ name: 'ngOnInit' })
  public ngOnInit(): void {
    this.actions$.pipe(ofType(formsActions.updateScriptingForm, formsActions.updateForm), take(1), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.focusFirstField();
    });
    this.selectStoreForm();
    this.initializeForm();
    this.performOnOpenScripting();
    this.calculateFormulas();
    // required for change detection
    this.cdr.detectChanges();

    this.router.events.pipe(takeUntilDestroyed(this.destroyRef), filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe((event)=>{
        this.cancel();
    });
  }
  /**
   * Updates the form control values for file title and extension field when a new file is uploaded.
   * Event thrown by ControlledDocumentControlComponent.
   * @param file
   * @param field
   * @see ControlledDocumentControlComponent
   */
  public fileUploaded(file: MediaCommitDto, field: CollectionFormField): void {
    if (this.formData) {
      const titleField =
        CollectionFormService.getField(this.formData, (f) => {
          return f.ProtectedFieldType == ProtectedFieldType.Title && f.CollectionsID == field.CollectionsID;
        });
      const fileField =
        CollectionFormService.getField(this.formData, (f) => {
          return f.ProtectedFieldType == ProtectedFieldType.File && f.CollectionsID == field.CollectionsID;
        });
      const extensionField =
        CollectionFormService.getField(this.formData, (f) => {
          return f.ProtectedFieldType == ProtectedFieldType.Extension && f.CollectionsID == field.CollectionsID;
        });
      const sizeField =
        CollectionFormService.getField(this.formData, (f) => {
          return f.ProtectedFieldType == ProtectedFieldType.Size && f.CollectionsID == field.CollectionsID;
        });
      const documentChekInStatusField =
        CollectionFormService.getField(this.formData, (f) => {
          return f.ProtectedFieldType == ProtectedFieldType.DocumentCheckinStatus
            && f.CollectionsID == field.CollectionsID;
        });

      if (titleField) {
        this.form.get(titleField.Id.toString())?.setValue(getFileName(file.FileName));
      }
      if (fileField) {
        this.form.get(fileField.Id.toString())?.setValue(file.MediasID);
      }
      if (extensionField) {
        this.form.get(extensionField.Id.toString())?.setValue(getFileExtension(file.FileName));
      }
      if (documentChekInStatusField) {
        const formField = this.form.get(documentChekInStatusField.Id.toString());
        if (formField) {
          const currentValue = formField.value;
          formField?.setValue(currentValue ?? DocumentCheckinType.CheckedIn);
        }

      }
      if (sizeField) {
        //TODO kb needs to be added but field is numeric ?
        this.form.get(sizeField.Id.toString())?.setValue(Math.ceil(file.Size / 1000));
      }
    }
  }

  public examCreatorAction(action: ExamCreatorFormControlAction | any): void {
    if (this.formData) {
      if (action?.type == ExamCreatorFormControlActionType.GenerateAi && action.generateAi != null) {

        const examName = CollectionFormService.getField(this.formData, (f) => {
          return f.ProtectedFieldType == ProtectedFieldType.ExamName;
        })?.Value;
        const id = CollectionFormService.getField(this.formData, (f) => {
          return f.ProtectedFieldType == ProtectedFieldType.ID;
        })?.Value;

        if (examName != null
          && id != null
          && action.generateAi.questions != null
          && action.generateAi.answersPerQuestion != null) {
          this.generateExamQuestionsWithAi.emit({
            answers: action.generateAi.answersPerQuestion,
            examName: examName,
            id: id,
            questions: action.generateAi.questions,
            collectionId: this.formData.CollectionsID
          });
        }
      }
    }
  }

  public formUnlocked(asManager: boolean): void {
    if (asManager) {
      this.unlockAsManager();
    } else {
      this.unlock();
    }
    this.actions$.pipe(ofType(formsActions.updateForm), take(1), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.focusFirstField();
    });

  }

  /**
   * Runs through all fields to enable/disable them based on the IsReadOnly property.
   * @param form
   * @param crossLinkCollectionsId
   */
  public enableDisableFields(
    form: CollectionForm,
    crossLinkCollectionsId = 0
  ): void {
    let currentDataSource: ViewDataSource | undefined;
    const openedFromAnotherStack = crossLinkCollectionsId > 0;

    if (openedFromAnotherStack) {
      currentDataSource = form.ViewDataSources.find(
        (v) => v.ViewDataSourcesID == crossLinkCollectionsId
      );
    }

    CollectionFormService.extractFieldsFromForm(form).forEach((field) => {
      if (field.FieldType || field.FormFieldType == FormFieldType.List) {
        const vds = form.ViewDataSources.find(
          (v) => v.ViewDataSourcesID == field.ViewDataSourcesID
        );

        if (openedFromAnotherStack) {
          if (field.SourceCollectionsID == crossLinkCollectionsId)
            field.IsReadOnly = true;
          else if (
            field.ViewDataSourcesID != 0 &&
            currentDataSource?.ViewDataSourcesID == vds?.ParentDataSourcesID
          )
            field.IsReadOnly = true;
        }
      }
    });

    // TODO: GL, implement with read-only changes
    LookupService.enableDisableFields(
      CollectionFormService.extractFieldsFromForm(form),
      form.ViewDataSources,
      undefined,
      crossLinkCollectionsId
    );
  }

  public goToFormField(fieldId: number): void {
    if (this.formData) {
      const fieldLocation = CollectionFormService.getLocationByFieldId(this.formData, fieldId);
      if (fieldLocation) {
        if (this.formData.Accordions.find(a => a.Id == fieldLocation.accordionId)?.AccordionStatus == AccordionStatusType.Closed) {
          this.update(CollectionFormService.toggleFormAccordion(this.formData, fieldLocation.accordionId));

          // Use a MutationObserver to scroll to the field after the accordion has opened (it's 'hidden' class was removed by ngClass directive)
          const element = document.getElementById(fieldLocation.accordionId.toString());
          if (element) {
            const observer = new MutationObserver((record, observer) => {
              this.scrollToElement(fieldId.toString());
              observer.disconnect();
            });
            observer.observe(element, { attributeFilter: ['class'] });
          }
        } else {
          this.scrollToElement(fieldId.toString());
        }
      }
    }
  }

  public toggleAccordion(isOpen: boolean, accordion: CollectionFormAccordion): void {
    if (this.formData)
      this.update(CollectionFormService.toggleFormAccordion(this.formData, accordion.Id));
  }

  public updateScheduler(scheduler: SchedulerDto): void {
    this.store$.dispatch(formsActions.updateScheduler({ formId: this.formId, scheduler: scheduler }));
  }

  public update(form: CollectionForm): void {
    this.store$.dispatch(
      formsActions.updateForm({
        update: CollectionFormService.toStoreUpdate(form, this.formId)
      })
    );
  }

  public submit(params: { closeStep: boolean, closeForm: boolean }): void {
    // Check if the form has a parent form and field (linked form)
    if (this.storeForm?.options?.parentFormId !== undefined && (this.storeForm?.options?.parentFormFieldId !== undefined || this.storeForm?.options?.parentGridFieldId !== undefined)) {
      this.saveLinkedForm(params);
    } else {
      this.saveForm(params);
    }
  }

  private saveLinkedForm(params: { closeStep: boolean, closeForm: boolean }): void {
    if (this.storeForm?.options !== undefined && this.storeForm.options.parentFormId !== undefined && (this.storeForm.options.parentFormFieldId !== undefined || this.storeForm.options.parentGridFieldId !== undefined)) {
      // Check if it's 1x1 or 1xN linked form
      if (this.storeForm.options.linkType == LinkedCollectionType.GridRecord && this.storeForm.options.parentGridFieldId !== undefined) {
        if (this.storeForm.data.VersionsID > 0 && this.storeForm.options.parentFormFieldId !== undefined)
          this.store$.dispatch(formsActions.saveGridLinkedFormInstance({
            form: this.storeForm.data,
            formId: this.formId,
            parentFormId: this.storeForm.options.parentFormId,
            parentFormFieldId: this.storeForm.options.parentFormFieldId,
            parentGridFieldId: this.storeForm.options.parentGridFieldId,
            parentRecordId: this.storeForm.options.parentRecordId!,
            preValidate: this.storeForm.data.SignOnSave
          }));
        else this.store$.dispatch(formsActions.createGridLinkedFormInstance({
          form: this.storeForm.data,
          formId: this.formId,
          parentFormId: this.storeForm.options.parentFormId,
          parentFormFieldId: this.storeForm.options.parentFormFieldId,
          parentGridFieldId: this.storeForm.options.parentGridFieldId,
          parentRecordId: this.storeForm.options.parentRecordId,
          preValidate: this.storeForm.data.SignOnSave
        }));
      } else if (this.storeForm.options.linkType == LinkedCollectionType.SingleRecord && this.storeForm.options.parentFormFieldId !== undefined) {
        if (this.storeForm.data.VersionsID > 0)
          this.store$.dispatch(formsActions.saveLinkedFormInstance({
            form: this.storeForm.data,
            formId: this.formId,
            parentFormId: this.storeForm.options.parentFormId,
            parentFormFieldId: this.storeForm.options.parentFormFieldId,
            preValidate: this.storeForm.data.SignOnSave
          }));
        else this.store$.dispatch(formsActions.createLinkedFormInstance({
          form: this.storeForm.data,
          formId: this.formId,
          parentFormId: this.storeForm.options.parentFormId,
          parentFormFieldId: this.storeForm.options.parentFormFieldId,
          preValidate: this.storeForm.data.SignOnSave
        }));
      } else {
        this.saveForm(params);
      }
    }
  }

  /**
   * Dispatches the correct form action to save the form.
   * Algorithm documentation can be found here:
   * https://github.com/bizzmine/BizzMine-Frontend/wiki/Form-Save-Algorithm
   * @param {{closeStep: boolean, closeForm: boolean}} params
   * @private
   */
  private saveForm(params: { closeStep: boolean, closeForm: boolean }): void {
    if (this.storeForm?.data) {
      // Scheduler
      if (this.storeForm.schedulerData) {
        this.store$.dispatch(formsActions.validateSchedulerForm({
          formId: this.formId,
          form: this.storeForm.data,
          schedule: this.storeForm.schedulerData
        }));
      } else if (params.closeStep) {
        // Flow
        if (this.storeForm.options?.startRevision &&
          (this.storeForm.data.MethodType == CollectionMethodType.RevisionWithMinorVersionChange ||
            this.storeForm.data.MethodType == CollectionMethodType.RevisionWithMajorVersionChange)) {
          // New Revision
          this.store$.dispatch(formsActions.saveRevision({
            formId: this.formId,
            form: this.storeForm.data,
            closeStep: params.closeStep,
            closeForm: params.closeForm,
            preValidate: this.storeForm.data.SignOnComplete
          }));
        } else if (this.storeForm.data.VersionsID > 0) {
          if (this.storeForm.data.TasksID > 0) {
            this.store$.dispatch(formsActions.executeTask({
              formId: this.formId,
              form: this.storeForm.data,
              closeForm: params.closeForm,
              preValidate: this.storeForm.data.SignOnComplete
            }));
          } else {
            throw new Error('Attempted to close a step on existing record without a task');
          }
        } else {
          // Registration Step
          this.store$.dispatch(formsActions.createFormInstance({
            formId: this.formId,
            form: this.storeForm.data,
            closeStep: params.closeStep,
            closeForm: params.closeForm,
            preValidate: this.storeForm.data.SignOnSave
          }));
        }
      } else if (this.storeForm.data.VersionsID > 0) {
        // existing record
        if (this.storeForm.data.TasksID > 0)
          // Task record
          // No PreValidation => saving tasks has no validation
          this.store$.dispatch(formsActions.saveTask({
            formId: this.formId,
            form: this.storeForm.data,
            closeForm: params.closeForm,
            preValidate: false
          }));
        else
          // Regular Save
          this.store$.dispatch(formsActions.saveFormInstance({
            formId: this.formId,
            form: this.storeForm.data,
            closeStep: params.closeStep,
            closeForm: params.closeForm,
            preValidate: this.storeForm.data.SignOnSave
          }));
      } else {
        // No flow, new record
        if (this.storeForm.data.FoldersID > 0)
          this.store$.dispatch(formsActions.createDocumentInstance({
            formId: this.formId,
            form: this.storeForm.data,
            closeStep: params.closeStep,
            closeForm: params.closeForm,
            preValidate: this.storeForm.data.SignOnSave
          }));
        else
          this.store$.dispatch(formsActions.createFormInstance({
            formId: this.formId,
            form: this.storeForm.data,
            closeStep: params.closeStep,
            closeForm: params.closeForm,
            preValidate: this.storeForm.data.SignOnSave
          }));
      }
    }
  }

  /**
   * Sets the button states based on the form data.
   * @private
   */
  private setButtonStates(form: StoreCollectionForm): void {
    this.formButtonStates.setButtonStates(
      form,
      this.authService.getAccessTokenClaim(Claims.UserType) as UserType
    );
  }

  private selectStoreForm(): void {
    this.storeForm$ = this.store$.select(selectForm(this.formId));
    this.storeForm$.pipe(takeUntilDestroyed(this.destroyRef), tap(value => {
      if (value) this.setButtonStates(value);
    })).subscribe({
      next: value => {
        if (value?.reset) {
          this.form.markAsPristine();
          this.store$.dispatch(formsActions.formGroupReset({ formId: this.formId }));
        }
        this.storeForm = value;
      }
    });
  }

  private scrollToElement(id: string): void {
    let element = document.getElementById(id);
    element = element ?? document.querySelector(`[for="${id}"]`);

    if (element)
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  /**
   * Unlocks the form as manager.
   */
  private unlockAsManager(): void {
    if (this.formData) {
      this.store$.dispatch(formsActions.deleteLock(
        {
          formId: this.formId,
          form: this.formData
        }
      ));
    }
  }

  /**
   * Unlocks the form as user.
   */
  private unlock(): void {
    if (this.formData) {
      this.store$.dispatch(formsActions.deleteSelfLock(
        {
          formId: this.formId,
          form: this.formData,
          close: false
        }
      ));
    }

  }

  /**
   * Initializes the form group based on form data. Also sets the button states.
   * @private
   */
  private initializeForm(): void {
    if (this.formData) {
      this.form = this.formUtilitiesService.getFormGroupFromData(this.formData);
    } else {
      console.warn(
        'Could not initialize form group, selector signal from store is undefined'
      );
    }
  }

  private focusFirstField(): void {
    if (this.formData) {
      const firstField = CollectionFormService.getFirstFocusableFieldFromForm(this.formData);
      if (firstField) {
        this.autofocusService.setElementFocus(this.formId, firstField.Id);
      }
    }
  }

  protected readonly environment = environment;
}