@defer(on viewport;) {
    @if(parentFilter && widgetData$ | async; as data) {
        <bizz-form-collection-list
        [data]="data" 
        [parentFilter]="parentFilter"
        [openLinksInStack]="openLinksInStack"
        [readonly]="readonly"
        [tabIndex]="readonly ? -1  : 0"
        >
        </bizz-form-collection-list>
    }
} @placeholder {
    <bizz-loader class="h-24 w-full bg-form/30"></bizz-loader>
}
