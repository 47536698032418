import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { BaseFormControlComponent } from '../../../classes/base-form-control.component';
import { FormFieldType } from 'src/models/ts/form-field-type.model';
import { CollectionFormField } from '../../../../../../../models/ts/collection-form-field.model';
import { TrnSkillGradeExpiracyStatus } from '../../../../../../../models/ts/trn-skill-grade-expiracy-status.model';
import { CheckBoxModule } from '@progress/kendo-angular-inputs';
import { ArcGaugeModule, CircularGaugeModule } from '@progress/kendo-angular-gauges';
import { FormTrainingappAssessmentSliderComponent } from '../../form-trainingapp-assessment/form-trainingapp-assessment-slider/form-trainingapp-assessment-slider.component';
import { FormsModule } from '@angular/forms';
import { GridModule, HeaderModule, SharedModule } from '@progress/kendo-angular-grid';
import { IconComponent } from '../../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../../shared/pipes/translate/translate.pipe';
import { GridComponent } from '@progress/kendo-angular-grid/grid.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { asyncScheduler, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs';
import { TrnSkillGradeDto } from '../../../../../../../models/ts/trn-skill-grade-dto.model';
import { UserType } from '../../../../../../../models/ts/user-type.model';
import { AuthService } from '../../../../../../core/services/auth/auth.service';
import { TrnFieldProperties } from '../../../../../../../models/ts/trn-field-properties-dto.model';
import { TrainingSubscribedUserDeleteDialogComponent } from './training-subscribed-user-delete-dialog/training-subscribed-user-delete-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import {
  selectForm,
} from '../../../../../../store/features/forms/forms-selectors';
import { ProtectedCollectionType } from '../../../../../../../models/ts/protected-collection-type.model';
import { UpdateDeleteState } from '../../../../../../../models/ts/update-delete-state.model';
import { TrainingApiService } from '../../../../../../api/bizzmine/training/training-api.service';
import { ExecutorUserType } from '../../../../../../../models/ts/executor-user-type.model';
import { OrgChartItem } from '../../../../org-chart/interfaces/org-chart-item';
import { OrgChartModalComponent } from '../../../../org-chart/org-chart-modal/org-chart-modal.component';
import { Actions, ofType } from '@ngrx/effects';
import { refreshActions } from '../../../../../../store/features/refresh/refresh-actions';
import { OrganizationChartItemType } from '../../../../../../../models/ts/organization-chart-item-type.model';
import { AdditionalModes } from '../../../../org-chart/org-chart.component';
import { DialogModalModelMode } from '../../../../../../shared/components/modals/dialog-modal/dialog-modal-model';
import { DialogModalComponent } from '../../../../../../shared/components/modals/dialog-modal/dialog-modal.component';

@Component({
  selector: 'bizz-training-subscribed-users-control',
  standalone: true,
  imports: [
    CheckBoxModule,
    CircularGaugeModule,
    FormTrainingappAssessmentSliderComponent,
    FormsModule,
    GridModule,
    HeaderModule,
    IconComponent,
    SharedModule,
    TranslatePipe,
    ArcGaugeModule
  ],
  templateUrl: './training-subscribed-users-control.component.html',
  styleUrl: './training-subscribed-users-control.component.scss'
})
export class TrainingSubscribedUsersControlComponent extends BaseFormControlComponent implements OnInit, AfterViewInit {
  public FormFieldType = FormFieldType;
  public data: CollectionFormField;
  public colors = [
    {
      from: 0,
      color: '#3fad10'
    }
  ];
  public UserType = UserType;
  protected readonly ExpiracyStatus = TrnSkillGradeExpiracyStatus;
  @ViewChild('trainingSubscribedUsersKendoGrid', { read: ElementRef })
  private kendoGridElement: ElementRef;
  @ViewChild('trainingSubscribedUsersKendoGrid')
  private kendoGridComponent: GridComponent;
  private totalGridWidth: number;
  private combinedWidthOfOtherColumns: number;
  private initialLastColumnWidth: number;

  public constructor(private authService: AuthService, private dialog: Dialog, private trainingApiService: TrainingApiService, private actions$: Actions) {
    super();
    this.actions$.pipe(
      ofType(refreshActions.refreshSkillGrades),
      distinctUntilChanged(),
      filter(props => props?.trainingAppId != null && props.trainingAppId > 0),
      tap(props => {
        this.data.TrainingAppsID = props.trainingAppId;
      }),
      switchMap(props => trainingApiService.getSkillGrades({trainingAppId: props.trainingAppId})),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((skillgrades) => {
      this.data.TrnSkillGrades = skillgrades;
    });
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    const field = this.formFieldSignal();
    if (field) {
      this.data = structuredClone(field);

      this.formControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.data = structuredClone(this.formControl.value);
      });
    }
  }

  public ngAfterViewInit(): void {
    asyncScheduler.schedule(() => {
      this.kendoGridComponent.columnResize.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.resizeLastGridColumn();
      });
      this.resizeLastGridColumn();
    });
  }

  public delete(trainee: TrnFieldProperties): void {
    this.dialog.open<boolean | undefined>(TrainingSubscribedUserDeleteDialogComponent, {
      data: { trainee }
    })
      .closed.subscribe((result) => {
      if (result != undefined) {
        if (result) {
          this.data.TrnDeletedUsers = this.data.TrnDeletedUsers ?? [];
          this.data.TrnDeletedUsers.push(trainee);
        }
        this.data.TrnUsers = this.data.TrnUsers.filter(x => x.UsersID != trainee.UsersID);
        this.emitChanged();
      }
    });
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.resizeLastGridColumn();
  }

  public getScore(skillGradeId: number, defaultValue: boolean): TrnSkillGradeDto | null {
    if (!this.data || !this.data.TrnSkillGrades || this.data.TrnSkillGrades.length == 0) {
      return null;
    }
    const found = this.data.TrnSkillGrades.find(x => x.ID == skillGradeId);
    if (found) {
      return found;
    }
    if (defaultValue) {
      return this.data.TrnSkillGrades[0];
    }
    return null;
  }

  public addUser(): void {
    let hasTraining = false;
    const form = this.store$.selectSignal(selectForm(this.formId))();
    const viewDataSources = form?.data?.ViewDataSources;
    let training: number | undefined = undefined;
    if (viewDataSources) {
      const trainingInstances = viewDataSources.find(x => x.ProtectedCollectionType == ProtectedCollectionType.LinkTrainingTrainingsessions)?.Instances;

      if (trainingInstances == null || trainingInstances.length == 0) {
        hasTraining = false;
      } else {
        const trainingInstance = trainingInstances.find(el => el.State != UpdateDeleteState.Delete);

        if (trainingInstance == null) {
          hasTraining = false;
        } else {
          hasTraining = true;
          training = trainingInstance.ChildVersionsID;
        }
      }

      if (hasTraining) {
        this.dialog.open<Array<OrgChartItem>>(OrgChartModalComponent, { data: {allowedItemTypes: [OrganizationChartItemType.User], additionalModes: [{additionMode: AdditionalModes.TRAINING_QUEUE, data: {trainingId: training!, trainingAppId: this.data.TrainingAppsID}}]} })
          .closed.subscribe((result) => {
          if (result && training) {
            result.forEach(user => {
              this.trainingApiService.getTrainee({
                userId: user.Id,
                trainingAppId: this.data.TrainingAppsID,
                skillVersionId: training!
              }).subscribe(found => {
                this.data.TrnUsers.push({
                  UsersID: user.Id,
                  Expiracy: found?.Expiracy,
                  Grade: found?.SkillGradeID,
                  Name: user?.Name,
                  Present: true,
                  Remarks: ''
                });

                this.data.OrgChartUnitSelector.push(
                  {
                    ForeignID: this.data.CollectionFieldsID,
                    GroupsID: this.data.Value,
                    ObjectID: user.Id,
                    ObjectType: user.ItemType,
                    text: user.Name,
                    ID: 0,
                    PermissionType: -1,
                    DataDesignCrossID: 0,
                    State: user.State,
                    ObjectState: 0,
                    SourceExecutorType: ExecutorUserType.API
                  }
                );
                this.emitChanged();
              });
            });
          }
        });

      } else {
        this.dialog.open(DialogModalComponent, {data: { mode: DialogModalModelMode.simple, title: 'NoTraining', message: 'NoTrainingFound', confirmationtext: '', showCancelButton: true}});

      }
    }
  }

  private emitChanged(): void {
    this.formControl.setValue(this.data);
  }

  private resizeLastGridColumn(): void {
    if (this.kendoGridElement == undefined) {
      throw new Error('kendoGridElement is undefined');
    }
    this.totalGridWidth = this.kendoGridElement.nativeElement.offsetWidth;
    this.combinedWidthOfOtherColumns = this.kendoGridComponent.columns.toArray().slice(0, -1).reduce(
      (accumulator, currentObject) => {
        return accumulator + currentObject.width;
      }, 0);
    if (this.kendoGridComponent.columns.last != undefined) {
      if (this.initialLastColumnWidth == undefined)
        this.initialLastColumnWidth = this.kendoGridComponent.columns.last.width + 10;

      if (this.totalGridWidth > this.combinedWidthOfOtherColumns)
        this.kendoGridComponent.columns.last.width =
          Math.max(this.totalGridWidth - this.combinedWidthOfOtherColumns, this.initialLastColumnWidth);
    }
  }
   // Implement the abstract member from TrainingSubscribedUsersControlComponent
   protected override focus(): void {
    // Add your implementation here
  }
}
