@if (taskInfo) {

    <div class="modal-container max-w-3xl">

        <div class="modal-close-container">
            <bizz-icon class="ml-auto block h-5 w-5 text-button md:ml-0"
                       (click)="close()"
                       iconName="xmark" [hover]="true">
            </bizz-icon>
        </div>

        <div class="modal-body gap-8 w-full">
            <div class="modal-header">
                <h1>{{ task.Subject }}</h1>
            </div>
            <hr class="w-5/6 mx-auto">
            @if (taskInfo.IsFallback) {
                <div class="mb-3 flex flex-wrap w-full items-center rounded bg-yellow-500/20 px-7 py-4 text-sm gap-4">
                    <bizz-icon class="size-6 text-yellow-500"
                               iconName="circle-info">
                    </bizz-icon>
                    <div>
                        @if (taskInfo.NumberOfOriginalAssignedTo == 0) {
                            <span>{{ "TaskOriginalAssignedToNobody" | translate }}</span>
                        }
                        @if (taskInfo.NumberOfOriginalAssignedTo >= 1) {
                            <span>{{ "TaskOriginalAssignedTo" | translate }} {{ taskInfo.OriginalAssignedTo }}</span>
                        }
                        <br/>
                        @if (taskInfo.NumberOfOriginalAssignedTo == 0) {
                            <span>{{ "TaskNobodyFallbackAssignedTo" | translate }}</span>
                        }
                        @if (taskInfo.NumberOfOriginalAssignedTo == 1) {
                            <span>{{ "TaskSingleFallbackAssignedTo" | translate }}</span>
                        }
                        @if (taskInfo.NumberOfOriginalAssignedTo > 1) {
                            <span>{{ "TaskMultipleFallbackAssignedTo" | translate }}</span>
                        }
                    </div>
                </div>
            }

            @if (errors && errors.Errors && errors.Errors.length > 0) {
                <div class="mb-3 flex flex-wrap w-full items-center rounded bg-red-500/20 px-7 py-4 text-sm gap-4">
                    <bizz-icon class="size-6 text-red-400"
                               iconName="circle-exclamation">
                    </bizz-icon>
                    <div class="flex flex-col">
                        @for (error of errors.Errors; track $index) {
                            <span>{{ error.Message }}</span>
                        }
                    </div>
                </div>
            }
            <div class="flex flex-wrap text-sm" [innerHtml]="task.Body | safe: 'html'">
            </div>
            <hr class="w-5/6 mx-auto">
            <div class="flex gap-4">
                <div class="flex-1 gap-4">
                    <div class="flex flex-col gap-4">
                        <div class="text-blue-500 text-lg font-bold">{{ "DocumentRevision" | translate }}</div>
                        @if (taskInfo.CanStartMinorRevision && taskInfo.TaskType == taskType.RevisionFlow) {
                            <button class="default w-fit" (click)="minor()">
                                <span class="text"><span
                                        class="mr-2">m</span> {{ "CreateMinorChanges" | translate }}</span>
                            </button>
                        }
                        @if (taskInfo.CanStartMajorRevision && taskInfo.TaskType == taskType.RevisionFlow) {
                            <button class="default w-fit" (click)="major()">
                                <span class="text"><span
                                        class="mr-2">M</span> {{ "CreateMajorChanges" | translate }}</span>
                            </button>
                        }
                        @if (taskInfo.CanRestartWorkflow && taskInfo.TaskType == taskType.RevisionTaskRestart) {
                            <button class="default w-fit" [disabled]="disableRestart">
                            <span class="text"><bizz-icon iconName="chart-line" (click)="restart()"
                                                          class="size-3 mr-2"></bizz-icon>
                                {{ "RestartWorkflow" | translate }}</span>
                            </button>
                        }
                    </div>
                </div>
                @if (task.CanbePostPoned) {
                    <div class="flex-1 gap-4">
                        <div class="flex flex-col gap-4">
                            <div class="text-blue-500 text-lg font-bold">{{ "PostponeTask" | translate }}</div>
                            <span class="text-sm">{{ "ToDate" | translate }}</span>
                            <kendo-datetimepicker
                                    [(ngModel)]="postPoneDateTime"
                                    [format]="'dd/MM/yyyy HH:mm'"
                                    [adaptiveMode]="'auto'">
                            </kendo-datetimepicker>
                            <button class="default w-fit" (click)="postPone()">
                                <span class="text">{{ "Postpone" | translate }}</span>
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>


        <div class="modal-buttons">
            <div class="flex flex-row justify-end gap-4 text-center">
                <button class="save" (click)="details()">
                    <bizz-icon iconName="eye" class="size-4 mr-2"></bizz-icon>
                    {{ "ViewDetails" | translate }}
                </button>
            </div>

        </div>
    </div>
}