import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepType } from '../../../../../../models/ts/step-type.model';
import { TaskCompletedType } from '../../../../../../models/ts/task-completed-type.model';
import { AssessmentType } from '../../../../../../models/ts/assessment-type.model';
import { IconComponent } from '../../../ui/icon/icon.component';
import { TimePipe } from '../../../../pipes/dates/time.pipe';
import { TranslatePipe } from '../../../../pipes/translate/translate.pipe';
import { UtcDatePipe } from '../../../../pipes/dates/utc-date.pipe';
import { MonitorStepDto } from '../../../../../../models/ts/monitor-step-dto.model';
import { MonitorTaskDto } from '../../../../../../models/ts/monitor-task-dto.model';

@Component({
  selector: 'bizz-monitor-step',
  standalone: true,
  imports: [CommonModule, IconComponent, TimePipe, TranslatePipe, UtcDatePipe],
  templateUrl: './monitor-step.component.html',
  styleUrls: ['./monitor-step.component.scss'],
})
export class MonitorStepComponent {
  protected readonly StepType = StepType;
  protected readonly TaskCompletedType = TaskCompletedType;
  protected readonly AssessmentType = AssessmentType;

  @Input({ required: true }) public step: UIMonitorStep;
  @Input({ required: true }) public isLastStep: boolean;

  protected isTextCollapsable(elementId: string): boolean {
    const elem = document.getElementById(elementId);
    if (elem) {
      //Always show icon if text was truncated.
      if (elem.classList.contains('truncate')) {
        return true;
      }
      if (document.defaultView != undefined) {
        const lineHeight = parseFloat(
          document.defaultView
            .getComputedStyle(elem, null)
            ?.getPropertyValue('line-height'),
        );
        return lineHeight < elem.offsetHeight;
      }
      return false;
    }
    return true;
  }
}

export interface UIMonitorStep extends MonitorStepDto {
  collapsed: boolean;
  Tasks: UIMonitorTask[];
}

export interface UIMonitorTask extends MonitorTaskDto {
  collapsed: boolean;
  reasonCollapsed: boolean;
}
