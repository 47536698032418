@if (formFieldSignal(); as formField) {
    @if(errorState?.Message; as errorMessage){
            <bizz-tooltip class="absolute -top-6 right-4 z-[2] h-full cursor-pointer text-red-400" [text]="errorMessage"></bizz-tooltip>
    }
<kendo-editor #editor class="min-w-full max-w-full" [value]="formControl.value"
    (valueChange)="setValue($event)" [ngClass]="{'hidden' : formField.IsHidden}" [style]="{'min-height': formField.Height + 'px'}" [id]="'tabID' + formField.Bookmark"
    [readonly]="readOnlyOverride || formField.IsReadOnly" tabIndex="-1" [resizable]="true"
              [class.error]="errorState != null"
              [iframeCss]="{ content: embeddedCss }">
    <kendo-toolbar [ngClass]="{'hidden' : !formField.ShowHTMLEditor || readOnlyOverride || formField.IsReadOnly, 'mobile': isMobileView}" 
        [overflow]="isInGrid">
        @if (formField.ShowHTMLEditor && !readOnlyOverride && !formField.IsReadOnly) {
            @if(!isMobileView){
            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            <kendo-toolbar-colorpicker kendoEditorForeColor view="gradient"></kendo-toolbar-colorpicker>
            <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
            <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
            } @else {
            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
            <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
            <kendo-toolbar-spacer></kendo-toolbar-spacer>
            <kendo-toolbar-dropdownbutton [svgIcon]="chevronDownIcon" [data]="data"></kendo-toolbar-dropdownbutton>
            <kendo-toolbar-colorpicker #foreColorPicker kendoEditorForeColor view="gradient"></kendo-toolbar-colorpicker>
            <kendo-toolbar-colorpicker #backColorPicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
            }

        }
    </kendo-toolbar>
</kendo-editor>

}