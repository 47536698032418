import {
  AlphaNumericControlComponent,
} from '../components/controls/alpha-numeric-control/alpha-numeric-control.component';
import { CanvasControlComponent } from '../components/controls/canvas-control/canvas-control.component';
import { CheckboxControlComponent } from '../components/controls/checkbox-control/checkbox-control.component';
import { ComboboxControlComponent } from '../components/controls/combobox-control/combobox-control.component';
import {
  ControlledDocumentControlComponent,
} from '../components/controls/controlled-document-control/controlled-document-control.component';
import { DatePickerControlComponent } from '../components/controls/date-picker-control/date-picker-control.component';
import {
  DateTimePickerControlComponent,
} from '../components/controls/date-time-picker-control/date-time-picker-control.component';
import { EmailControlComponent } from '../components/controls/email-control/email-control.component';
import { HyperlinkControlComponent } from '../components/controls/hyperlink-control/hyperlink-control.component';
import {
  MacroButtonControlComponent,
} from '../components/controls/macro-button-control/macro-button-control.component';
import { MemoControlComponent } from '../components/controls/memo-control/memo-control.component';
import { NumericControlComponent } from '../components/controls/numeric-control/numeric-control.component';
import { RadioGroupControlComponent } from '../components/controls/radio-group-control/radio-group-control.component';
import { TimePickerControlComponent } from '../components/controls/time-picker-control/time-picker-control.component';
import {
  OrganizationChartControlComponent,
} from '../components/controls/organization-chart-control/organization-chart-control.component';
import { Type } from '@angular/core';
import { FormListControlComponent } from '../components/controls/form-list-control/form-list-control.component';
import { EnumListControlComponent } from '../components/controls/enum-list-control/enum-list-control.component';
import { DynamicFormControlComponent } from '../../../../shared/interfaces/dynamic-form-control-component';
import { TableFieldDataType } from '../../../../../models/ts/table-field-data-type.model';
import { ExamScoreEvalutationControlComponent } from '../components/controls/exam-score-evalutation-control/exam-score-evalutation-control/exam-score-evalutation-control.component';
import { ExamCreatorControlComponent } from '../components/controls/exam-creator-control/exam-creator-control/exam-creator-control.component';
import { TrainingSubscribedUsersControlComponent } from '../components/controls/training-subscribed-users-control/training-subscribed-users-control.component';

// TODO: complete this list
/**
 * This constant provides a mapping between the TableFieldDataType and the corresponding component.
 */
export const TABLE_FIELD_DATA_TYPE_COMPONENTS: { [dataType: number]: Type<DynamicFormControlComponent> } = {
  [TableFieldDataType.AlphaNumeric]: AlphaNumericControlComponent,
  [TableFieldDataType.Numeric]: NumericControlComponent,
  [TableFieldDataType.Email]: EmailControlComponent,
  [TableFieldDataType.DatePicker]: DatePickerControlComponent,
  [TableFieldDataType.TimePicker]: TimePickerControlComponent,
  [TableFieldDataType.DateTimePicker]: DateTimePickerControlComponent,
  [TableFieldDataType.Checkbox]: CheckboxControlComponent,
  [TableFieldDataType.RadioGroup]: RadioGroupControlComponent,
  [TableFieldDataType.Memo]: MemoControlComponent,
  [TableFieldDataType.Combobox]: ComboboxControlComponent,
  [TableFieldDataType.OrganizationChartUnitSelector]: OrganizationChartControlComponent,
  [TableFieldDataType.ControlledDocument]: ControlledDocumentControlComponent,
  [TableFieldDataType.HyperLink]: HyperlinkControlComponent,
  [TableFieldDataType.MacroButton]: MacroButtonControlComponent,
  //[TableFieldDataType.PdfFile]: AlphaNumericControlComponent,
  [TableFieldDataType.Canvas]: CanvasControlComponent,
  //[TableFieldDataType.OrganizationChartUnitSelectorForTrnApp]: OrganizationChartControlComponent,
  [TableFieldDataType.ExamCreator]: ExamCreatorControlComponent,
  [TableFieldDataType.ExamScoreEvaluations] : ExamScoreEvalutationControlComponent,
  //[TableFieldDataType.MailingList] : AlphaNumericControlComponent,
  [TableFieldDataType.EnumList]: EnumListControlComponent,
  //[TableFieldDataType.TextValue]: AlphaNumericControlComponent,
  [TableFieldDataType.SimpleList]: FormListControlComponent,
  [TableFieldDataType.OrganizationChartUnitSelectorForTrnApp]: TrainingSubscribedUsersControlComponent


}