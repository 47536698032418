import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { NgIf } from '@angular/common';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { DialogModalButtons } from 'src/app/shared/enums/dialog-modal-buttons';
import { TableViewWidgetData } from '../../../../../../models/ts/table-view-widget-data.model';
import { FilterComponent } from '../../../../../shared/components/ui/filter-component/filter.component';
import { GridComponent } from '../../../../../shared/components/grid/grid.component';
import { ListDesignGridOptionsDto } from '../../../../../../models/ts/list-design-grid-options-dto.model';
import { LinkedCollectionType } from 'src/models/ts/linked-collection-type.model';
import { ModalComponent } from '../../../../../shared/components/modals/modal/modal.component';
import { WidgetsType } from 'src/models/ts/widgets-type.model';
import { CollectionType } from 'src/models/ts/collection-type.model';
import { CollectionListDataInstance } from '../../../../../shared/interfaces/collection-list-data-instance';
import { RowArgs } from '@progress/kendo-angular-grid/rendering/common/row-args';
import { LookupData } from 'src/app/shared/services/lookup/lookup.service';
import { InstanceSelectionListComponent } from './instance-selection-list/instance-selection-list/instance-selection-list.component';

@Component({
  selector: 'bizz-lookup-search-modal',
  templateUrl: './lookup-search-modal.component.html',
  styleUrls: ['./lookup-search-modal.component.scss'],
  standalone: true,
  imports: [
    IconComponent,
    NgIf,
    TranslatePipe,
    FilterComponent,
    GridComponent,
    ModalComponent,
    InstanceSelectionListComponent
  ]
})
export class LookupSearchModalComponent implements OnInit {
  private selection: CollectionListDataInstance[] = [];
  protected readonly DialogModalButtons = DialogModalButtons;
  public widgetData: TableViewWidgetData;

  public constructor(
    public dialogRef: DialogRef<CollectionListDataInstance[]>,
    @Inject(DIALOG_DATA) public dialogModel: LookupModalData) {
    this.dialogRef.disableClose = true;
  }

  public ngOnInit(): void {
    //Backend doesn't always return the correct options here.
    this.dialogModel.listOptions.GridOptions.selectable = {
      enabled: true,
      checkboxOnly: true,
      mode: this.dialogModel.isGridField ? 'multiple' : 'single',
      cell: false,
      drag: false
    };
    this.widgetData = {
      ID: 0,
      ShowCaption: false,
      Caption: '',
      CssClass: '',
      WidgetsType: WidgetsType.ListOfCollectionItems,
      CollectionsID: 0,
      CollectionsType: CollectionType.Workflow,
      ListOptions: this.dialogModel.listOptions,
      FilterList: [],
      CanExport: false,
      CanAccessTrashCan: false,
      CanCreate: this.dialogModel.lookupData.linkedLevel === 1,
      CanSetPermissions: false,
      DisableCreateNewInstances: false
    };
    if(!this.dialogModel.listOptions.ViewID)
      this.dialogModel.listOptions.ViewID = this.dialogModel.lookupData.viewsId;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onSelectionChange(newSelection: any[]): void {
    this.selection = newSelection;
  }

  public close(): void {
    this.dialogRef.close(this.selection);
  }

  public isRowSelected(e: RowArgs): boolean {
    const row = e.dataItem as CollectionListDataInstance;
    if (this.selection)
      return this.selection.some((selectedRow) => selectedRow.ID === row.ID);
    else return false;
  }
}

// old data -> { listId: number, filter: GridFilterDto, options: ListDesignGridOptionsDto }
export interface LookupModalData {
  listId: number;
  lookupData: LookupData,
  singleOrMany: LinkedCollectionType,
  canViewHiddenDocuments: boolean,
  listOptions: ListDesignGridOptionsDto,
  formFieldId?: number,
  isGridField: boolean,
  formFieldSourceCollectionId?: number,
  canCreate: boolean
}