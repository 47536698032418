import { Component, DestroyRef, inject, OnDestroy } from '@angular/core';
import { BehaviorSubject, catchError, combineLatestWith, map, shareReplay, switchMap, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BizzMineSessionStorageService } from '../../../../shared/services/localStorage/bizzmine-session-storage.service';
import { AsyncPipe } from '@angular/common';
import { ViewStackSkeletonLoaderComponent } from '../../../../features/bizzmine/form/components/view-stack-skeleton-loader/view-stack-skeleton-loader.component';
import { ExternalCompletedComponent } from '../external-completed/external-completed.component';
import { HeaderComponent } from '../../../../shared/components/ui/header/header.component';
import { ViewStackComponent } from '../../../../shared/components/ui/view-stack/view-stack.component';
import { IconComponent } from '../../../../shared/components/ui/icon/icon.component';
import { ViewStackSkeletonElementComponent } from '../../../../features/bizzmine/form/components/view-stack-skeleton-loader/view-stack-skeleton-element/view-stack-skeleton-element.component';
import { ActivatedRoute, Router, RouterLinkActive } from '@angular/router';
import { UpdateDeleteState } from '../../../../../models/ts/update-delete-state.model';
import { IconDto } from '../../../../../models/ts/icon-dto.model';
import { Store } from '@ngrx/store';
import { TranslationService } from '../../../../core/services/translation/translation.service';
import { LanguageDto } from '../../../../../models/ts/language-dto.model';
import { ThemeDto } from '../../../../../models/ts/theme-dto.model';
import { ThemeService } from '../../../../core/services/theme/theme.service';
import { userSettingsActions } from '../../../../store/features/user-settings/user-settings-actions';
import { userSettingsFeature } from '../../../../store/features/user-settings/user-settings-feature';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoaderComponent } from '../../../../shared/components/ui/loader/loader.component';

@Component({
  selector: 'bizz-anonymous-portal',
  standalone: true,
  imports: [
    AsyncPipe,
    ViewStackSkeletonLoaderComponent,
    ExternalCompletedComponent,
    HeaderComponent,
    ViewStackComponent,
    IconComponent,
    ViewStackSkeletonElementComponent,
    RouterLinkActive,
    LoaderComponent
  ],
  templateUrl: './anonymous-portal.component.html',
  styleUrl: './anonymous-portal.component.scss'
})
export class AnonymousPortalComponent implements OnDestroy {
  public readonly WidgetAnonymousRegistrationButtonSize = WidgetAnonymousRegistrationButtonSize;
  public readonly WidgetAnonymousRegistrationButtonHeight = WidgetAnonymousRegistrationButtonHeight;
  public languages: Array<LanguageDto> = [];
  public selectedLanguage: number | undefined;
  private httpClient = inject(HttpClient);
  private store = inject(Store);
  private destroyRef = inject(DestroyRef);
  private sessionStorage = inject(BizzMineSessionStorageService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private themeService = inject(ThemeService);
  private translationService = inject(TranslationService);
  private caption: string;
  private guid: string;
  private refreshButtons$ = new BehaviorSubject<void>(undefined);
  public loading = true;
  public forms$ = this.activatedRoute.params.pipe(combineLatestWith(this.translationService.getLanguageOptions()), tap(([params, langOptions]) => {
    this.loading = true;
    this.caption = params['caption'];
    this.guid = params['guid'];
    this.languages = langOptions;
    const languageCode = params['languageCode'];
    if (languageCode != undefined && this.languages != undefined) {
      this.selectedLanguage = this.languages.find(l => l.Code.toLowerCase() == languageCode.toLowerCase())?.ID;
    } else {
      this.selectedLanguage = this.languages.find(l => l.IsSystemLanguage)?.ID ?? this.languages?.[0]?.ID;
    }

    if (this.selectedLanguage != null && this.selectedLanguage > 0) {
      this.store.dispatch(userSettingsActions.setAnonymousPortalLanguage({ language: this.selectedLanguage }));
    }
  }), switchMap(([params]) => {
    return this.httpClient.get<{
      Buttons: Array<WidgetAnonymousRegistrationButton>,
      Theme: ThemeDto | undefined
    }>(`api/anonregs/` + params['caption'] + '/' + this.selectedLanguage + '/' + params['guid'], {
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'x-tenant': window.location.host
      }
    });
  }))
    .pipe(catchError((error) => {
      this.loading = false;
      throw error;
    }), map(found => {
      if (found?.Theme != undefined) {
        this.changeTheme(found.Theme);
      }
      const result = found?.Buttons?.sort((n1, n2) => (n1.Order ?? 99) - (n2.Order ?? 99));
      return result;
    }),tap(() => this.loading = false), shareReplay(1));

  public constructor(private actions: Actions) {
    this.actions.pipe(ofType(userSettingsActions.setAnonymousPortalLanguage)).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      if (data != undefined) {
        if (data.language != undefined && this.selectedLanguage !== data.language) {
          const langCode = this.languages.find(l => l.ID == data.language)?.Code;
          if(langCode != undefined) {
            this.router.navigate([`${langCode}/Account/Forms/${this.caption}/${this.guid}`]).then(() => this.refreshButtons$.next());
          }
        }
      }
    });
  }

  public changeTheme(theme: ThemeDto): void {
    if (theme) {
      this.themeService.applyCssThemeAndBranding(theme);
      this.store.dispatch(userSettingsActions.setAnonymousPortalTheme({ theme: theme.ID }));
    }
  }

  public openForm(form: { Token: string }): void {
    this.router.navigate([`Account/Form`], {
      queryParams: {
        token: form.Token,
        theme: this.store.selectSignal(userSettingsFeature.selectThemesID)(),
        //language: this.selectedLanguage,
        returnUrl: this.router.url
      }
    });
  }

  public ngOnDestroy(): void {
    this.sessionStorage.clear();
  }

  public translate(value: any, property: string): string {
    const lang = this.selectedLanguage != undefined && this.selectedLanguage > 0 ? this.languages?.find(l => l.ID == this.selectedLanguage) : this.languages?.find(l => l.IsSystemLanguage);
    if (lang == null) {
      return value[property] as string;
    }
    const translations = value.TranslationInfo?.Languages?.find((l: {
      LanguageID: number | undefined;
    }) => l.LanguageID == this.selectedLanguage);
    if (translations == null) {
      return value[property] as string;
    }

    return translations.TranslationFieldValues?.find((tfv: {
      Caption: string;
    }) => tfv.Caption == property)?.Value ?? value[property] as string;
  }
}


export interface WidgetAnonymousRegistrationButton {
  ID: number;
  WidgetsID: number;
  CollectionsID: number | null;
  CollectionFormsID: number | null;
  Disabled: boolean;
  Name: string | null;
  Token: string;
  Width: WidgetAnonymousRegistrationButtonSize | null;
  Height: WidgetAnonymousRegistrationButtonHeight | null;
  Order: number | null;
  Qr: string | null;
  State: UpdateDeleteState;
  Icon: IconDto | null;
}

export enum WidgetAnonymousRegistrationButtonSize {
  small = 0,
  large = 1
}

export enum WidgetAnonymousRegistrationButtonHeight {
  regular = 0,
  heigh = 1
}
