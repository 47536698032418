import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { FlowStatusTasksGridComponent } from './flow-status-tasks-grid/flow-status-tasks-grid.component';
import { FlowStatusTaskType } from '../../interfaces/flow-status-task-type';
import { Dialog } from '@angular/cdk/dialog';
import { FlowStatusTaskDetailsModalComponent } from '../modals/flow-status-task-details-modal/flow-status-task-details-modal.component';
import { FlowStatusTaskDetailsModel } from '../modals/flow-status-task-details-modal/flow-status-task-details-model';
import { FlowStatusTaskAssessmentModalComponent } from '../modals/flow-status-task-assessment-modal/flow-status-task-assessment-modal.component';
import { FlowStatusTaskAssessmentModel } from '../modals/flow-status-task-assessment-modal/flow-status-task-assessment-model';
import { ScrollbarComponent } from '../../../../../shared/components/ui/scrollbar/scrollbar.component';
import { TranslatePipe } from '../../../../../shared/pipes/translate/translate.pipe';
import { StatusIconComponent } from '../../../../../shared/components/ui/status-icon/status-icon.component';
import { PopperComponent } from '../../../../../shared/components/ui/popper/popper.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { IconComponent } from '../../../../../shared/components/ui/icon/icon.component';
import { GridComponent } from '../../../../../shared/components/grid/grid.component';
import { FlowStatusTaskDto } from '../../../../../../models/ts/flow-status-task-dto.model';
import { CollectionType } from '../../../../../../models/ts/collection-type.model';
import { CollectionFlowStatusApiService } from '../../../../../api/bizzmine/collection-flow-status/collection-flow-status-api.service';
import { FlowStatusTaskService } from '../../services/flow-status-task.service';
import * as Sentry from '@sentry/angular';
import { take } from 'rxjs';

@Component({
  selector: 'bizz-flow-status-tasks',
  standalone: true,
  imports: [CommonModule, TranslatePipe, ScrollbarComponent, StatusIconComponent, PopperComponent, NgScrollbarModule, IconComponent, GridComponent, GridModule, FlowStatusTasksGridComponent],
  templateUrl: './flow-status-tasks.component.html',
  styleUrls: ['./flow-status-tasks.component.scss']
})
@Sentry.TraceClass({ name: 'FlowStatusTasksComponent' })
export class FlowStatusTasksComponent implements OnInit, OnDestroy {

  @Input({ required: true }) public tasks: FlowStatusTaskDto[];
  public pendingTasks: FlowStatusTaskDto[];
  public completedTasks: FlowStatusTaskDto[];

  public constructor(private dialog: Dialog, private collectionFlowStatusApiService: CollectionFlowStatusApiService) {

  }

  @Sentry.TraceMethod({ name: 'ngOnInit' })
  public ngOnInit(): void {
    this.pendingTasks = FlowStatusTaskService.filterPendingTasks(this.tasks);
    this.completedTasks = FlowStatusTaskService.filterCompletedTasks(this.tasks);
  }

  @Sentry.TraceMethod({ name: 'ngOnDestroy' })
  public ngOnDestroy(): void {
  }

  public showAssessmentDetails(task: FlowStatusTaskDto): void {
    this.collectionFlowStatusApiService.getFlowStatusTaskAssessment(task.TasksID).pipe(take(1))
      .subscribe((tasks) => {
        this.dialog.open<FlowStatusTaskAssessmentModel>(FlowStatusTaskAssessmentModalComponent, {
          data: {
            tasks: tasks
          }
        });
      });
  }

  public taskDetails(task: FlowStatusTaskDto): void {
    this.collectionFlowStatusApiService.getFlowStatusTaskDetails(task.TasksID).subscribe((tasks) => {
      this.dialog.open<FlowStatusTaskDetailsModel>(FlowStatusTaskDetailsModalComponent, {
        data: {
          tasks: tasks
        }
      });
    });
  }

  protected readonly CollectionType = CollectionType;
  protected readonly FlowStatusTaskType = FlowStatusTaskType;
}
