import { AfterViewInit, Component, EventEmitter, HostListener, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OrgChartModalComponent } from '../org-chart-modal/org-chart-modal.component';
import { Dialog } from '@angular/cdk/dialog';
import { OrgChartItem } from '../interfaces/org-chart-item';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { IconComponent } from '../../../../shared/components/ui/icon/icon.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OrganizationChartApiService } from '../../../../api/bizzmine/organization-chart/organization-chart-api.service';
import { OrganizationChartItemType } from '../../../../../models/ts/organization-chart-item-type.model';
import { debounceTime, distinctUntilChanged, exhaustMap, of, Subject, take } from 'rxjs';
import { JsonPipe } from '@angular/common';
import { UpdateDeleteState } from 'src/models/ts/update-delete-state.model';
import { CollectionFormValidatorError } from '../../form/classes/collection-form-validator-error';
import { TooltipComponent } from '../../../../shared/components/ui/tooltip/tooltip.component';

@Component({
  selector: 'bizz-org-chart-select',
  standalone: true,
  imports: [
    MultiSelectModule,
    IconComponent,
    ReactiveFormsModule,
    JsonPipe,
    TooltipComponent
  ],
  templateUrl: './org-chart-select.component.html',
  styleUrl: './org-chart-select.component.scss'
})
export class OrgChartSelectComponent implements OnInit, AfterViewInit {

  @Input() public errorState: CollectionFormValidatorError | undefined;
  @Input() public readonly = false;
  @Input() public allowedItemTypes: Array<OrganizationChartItemType> = [OrganizationChartItemType.User, OrganizationChartItemType.CurrentUser, OrganizationChartItemType.Department, OrganizationChartItemType.Function];
  @Input({ required: true }) public selection: Array<OrgChartItem> = [];
  @Output() public selectionChanged = new EventEmitter<Array<OrgChartItem>>();
  @Output() public selectionWithAllStatesChanged = new EventEmitter<Array<OrgChartItem>>();
  @Input() public min: number;
  @Input() public max: number;
  @Input() public excludedUserIds: Array<number> = [];
  public selectionWithAllStates: Array<OrgChartItem> = [];


  public lookupResults: Array<OrgChartItem> = [];
  public lookup$ = new Subject<string>();
  private dialog = inject(Dialog);
  private orgChartApiService = inject(OrganizationChartApiService);
  public isFocused: boolean = false;
  public inputId: any = 'multiselect_' + crypto.randomUUID();
  public kendoElement: HTMLElement;
  public constructor() {
    this.lookup$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      exhaustMap(search => {
        if (search.length > 0)
          return this.orgChartApiService.searchOrgChart(search, this.allowedItemTypes, this.excludedUserIds).pipe(take(1));
        else
          return of([]);
      }
      )
    ).subscribe({
      next: result => {
        this.lookupResults = result;
      },
      error: error => {
        this.lookupResults = [];
      }
    });

  }

  protected readonly OrganizationChartItemType = OrganizationChartItemType;

  public ngOnInit(): void {
    this.selectionWithAllStates = structuredClone(this.selection);

  }
  public ngAfterViewInit(): void {
    this.kendoElement = document.getElementById(this.inputId) as HTMLElement;

  }

  public selectionChange($event: OrgChartItem[]): void {
    this.selection = structuredClone($event);
    //TODO: IF NEW item of selection PUSH IN selectionWithAllStates
    this.selection.forEach(item => {
      if (this.selectionWithAllStates.find(s => s.UniqueID === item.UniqueID) == null) {
        item = structuredClone(item);
        item.UpdateState = UpdateDeleteState.Update;
        this.selectionWithAllStates.push(item);
      }
    });

    //Set removed items to delete
    this.selectionWithAllStates = this.selectionWithAllStates.map(item => {
      if (this.selection.find(s => s.UniqueID === item.UniqueID) == null) {
        item.UpdateState = UpdateDeleteState.Delete;
      } else {
        item.UpdateState = UpdateDeleteState.Update;
      }
      return item;
    })
    this.selectionWithAllStatesChanged.emit(this.selectionWithAllStates);
    this.selectionChanged.emit(this.selection);

    //Warn if more than the max allowed items are selected.
    if ($event.length) {
      this.errorState = {
        Message: ''
      };
      if (this.max > 0 && $event && $event.length > this.max) {
        this.errorState.Message = "Provide a value equal or lower than " + this.max
      } else if (this.min > 0 && $event && $event.length < this.min) {
        this.errorState.Message = "Provide a value equal or higher than " + this.min
      } else {
        this.errorState = undefined;
      }
    }
  }
  public openOrgChart(): void {
    const dialogRef = this.dialog.open<Array<OrgChartItem>>(OrgChartModalComponent, { data: { items: this.selection, min: this.min, max: this.max, allowedItemTypes: this.allowedItemTypes, excludedUserIds: this.excludedUserIds } });

    dialogRef.closed.pipe(take(1)).subscribe({
      next: (result) => {
        if (result) {
          this.selectionChange(result);
        } else {
          this.selectionChanged.emit(result);
        }
      }
    });
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if(!this.readonly){
      if (!this.kendoElement.contains(event.target as Node)) {
        this.isFocused = false;
      } else {
        this.isFocused = true;
        setTimeout(() => {
          this.kendoElement.getElementsByTagName('input')[0].focus();
        }, 1);
        if (event) event.stopImmediatePropagation();
      }
    }
  }

}
