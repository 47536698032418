<div class="rounded bg-form text-default shadow-md">
    <bizz-form-accordion-header [canCollapse]="false" accentClass="bg-yellow-700">
        <bizz-icon class="size-4 text-yellow-600 sm:h-4 sm:w-4" iconName="lock-keyhole"></bizz-icon>
        <p
                class="font-base flex flex-wrap gap-1 font-title text-lg capitalize text-yellow-800 sm:text-base">
            @if (userType() != UserType.AnonymousUser && userType() != UserType.ExternalUser) {
                <span>
                    {{ "LockedBy" | translate }}
                  </span>
                            <span class="font-semibold">{{ formData.LockedByUser }}</span>
                            <span>
                    {{ "OnLowercase" | translate }}
                  </span>
                <span class="font-semibold">{{ formData.LockedOn | date }}</span>
            } @else {
                <span>
                    {{ "TaskLocked" | translate }}
                  </span>
            }
        </p>
    </bizz-form-accordion-header>
    <div class="flex flex-col items-start gap-4 rounded-b p-4 text-yellow-700 sm:flex-row sm:px-5 sm:pb-3 sm:pt-1.5">
        <!-- Lock Information -->
        <ng-container [ngTemplateOutlet]="activeLockTemplateRef">
        </ng-container>

        <ng-template #managerLock>
            <p>{{ "Info_UnlockInstanceAsManager" | translate }}</p>
            <button class="ml-auto border-none bg-yellow-500 text-yellow-900 hover:bg-yellow-400"
                    (click)="unlock(true)">
                <bizz-icon class="size-4" iconName="unlock"></bizz-icon>
                <span>{{ "Unlock" | translate }}</span>
            </button>
        </ng-template>

        <ng-template #lock>
      <span>
        {{ "Info_UnlockInstance" | translate }}
          <!--  TODO: implement          -->
          <!--        <ng-bizz-collection-managers *ngIf="data.LockType != CollectionLockType.Selflock" collections-id="data.CollectionsID"></ng-bizz-collection-managers>-->
      </span>
        </ng-template>

        <ng-template #selfLock>
            <span>{{ (userType() == UserType.ExternalUser ? "Info_TaskLocked" : "Info_UnlockInstanceLockedByYou") | translate }}</span>
            <button class="ml-auto border-none bg-yellow-500 text-yellow-900 hover:bg-yellow-400" (click)="unlock()">
                <bizz-icon class="size-4" iconName="unlock"></bizz-icon>
                <span>{{ "Unlock" | translate }}</span>
            </button>
        </ng-template>
    </div>
</div>
