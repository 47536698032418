export enum Operators {
  Contains = 1,
  Equals = 2,
  BeginsWith = 3,
  EndsWith = 4,
  DoesntContain = 5,
  NotEquals = 6,
  IsEmpty = 7,
  HasValue = 8,
  SmallerThan = 9,
  GreaterThan = 10,
  SmallerOrEqualThan = 11,
  GreaterOrEqualThan = 12,
  Yes = 14,
  No = 15,
  HasMember = 16,
  DoesNotHaveMember = 17,
  Default = 2, // Add a new member with value 2 which represents the default value
}
