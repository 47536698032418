<div class="modal-container modal-sm text-button">
    <div class="modal-close-container">
        <bizz-icon class="block w-5 h-5 text-button ml-auto md:ml-0"
                   (click)="close()"
                   iconName="xmark" [hover]="true">
        </bizz-icon>
    </div>

    <div class="modal-body w-full overflow-auto gap-4">
        <div class="modal-header font-title justify-center">
            <h1>
                {{ "ElectronicSignature" | translate }}
            </h1>
        </div>

        <hr>

        <div class="flex flex-col">

            <div class="flex">
                {{ "SignedInAs" | translate }}:
                <div class="flex items-center bg-widget rounded-md mx-2 justify-between px-2 gap-2">
                    <bizz-icon iconName="user" class="size-3.5 text-blue-500"></bizz-icon>
                    {{ data.UserName }}
                </div>
            </div>

            <div class="flex">
                @switch (data.SignatureType) {
                    @case (ElectronicSignatureType.Read) {
                        {{ "ProvideElectronicSignatureToRead" | translate }}
                    }
                    @case (ElectronicSignatureType.Edit) {
                        {{ "ProvideElectronicSignatureToEdit" | translate }}
                    }
                    @case (ElectronicSignatureType.Create) {
                        {{ "ProvideElectronicSignatureToCreate" | translate }}
                    }
                    @case (ElectronicSignatureType.Export) {
                        {{ "ProvideElectronicSignatureToExport" | translate }}
                    }
                    @case (ElectronicSignatureType.Delete) {
                        {{ "ProvideElectronicSignatureToDelete" | translate }}
                    }
                    @case (ElectronicSignatureType.RecoverTrashCan) {
                        {{ "ProvideElectronicSignatureToRecover" | translate }}
                    }
                    @case (ElectronicSignatureType.CheckIn) {
                        {{ "ProvideElectronicSignatureToCheckin" | translate }}
                    }
                    @case (ElectronicSignatureType.Download) {
                        {{ "ProvideElectronicSignatureToDownload" | translate }} <strong>{{ data.FileName }}</strong>.
                    }
                    @default {
                        {{ "ProvideElectronicSignatureToExecute" | translate }}
                    }
                }
            </div>


            @if (data.SigningType == ElectronicSignatureSigningType.Email) {
                <div class="flex">
                    {{ "UserTwoFactorEmailCodeSubHeader" | translate }}
                </div>
            }

            @if (data.ErrorDescription) {
                <div class="flex w-full rounded bg-red-600/25 text-button p-4 mt-6 items-center gap-4">
                    <bizz-icon iconName="circle-exclamation" class="size-6 text-danger"></bizz-icon>
                    {{ data.ErrorDescription }}
                </div>

            }
            @if (data.SigningType == ElectronicSignatureSigningType.Device && !data.HasTwoFactorSecret) {
                <div class="flex flex-col w-full rounded bg-warning/25 text-button p-4 mt-6 items-start gap-4 text-sm justify-center">
                    <div class="flex gap-4">
                        <bizz-icon iconName="circle-exclamation"
                                   class="size-8 items-center align-middle text-warning m-auto"></bizz-icon>
                        <div class="flex flex-col">
                            <p class="flex flex-wrap gap-1 justify-start">
                                {{ "TwoFactorSetup_Warning1" | translate }}
                            </p>
                            <p>
                        <span class="text-blue-500 cursor-pointer font-semibold"
                              (click)="redirectToTwoFactorSetup()">{{ "ClickHere" | translate }}
                        </span>
                                {{ "TwoFactorSetup_Warning2" | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            }
        </div>


        <div class="flex justify-center sm:m-6 gap-4" (submit)="confirm()">
            <!-- Show Error Description only when a Password is provided -->
            <div class="sm:w-5/6 w-full flex flex-col text-start justify-center items-center min-h-24 [&>label]:self-start gap-2">
                @switch (data.SigningType) {
                    @case (ElectronicSignatureSigningType.Password) {

                        <label>{{ "Password" | translate }} <span
                                class="text-danger">*</span></label>
                        <input type="password" [(ngModel)]="password" name="password"
                               (keydown.enter)="confirm()"
                               [class.border-red-700]="data.ErrorDescription != null"
                               [class.focus:border-red-700]="data.ErrorDescription != null"
                               class="text-2xl"
                               (ngModelChange)="validate()" autocomplete="new-password" autofocus/>

                    }
                    @case (ElectronicSignatureSigningType.Device) {
                        <bizz-two-factor-token [hasTwoFactorSecret]="data.HasTwoFactorSecret" (confirm)="twoFactorAuth($event)"></bizz-two-factor-token>

                    }
                    @case (ElectronicSignatureSigningType.LoginAndPassword) {
                        <label>{{ "Username" | translate }} <span
                                class="text-danger">*</span></label>
                        <input type="text" class="form-control" [(ngModel)]="userName" name="userName"
                               [class.border-red-700]="data.ErrorDescription != null"
                               [class.focus:border-red-700]="data.ErrorDescription != null"
                               (keydown.enter)="confirm()"
                               (ngModelChange)="validate()" autocomplete="new-password" autofocus/>
                        <label>{{ "Password" | translate }} <span
                                class="text-danger">*</span></label>
                        <input type="password" class="form-control" [(ngModel)]="password" name="password"
                               [class.border-red-700]="data.ErrorDescription != null"
                               [class.focus:border-red-700]="data.ErrorDescription != null"
                               (keydown.enter)="confirm()"
                               (ngModelChange)="validate()" autocomplete="new-password" autofocus/>

                    }
                    @case (ElectronicSignatureSigningType.Email) {
                        <label>{{ "UserTwoFactorEmailCodeHeader" | translate }}
                            <span
                                    class="text-danger">*</span></label>
                        <input type="text" placeholder="123456" size="6"
                               [(ngModel)]="emailCode" (ngModelChange)="validate()" autocomplete="off"
                               name="password"
                               class="text-2xl"
                               (keydown.enter)="confirm()"
                               [class.border-red-700]="data.ErrorDescription != null"
                               [class.focus:border-red-700]="data.ErrorDescription != null"
                               autofocus/>
                    }
                }
            </div>
        </div>
    </div>
    <div class="modal-buttons">
        <div class="flex flex-row justify-end gap-3 text-center">
            <button class="default" (click)="close()">
                {{ "Cancel" | translate }}
            </button>
        </div>

        <bizz-loader-button [class]="'save'" (click)="confirm()" [disabled]="isConfirmDisabled"
                            [isLoading]="isLoading">
            <p>
                {{ "Confirm" | translate }}
            </p>

        </bizz-loader-button>

    </div>


</div>