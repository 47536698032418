import { UserType } from '../../../../../models/ts/user-type.model';
import { StepType } from '../../../../../models/ts/step-type.model';
import { DocumentCheckinType } from '../../../../../models/ts/document-checkin-type.model';
import { CollectionType } from '../../../../../models/ts/collection-type.model';
import { TaskType } from '../../../../../models/ts/task-type.model';
import { StateType } from '../../../../../models/ts/state-type.model';
import { StoreCollectionForm } from '../../../../store/features/forms/forms-state';

// TODO: make reactive (use form signal and compute)
/**
 * Keeps track of the button states of a Collectionform.data.
 * Used to determine which buttons to show in the form.data.
 * @example
 * const buttonStates = new FormButtonStates(form, userType);
 */
export class FormButtonStates {
  showSaveButton: boolean;
  showSaveCloseStepButton: boolean;
  showExecute: boolean;
  showSaveCloseForLightUser: boolean;
  showEditButton: boolean;
  showPropertiesButton: boolean;
  showTrackChangesButton: boolean;
  showHistoryButton: boolean;
  showMonitorButton: boolean;
  showReportButton: boolean;
  showPermissionButton: boolean;
  showFlowStatusButton: boolean;
  showArchiveButton: boolean;
  showDropdownButton: boolean;

  public setButtonStates(form: StoreCollectionForm, userType: UserType): void {

    // Scheduler
    if (form.schedulerData) {
      this.showSaveButton = true;
      return;
    }

    // Save & Execute
    if ((form.data.ReadMode && form.data.StepType != StepType.DistributionStep) || (form.data.IsLocked && form.data.StepType != StepType.DistributionStep)) {
      this.showSaveCloseStepButton = this.showSaveButton = false;
      this.showSaveCloseForLightUser = this.showSaveCloseStepButton;
    } else {
      // document checkin status (checkedout)
      const isCheckedout = form.data.DocumentProperties && form.data.DocumentProperties.DocumentCheckinStatus == DocumentCheckinType.CheckedOut;

      // A light user can save and close a step when: it's a start step and it is not a document collection with a workflow - if it is a distribution step (notificationtask)  (SAVE AND CLOSE STEP BUTTON IS SHOWN)
      const showSaveCloseForLightUser = userType === UserType.LightUser && ((form.data.StepType === StepType.StartStep && ((form.data.CollectionType == CollectionType.ControlledDocument && !form.data.HasWorkflow) || form.data.CollectionType == CollectionType.Workflow)) || form.data.TaskType == TaskType.NotificationTask);
      // A light user can save an instance when: it's a non-document collection without a workflow (SAVE BUTTON IS SHOWN)
      const showSaveForLightUser = userType === UserType.LightUser && !form.data.HasWorkflow && form.data.HasValidLicenseToEditCollection;

      // All other user scan save and close a step when: there is a workflow (SAVE AND CLOSE STEP BUTTON IS SHOWN)
      const showSaveCloseForOtherUser = userType !== UserType.LightUser && form.data.HasWorkflow && form.data.HasValidLicenseToEditCollection;
      // Otherwise a save button will be shown (SAVE BUTTON IS SHOWN)

      this.showSaveCloseStepButton = form.data.StepVersionsID > 0 && !isCheckedout && (showSaveCloseForLightUser || showSaveCloseForOtherUser);
      this.showSaveButton = (userType !== UserType.LightUser && !this.showSaveCloseStepButton) || showSaveForLightUser;
      //$scope.isLinked = form.data.byCrossLinkCollectionsID > 0;

      this.showSaveCloseForLightUser = showSaveCloseForLightUser;
    }

    // Execute text
    this.showExecute = form.data.TasksID > 0;

    // Edit
    this.showEditButton = form.data.ReadMode && !!form.data.Permissions?.['CanEditProperties'] && form.data.State == StateType.Active
      && !form.data.IsLocked && form.data.HasValidLicenseToEditCollection && !this.showExecute;

    // Properties
    this.showPropertiesButton = form.data.VersionsID > 0 && form.data.CanViewProperties && userType !== UserType.LightUser && form.schedulerData == undefined;
  
    // Track Changes
    this.showTrackChangesButton = form.data.VersionsID > 0 && !!form.data.Permissions?.['CanAccessTrackChanges'];

    // History
    this.showHistoryButton = form.data.VersionsID > 0 && !!form.data.Permissions?.['CanAccessHistory'];

    // Monitor
    this.showMonitorButton = form.data.VersionsID > 0 && form.data.CanViewMonitor;

    // Report
    this.showReportButton = form.data.VersionsID > 0 && !!form.data.Permissions?.['CanGenerateReport'] && form.data.State == StateType.Active;

    // Permissions
    this.showPermissionButton = !!form.data.Permissions?.['CanSetPermissions'];

    // Flow Status
    this.showFlowStatusButton = (form.data.HadWorkflow || form.data.TasksID > 0) && form.data.InstancesID > 0 && !!form.data.Permissions?.['CanAccessHistory'];

    // Archive
    this.showArchiveButton = form.data.VersionsID > 0 && !!form.data.Permissions?.['CanAccessArchives'];

    // TODO: Jonas, update after external users code is migrated
    // if (form.data.modal || (form.data.FormType == FormType.ExternalForm && userType == UserType.ExternalUser))
    //   this.showDropdownButton = false;
    // else
    this.showDropdownButton = this.showPropertiesButton || this.showTrackChangesButton || this.showHistoryButton || this.showMonitorButton || this.showReportButton || this.showPermissionButton || this.showFlowStatusButton || this.showArchiveButton;
  }
}
